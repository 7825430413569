//LIBRARY
import React, {Fragment, useEffect, useState, useContext, Profiler} from 'react';
import { BrowserRouter as Router,
    Switch,
    Route, 
    useParams, 
    useRouteMatch } from 'react-router-dom';

//clients
import ClientIntroProfile from './clientIntroProfile'
import ClientMainPage from './clientMainPage'
import ClientCompletedListing from './clientCompletedLists'
import ClientProfileUpdater from './clientProfileUpdate'
import ClientSystemSetting from './clientProfileSysSetting'
import ClientProjectActivity from './clientProjectActivity';
import ClientMessageBoard from './clientMessageBoard'
import ClientProjectMarketplace from './clientBuysMarketplace'
import ClientDepositPaypalSuccess from './clientDepositPaypalSuccess'
import ClientDepositMongoSuccessPage from './clientPaymongoSuccess';


const ClientPagesAndAPILoader = (props)=>{
    const {user, userid, email, cbalance, orders} = props
    const [switchToBuy, setSwitchToBuy] = useState(false)

    let matchProfile = useRouteMatch("/dashboard/profile") //conditions
    let matchSettings = useRouteMatch("/dashboard/settings") //conditions
    let matchPaypal = useRouteMatch("/dashboard/paypal") //conditions
    let matchMongo = useRouteMatch("/dashboard/paymongo") //conditions
    let matchProjectView = useRouteMatch({
        path:"/dashboard/project/:projectid",
        strict:true,
        sensitive:true
    }) //conditions
    let {path} = useParams()

    return (
        <Fragment>
            {!matchProfile && !matchSettings && !matchPaypal && !matchProjectView && !matchMongo?<Fragment>
                            {switchToBuy===true?
                            <Fragment>
                                <section style={{position:"relative", marginTop:50}}>
                                <button onClick={()=>{setSwitchToBuy(false)}} className='switch-account' style={{position:"relative", float:"right"}}><svg style={{position:"relative", top:2.2, left:-3, width:16}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                </svg>EXIT MARKETPLACE</button><br/>
                                </section>
                            </Fragment>:
                            <Fragment>
                                <section style={{position:"relative", marginTop:50}}>
                                <button onClick={()=>{setSwitchToBuy(true)}} className='switch-buyer' style={{position:"relative", float:"right"}}>BUY NEW PROJECT</button><br/>
                                </section>
                            </Fragment>}
                            {switchToBuy===true?
                            <ClientProjectMarketplace />:
                            <Fragment>
                                <ClientIntroProfile user = {user} balance={cbalance} userid = {userid} email={email} />
                                <ClientMainPage user = {user} userid = {userid} corders={orders} />
                                <ClientCompletedListing user = {user} userid = {userid} corders={orders} />
                                <ClientMessageBoard />
                            </Fragment>
                            }
                            
            </Fragment>:''}
            {matchSettings?<ClientSystemSetting />:''}
            {matchProfile?<ClientProfileUpdater user = {user} userid = {userid} email={email} />:''}
            {matchPaypal?<ClientDepositPaypalSuccess user = {user}/>:''}
            {matchMongo?<ClientDepositMongoSuccessPage />:''}
            {matchProjectView?
            <Fragment>
                {switchToBuy===true?
                <Fragment>
                    <section style={{position:"relative", marginTop:50}}>
                    <button onClick={()=>{setSwitchToBuy(false)}} className='switch-account' style={{position:"relative", float:"right"}}><svg style={{position:"relative", top:2.2, left:-3, width:16}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>EXIT MARKETPLACE</button><br/>
                    </section>
                </Fragment>:
                <Fragment>
                    <section style={{position:"relative", marginTop:50}}>
                    <button onClick={()=>{setSwitchToBuy(true)}} className='switch-buyer' style={{position:"relative", float:"right"}}>BUY NEW PROJECT</button><br/>
                    </section>
                </Fragment>}
                {switchToBuy===true?
                <ClientProjectMarketplace />:
                <ClientProjectActivity user= {user} userid= {userid} projectID = {matchProjectView.params.projectid} corders={orders}/>}
            </Fragment>:
            ''}
        </Fragment>
    );
  }

  export default ClientPagesAndAPILoader;