import react, {Fragment} from 'react'
import { useHistory } from 'react-router'

const MobileHLanding = ()=>{
    const historian = useHistory()
    return(
        <Fragment>
            <section className="mobile-landing">
                    <img width="100%" src = "../animated/mobile_landing_onlyBG.svg"/>
                    <section style={{position:"absolute", top:70, margin:"auto", left:"auto", width:"100%"}}>
                        <h1 style={{position:"relative", top:50, margin:"auto", color:"white", fontSize:"44pt", fontFamily:"Segoe UI", fontWeight:600}}>WE</h1>
                        <h1 style={{position:"relative", top:10, margin:"auto", color:"white", fontSize:"77pt", fontFamily:"Segoe UI", fontWeight:600}}>DESIGN</h1>
                        <h1 style={{position:"relative", top:-40, margin:"auto", color:"white", fontSize:"71pt", fontFamily:"Segoe UI", fontWeight:600}}>CREATE</h1>
                        <h1 style={{position:"relative", left:0, top:-50, margin:"auto", color:"white", fontSize:"22pt", fontFamily:"Segoe UI", fontWeight:600}}>ILLUSTRATION</h1>
                        <h1 style={{position:"relative", left:0, top:-55, margin:"auto", color:"white", fontSize:"22pt", fontFamily:"Segoe UI", fontWeight:600}}>GRAPHIC</h1>
                        <h1 style={{position:"relative", left:0, top:-60, margin:"auto", color:"white", fontSize:"22pt", fontFamily:"Segoe UI", fontWeight:600}}>WEB</h1>

                        <section style={{position:"relative", bottom:30, margin:"auto", width:"60%"}}>
                        <button style={{position:"relative", backgroundColor:"black", color:"white", width:"100%",  padding:"10px 10px 10px 10px", margin:"auto", left:"auto", borderRadius:30, borderWidth:0, fontFamily:"Segoe UI", fontWeight:"500", fontSize:"22pt"}} onClick={(e)=>{e.preventDefault(); historian.push('/services')}}>
                        ORDER NOW
                        </button>
                    </section>
                    </section>
            </section>
        </Fragment>        
    )
}

export default MobileHLanding