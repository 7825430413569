//COMPONENTS
import React, {Fragment, useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {projectXViewToggle, currPrimary, defaultCurrBalance, viewUser} from '../../../redux/slices/counter'
import {accessSwitch} from '../variables'
import {
    ADUX_ALLAPIL, 
    ADUX_ALLAPIW, 
    ADMINTO_S_ASSIGNER_SPROL, 
    ADMINTO_S_ASSIGNER_SPROW,
    ADM_PROCOMP_MARKERL,
    ADM_PROCOMP_MARKERW,
    ADM_DELORDERL,
    ADM_DELORDERW,
    ORDER_M_ACTIVEL,
    ORDER_M_ACTIVEW
} from '../../../config'

const ProjectListings = ()=>{
    const [userItem, setUserItem] = useState([])
    const [userUniqueID, setUserUniqueID] = useState('')
    const [assignTo, setAssignTo] = useState(false) 
    const [previewProject, setPreviewProject] = useState(false)
    const [completedViewer, setCompletedViewer] = useState(false)
    const [triggerChangeProg, setTriggerChangerProg] = useState(false) //for Slider Progress
    const currencyFiat = useSelector(state => state.counter.currFiat)

    const dispatch = useDispatch()
    const currPrime = ()=>{
        setInterval(()=>{
            dispatch(currPrimary())
            dispatch(defaultCurrBalance())
            dispatch(viewUser())
        }, 500)
    }
    const xViewer = (bole)=>{
        dispatch(projectXViewToggle(bole))
    }
    //PROJECT MANUAL PROGRESS SLIDER
    const [slideValue, setSlideValue] = useState(0)
    const aduxSwitcher =(bol)=>{
        if(bol===true){
            return ADUX_ALLAPIW
        }else{
            return ADUX_ALLAPIL
        }
    }
    const ordersLanded = async()=>{
        await fetch(aduxSwitcher(pitch)) //true to Web-based MongoDB
        .then((responsive)=>responsive.json())
        .then((data)=>{
            const wanton = data.data
            const drearies = []
            for(const key in wanton){
                drearies.push({
                    id:key,
                    uid:wanton[key].userUniqueID,
                    username: wanton[key].username,
                    email: wanton[key].email,
                    role: wanton[key].role,
                    dateSince: wanton[key].dateCreated,
                    orders:wanton[key].orders, 
                    payments:wanton[key].paymentSources,
                    orders:wanton[key].orders
                })
            }
            setUserItem(drearies)
        })
    }

    const updateWorkProgressSwitcher = (pitch)=>{
        if(pitch===true){
            return "https://salty-inlet-81949.herokuapp.com/api/j5d-client-order-progress-update/" //DELEACC_CW
        }else{
            return "http://localhost:8888/api/j5d-client-order-progress-update/" //DELEACC_CL 
        }
    }
    const getWorkProgressUpdated = async()=>{
        const progressRes = await fetch(updateWorkProgressSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userUniqueID, orderID:projectData.orderID, progress:slideValue})
        })
        const progressRec = await progressRes.json()
        const {status, data} = progressRec
        if(status ==="Successful!"){
            console.log("<<Progress Updated>>")
            window.location.reload(); //<--temporary
        }
    }
    const handleSliderChange = (event, newValue) => {
        setSlideValue(newValue)
        setTriggerChangerProg(true) //<-- to prevent from dismissing the dialog box
        //console.log("Slide Value: "+slideValue)
    }
    
      
    const valuetext = (value)=>{
        return `${value}%`;
    }
    const DiscreteSliderMarks = (props)=>{
        const marks = [
            {
              value: props.value,
              label: props.value+'%',
            },
            {
              value: 33
            },
            {
              value: 47
            },
            {
              value: 62
            },
            {
              value: 77
            },
            {
              value: 92
            },
            {
              value: 100
            }
          ]
        return (
        <ThemeProvider theme={theme}>
          <Box sx={{ width: "100%" }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={props.value}
              getAriaValueText={valuetext}
              step={null}
              valueLabelDisplay="auto"
              marks={marks}
              color="natural"
              onChange={handleSliderChange}
            />
          </Box>
        </ThemeProvider>
        );
    }

    const pitch = accessSwitch //true to Web-based MongoDB
    const switcher =(bol)=>{
        if(bol===true){
            return ADUX_ALLAPIW
        }else{
            return ADUX_ALLAPIL
        }
    }
    const assignerSwitcher =(bol)=>{
        if(bol===true){
            return ADMINTO_S_ASSIGNER_SPROW
        }else{
            return ADMINTO_S_ASSIGNER_SPROL
        }
    }
    const markerSwitcher =(bol)=>{
        if(bol===true){
            return ADM_PROCOMP_MARKERW
        }else{
            return ADM_PROCOMP_MARKERL
        }
    }
    const orderDeleteSwitcher =(bol)=>{
        if(bol===true){
            return ADM_DELORDERW
        }else{
            return ADM_DELORDERL
        }
    }
    const reactiveSwitcher =(bol)=>{
        if(bol===true){
            return ORDER_M_ACTIVEW
        }else{
            return ORDER_M_ACTIVEL
        }
    }
    const usersListAPI = async()=>{
        await fetch(switcher(pitch)) //true to Web-based MongoDB
        .then((responsive)=>responsive.json())
        .then((data)=>{
            const wanton = data.data
            const drearies = []
            for(const key in wanton){
                drearies.push({
                    id:key,
                    balance:wanton[key].balance,
                    uid:wanton[key].userUniqueID,
                    username: wanton[key].username,
                    email: wanton[key].email,
                    role: wanton[key].role,
                    dateSince: wanton[key].dateCreated,
                    orders:wanton[key].orders, 
                    payments:wanton[key].paymentSources,
                    orders:wanton[key].orders
                })
            }
            setUserItem(drearies)
        })
    }
    useEffect(()=>{
        ordersLanded()
        usersListAPI()
    },[])
    
    let totalPupil = 0
    let completedT = 0
    const totallingAllProjects = (userItem)=>{
        let vuser = []
        userItem.map(zuser=>{
            vuser.push(zuser.orders)
            totalPupil += zuser.orders.length
        })
        
    }
    totallingAllProjects(userItem)

    const [currCurrency, setCurrCurrency] = useState('')
    const getCurrency = (userItem)=>{
        //console.log(userItem)
        userItem.map((uzer)=>{
            if(uzer.balance === null || uzer.balance === undefined){
                    console.log("Nothing!")
            }else{
                uzer.balance.map((curr)=>{
                    if(curr.primary === true){
                        setCurrCurrency(curr.currency)
                    }
                })
            }
        })
    }

    //ASSIGNING ORDER TO AN EMPLOYEE
    const [projectData, setProjectData] = useState({
        indexKey:0,
        userid:'',
        currency:'', //not in use
        orderID:'',
        categoryChosen: '',
        phpPrice: '',
        usdPrice: '',
        addedMsg: '',
        addedImg:'',
        dateOrdered: '',
        progress:0,
        quantity:0,
        paid:''
    })
    const [processingAssignment, setProcessingAssignment] = useState(false)
    const [selectedWorker, setSelectedWorker] = useState('')
    
    const [successfulConfirmation, setSuccessfulConfirmation] = useState(false)
    const assignProjectToDesignatedEmployee = async(assigner)=>{
        console.log(assigner)
        const tellRecordQuery = await fetch(assignerSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(assigner)
        })
        const respondedRecovery = await tellRecordQuery.json()
        const {status, data} = respondedRecovery 
        if(status === "Successful!"){
            setProcessingAssignment(false)
            setSuccessfulConfirmation(true)
        }
    }

    const getToAssignEmployee = ()=>{
        
        setAssignTo(false);
        setProcessingAssignment(true);
        
        //console.log({userUniqueID:userUniqueID, assignedEmployee:selectedWorker, targetIndexPosition:projectData.indexKey.key})
        assignProjectToDesignatedEmployee({
            userUniqueID:userUniqueID, 
            assignedEmployee:selectedWorker, 
            orderID:projectData.orderID, 
            targetIndexPosition:projectData.indexKey.key
        })
    }
    const getEmployeeSelected = username=>event=>{
        event.preventDefault()
        username = event.target.value
        setSelectedWorker(username)
    }
    const SuccessfulConfirmation = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessingAssignment(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "150px", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)", padding:"30px 0px 20px 0px", textAlign:"center",}}>
                            <span style={{position:"relative", color:"#16a068", fontSize:"14pt"}}>Successfully Assigned to</span>  <br/>
                            <span style={{position:"relative", color:"#000", fontWeight:"bold", fontSize:"33pt"}}>{selectedWorker}</span><br/><br/>
                            <button style={{position:"relative", width:"60%", backgroundColor:"#16a068", color:"#fff", borderWidth:0, borderRadius:20, padding:"5px 10px 5px 10px"}} onClick={()=>{setSuccessfulConfirmation(false); xViewer(false); xViewer(true)}}>Exit</button>
                    </section>
                </section>
            </Fragment>
        )
    }
    const ProcessAssignment = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessingAssignment(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                            <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                            <span style={{position:"absolute", color:"#fff", top:40, right:85}}>Assigning Project to</span>  
                            <span style={{position:"absolute", color:"#fff", fontSize:"16pt", fontWeight:600, top:57, right:85}}>{selectedWorker}</span> 
                    </section>
                </section>
            </Fragment>
        )
    }
    const AssignToWorker = (props)=>{
        const {details} = props
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.2)"}}> 
                </section>
                <section style={{position:"fixed", left:0, top:"10%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"40%", height: "90%", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)",  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", padding:"10px 0px 20px 0px"}}>
                        <button onClick={()=>{setAssignTo(false); setSelectedWorker('')}} className='assignto-close-btn'>X</button>
                        <section style={{position:"relative", margin:"20px 20px 5px 20px", borderColor:"#f1f1f1", borderWidth:1, borderStyle:"solid"}}>
                            <section style={{position:"relative", top:-13, margin:"auto", width:"auto", textAlign:"center", padding:"5px 15px 5px 15px", color:"#16a068", fontSize:"10pt"}}>
                                <span style={{backgroundColor:"white", padding:"5px 15px 5px 15px", color:"#16a068", fontSize:"10pt"}}>
                                    PROJECT #{details.orderID.toUpperCase()}
                                </span>
                            </section>
                            <section style={{position:"relative", margin:"auto", width:"75%", marginTop:0, marginBottom:30}}>
                                <h3 style={{position:"relative", textAlign:"center"}}>Choose a service provider for this project</h3>
                                <select onChange={getEmployeeSelected('username')} style={{position:"relative", textAlign:"center", margin:"auto", width:"100%", backgroundColor:"#000", color:"#fff", borderWidth:0, borderColor:"white"}}>
                                    {selectedWorker===''? <option value="">None Selected</option>:<option value="">You've chosen {selectedWorker}</option>}
                                    {userItem.map(oneUser => {
                                        if(oneUser.role === 'employee'){
                                            return(
                                                <Fragment>
                                                    <option style={{backgroundColor:"#fff", color:"#16a068"}} name={oneUser.username} value={oneUser.username}>{oneUser.username}</option>
                                                </Fragment>
                                            )
                                        }
                                    })}
                                </select><br/>
                            </section>
                            <section style={{position:"relative", margin:"auto", width:"60%", marginTop:0, marginBottom:25}}>
                                {selectedWorker===''?
                                <button className='assigment-confirm-btn'>
                                        Confirm Assigment
                                </button>:
                                <button onClick={getToAssignEmployee} className='assigment-confirm-btn'>
                                        Confirm Assigment
                                </button>}
                                <button onClick={()=>{setAssignTo(false); setSelectedWorker('')}} className='assigment-confirm-btn'>
                                        Cancel Attempt
                                </button>
                            </section>
                            
                        </section>
                    </section>
                </section>
            </Fragment>
        )
    }
    //CIRCULAR PROGRESS
    const theme = createTheme({
        status: {
          danger: '#e53e3e',
        },
        palette: {
          primary: {
            main: '#0971f1',
            darker: '#053e85',
          },
          natural: {
            main: '#16a068',
            contrastText: '#fff',
          },
        },
    })
    const CircularProgressWithLabel=(props)=>{
        return (
        <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" color="natural" {...props} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                color:'red',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(props.value)}%`}
              </Typography>
            </Box>
          </Box>
        </ThemeProvider>
        );
      }
      
      CircularProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate variant.
         * Value between 0 and 100.
         * @default 0
         */
        value: PropTypes.number.isRequired,
      };
    const PreviewProjectDetails = (props)=>{
        const [progress, setProgress] = React.useState(66);
        const {details} = props 
        let amountx = 0
        if(details.paid === null || details.paid === undefined){
            return amountx
        }else{
            details.paid.map((money)=>{
                amountx+=money.amount
            })
        }
        // const getCorrupted = (userItem)=>{
        //     setTimeout(()=>{
        //         userItem.map((user)=>{
        //             if(user.uid === userUniqueID){
        //                 console.log("Wowow "+user.uid)
        //                 user.balance.map((current)=>{
        //                     if(current.primary === true){
        //                         setCorfew(current.currency)
        //                     }
        //                 })
        //             }
        //         })
        //     }, 300)
        // }
        const getCorrupted = (userItem)=>{
            //console.log(userItem)
                let itom = ''
                userItem.map((user)=>{
                    if(user.uid === userUniqueID){
                        if(user.balance === null || user.balance === undefined){
                            itom = ''
                        }else{
                            user.balance.map((current)=>{
                                if(current.primary === true){
                                    itom += current.currency
                                }
                            })
                        }
                    }
                })
                return itom

        }
        const UpdateProjectProgress = ()=>{
            return(
                <Fragment>
                    <section onClick = {()=>{setAssignTo(false)}} style={{position:"fixed", left:0, top:0, zIndex:9, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.2)"}}> 
                    </section>
                    <section style={{position:"fixed", left:0, top:0, zIndex:9, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                        <section style={{position:"relative", margin:"auto", zIndex:9, margin:"auto", top:50, width:"20%", padding:"20px 20px 20px 20px",   borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)",  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", padding:"10px 0px 20px 0px"}}>
                            <button style={{position:"absolute", top: 10, right:10, border:"none", padding:"5px 5px 5px 5px", backgroundColor:"#fff", cursor:"pointer"}} onClick={()=>{console.log("Closed With: "+ slideValue); getWorkProgressUpdated(); setTriggerChangerProg(false); }}>X</button>
                            {/* Progress Change Lever Section */}
                            <section style={{position:"relative", margin:"auto", width:"100%", textAlign:"center"}}>
                                <h3>Change Progress Manually</h3>
                                <section style={{position:"relative", margin:"auto", width:"80%"}}>
                                    <DiscreteSliderMarks value={details.progress}/>
                                </section>
                            </section>
                        </section>
                    </section>
                </Fragment>
            )
        }
        return(
            <Fragment>
                <section onClick = {()=>{setAssignTo(false)}} style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.2)"}}> 
                </section>
                <section style={{position:"fixed", left:0, top:"10%", zIndex:2, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:2, margin:"auto", width:"69%", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)",  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", padding:"10px 0px 20px 0px"}}>
                        <button onClick={()=>{setPreviewProject(false)}} className='assignto-close-btn'>X</button>
                        <section style={{position:"relative", margin:"20px 20px 20px 20px", borderColor:"white", width:"95%", height:300, borderWidth:1, borderStyle:"solid", paddingBottom:20}}>
                            <section style={{position:"relative"}}>
                                <section style={{position:"absolute", left:20}}>
                                    <span style={{fontSize:"16pt"}}>{details.categoryChosen.toUpperCase()}</span> <br/>
                                    <span style={{fontSize:"10pt", color:"#16a068"}}>PROJECT #{details.orderID.toUpperCase()}</span><br/>
                                </section>
                                <section style={{position:"absolute", right:480}}>
                                    <span style={{fontSize:"16pt"}}>BUDGET</span> <br/>
                                    <span style={{position:"relative", color:"#16a068", fontWeight:"bold", fontSize:"24pt"}}>{getCorrupted(userItem)==="PHP"?<Fragment>₱{details.phpPrice}</Fragment>:<Fragment>${details.usdPrice}</Fragment>}</span>
                                </section>
                                <section style={{position:"absolute", right:300, cursor:"pointer"}}>
                                    <span style={{fontSize:"16pt"}}>PAID</span> <br/>
                                    <span style={{position:"relative", color:"#16a068", fontWeight:"bold", fontSize:"24pt"}}>{getCorrupted(userItem)==="PHP"?<Fragment>₱{amountx*55.00}</Fragment>:<Fragment>${amountx}</Fragment>}</span>
                                </section>
                                <section style={{position:"absolute", right:150}}>
                                    <span style={{fontSize:"16pt"}}>QUANTITY</span> <br/>
                                    <span style={{position:"relative", color:"#16a068", left:33, fontWeight:"bold", fontSize:"27pt"}}>{details.quantity}</span>
                                </section>
                                <section style={{position:"absolute", top:0, right:20}}>
                                    <span style={{fontSize:"16pt"}}>STATUS</span> <br/>
                                    <section style={{position:"absolute", top:40, left:15, cursor:"pointer"}} onClick={()=>{setTriggerChangerProg(true)}}>
                                        <CircularProgressWithLabel value={details.progress} />
                                    </section>
                                </section>
                            </section>
                            <section style={{position:"relative", top:120, borderWidth:1, borderStyle:"solid", borderColor:"#dfdfdf", borderRadius:7, padding:20}}>
                                <span style={{fontSize:"16pt",color:"black"}}>DESCRIPTION</span><br/>
                                <span style={{fontSize:"10pt",color:"grey"}}>{details.addedMsg}</span><br/>
                            </section>
                        </section>
                    </section>
                </section>
                {triggerChangeProg === true? <UpdateProjectProgress />: ''}
            </Fragment>
        )
    }
    //VIEW OF THE ACTIVE VIEWERS
    const [activeProjectViewer, setActiveProjectViewer] = useState(false)
    let activeS = 0
    const ActiveProjectsViewer = ()=>{
        return(
            <Fragment>
                <section style={{position:"relative",width:"100%"}}>
                    <section style={{marginTop:70}}>
                        <section>
                            <h3 style={{position:"absolute", top:-70, left:40, textAlign:"left", color:"#16a068"}}>Active Projects ({activeS})</h3>
                            <button className='completed-switcher-btn' onClick={()=>{setActiveProjectViewer(false)}} style={{position:"absolute", top:-50, right:28}}>
                                Switch to All Projects
                            </button>
                        </section>
                        {userItem.map(zuser=>{
                            return(
                                <Fragment>
                                    {zuser.orders.map((order, key)=>{
                                    if(order.status === 'active'){
                                        activeS+=key
                                        if(key===0){
                                            activeS+=key+1
                                        }
                                        return(
                                            <Fragment>
                                            <ul className='user-orders'>
                                                <span style={{float:"left", fontSize:"10pt", color:"#16a068"}}>Owned By {zuser.username}</span>
                                                <span style={{position:"relative", float:"right", fontSize:"10pt", color:"#16a068", fontStyle:"italic", right:40}}>Order No. {order.orderID.toUpperCase()}</span>
                                              <li style={{borderWidth:1, borderColor:"#f1f1f1", borderStyle:"solid", paddingTop:10, paddingLeft:10}}>
                                                <ul style={{top:0, marginLeft:30}}>
                                                    <li style={{marginBottom:10}}>
                                                        <section style={{float:"left", marginBottom:6, textAlign:"left"}}>
                                                            <span style={{fontWeight:"bold"}}>{order.categoryChosen}</span> US${order.usdPrice} 
                                                            <span style={{color:"#16a068"}}> <b>Handled By</b> {order.handledBy===''?'No One':order.handledBy}</span><br/>
                                                            <span style={{fontSize:"8pt", color:"#16a068"}}>Ordered On: {order.dateOrderedWithTimezone}</span>
                                                        </section><br/>
                                                        <section style={{position:"relative", top:-5, left:0, float:"left", marginTop:10, marginBottom:10}}>
                                                                        <button onClick = {(e)=>{e.preventDefault(); setUserUniqueID(zuser.uid); setAssignTo(true); setProjectData({
                                                                            indexKey: {key},
                                                                            orderID: order.orderID,
                                                                            categoryChosen: order.categoryChosen,
                                                                            phpPrice: order.phpPrice,
                                                                            usdPrice: order.usdPrice,
                                                                            addedMsg: order.addedMsg,
                                                                            addedImg: order.addedImg,
                                                                            paid:order.paid,
                                                                            dateOrderedWithTimezone: order.dateOrderedWithTimezone
                                                                        })}} className='admin-userorder-console-btn-assigner-btn'>Assign To</button>
                                                                        <button onClick = {(e)=>{e.preventDefault(); setPreviewProject(true); setProjectData({
                                                                            indexKey: {key},
                                                                            orderID: order.orderID,
                                                                            categoryChosen: order.categoryChosen,
                                                                            phpPrice: order.phpPrice,
                                                                            usdPrice: order.usdPrice,
                                                                            addedMsg: order.addedMsg,
                                                                            addedImg: order.addedImg,
                                                                            dateOrderedWithTimezone: order.dateOrderedWithTimezone,
                                                                            progress: order.progress
                                                                        })}}className='admin-userorder-console-btn-preview-btn'>Preview</button>
                                                                        <button onClick = {(e)=>{
                                                                            e.preventDefault();
                                                                            getToMarkComplete();
                                                                        }}
                                                                        className='admin-userorder-console-btn-marker-btn'>Mark Complete</button>
                                                                        <button onClick = {(e)=>{
                                                                             e.preventDefault(); 
                                                                             setUserUniqueID(zuser.uid); 
                                                                             setProjectData({
                                                                                 orderID: order.orderID
                                                                             }); 
                                                                             setDeleteOrderProcess(true);
                                                                             getToDeleteSingleProject();
                                                                             setActiveProjectViewer(true);
                                                                        }}
                                                                        className='admin-userorder-console-btn-delete-btn'>Delete</button>
                                                        </section>
                                                    </li>
                                                </ul>
                                             </li>
                                            </ul>
                                            </Fragment>
                                        )
                                    }
                                })}
                                
                                </Fragment>
                            )
                        })}
                    </section>
                </section>
            </Fragment>
        )
    }
    const [toReactivatedOrder, setToReactivatedOrder] = useState(false)
    const [processReactivate, setProcessReactivate] = useState(false)
    const [errorReActivatingOrder, setErrorReActivatingOrder] = useState(false)
    const [orderIDid, setOrderIDid] = useState('')
    const [marker, setMarker] = useState('active')
    const toReactivateSingleProject = async(reactiveId)=>{
        console.log(reactiveId)
        
        const reactivateOrderQuery = await fetch(reactiveSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(reactiveId)
        })
        const respondingReactivation = await reactivateOrderQuery.json()
        const {status, data} = respondingReactivation 
        if(data.modifiedCount>0){
            setProcessReactivate(false)
            setToReactivatedOrder(true)
        }else{
            setProcessReactivate(false)
            setErrorReActivatingOrder(true)
        }
    }

    const getToReactivateOrder = ()=>{
        setProcessReactivate(true)
        toReactivateSingleProject({userUniqueID:userUniqueID, orderID:orderIDid, marked:marker})
    }
    const ProcessReactivation = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{
                    setProcessReactivate(false);  
                    }}
                    style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                            <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                            <span style={{position:"absolute", color:"#fff", top:40, right:85}}>Reactivating Order No.</span> <br/> 
                            <span style={{position:"absolute", color:"#fff", fontSize:"16pt", fontWeight:600, top:57, right:85}}>{orderIDid.toUpperCase()}</span> 
                    </section>
                </section>
            </Fragment>
        )
    }
    const ReactivatedConfirmation = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessingAssignment(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "150px", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)", padding:"30px 0px 20px 0px", textAlign:"center",}}>
                            <span style={{position:"relative", color:"#16a068", fontSize:"14pt"}}>Successfully</span>  <br/>
                            <span style={{position:"relative", color:"#000", fontWeight:"bold", fontSize:"33pt"}}>REACTIVATED</span><br/><br/>
                            <button style={{position:"relative", width:"60%", backgroundColor:"#16a068", color:"#fff", borderWidth:0, borderRadius:20, padding:"5px 10px 5px 10px"}} onClick={()=>{
                                setCompletedViewer(true)
                                setProcessReactivate(false);
                                //window.location.reload(true);
                                xViewer(false);
                                setTimeout(xViewer(true),1000);
                                }}>Exit</button>
                    </section>
                </section>
            </Fragment>
        )
    }
    const ErrorReactivatingOrder = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setErrorReActivatingOrder(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"30%", height: "100px", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)", padding:"30px 0px 20px 0px", textAlign:"center",}}>
                            <span style={{position:"relative", color:"#16a068", fontSize:"14pt"}}>Error Reactivating No.</span>  <br/>
                            <span style={{position:"relative", color:"#000", fontWeight:"bold", fontSize:"12pt"}}>{orderIDid.toUpperCase()}</span><br/><br/>
                            <button style={{position:"relative", width:"60%", backgroundColor:"#16a068", color:"#fff", borderWidth:0, borderRadius:20, padding:"5px 10px 5px 10px"}} onClick={()=>{
                                setProcessReactivate(false);
                                //window.location.reload(true);
                                xViewer(false);
                                setTimeout(xViewer(true),1000);
                                }}>Exit</button>
                    </section>
                </section>
            </Fragment>
        )
    }
    //VIEW OF THE COMPLETED ORDERS
    const CompletedViewer = ()=>{
        return(
            <Fragment>
                <section style={{position:"relative",width:"100%"}}>
                    <section style={{marginTop:70}}>
                        <section>
                            <h3 style={{position:"absolute", top:-70, left:40, textAlign:"left", color:"#16a068"}}>Completed Projects ({completedT})</h3>
                            <button className='completed-switcher-btn' onClick={()=>{setCompletedViewer(false)}} style={{position:"absolute", top:-50, right:28}}>
                                Switch to All Projects
                            </button>
                        </section>
                        {userItem.map(zuser=>{
                            return(
                                <Fragment>
                                    {zuser.orders.map((order, key)=>{
                                    if(order.status === 'completed'){
                                        completedT+=key
                                        if(key===0){
                                            completedT+=key+1
                                        }
                                        return(
                                            <Fragment>
                                            <ul onLoad={()=>{setOrderIDid(order.orderID)}} className='user-orders'>
                                                <span style={{float:"left", fontSize:"10pt", color:"#16a068"}}>Owned By {zuser.username}</span>
                                                <span style={{position:"relative", float:"right", fontSize:"10pt", color:"#16a068", fontStyle:"italic", right:40}}>Order No. {order.orderID.toUpperCase()}</span>
                                              <li style={{borderWidth:1, borderColor:"#f1f1f1", borderStyle:"solid", paddingTop:10, paddingLeft:10}} >
                                                <ul style={{top:0, marginLeft:30}}>
                                                    <li style={{marginBottom:10}} >
                                                        <section style={{position:"relative", marginBottom:6, textAlign:"left"}}>
                                                            <span style={{fontWeight:"bold"}}>{order.categoryChosen}</span> US${order.usdPrice} 
                                                            <span style={{color:"#16a068"}}> <b>Handled By</b> {order.handledBy===''?'No One':order.handledBy}</span><br/>
                                                            <span style={{fontSize:"8pt", color:"#16a068"}}>Ordered On: {order.dateOrderedWithTimezone}</span>
                                                        </section>
                                                        <section style={{position:"relative", top:-5, left:0, marginTop:10, marginBottom:10, width:"100%"}}>
                                                            <button onClick = {(e)=>{e.preventDefault(); setPreviewProject(true); setProjectData({
                                                                orderID: order.orderID,
                                                                categoryChosen: order.categoryChosen,
                                                                phpPrice: order.phpPrice,
                                                                usdPrice: order.usdPrice,
                                                                addedMsg: order.addedMsg,
                                                                addedImg: order.addedImg,
                                                                paid:order.paid,
                                                                dateOrderedWithTimezone: order.dateOrderedWithTimezone,
                                                                progress:order.progress
                                                            })}} className='admin-userorder-console-btn-assigner-btn'>Preview</button>
                                                            <button onMouseOver={()=>{
                                                                setOrderIDid(order.orderID);
                                                                setUserUniqueID(zuser.uid);
                                                                setMarker('active');
                                                            }} onClick = {(e)=>{
                                                                e.preventDefault();
                                                                getToReactivateOrder();
                                                            }}className='admin-userorder-console-btn-marker-btn' style={{borderTopRightRadius:20, borderBottomRightRadius:20}}>ReActivate</button>
                                                        </section>
                                                    </li>
                                                </ul>
                                             </li>
                                            </ul>
                                            </Fragment>
                                        )
                                    }
                                })}
                                
                                </Fragment>
                            )
                        })}
                    </section>
                </section>
            </Fragment>
        )
    }
    //MARKING ORDER COMPLETE
    const [toProcessMarkComplete, setToProcessMarkComplete] = useState(false)
    const [markingCompleted, setMarkingCompleted] = useState(false)
    const [markErrorConfirmed, setMarkErrorConfirmed] = useState(false)
    const toMarkCompleteSingleProject = async(projid)=>{
        //console.log(projid)
        const markCompleteQuery = await fetch(markerSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(projid)
        })
         const respondingMarker = await markCompleteQuery.json()
         const {status, data} = respondingMarker 
        if(data.modifiedCount>0){
            setToProcessMarkComplete(false)
            setMarkingCompleted(true)
        }else{
            setToProcessMarkComplete(false)
            setMarkErrorConfirmed(true)
        }
    }
    const getToMarkComplete = ()=>{
        setToProcessMarkComplete(true)
        toMarkCompleteSingleProject({userUniqueID:userUniqueID, orderID:projectData.orderID, marked:marker})
    }

    const MarkCompleteErrorConfirmation = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessingAssignment(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "150px", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)", padding:"30px 0px 20px 0px", textAlign:"center",}}>
                            <span style={{position:"relative", color:"#16a068", fontSize:"14pt"}}>Marking Result:</span>  <br/>
                            <span style={{position:"relative", color:"#000", fontWeight:"bold", fontSize:"33pt"}}>ERROR</span><br/><br/>
                            <button style={{position:"relative", width:"60%", backgroundColor:"#16a068", color:"#fff", borderWidth:0, borderRadius:20, padding:"5px 10px 5px 10px"}} onClick={()=>{
                                setMarkErrorConfirmed(false);
                                xViewer(false);
                                setTimeout(xViewer(true),1000);
                                }}>Exit</button>
                    </section>
                </section>
            </Fragment>
        )
    }

    const MarkCompleteConfirmation = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessingAssignment(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "150px", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)", padding:"30px 0px 20px 0px", textAlign:"center",}}>
                            <span style={{position:"relative", color:"#16a068", fontSize:"14pt"}}>Successfully Marked</span>  <br/>
                            <span style={{position:"relative", color:"#000", fontWeight:"bold", fontSize:"33pt"}}>COMPLETED</span><br/><br/>
                            <button style={{position:"relative", width:"60%", backgroundColor:"#16a068", color:"#fff", borderWidth:0, borderRadius:20, padding:"5px 10px 5px 10px"}} onClick={()=>{
                                setMarkingCompleted(false);
                                //window.location.reload(true);
                                xViewer(false);
                                setTimeout(xViewer(true),1000);
                                }}>Exit</button>
                    </section>
                </section>
            </Fragment>
        )
    }
    const ProcessMarkingComplete = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setToProcessMarkComplete(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                            <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                            <span style={{position:"absolute", color:"#fff", top:40, right:85}}>Marking Complete Project #</span> <br/> 
                            <span style={{position:"absolute", color:"#fff", fontSize:"16pt", fontWeight:600, top:57, right:85}}>{projectData.orderID.toUpperCase()}</span> 
                    </section>
                </section>
            </Fragment>
        )
    }
    //DELETING ONE ORDER
    const [deleteOrderProcess, setDeleteOrderProcess] = useState(false)
    const [deleteOrderSuccess, setDeleteOrderSuccess] = useState(false)
    const [deleteOrderFailed, setDeleteOrderFailed] = useState(false)
    const deleteSingleProject = async(looper)=>{
        console.log(looper)
        const deleteQuery = await fetch(orderDeleteSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(looper)
        })
        const dessectDeleteQ = await deleteQuery.json()
        const {status, data} = dessectDeleteQ
        if(data.modifiedCount>0){
            setDeleteOrderProcess(false)
            setDeleteOrderSuccess(true)
        }else{
            setDeleteOrderProcess(false)
            setDeleteOrderFailed(true)
        }
    }
    const getToDeleteSingleProject = ()=>{
        deleteSingleProject({userUniqueID:userUniqueID, orderID:projectData.orderID})
    }
    const DeleteOrderSuccessConfirmation = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessingAssignment(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "100px", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)", padding:"30px 0px 20px 0px", textAlign:"center",}}>
                            <span style={{position:"relative", color:"#16a068", fontSize:"14pt"}}>Successfully Deleted Order No.</span>  <br/>
                            <span style={{position:"relative", color:"#000", fontWeight:"bold", fontSize:"11pt"}}>{projectData.orderID.toUpperCase()}</span><br/><br/>
                            <button style={{position:"relative", width:"60%", backgroundColor:"#16a068", color:"#fff", borderWidth:0, borderRadius:20, padding:"5px 10px 5px 10px"}} onClick={()=>{
                                setDeleteOrderProcess(false);
                                //window.location.reload(true);
                                xViewer(false);
                                setTimeout(xViewer(true),1000);
                                }}>Exit</button>
                    </section>
                </section>
            </Fragment>
        )
    }

    const DeletingOrderProcess = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setDeleteOrderProcess(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                            <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                            <span style={{position:"absolute", color:"#fff", top:40, right:85}}>Deleting Order No.</span> <br/> 
                            <span style={{position:"absolute", color:"#fff", fontSize:"16pt", fontWeight:600, top:57, right:85}}>{projectData.orderID.toUpperCase()}</span> 
                    </section>
                </section>
            </Fragment>
        )
    }
    const DeletingOrderFailed = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessingAssignment(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "100px", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)", padding:"30px 0px 20px 0px", textAlign:"center",}}>
                            <span style={{position:"relative", color:"#16a068", fontSize:"14pt"}}>Failed to Delete Order No.</span>  <br/>
                            <span style={{position:"relative", color:"#000", fontWeight:"bold", fontSize:"11pt"}}>{projectData.orderID.toUpperCase()}</span><br/><br/>
                            <button style={{position:"relative", width:"60%", backgroundColor:"#16a068", color:"#fff", borderWidth:0, borderRadius:20, padding:"5px 10px 5px 10px"}} onClick={()=>{
                                setDeleteOrderFailed(false);
                                xViewer(false);
                                setTimeout(xViewer(true),1000);
                                }}>Exit</button>
                    </section>
                </section>
            </Fragment>
        )
    }
    return(
        <Fragment>
            <section style={{margintop:10}}>
                {completedViewer === false && activeProjectViewer === false?
                <Fragment>
                    <section style={{marginBottom:25}}>
                        <section>
                            <h3 style={{position:"relative", left:40, textAlign:"left"}}>All Project Orders ({totalPupil})</h3>
                            <button className='completed-switcher-btn' onClick={()=>{setActiveProjectViewer(true)}} style={{position:"absolute", top:30, right:186, borderTopRightRadius:0, borderBottomRightRadius:0}}>
                                Active Projects
                            </button>
                            <button className='completed-switcher-btn' onClick={()=>{setCompletedViewer(true)}} style={{position:"absolute", top:30, right:28, borderTopLeftRadius:0, borderBottomLeftRadius:0}}>
                                Completed Projects
                            </button>
                        </section>
                    </section>
                    {userItem.map(zuser=>{
                    if(zuser.orders.length>0){
                        return(
                            <Fragment>
                                <ul className='user-orders'>
                                    <li style={{borderWidth:1, borderColor:"#f1f1f1", borderStyle:"solid", paddingTop:10, paddingLeft:10}}>
                                        <section className='order-box'>
                                            <section style={{position:"absolute", width:"25%", float:"left"}}>
                                                <span style={{fontWeight:"bold", color:"#16a068"}} >{zuser.username} ({zuser.orders.length})</span>
                                            </section>
                                            <section style={{position:"relative", top:0, textAlign:"right", width:"85%", float:"right"}}>
                                                {zuser.orders.map((value, key)=>{
                                                    return(
                                                        <Fragment>
                                                            <ul style={{top:0, marginLeft:30}}>
                                                                <li onMouseOver={()=>{
                                                                    setProjectData({
                                                                        indexKey: {key},
                                                                        currency: currencyFiat,
                                                                        orderID: value.orderID,
                                                                        categoryChosen: value.categoryChosen,
                                                                        phpPrice: value.phpPrice,
                                                                        usdPrice: value.usdPrice,
                                                                        addedMsg: value.addedMsg,
                                                                        addedImg: value.addedImg,
                                                                        paid:value.paid,
                                                                        dateOrderedWithTimezone: value.dateOrderedWithTimezone,
                                                                        progress: value.progress,
                                                                        quantity: value.quantity
                                                                        });
                                                                    setUserUniqueID(zuser.uid);
                                                                    setMarker('completed');
                                                                }} style={{position:"relative", left:-25, marginBottom:20, padding:"10px 0px 0px 42px",  backgroundImage:'linear-gradient(#fff, #d1d1d1)'}}>
                                                                    <section style={{float:"left", marginBottom:6, textAlign:"left"}}>
                                                                        <span style={{fontWeight:"bold"}}>{value.categoryChosen}</span> US${value.usdPrice} 
                                                                        <span style={{color:"#16a068"}}> <b>Handled By</b> {value.handledBy===''?'No One':value.handledBy}</span><br/>
                                                                        <span style={{fontSize:"8pt", color:"#16a068"}}>Ordered On: {value.dateOrderedWithTimezone}</span>
                                                                    </section><br/>
                                                                    <section style={{position:"relative", top:-5, left:0, float:"left", marginTop:10, marginBottom:10}}>
                                                                        <button onClick = {(e)=>{e.preventDefault(); setUserUniqueID(zuser.uid); getCurrency(userItem); setAssignTo(true); setProjectData({
                                                                            indexKey: {key},
                                                                            currency: currencyFiat,
                                                                            orderID: value.orderID,
                                                                            categoryChosen: value.categoryChosen,
                                                                            phpPrice: value.phpPrice,
                                                                            usdPrice: value.usdPrice,
                                                                            addedMsg: value.addedMsg,
                                                                            addedImg: value.addedImg,
                                                                            paid:value.paid,
                                                                            dateOrderedWithTimezone: value.dateOrderedWithTimezone
                                                                        })}} className='admin-userorder-console-btn-assigner-btn'>Assign To</button>
                                                                        <button onClick = {(e)=>{e.preventDefault(); getCurrency(userItem); setPreviewProject(true); setProjectData({
                                                                            indexKey: {key},
                                                                            userid:zuser.uid, 
                                                                            currency: currencyFiat,
                                                                            orderID: value.orderID,
                                                                            categoryChosen: value.categoryChosen,
                                                                            phpPrice: value.phpPrice,
                                                                            usdPrice: value.usdPrice,
                                                                            addedMsg: value.addedMsg,
                                                                            addedImg: value.addedImg,
                                                                            paid:value.paid,
                                                                            dateOrderedWithTimezone: value.dateOrderedWithTimezone,
                                                                            progress: value.progress,
                                                                            quantity: value.quantity
                                                                        })}}className='admin-userorder-console-btn-preview-btn'>Preview</button>
                                                                        <button onClick = {(e)=>{
                                                                            e.preventDefault();
                                                                            getToMarkComplete();
                                                                        }}
                                                                        className='admin-userorder-console-btn-marker-btn'>Mark Complete</button>
                                                                        <button onClick = {(e)=>{
                                                                            e.preventDefault(); 
                                                                            setUserUniqueID(zuser.uid); 
                                                                            setProjectData({
                                                                                orderID: value.orderID
                                                                            }); 
                                                                            setDeleteOrderProcess(true);
                                                                            getToDeleteSingleProject();
                                                                        }}
                                                                        className='admin-userorder-console-btn-delete-btn'>Delete</button>
                                                                    </section>
                                                                    {value.status==='active'?<section className='order_new_status_ribbon'>
                                                                    </section>:
                                                                    <section className='order_complete_status_ribbon'>
                                                                    </section>}
                                                                </li>
                                                            </ul>
                                                        </Fragment>
                                                    )
                                                })}
                                                <br/>
                                            </section>
                                        </section>
                                    </li>
                                </ul>
                            </Fragment>
                        )
                    }
                })}
                </Fragment>
                :''}
                {completedViewer===true? <CompletedViewer />:''}
                {activeProjectViewer === true? <ActiveProjectsViewer />:''}
            </section>

            {assignTo === true?<AssignToWorker details={projectData} />:''}
            {previewProject===true?<PreviewProjectDetails details={projectData} />:''}
            {processingAssignment === true? <ProcessAssignment />:''}
            {successfulConfirmation === true? <SuccessfulConfirmation />: ''} 
            {toProcessMarkComplete === true? <ProcessMarkingComplete />:''}
            {markingCompleted === true? <MarkCompleteConfirmation />:''}
            {markErrorConfirmed === true? <MarkCompleteErrorConfirmation />:''}
            {deleteOrderProcess === true? <DeletingOrderProcess />:''}
            {deleteOrderSuccess === true? <DeleteOrderSuccessConfirmation />:''}
            {deleteOrderFailed === true? <DeletingOrderFailed />:''}
            {processReactivate === true? <ProcessReactivation />:''}
            {toReactivatedOrder === true? <ReactivatedConfirmation />:''}
            {errorReActivatingOrder === true? <ErrorReactivatingOrder />:''}
        </Fragment>
    )
}
export default ProjectListings