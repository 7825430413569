import React, {Fragment} from 'react';
import TabletHeader from '../tabletComponents/tabletPagesHeader';
import TabletMainContent from '../tabletComponents/tabletContentHome';
import TabletFooter from '../tabletComponents/tabletFooter';
import TabletLanding from '../tabletComponents/tabletLanding';

//COMPONENTS
import { accessSwitch } from '../components/variables';
import { 
    SDAPIL, 
    SDAPIW,
    OAPIL,
    OAPIW 
} from '../../config'
const pitch = accessSwitch //true to Web-based MongoDB
const switcher =(bol)=>{
      if(bol===true){
          return SDAPIW
      }else{
          return SDAPIL
      }
}
const servicesPingAPI = ()=>{
    fetch(switcher(pitch)) 
    .then((responder)=>responder.json())
    .then((data)=>{
        const duga = data.data
        const doughnut = []
        for(const key in duga){
            doughnut.push({
                id:key,
                title: duga[key].title,
                price: duga[key].price,
                description: duga[key].description,
                imageLarge: duga[key].imageLarge,
                tags:duga[key].tags
            })
        }
    })
}
//triggering the servicers data load
const triggeredNow = ()=>{
    if(servicesPingAPI()){
        console.log("triggered API")
    }else{
        console.log("API not triggered")
    }
}
triggeredNow()
let DeskHomePage = ()=>{
    return(
        <Fragment>
            <div id="MAIN-BODY">
                <TabletHeader />
                <section style={{position:"relative", width:"100%", height:50, background:"#fff" }}></section>
                <TabletLanding />
                <TabletMainContent /> {/*<--Problematic*/}
                <TabletFooter />
            </div>
        </Fragment>
    )
}

export default DeskHomePage;