//LIBRARIES
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { proPicView, viewUser, viewOrder, viewClientOrders, messageTabToggle} from '../../../redux/slices/counter'
import {querriedData, getAllUsers} from '../../../redux/querries/userdataquery'
import EmojiPicker, { Picker, Emoji, EmojiStyle} from 'emoji-picker-react' 
//<EmojiPicker />
//COMPONENTS
import {
  GCHATSYS_L, GCHATSYS_W,
  CDISQ_L, CDISQ_W,
  ADUX_ALLAPIL, ADUX_ALLAPIW, 
  CDISQ_REL, CDISQ_REW
} from '../../../config'
import {accessSwitch} from '../variables'

//OPERATIONS
//THIS IS A MESSAGE COMPONENT
const ChattyBoxClient = (props)=>{ 
    const {chatWith, ids, userid, orderid} = props
    const pitch = accessSwitch
    const user = useSelector(state => state.counter.userDetails)
    const photo = useSelector(state => state.counter.profilePic)

    const dispatch = useDispatch()
    
    dispatch(viewUser(), proPicView())
  
    const refreshUserDetails = ()=>{
      dispatch(proPicView())
    }
    //PICKER STATE AND METHOD
    const [inputStr, setInputStr] = useState('')
    const [showPicker, setShowPicker] = useState(false)
    const onEmojiClick = (emojiObject)=>{
      setInputStr(prevInput => prevInput + emojiObject.emoji);
    }

    //refreshUserDetails()
    const messageTabler = (top)=>{
      dispatch(messageTabToggle(top))
    }
  
    const chattySwitcher = (pitch)=>{
      if(pitch===true){
          return CDISQ_W
      }else{
          return CDISQ_L
      }
    }
    const chaReSwitcher = (pitch)=>{
      if(pitch===true){
          return CDISQ_REW
      }else{
          return CDISQ_REL
      }
    }
    //DEFAULT QUERY
    const [inquiries, setInquiries] = useState([])
    const defaultQueryMessageGetter = async(sing)=>{
      const defaultQRedux = await fetch(chaReSwitcher(pitch), { //chaReSwitcher(pitch)
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(sing)
      })
      const defcon = await defaultQRedux.json()
      const {status, data} = defcon
      const {oid} = ids
      var mast = []
      data.map(item=>{
          if(item.orders){
            mast.push(item.orders)
          }

      })
      //console.log(mast[0])  //<--works
      let gast = []
      try{
        if(mast[0]){
          mast[0].map(oten=>{
            if(oten.orderID === oid){
             gast.push(oten.discussion)
            }
          })
        }
      }catch(e){
        console.log(e)
      }
      setInquiries(gast)
      //snapBackToDefault()
    }

    useEffect(()=>{
      defaultQueryMessageGetter({userUniqueID:userid})
    },[])

    const messageTabToggler = (tog)=>{
        dispatch(messageTabToggle(tog))
    }
    const [messageEntry, setMessageEntry] = useState({
      uname:'',
      role:'',
      subrole:'',
      message:''
    })
    const [refreshLoadedList, setRefreshLoadedList] = useState(false)
    const [newChatEntry, setNewChatEntry] = useState([])
  
    //RE-QUERY
    const reconnectReduxAPI = async(sing)=>{
      const reconRedux = await fetch(chaReSwitcher(pitch), { //chaReSwitcher(pitch)
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(sing)
      })
      const frecon = await reconRedux.json()
      const {status, data} = frecon
      //console.log("OID: "+ids.oid)
      //console.log(data[0].orders) //works
      const {oid} = ids
      //console.log("OID: "+oid)
      let dashlings = data[0].orders
      let dashmag = []
      dashlings.map(item=>{
        if(item.orderID === oid){
          dashmag.push(item.discussion)
        }
      })
      setNewChatEntry(dashmag)
    }
    //FIRST QUERY
    const processNewMessage = async()=>{
      const mEntries = {
        uname:user.username, 
        userUniqueID:ids.uid, //<-- client userID
        oid:ids.oid, 
        message:messageEntry.message, 
        role:user.role,
        subrole:user.subrole,
        unread:true
      }
      //console.log(mEntries)
      const clientMessageQuery = await fetch(chattySwitcher(pitch), { //chattySwitcher(pitch)
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body:JSON.stringify(mEntries)
      })
      const responseGchat = await clientMessageQuery.json()
      const {status, data, inquiry} = responseGchat
      if(data.modifiedCount>0){
          console.log("Successfull Connection")
          reconnectReduxAPI({userUniqueID:ids.uid}) //--> TO RE-QUERY
          setRefreshLoadedList(true)
          snapBackToDefault()
      }else{
        //console.log("Something is Wrong!")
      }
      messageTabToggler('message');
      setInputStr('')
    }

    const snapBackToDefault = ()=>{
      setRefreshLoadedList(false)
      defaultQueryMessageGetter({userUniqueID:ids.uid})
    }
    const deliverance = ()=>{
      if(inquiries.length===0){
        setTimeout(()=>{snapBackToDefault()}, 1000)
      }else{
        return
      }
    }
    deliverance()
    //NON-REDUX-BASED CONTENT GENERATED
    //default message listing SHORTENER
    let display = 5
    let tailEnder = [];
    if(newChatEntry.length<5){
      let laster = newChatEntry.length
      tailEnder = newChatEntry.slice(0, laster)
    }else{
      let laster = newChatEntry.length
      tailEnder = newChatEntry.slice(newChatEntry.length-display, laster)
    }
    const RefreshChatContent = ()=>{
      //console.log(newChatEntry)//<-checked
      return(
        <Fragment>
                {newChatEntry.length===0?
                <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                  <span style={{color:"#16a068"}}>loading data...</span>
                </section>
                :
                <Fragment>
                  {tailEnder.map(msg=>{
                  if(msg.length===0){
                    return(
                      <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>data loading...</span>
                      </section>
                    )
                  }else{
                        return(
                          <section style={{position:"relative", top:0, left:0, margin:"auto", width:"80%", marginBottom:0, padding:"10px 20px 10px 20px"}} onMouseOver={snapBackToDefault}>
                            {msg.role !== 'guest'?
                            <section style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            <span style={{color:"#fff", fontWeight:"bold"}}>
                              {/* {msg.uname.charAt(0).toUpperCase()}{msg.uname.charAt(1).toUpperCase()} */}
                              <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.subrole}</span><br/>
                            </span>
                          </section>
                            :<section style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            {/* {msg.photo?
                            <img src={msg.photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:20, width:20}}/>
                            :<span style={{color:"#16a068", fontWeight:"bold"}}>
                            {msg.uname.charAt(0).toUpperCase()}{msg.uname.charAt(1).toUpperCase()}
                            </span>} */}
                            <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>}
                            
                            <span style={{position:"relative", left:25, top:-10, color:"#16a068", fontSize:"8pt"}}>{msg.datePostedWithUTC}</span><br/>
                            <span style={{position:"relative", left:25, top:-10, color:"#16a068"}}>{msg.message}</span>
                          </section>
                        )
                  }
                })}
                </Fragment>
              }
        </Fragment>
      )
    }
  
    const  getNewMessage = message=>event=>{
      event.preventDefault()
      message = event.target.value //uname:user.username, role:user.role, subrole:'', message:message
      setMessageEntry({...messageEntry, message:message})
    }
    const bakery = inquiries[0]
    let limit = 4
    let tailInquiry = [];
    //let laster = bakery.length
    // if(bakery.length<4){
    //   let laster = bakery.length
    //   tailInquiry.push(bakery.slice(0, laster))
    // }else{
    //   let laster = inquiries.length
    //   tailInquiry.push(bakery.slice(bakery.length-limit, laster))
    // }
         
    
    return (
      <Fragment>
          <section style={{top:0, margin:10}} onMouseOver={snapBackToDefault}>
            
            {/* <section style={{position:"absolute", width:"100%", top:0, left:80, height:20,  marginBottom:30, textAlign:"center"}}>
               <span style={{position:"relative", top:-15, color:"#fff",  fontSize:"10pt", textAlign:"center", borderRadius:10, padding:"5px 10px 5px 10px", backgroundColor:"#16a068"}}>Chat with {ids.handler}</span>
            </section> */}
            <section style={{position:"relative", top:-70, width:"100%", margin:"auto"}}>
              {inquiries.length===0?
              <section style={{position:"relative", top:20, margin:"auto", textAlign:"center", width:"100%"}}>
                
                <span style={{position:"relative", width:"90%", margin:"auto", fontSize:"11pt", color:"#16a068",  padding:"50px 20px 20px 20px"}}>There are no messages to load</span>
              </section>
              :''
              }
              {refreshLoadedList===true?
              <RefreshChatContent />
              :<Fragment>
                {inquiries[0]?
                bakery.slice(bakery.length<4?0:bakery.length-4).map(msg=>{
                  if(msg.length===0){
                    return(
                      <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>data loading...</span>
                      </section>
                    )
                  }else{
                    return(
                      <section style={{position:"relative", top:30, left:0, margin:"auto", backgroundColor:"#fff", width:"90%", marginBottom:15, marginTop:15}}>
                        {msg.role !== 'client'?
                          <section style={{position:"absolute", top:-10, left:-10, textAlign:"center", backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          <span style={{color:"#fff", fontWeight:"bold"}}>{msg.uname.charAt(0).toUpperCase()}{msg.uname.charAt(1).toUpperCase()}</span>
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.subrole}</span><br/>
                          </section>
                        :<section style={{position:"absolute", top:-10, left:-10, textAlign:"center", backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          {msg.photo?
                          <img src={msg.photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:20, width:20}}/>
                          :<Fragment>
                            <span style={{color:"#16a068", fontWeight:"bold"}}>{msg.uname.charAt(0).toUpperCase()}{msg.uname.charAt(1).toUpperCase()}</span><br/>
                          </Fragment>
                          } 
                          <span style={{position:"relative", color:"#16a068", fontSize:"6pt"}}>{msg.role==='client'?'client':'employee'}</span><br/>
                        </section>
                        }
                          <span style={{position:"absolute", top:-12, left:30, color:"#16a068", fontSize:"6pt"}}>{msg.datePostedWithUTC}</span><br/>
                          <section style={{position:"relative", top:-20, left:20, width:"90%", padding:"5px 10px 5px 10px"}}>
                            <span style={{color:"#16a068"}}>{msg.message}</span>
                          </section>
                      </section>
                    )
                  }
                })
                :''
              }
                </Fragment>}
  
            </section>
            <section style={{position:"absolute", width:"100%", bottom:30, marginBottom:20}}>
              {/*  
                <section style={{position:"relative", left:-20, top:10, width:30, height:30, borderRadius:"50%"}}>
                {photo?
                <img onLoad={snapBackToDefault} src={photo} style={{position:"absolute", zIndex:3,  left:18, top:0, borderRadius:"50%", height:40, width:40, borderWidth:2, borderStyle:"solid", borderColor:"#fff"}}/>:
                <b style={{position:"absolute", top:10, left:10, fontSize:"26pt"}}>{user.username.charAt(0).toUpperCase()}{user.username.charAt(1).toUpperCase()}</b>
                }
              </section> 
              */}
              <section style={{position:"relative", right:-10, borderColor:"#16a068", bottom:0, width:"90%", height:70, borderRadius:5, borderWidth:1, borderStyle:"solid"}}>
                <textarea value={inputStr} onChange={(e)=>{setInputStr(e.target.value)}} onMouseDown={()=>{setShowPicker(true)}} onMouseLeave={getNewMessage('message')} id='guest-message' style={{position:"relative", backgroundColor:"#fff", top:5, left:10, width:"90%", height:40, borderColor:"#fff", borderStyle:"solid", lineHeight: 1, borderWidth:0, borderRadius:5, padding:"10px 10px 10px 10px", color:"#16a068", fontFamily:"Baloo", fontSize:"11pt" }} type="text" row="20"></textarea>
                {!messageEntry.message?
                <button onMouseOver={()=>{setShowPicker(false)}} style={{position:"absolute", top:80, right:0, height:30, padding:"5px 20px 5px 20px", backgroundColor:"#c1c1c1", color:"white"}} className='guest-chat-btn'>
                  Send
                </button>
                :<button onMouseOver={()=>{setShowPicker(false)}} style={{position:"absolute", top:80, right:0, height:30, padding:"5px 20px 5px 20px"}} className='guest-chat-btn' onClick={(e)=>{
                  e.preventDefault();
                  setRefreshLoadedList(false);
                  document.getElementById("guest-message").value = "";
                  processNewMessage();
                  }}>
                  Send
                </button>}
              </section>
              {!messageEntry.message?
              <span style={{position:"absolute", top:80, left:20, color:"#16a068", fontSize:"8pt"}}>The field is empty!</span>
              :''}
            </section>
              {showPicker?
                <Fragment>
                <section style={{position:"fixed", bottom:"10%", right:"27%",   zIndex:11}}>
                    <EmojiPicker 
                    pickerStyle={{width:'100%'}} 
                    onEmojiClick={onEmojiClick}
                    />
                </section>
                </Fragment>
              :''}
          </section>
      </Fragment>
    )
  }

  export default ChattyBoxClient