//LIBRARY
import React, {Fragment, useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, useHistory } from 'react-router-dom'
import DeskHeader from './deskHeader';
import DeskFooter from './deskFooter';
import Axios from 'axios';

//COMPONENTS
import DefaultLoginStateContext from '../../controllers/loginContext'
import * as coated from '../../controllers/loginContext' //loads all marked export
import {Logger, LoggerInfo} from '../types/loggers' //classes to segregate users
import {
    APIL1, APIL2, 
    API1, API2,
    LOGIN_BOOKL, LOGIN_BOOKW,
    LOGOUTER_BQL, LOGOUTER_BQW
} from '../../config'
import {authenticate} from '../components/auth/auth'
import {accessSwitch} from '../components/variables'

const DeskLoginPage = ()=>{
    //TEST>
        
    //<TEST
    const historian = useHistory()
    const [logger, setLogger] = useState({
        email:'',
        password:'',
        error: false
    })

    const handleChange = name=>event=>{
        setLogger(
            {...logger, error:false, [name]:event.target.value}
        )
    }

    const {email, password, error} = logger
    const loggedMan = useContext(DefaultLoginStateContext)

    //GETTING RESULTS FROM DATABASE  
    const [logErrorCatcher, setLogErrorCatcher] = useState(false)

    const ErrorWarning=()=>{
        return(
            <section className="error_warning">
                <section className="centered_warning">
                    <h4 style={{color:"black"}}>Be sure to have all the fields filled out!</h4>
                    <button className="errorWarningCloseBTN" onClick={(e)=>{e.preventDefault(); setLogErrorCatcher(false) }}>CLOSE</button>
                </section>
            </section>
        )
    }
    //LOGINS BOOK 
    const pitch = accessSwitch //true to Web-based MongoDB
    const bookLoginSwitcher =(bol)=>{
        if(bol===true){
            return LOGIN_BOOKW
        }else{
            return LOGIN_BOOKL
        }
    }

    const getLogRecorded = async(keys)=>{
        const loggerRes = await fetch(bookLoginSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(keys)
        })
        const trackedLoggerRec = await loggerRes.json()
        const {status, data} = trackedLoggerRec
        if(data.modifiedCount>0){
            console.log("<<Getting Self On The Line Today>>")
        }
    }

    const [loginSpinner, setLoginSpinner] = useState(false)
    const clickSubmit = async (event)=>{
        event.preventDefault()
        //signUp({email:email, password:password})
        setLoginSpinner(true)
        const loginErrorCatcher = [email, password]
        if(loginErrorCatcher.includes("") || loginErrorCatcher.includes(" ")){
            setLogErrorCatcher(true)
        }else{
            const logOner = new Logger({email:email, password:password})
            const logStatus = await logOner.signUpProcess()

            //{status:status, username:username, email:email, token:token, role:role}
            if(logStatus.status==="Success" && logStatus.role==="admin"){
                getLogRecorded({userUniqueID:logStatus.userUID})
                authenticate(
                    logStatus,()=>{
                        historian.push('/dashboard')
                }) 
            }else if(logStatus.status==="Success" && logStatus.role==="guest"){
                //-->To set Online
                getLogRecorded({userUniqueID:logStatus.userUID})
                authenticate(
                    logStatus,()=>{ 
                        historian.push('/dashboard')
                }) 
                        
            }else if(logStatus.status==="Success" && logStatus.role==="employee"){
                //-->To set Online
                getLogRecorded({userUniqueID:logStatus.userUID})
                authenticate(
                    logStatus,()=>{ 
                        historian.push('/dashboard')
                })    
            }else if(logStatus.status==="Success" && logStatus.role==="client"){
                //-->To set Online
                getLogRecorded({userUniqueID:logStatus.userUID})
                authenticate(
                    logStatus,()=>{ 
                        historian.push('/dashboard')
                })     
            }else if(!logStatus){
                historian.push('/login-error')
            }else{
                historian.push('/login-error')
            }
        }
        setLoginSpinner(false)
    }

    const cancelToHome=()=>{
        historian.push('/')
    }
    const LoginSpinner = ()=>{
        return(
            <Fragment>
                <div style={{position:"fixed", zIndex:2, width:"100%", height:"100%", margin:"auto", top:0, left:0, backgroundColor:"rgba(255,255,255,1.0)"}}>
                    <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>logging you in...</span>
                    </section>
                </div>
               
            </Fragment>
        )
    }
    return(
        <Fragment>
            <div id="MAIN-BODY">
                <DeskHeader />
                {logErrorCatcher?<ErrorWarning/>:''}
                <div className="log-form-space">
                            <h1>Welcome to Login!</h1>
                            <form className="login-form">
                                <label className="label">Email</label>
                                <input className="txt-field" onChange={handleChange('email')} type="text" placeholder="Email"></input><br/><br/><br/>
                                <label className="label">Password</label>
                                <input className="txt-field" onChange={handleChange('password')} type="password" placeholder="Password"></input><br/><br/><br/><br/>
                                <button onClick={clickSubmit} className="log-btn">LOGIN</button>
                                <button className="log-cancel-btn" onClick={cancelToHome}> 
                                    CANCEL
                                </button>
                            </form>
                            <section style={{position:"relative", top:-50, padding:"0px 0px 20px 0px", textAlign:"center"}}>
                                <span onClick={()=>{historian.push("/password-reset")}} style={{fontSize:"2em", fontWeight:"bolder", color:"#16A068", cursor:"pointer"}}>Forgot your password?</span>
                            </section>
                    </div>
                <DeskFooter />
            </div>
            {loginSpinner===true?<LoginSpinner />:''}
        </Fragment>
    )
}
export default DeskLoginPage;