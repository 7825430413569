//LIBRARIES
import React, {Fragment, useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import {orderen, querriedData} from '../../../redux/querries/userdataquery'
import {currPrimary, currBalanceView, defaultCurrBalance, proPicView} from '../../../redux/slices/counter'
import {CounterClientOrderView, CounterOrderView} from '../../../redux/viewslices/counterview'

//COMPONENTS
import { 
    SDAPIL, SDAPIW,
    OAPIL, OAPIW,
    ADUX_ALLAPIL, ADUX_ALLAPIW,
    BUDALOC_CL, BUDALOC_CW 
} from '../../../config'
import {accessSwitch} from '../variables'
import { totalmem } from 'os';
import { StrikethroughS } from '@mui/icons-material';

const ClientActiveListing = (props)=>{
        const historian = useHistory()
        const {user, userid, corders} = props
        const [proToggler, setProToggler] = useState(true)

        const bamount = useSelector(state => state.counter.currBalance)
        const currencyFiat = useSelector(state => state.counter.currFiat)
        const details = useSelector(state => state.counter.userDetails)
        const dispatch = useDispatch()

        const currencySwitcher = (curr)=>{
            dispatch(currBalanceView(curr))
        }
        const currencyPrimary = ()=>{
            dispatch(currPrimary)
        }
        //const [corders, setCorders] = useState(null)
        const [orders]= orderen
        const aduxSwitcher =(bol)=>{
            if(bol===true){
                return ADUX_ALLAPIW
            }else{
                return ADUX_ALLAPIL
            }
        }
        const [userItem, setUserItem] = useState([])
        const [ordersItem, setOrdersItem] = useState([])
        const [isGashing, setIsGashing] = useState(true)
        const [eachPrice, setEachPrice] = useState(0)
        const [eachOid, setEachOid] = useState('')
        const [itemQuantity, setItemQuantity] = useState(0)
        const ordersLanded = async()=>{
            await fetch(aduxSwitcher(pitch)) //true to Web-based MongoDB
            .then((responsive)=>responsive.json())
            .then((data)=>{
                const wanton = data.data
                const drearies = []
                for(const key in wanton){
                    drearies.push({
                        id:key,
                        uid:wanton[key].userUniqueID,
                        username: wanton[key].username,
                        email: wanton[key].email,
                        role: wanton[key].role,
                        dateSince: wanton[key].dateCreated,
                        orders:wanton[key].orders, 
                        payments:wanton[key].paymentSources,
                        orders:wanton[key].orders,
                        logins:wanton[key].logins
                    })
                }
                setUserItem(drearies)
            })
        }
        useEffect(()=>{
            setTimeout(handleSmallImage(), 1000)
            ordersLanded()
        },[]) 
        
        //SERVICES LIST LOADING
        const [dito, setDito] = useState([])
        const [isLoading, setIsLoading] = useState(true)
        const pitch = accessSwitch //true to Web-based MongoDB
        const switcher =(bol)=>{
                if(bol===true){
                    return SDAPIW
                }else{
                    return SDAPIL
                }
        }
        const servicesPingAPI = async()=>{
            setIsLoading(true)
            await fetch(switcher(pitch)) //switcher(pitch) //'https://webbyjolme.com/api/j5d-servicesdisplay/'
            .then((responder)=>responder.json())
            .then((data)=>{
                const duga = data.data
                const doughnut = []
                for(const key in duga){
                    doughnut.push({
                        id:key,
                        title: duga[key].title,
                        price: duga[key].price,
                        description: duga[key].description,
                        imageLarge: duga[key].imageLarge,
                        imageSmall: duga[key].imageSmall,
                        tags: duga[key].tags
                    })
                }
                setDito(doughnut)
            })
            setIsLoading(false)
        } 
        const handleSmallImage = ()=>{
            servicesPingAPI()
        }
        //CURRENCY MATTERS
        const [currencyList, setCurrencyList] = useState({
            PHP:0,
            USD:0,
            EUR:0,
            GBP:0,
            AUD:0,
            CAD:0,
            CNY:0,
            RUB:0
        })
        const currencySniffer = async()=>{
                const sniffer = await fetch("https://api.currencyfreaks.com/latest?apikey=abeb9af08b68465c8446be6e4c91fdef&symbols=PHP,GBP,EUR,AUD,CAD,RUB,CNY,USD", 
                {
                    method:"GET",
                    headers:{"Content-Type":"application/json"}
                }
                )
                const gotSniffed = await sniffer.json()
                if(!gotSniffed || gotSniffed === 'undefined'){
                    setCurrencyList({...currencyList, 
                        PHP:0.00,
                        USD:0.00,
                        EUR:0.00,
                        GBP:0.00,
                        AUD:0.00,
                        CAD:0.00,
                        CNY:0.00,
                        RUB:0.00
                    })
                }else{
                    setCurrencyList({...currencyList, 
                        PHP:58.85,
                        USD:1.0,
                        EUR:0.92,
                        GBP:1.32,
                        AUD:0.76,
                        CAD:0.72,
                        CNY:6.26,
                        RUB:60.88
                    })
                    // setCurrencyList({...currencyList, 
                    //     PHP:gotSniffed['rates']['PHP'],
                    //     USD:gotSniffed['rates']['USD'],
                    //     EUR:gotSniffed['rates']['EUR'],
                    //     GBP:gotSniffed['rates']['GBP'],
                    //     AUD:gotSniffed['rates']['AUD'],
                    //     CAD:gotSniffed['rates']['CAD'],
                    //     CNY:gotSniffed['rates']['CNY'],
                    //     RUB:gotSniffed['rates']['RUB']
                    // })
                }
        }
        

        const [pricesInCurrency, setPricesInCurrency] = useState(false)
        const [money, setMoney] = useState(0.00) //amount entered
        const [allocateFund, setAllocateFund] = useState(false)
        const getMoneySticked = (num)=>(event)=>{
          event.preventDefault()
          let money = event.target.value
          setMoney(money)
        }
        const budgetAllocationSwitcher = (bol)=>{
            if(bol===true){
                return  BUDALOC_CW
            }else{
                return  BUDALOC_CL
            }
        }
        const AllocateFloater = (props)=>{
            currencySwitcher('') //decapitate this function
            currencyPrimary() //triggering primary currency
            const {fiat, balance, each, nopcs, oid, alloc} = props
            //processing allocation
            const [processStatus, setProcessStatus] = useState(false)
            const [pushedSuccessful, setPushedSuccessful] = useState(false)
            const processAllocation = async()=>{
                setProcessStatus(true)
                const pushAllocation = await fetch(budgetAllocationSwitcher(pitch), {
                    method:"POST",
                    headers:{"Content-Type":"application/json"},
                    body:JSON.stringify({
                        userUniqueID:userid, 
                        orderid:oid, 
                        balance:balance, 
                        amount:money, 
                        conversions:[
                            {USD : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['USD'])).toFixed(2)}, 
                            {PHP : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['PHP'])).toFixed(2)}, 
                            {EUR : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['EUR'])).toFixed(2)}, 
                            {GBP : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['GBP'])).toFixed(2)}, 
                            {AUD : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['AUD'])).toFixed(2)}, 
                            {CAD : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['CAD'])).toFixed(2)}, 
                            {CNY : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['CNY'])).toFixed(2)}, 
                            {RUB : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['RUB'])).toFixed(2)}
                        ],  
                        currencyUsed:fiat})
                })
                const responsed = await pushAllocation.json()
                const {data} = responsed
                if(data.modifiedCount === 1){
                    console.log("Amount Updated!")
                    setTimeout(()=>{
                        setProcessStatus(false)
                        setMoney(0.00)
                        setPushedSuccessful(true)
                    }, 2000)
                }
                
            }
            const disrate = 1.0-(parseFloat(details.discount)/100)
            const discountedAmt = (parseFloat(each)*parseFloat(nopcs)*parseFloat(currencyList[`${fiat}`])).toFixed(2)*disrate.toFixed(2)
            const allocatedAmt = ((parseFloat(alloc))).toFixed(2)
            console.log(discountedAmt+"="+allocatedAmt)
            return(
                <Fragment>
                    <section style={{position:"fixed", zIndex:10, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                    </section>
                    <section style={{position:"fixed", zIndex:10, top:0, left:0, width:"100%", borderRadius:0, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                        <section style={{position:"relative", margin:"auto", width:"75%", top:15, height:70, marginTop:50, marginBottom:50}}>
                            <section style={{position:"absolute", left:-100, top:-20, width:630, height:100, borderWidth:1, borderStyle:"solid", borderColor:"#f1f1f1", borderRadius:5, textAlign:"center"}}>
                                <h4 style={{position:"absolute", left:40, color:"#16a068"}}>Account Balance</h4>
                                <span style={{position:"absolute", top:43, left:40}} ><b>{!fiat || fiat=== 'undefined' || fiat === 'null' || fiat === ''?'':fiat}</b> {balance}</span><br/>
                                <h4 style={{position:"absolute", top:0, left:220, color:"#16a068"}}>Unit Cost</h4>
                                <span style={{position:"absolute", top:43, left:220}} ><b>{!fiat || fiat=== 'undefined' || fiat === 'null' || fiat === '' ?'':fiat}</b> {(parseFloat(each)*parseFloat(currencyList[`${fiat}`])).toFixed(2)}</span><br/>
                                <h4 style={{position:"absolute", top:0, left:360, color:"#16a068"}}>Discounted Total</h4>
                                <span style={{position:"absolute", top:43, left:360, textDecoration:"line-through"}} ><b>{!fiat || fiat=== 'undefined' || fiat === 'null' || fiat === ''?'':fiat}</b> {(parseFloat(each)*parseFloat(nopcs)*parseFloat(currencyList[`${fiat}`])).toFixed(2)}</span><br/>
                                <span style={{position:"absolute", top:65, left:360}} ><b>{!fiat || fiat=== 'undefined' || fiat === 'null' || fiat === ''?'':fiat}</b> {(parseFloat(each)*parseFloat(nopcs)*parseFloat(currencyList[`${fiat}`])).toFixed(2)*disrate.toFixed(2)}</span><br/>
                                <h4 style={{position:"absolute", top:0, left:520, color:"#16a068"}}>Allocated</h4>
                                <span style={{position:"absolute", top:43, left:520}} ><b>{!fiat || fiat=== 'undefined' || fiat === 'null' || fiat === ''?'':fiat}</b> {((parseFloat(alloc))).toFixed(2)}</span><br/> {/* *parseFloat(currencyList[`${fiat}`]) */}
                            </section>
                            {parseFloat(allocatedAmt).toFixed()>=parseFloat(discountedAmt).toFixed()?
                            <Fragment>
                                <section style={{position:"absolute", left:565}}>
                                    <section style={{position:"relative", zIndex:0, margin:"auto", top:0, left:50, backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                                        <span style={{fontSize:"1.5em", fontWeight:"bolder"}}>Project Fully Funded</span>
                                        <button onClick={()=>{setAllocateFund(false); window.location.reload()}} style={{position:"relative", left: 20, backgroundColor:"#16a068", padding:"10px 40px 10px 40px", color:"#fff", borderWidth:0, borderRadius:20}}>Exit
                                        </button>
                                    </section>
                                </section> 
                            </Fragment>
                            :
                            <Fragment>
                                {processStatus === true?
                                <section style={{position:"absolute", left:565}}>
                                    <section style={{position:"relative", zIndex:0, margin:"auto", top:-30, left:50, backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                                    <span style={{position:"absolute", top:47, left:120, color:"#16a068"}}>Processing...</span>
                                    </section>
                                </section>
                                :
                                <Fragment>
                                    {pushedSuccessful === true?
                                        <section style={{position:"absolute", left:565}}>
                                            <section style={{position:"relative", zIndex:0, margin:"auto", top:-30, left:50, backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                                                <h3>Successfully Allocated Amount!</h3>
                                                <button>
                                                    Exit
                                                </button>
                                            </section>
                                        </section>  
                                        :<section style={{position:"absolute", left:565}}>
                                        <section style={{position:"absolute", top:-50}}>
                                            {money>balance?<span style={{color:"#16a068", fontSize:"9pt"}}>Warning! Amount must not be greater than current balance...</span>:''}
                                            {pushedSuccessful===true?
                                            <span style={{position:"absolute", top:-25, color:"#16a068", fontWeight:"bolder", fontSize:"9pt"}}>Allocating Amount is Successful!</span>:''}
                                        </section>
                                        <label style={{position:"absolute", zIndex:2, top:-10, left:20, fontSize:"10pt", color:"#d1d1d1"}}>Amount To Allocate</label><input className='depo-placeholder' type="number" placeholder = {money===0 || money===''?'0.00':money} onMouseLeave={getMoneySticked("money")} style={{position:"relative", top:-18, fontSize:"22pt", width:290, height:87, borderWidth:0.5, borderColor:"#d1d1d1", color:"#16a068", borderStyle:"solid", borderRadius:5,  padding:"5px 20px 5px 20px"}} /><br/><br/>
                                        <button onClick={()=>{setMoney(0); }} className='depo-clearclose-btn' style={{position:"absolute", top:-14, width:150, left:365, fontSize:"12pt", borderRadius:5, padding:"3px 40px 3px 40px"}} >Clear</button>
                                        {money<1 || money>balance?
                                        <button className='depo-btn' style={{position:"absolute", backgroundColor:"#b1b1b1", top:19, width:150, left:365, fontSize:"12pt", borderRadius:5, padding:"3px 40px 3px 40px"}} >Allocate</button>
                                        :<button onClick={processAllocation} className='depo-btn' style={{position:"absolute", top:19, width:150, left:365, fontSize:"12pt", borderRadius:5, padding:"3px 40px 3px 40px"}} >Allocate</button>
                                        }
                                        <button onClick={()=>{setAllocateFund(false); window.location.reload()}} className='depo-clearclose-btn' style={{position:"absolute", top:52, width:150, left:365, fontSize:"12pt", borderRadius:5, padding:"3px 40px 3px 40px"}} >Close</button>
                                    </section>}
                                </Fragment>
                                }
                            </Fragment>
                            }
                            
                            
                        </section>
                    </section>
                </Fragment>
            )
        }

        const PriceInCurrencyExchanges = (props)=>{
            const {each} = props
            return(
                <Fragment>
                    <section onClick={()=>{setPricesInCurrency(false)}} style={{position:"fixed", zIndex:11, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                    </section>
                    <section style={{position:"fixed", zIndex:12, top:"10%", left:0, width:"100%", borderRadius:0, backgroundColor:"rgba(255,255,255,0.0)"}}>
                        <section onMouseLeave={()=>{setPricesInCurrency(false)}} style={{position:"relative",backgroundColor:"rgba(255,255,255,1.0)", margin:"auto", width:250, boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)", borderRadius:5, paddingTop:5, paddingBottom:5}}>
                            <section style={{position:"relative", margin:"auto", width:200, marginTop:20, marginBottom:20, borderWidth:1, borderColor:"#f1f1f1", borderStyle:"solid", borderRadius:5}}>
                                <h4 style={{textAlign:"left", marginLeft:30}}>Real Time<br/><span style={{fontSize:"22pt", color:"#16a068"}}>Conversion</span></h4>
                                <ul className='currency-list-price'>
                                    <li>
                                        <b>PHP</b> <span style={{color:"#16a068"}}>{!currencyList?{currencySniffer}:(parseFloat(currencyList['PHP'])*parseFloat(each)).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <b>USD</b> <span style={{color:"#16a068"}}>{!currencyList?{currencySniffer}:(parseFloat(currencyList['USD'])*parseFloat(each)).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <b>EURO</b> <span style={{color:"#16a068"}}>{!currencyList?{currencySniffer}:(parseFloat(currencyList['EUR'])*parseFloat(each)).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <b>GBP</b> <span style={{color:"#16a068"}}>{!currencyList?{currencySniffer}:(parseFloat(currencyList['GBP'])*parseFloat(each)).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <b>AUD</b> <span style={{color:"#16a068"}}>{!currencyList?{currencySniffer}:(parseFloat(currencyList['AUD'])*parseFloat(each)).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <b>CAD</b> <span style={{color:"#16a068"}}>{!currencyList?{currencySniffer}:(parseFloat(currencyList['CAD'])*parseFloat(each)).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <b>YUAN</b> <span style={{color:"#16a068"}}>{!currencyList?{currencySniffer}:(parseFloat(currencyList['CNY'])*parseFloat(each)).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <b>RUBLE</b> <span style={{color:"#16a068"}}>{!currencyList?{currencySniffer}:(parseFloat(currencyList['RUB'])*parseFloat(each)).toFixed(2)}</span>
                                    </li>
                                </ul>
                            </section>
                        </section>
                    </section>
                </Fragment>
            )    
        }
        const [allocated, setAllocated] = useState(0)
        return(
            <Fragment>
                {proToggler===true?<section className='client-dash'> 
                    
                    <button onClick={()=>{setProToggler(false)}} style={{position:"absolute", right:20, marginTop:8, backgroundColor:"#16a068", borderWidth:0, padding:"5px 15px 5px 15px", borderRadius:25, color:"white", cursor:"pointer"}}>Minimize</button>
                    <span style={{position:"absolute", left:30, marginTop:8, fontSize:"12pt", color:"#16a068", textAlign:"center"}}>ACTIVE PROJECTS</span><br/><br/>
                    {userItem.length === 0?
                        <div style={{position:"fixed", zIndex:2, width:"100%", height:"100%", margin:"auto", top:0, left:0, backgroundColor:"rgba(255,255,255,1.0)"}}>
                            <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                                <span style={{color:"#16a068"}}>loading data...</span>
                            </section>
                        </div>
                    :<Fragment>
                        {userItem.map(euser=>{
                            
                            if(euser.username === user){
                                return(
                                    <Fragment>
                                        {euser.orders.map(item=>{
                                            let vilar = 0
                                            let fcurrency = ''
                                            let fconversed = ''
                                            let converted = ''
                                            let backer = ''
                                            let currmoney = 0
                                            if(item.status === 'active'){
                                                return(
                                                    <Fragment>
                                                        <section className='project-single'>
                                                            <ul className='single-spanned'>
                                                                <li>
                                                                    {dito.map(omg=>{
                                                                        if(omg.title === item.categoryChosen){
                                                                            //src={imageSSrc} 
                                                                            let imageSSrc = omg.imageLarge.replace('www.dropbox.com','dl.dropboxusercontent.com')
                                                                            let roestyle = {
                                                                                    position:"absolute",
                                                                                    height:72,
                                                                                    clip: "rect(15px,72px,72px,15px)"
                                                                            }
                                                                            return(
                                                                                <Fragment>
                                                                                    <div style={{position:"relative", width:72}}>
                                                                                        <img onLoad = {currencySniffer} src = {imageSSrc} style={roestyle} />
                                                                                    </div>
                                                                                </Fragment>
                                                                            )
                                                                        }
                                                                    })}
                                                                    
                                                                </li>
                                                                <li style={{paddingLeft:90}}>
                                                                    <div style={{position:"relative", width:240, left:0}}>
                                                                        <h3 style={{position:"relative", top:0, left:0}}>{item.categoryChosen}</h3>
                                                                        <span style={{position:"relative", top:-20, left:0, fontSize:"9pt", color:"#16a068"}}>{item.progress}% Complete</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div style={{position:"relative", top:20, textAlign:"center"}}>
                                                                        <button onClick={()=>{setAllocateFund(true); setEachPrice(item.usdPrice);setItemQuantity(item.quantity); setEachOid(item.orderID); setAllocated (vilar);}}className='client-allocate-btn'>Allocate Fund</button> <br/>
                                                                        <span style={{position:"relative", top:0, fontSize:"9pt", color:"#16a068"}}>Funded:
                                                                            <b> {currencyFiat==='USD'?'$':''}
                                                                            {currencyFiat==='GBP'?'£':''}
                                                                            {currencyFiat==='PHP'?'₱':''}
                                                                            {currencyFiat==='EUR'?'€':''}
                                                                            {currencyFiat==='AUD'?'AU$':''}
                                                                            {currencyFiat==='CAD'?'CA$':''}
                                                                            {currencyFiat==='RUB'?'₽':''}
                                                                            {currencyFiat==='CNY'?'¥':''}</b>
                                                                            {item.paid.map(fund=>{
                                                                                if(fund.orderid===item.orderID){
                                                                                    let array = []
                                                                                    array.push(parseFloat(fund.amount))

                                                                                    function disarray(total, num){
                                                                                        let nija = parseFloat(total)
                                                                                        let kanje = parseFloat(num)
                                                                                        return nija+kanje
                                                                                    }
                                                                                    let total = array.reduce(disarray)
                                                                                    vilar += total
                                                                                    fcurrency = fund.currency 
                                                                                    fconversed = fund.conversions
                                                                                    converted = fconversed
                                                                                    //console.log(item.paid)
                                                                                    try{
                                                                                        backer = {...converted}
                                                                                        if(currencyFiat === 'USD'){
                                                                                            currmoney+=parseFloat(backer[0]['USD'])
                                                                                        }
                                                                                        if(currencyFiat === 'PHP'){
                                                                                            currmoney+=parseFloat(backer[1]['PHP'])
                                                                                        }
                                                                                        if(currencyFiat === 'EUR'){
                                                                                            currmoney+=parseFloat(backer[2]['EUR'])
                                                                                        }
                                                                                        if(currencyFiat === 'GBP'){
                                                                                            currmoney+=parseFloat(backer[3]['GBP'])
                                                                                        }
                                                                                        if(currencyFiat === 'AUD'){
                                                                                            currmoney+=parseFloat(backer[4]['AUD'])
                                                                                        }
                                                                                        if(currencyFiat === 'CAD'){
                                                                                            currmoney+=parseFloat(backer[5]['CAD'])
                                                                                        }
                                                                                        if(currencyFiat === 'CNY'){
                                                                                            currmoney+=parseFloat(backer[6]['CNY'])
                                                                                        }
                                                                                        if(currencyFiat === 'RUB'){
                                                                                            currmoney+=parseFloat(backer[7]['RUB'])
                                                                                        }
                                                                                    }catch(err){
                                                                                        console.log(err)
                                                                                    }
                                                                                }
                                                                        })}{currencyFiat === fcurrency?<Fragment>{(parseFloat(vilar)).toFixed(2)}</Fragment>:<Fragment>{(parseFloat(currmoney)).toFixed(2)}</Fragment>}</span> 
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <h3 onClick={()=>{setPricesInCurrency(true); setEachPrice(item.usdPrice);}} style={{cursor:"pointer"}}>Price</h3>
                                                                        <span onClick={()=>{setPricesInCurrency(true); setEachPrice(item.usdPrice);}} style={{position:"relative", top:-20, fontSize:"9pt", color:"#16a068", cursor:"pointer"}}>
                                                                            
                                                                            {currencyFiat==='USD'?'$':''}
                                                                            {currencyFiat==='GBP'?'£':''}
                                                                            {currencyFiat==='PHP'?'₱':''}
                                                                            {currencyFiat==='EUR'?'€':''}
                                                                            {currencyFiat==='AUD'?'AU$':''}
                                                                            {currencyFiat==='CAD'?'CA$':''}
                                                                            {currencyFiat==='RUB'?'₽':''}
                                                                            {currencyFiat==='CNY'?'¥':''}
                                                                           
                                                                            {(parseFloat(item.usdPrice)*parseFloat(currencyList[`${currencyFiat}`])).toFixed(2)}/each
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <h3>Handled By</h3>
                                                                        {item.handledBy===''?
                                                                        <span style={{position:"absolute", top:45, color:"#16a068", fontSize:"9pt"}}>No One</span>
                                                                        :
                                                                        <Fragment>
                                                                            {userItem.map(sole=>{
                                                                                if(sole.username === item.handledBy){
                                                                                    let pegged = []
                                                                                    sole.logins.map((log)=>{
                                                                                        pegged.push(log.logged)
                                                                                    })
                                                                                    return(
                                                                                        <Fragment>
                                                                                            {pegged.pop()==='out'?
                                                                                            <span style={{position:"relative", top:-20, left:12, fontSize:"9pt", color:"#16a068"}}>
                                                                                            <section style={{position:"absolute", top:4, left:-12,  backgroundColor:"#ababab",  width:7.5, height:7.5, borderRadius:10}}></section>
                                                                                            {item.handledBy}</span>
                                                                                            :<span style={{position:"relative", top:-20, left:12, fontSize:"9pt", color:"#16a068"}}>
                                                                                            <section style={{position:"absolute", top:4, left:-12,  backgroundColor:"lime",  width:7.5, height:7.5, borderRadius:10}}></section>
                                                                                            {item.handledBy}</span>}
                                                                                        </Fragment>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </Fragment>
                                                                        }
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <Link onClick={()=>{historian.push(`/dashboard/project/${item.orderID}`)}} style={{textDecoration:"none"}}><h3 style={{color:"#16a068"}}>View</h3></Link>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </section>
                                                    </Fragment>   
                                                ) 
                                            }
                                        })}
                                    </Fragment>
                                )                                 
                            }      
                        })}                    
                    </Fragment>}
                </section>:<section onClick={()=>{setProToggler(true)}} className='client-prev-projects'>
                            <span style={{position:"relative", fontSize:"12pt", color:"#c1c1c1", textAlign:"center"}}>Active Projects</span>
                </section>}
                {allocateFund === true?<AllocateFloater fiat = {currencyFiat} balance={bamount} alloc = {allocated} each = {eachPrice} nopcs ={itemQuantity} oid={eachOid} />:''}
                {pricesInCurrency === true?<PriceInCurrencyExchanges each = {eachPrice}/>:''}
            </Fragment>
        )
    }
//}

export default ClientActiveListing