import '../App.css';
import React, {Fragment, Link, useState} from 'react'
import Navigation from './components/desknavigation'

//COMPONENTS


let Desktop = ()=>{
    const redirector = ()=>{
          if(window.location.protocol === "http:"){
           setTimeout(()=>{window.location.assign(`https://jolme5d.com${window.location.pathname}`)}, 100)
          }else{
            return 
          }
    }
    redirector() //never forget about this each time we push it up in Heroku
    return(
        <Fragment>
            <Navigation />
        </Fragment>
    )
}

export default Desktop 