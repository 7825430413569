//LIBRARY
import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, useHistory } from 'react-router-dom'
import Axios from 'axios';

//COMPONENTS
import DeskHeader from './deskHeader';
import DeskFooter from './deskFooter';
import {APIL1, APIL2, API1, API2} from '../../config'
import DefaultLoginStateContext from '../../controllers/loginContext'

const DeskLoginErrorPage = ({setToken})=>{
    const historian = useHistory()
    // if(errorLoggingFound.loggerIn === false){

    // }
    return(
        <Fragment>
            <div id="MAIN-BODY">
                <DeskHeader />
                <div id="MAIN-DASHBOARD">
                    <div className="error_login_notice" style={{color:'#19CB5B', textAlign:'center'}}>
                        <h1 style={{textAlign:'center', fontFamily:"Baloo", fontSize:"30pt"}}>Error Login</h1>
                        <section className="error_crendential">
                        <b>Credentials entered don't match our record <br/>or that your account still under review.</b>
                        </section>
                         <br/><br/>                       
                        <button className="error_back_btn" onClick={(e)=>{e.preventDefault();historian.push('/login')}}>Go Back to Login</button><br/>
                        <button className="error_forgot_btn" onClick={(e)=>{e.preventDefault();historian.push('/password-reset')}}>Forgot Password?</button>
                    </div> <br/><br/><br/><br/>
                </div>
                <DeskFooter />
            </div>
        </Fragment>
    )
}
DeskLoginErrorPage.propTypes = {
    setToken: PropTypes.func.isRequired
}
export default DeskLoginErrorPage;