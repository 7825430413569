//LIBRARIES
import '../App.css';
import React, {Fragment, Link} from 'react'

//COMPONENTS
import MobileNavigation from './mobileComponents/mobileNavigation'

let Mobile = ()=>{
    const redirector = ()=>{
        if(window.location.protocol === "http:"){
         setTimeout(()=>{window.location.assign(`https://jolme5d.com${window.location.pathname}`)}, 100)
        }else{
          return 
        }
    }
    redirector() //forgot this one  -- the rector of all reactors, I made a minute of party
    return(
        <Fragment>
            <MobileNavigation />
        </Fragment>
    )
}

export default Mobile;