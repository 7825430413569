//LIBRARIES
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

//COMPONENTS
  //import {SetLoginStateContext} from './controllers/loginContext' 
  //<SetLoginStateContext> </SetLoginStateContext> 
import { store } from './redux/store'
import { Provider } from 'react-redux'
//import SetLoginStateContext from './controllers/loginContext' //exported export should be inside {}
ReactDOM.render(
    // <SingleContextTrial/> 
  <Provider store={store}> 
    <React.StrictMode>
        <App />       
    </React.StrictMode>
  </Provider>, 
  document.getElementById('CONCURRENCE')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

  
