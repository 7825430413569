//LIBRARY
import React from 'react' 
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

//COMPONENTS
import MobileHomePage from './mobileHomePage'
import MobileFAQsPage from './mobileFAQsPage'
import MobileServicesPage from './mobileServicesPage'
import MobileAboutUsPage from './mobileAboutUsPage'
import MobilePortfolioPage from './mobilePortfolioPage'
import MobileDashboardPage from './mobileDashboard'
import MobileErrorPage from './mobileErrorPage'

const MobileMenuRoutes = ()=>{
    return(
        <Router>
            <Switch>
                <Route path="/" exact component = {MobileHomePage}/>
                <Route path="/services" exact component = {MobileServicesPage}/>
                <Route path="/portfolio" exact component = {MobilePortfolioPage}/>
                <Route path="/portfolio/:category" exact component = {MobilePortfolioPage}/>
                <Route path="/aboutus" exact component = {MobileAboutUsPage}/>
                <Route path="/faqs" exact component = {MobileFAQsPage}/>
                <Route path="/dashboard" component = {MobileDashboardPage}/> 
                <Route path="/error" component = {MobileErrorPage}/>
            </Switch>
        </Router>
    )
}

export default MobileMenuRoutes;