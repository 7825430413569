//LIBRARIES
import React, {Fragment, useState, useEffect} from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {orderen, querriedData} from '../../../redux/querries/userdataquery'
import {CounterClientOrderView, CounterOrderView} from '../../../redux/viewslices/counterview'
import { currBalanceView, defaultCurrBalance, proPicView, viewUser, viewOrder} from '../../../redux/slices/counter'

//MUI LIBRARIES
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/styles';

//COMPONENTS
import { 
    SDAPIL, 
    SDAPIW,
    OAPIL,
    OAPIW, 
    ADUX_ALLAPIL, 
    ADUX_ALLAPIW, 
    DELEACC_CW,
    DELEACC_CL
} from '../../../config'
import {accessSwitch} from '../variables'

const EmpActiveListing = (props)=>{
        const historian = useHistory()
        const {user, userid} = props //user us superceded by upuser
        //USERNAME MUST NOT BE CHANGED - DOING SO COULD CAUSE DISPLAY ERROR
        const upuser = useSelector(state => state.counter.userDetails.username)
        const dispatch = useDispatch()
        const loadProfilePic = ()=>{
            setInterval(()=>{
                dispatch(proPicView())
                dispatch(viewUser())
                dispatch(viewOrder())
            },1000)
        }
        loadProfilePic() //triggering to reload every 1 second

        const [proToggler, setProToggler] = useState(true)

        const pitch = accessSwitch //true to Web-based MongoDB
        const aduxSwitcher =(bol)=>{
            if(bol===true){
                return ADUX_ALLAPIW
            }else{
                return ADUX_ALLAPIL
            }
        }
        const switcher =(bol)=>{
                if(bol===true){
                    return SDAPIW
                }else{
                    return SDAPIL
                }
        }
        //const [corders, setCorders] = useState(null)
        const [orders]= orderen
        const [userItem, setUserItem] = useState([])
        const [ordersItem, setOrdersItem] = useState([])
        const [isGashing, setIsGashing] = useState(true)

        const ordersLanded = async()=>{
            await fetch(aduxSwitcher(pitch)) //true to Web-based MongoDB
            .then((responsive)=>responsive.json())
            .then((data)=>{
                const wanton = data.data
                const drearies = []
                for(const key in wanton){
                    drearies.push({
                        id:key,
                        uid:wanton[key].userUniqueID,
                        username: wanton[key].username,
                        email: wanton[key].email,
                        role: wanton[key].role,
                        dateSince: wanton[key].dateCreated,
                        orders:wanton[key].orders, 
                        payments:wanton[key].paymentSources,
                        orders:wanton[key].orders,
                        logins:wanton[key].logins
                    })
                }
                setUserItem(drearies)
            })
        }
        useEffect(()=>{
            setTimeout(handleSmallImage(), 1000)
            ordersLanded()
            
        },[]) 
        //console.log(userItem)

        //SERVICES LIST LOADING
        const [dito, setDito] = useState([])
        const [isLoading, setIsLoading] = useState(true)
        const servicesPingAPI = async()=>{
            setIsLoading(true)
            await fetch(switcher(pitch)) //switcher(pitch) //'https://webbyjolme.com/api/j5d-servicesdisplay/'
            .then((responder)=>responder.json())
            .then((data)=>{
                const duga = data.data
                const doughnut = []
                for(const key in duga){
                    doughnut.push({
                        id:key,
                        title: duga[key].title,
                        price: duga[key].price,
                        description: duga[key].description,
                        imageLarge: duga[key].imageLarge,
                        imageSmall: duga[key].imageSmall,
                        tags: duga[key].tags
                    })
                }
                setDito(doughnut)
            })
            setIsLoading(false)
        } 
        const handleSmallImage = ()=>{
            servicesPingAPI()
        }
        //PROGRESS SLIDER
        const [statusSlider, setStatusSlider] = useState(false)
        const [orderNum, setOrderNum] = useState("")
        const [orderProgress, setOrderProgress] = useState(0)
        console.log(orderProgress)//
        const StatusConsole = (props)=>{
            const {clientID, orderid, progressive} = props
            //PROJECT STATUS SLIDER CONSOLE
            const LinearProgressWithLabel = (props)=>{
                return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1}}>
                    <LinearProgress variant="determinate" {...props} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="#16a068">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                    </Box>
                </Box>
                );
            }
            
            LinearProgressWithLabel.propTypes = {
                /**
                 * The value of the progress indicator for the determinate and buffer variants.
                 * Value between 0 and 100.
                 */
                value: PropTypes.number.isRequired,
            };
            
            const LinearWithValueLabel = (props)=>{
                const {progression} = props
                console.log("Progression: "+progression)
                const useStyles = makeStyles(() => ({
                    root: {
                        "& .MuiLinearProgress-colorPrimary": {
                            backgroundColor: "red",
                        },
                        "& .MuiLinearProgress-barColorPrimary": {
                            backgroundColor: "#1ddd64",
                        },
                    },
                }))
                
                const [progress, setProgress] = useState(progression); //defNum
                useEffect(() => {
                const timer = setInterval(() => {
                    setProgress(progress); //(prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10)
                }, 800);
                return () => {
                    clearInterval(timer);
                };
                }, []);
                const classes = useStyles()
                return (
                <Box sx={{ width: '100%' }} className={classes.root}>
                    <LinearProgressWithLabel value={progress} style={{backgroundColor:"#16a068"}}/>
                </Box>
                );
            }
            //PERCENTAGE Scaler
            const disectUsers = (upuser, orderNum)=>{
                let rubinhood = 0
                {userItem.map(euser=>{
                            {euser.orders.map(order=>{
                                if(order.handledBy === upuser  && order.status === 'active'){ 
                                    if(order.orderID === orderNum){
                                        rubinhood += order.progress
                                    }
                                }
                            })}       
                })} 
                return rubinhood
            }
            // const disectUserID = ()=>{
            //     let userId = ""
            //     {userItem.map(euser=>{
            //            if(euser.username === ){
            //             userId +=euser.userid
            //            }   
            //     })} 
            //     console.log("Euser: "+userId)
            //     //return userId
            // }
            const [defNum, setDefNum] = useState(disectUsers(upuser, orderNum))
            const decreaseCount = ()=>{
                let def = defNum
                if(def===disectUsers(upuser, orderNum)){
                    return
                }
                if(def<90){
                    def--
                    setDefNum(def)
                }
            }
            const increaseCount = ()=>{
                let incref = defNum 
                if(incref<90){
                    incref++
                    setDefNum(incref)
                }
            }
            const updateWorkProgressSwitcher = (pitch)=>{
                if(pitch===true){
                    return "https://salty-inlet-81949.herokuapp.com/api/j5d-client-order-progress-update/" //DELEACC_CW
                }else{
                    return "http://localhost:8888/api/j5d-client-order-progress-update/" //DELEACC_CL 
                }
            }
            const getWorkProgressUpdated = async()=>{
                console.log("Updating progress...")
                const progressRes = await fetch(updateWorkProgressSwitcher(pitch), {
                    method:"POST",
                    headers:{"Content-Type":"application/json"},
                    body:JSON.stringify({userUniqueID:clientID, orderID:orderid, progress:defNum})
                })
                const progressRec = await progressRes.json()
                const {status, data} = progressRec
                if(status ==="Successful!"){
                    console.log("<<Progress Updated>>")
                }
            }
            return(
                <Fragment>
                    <section onClick={()=>{setStatusSlider(false); setOrderNum(""); window.location.reload(true);}} style={{position:"fixed", zIndex:9, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                    </section>
                    <section style={{position:"fixed", zIndex:9, top:0, left:0, margin:"auto", width:"100%", borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,0.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                        <section style={{position:"relative", margin:"auto", top:160,  width:"50%"}}>
                            {defNum>30?
                            <section style={{position:"relative", margin:"auto", top:0, width:"100%", backgroundColor:"#fff", padding:"20px 20px 20px 20px", margin:20, borderRadius:10, paddingBottom:30}}>
                                <button onClick={()=>{setStatusSlider(false); setOrderNum(""); window.location.reload(true);}} className='deposit-close-btn' style={{position:"absolute",backgroundColor:"white", color:"#16a068", padding:"16px 20px 16px 20px", top:0, right:2, borderRadius:10}}>
                                <span style={{fontWeight:"bold"}}>X</span>
                                </button>
                                <span style={{color:"#16a068", fontSize:"8pt"}}>PROJECT ORDER# {orderid.toUpperCase()}</span>
                                <h1>How much progress does this project have so far?</h1>
                                <section onMouseOut={getWorkProgressUpdated} style={{position:"absolute", top:62,  width:100,  right:63, padding:"10px 20px 10px 20px"}}>
                                        <span  style={{position:"absolute", fontWeight:600, width:120, right:75, top:0}}>Percentage</span>
                                        <button class='creaseBTN1' onClick={decreaseCount}>
                                            <svg style={{color:"#fff"}} xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                        </button>
                                        <input type="text" value={defNum} style={{position:"absolute", width:20, right:35, top:0, padding:"2px 5px 2px 5px", borderColor:"#16a068", borderStyle:"solid", borderRadius:3}} />
                                        <button class='creaseBTN2' onClick={increaseCount}>
                                            <svg style={{color:"#fff"}} xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                </section>
                                <LinearWithValueLabel progression={!defNum?progressive:defNum} />
                            </section>
                            :
                            <section style={{position:"relative", margin:"auto", top:0, width:"100%", backgroundColor:"#fff", padding:"20px 20px 20px 20px", margin:20, borderRadius:10, paddingBottom:0}}>
                                <button onClick={()=>{setStatusSlider(false); setOrderNum(""); window.location.reload(true);}} className='deposit-close-btn' style={{position:"absolute",backgroundColor:"white", color:"#16a068", padding:"16px 20px 16px 20px", top:0, right:-55, borderRadius:10}}>
                                <span style={{fontWeight:"bold"}}>X</span>
                                </button>
                                <span style={{color:"#16a068", fontSize:"8pt"}}>PROJECT ORDER# {orderid.toUpperCase()}</span>
                                <h1 style={{position:"relative", top:-30, fontSize:"33pt", textAlign:"center"}}>Project not fully funded</h1>
                                <p style={{position:"relative", top:-50, fontSize:"16pt",color:"#16a068",  textAlign:"center"}}>You wait until this project is fully funded.</p>
                            </section>
                            }
                        </section>
                    </section>
                </Fragment>
            )
        }
        const [clientID, setClientID] = useState("")
        return(
            <Fragment>
                {proToggler===true?<section className='client-dash'> 
                    
                    <button onClick={()=>{setProToggler(false)}} style={{position:"absolute", right:20, marginTop:8, backgroundColor:"#16a068", borderWidth:0, padding:"5px 15px 5px 15px", borderRadius:25, color:"white", cursor:"pointer"}}>Minimize</button>
                    <span style={{position:"absolute", left:30, marginTop:8, fontSize:"12pt", fontWeight:"bold", color:"#000", textAlign:"center"}}>CURRENT ASSIGNMENTS</span><br/><br/>
                    {userItem===null?
                        <div style={{position:"fixed", zIndex:2, width:"100%", height:"100%", margin:"auto", top:0, left:0, backgroundColor:"rgba(255,255,255,1.0)"}}>
                            <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                                <span style={{color:"#16a068"}}>loading data...</span>
                            </section>
                        </div>
                    :<Fragment>
                        {userItem.map(euser=>{
                            return(
                                <Fragment>
                                    {euser.orders.map(order=>{
                                        if(order.handledBy === upuser  && order.status === 'active'){
                                            return(
                                                <Fragment>
                                                    <section className='project-single'>
                                                        <ul className='single-spanned'>
                                                            <li>
                                                                {dito.map(omg=>{
                                                                    if(omg.title === order.categoryChosen){
                                                                        //src={imageSSrc} 
                                                                        let imageSSrc = omg.imageLarge.replace('www.dropbox.com','dl.dropboxusercontent.com')
                                                                        let roestyle = {
                                                                                position:"absolute",
                                                                                height:72,
                                                                                clip: "rect(15px,72px,72px,15px)"
                                                                        }
                                                                        return(
                                                                            <Fragment>
                                                                                <div style={{position:"relative", width:72}}>
                                                                                    <img src = {imageSSrc} style={roestyle} />
                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                })}
                                                            </li>
                                                            <li>
                                                                <div style={{position:"relative", width:280, left:100}}>
                                                                    <h3 style={{position:"relative", top:0, left:0}}>{order.categoryChosen}</h3>
                                                                    <span style={{position:"relative", top:-20, left:0, fontSize:"9pt", color:"#16a068"}}>{order.progress}% Complete</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <h3>Order By</h3>
                                                                    {userItem.map(sole=>{
                                                                        if(sole.username === euser.username){
                                                                            let pegged = []
                                                                            sole.logins.map((log)=>{
                                                                                pegged.push(log.logged)
                                                                            })
                                                                            return(
                                                                                <Fragment>
                                                                                    {pegged.pop()==='out'?
                                                                                    <span style={{position:"relative", top:-20, left:12, fontSize:"9pt", color:"#16a068"}}>
                                                                                    <section style={{position:"absolute", top:4, left:-12,  backgroundColor:"#ababab",  width:7.5, height:7.5, borderRadius:10}}></section>
                                                                                    {euser.username}</span>
                                                                                    :<span style={{position:"relative", top:-20, left:12, fontSize:"9pt", color:"#16a068"}}>
                                                                                    <section style={{position:"absolute", top:4, left:-12,  backgroundColor:"lime",  width:7.5, height:7.5, borderRadius:10}}></section>
                                                                                    {euser.username}</span>}
                                                                                </Fragment>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <button className='view-client-project-btn' onClick={()=>{historian.push(`/dashboard/project/${order.orderID}`)}}>
                                                                    <span>View</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button onClick={()=>{
                                                                    loadProfilePic();
                                                                    setClientID(euser.uid)
                                                                    setStatusSlider(true); 
                                                                    setOrderNum(order.orderID); 
                                                                    setOrderProgress(parseFloat(order.progress));
                                                                    }} className='update-project-status-btn'>
                                                                    <span>Update Project Status</span>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </section>
                                                </Fragment>
                                            ) 
                                        }
                                    })}
                                    
                                </Fragment>
                            )       
                        })}                
                    </Fragment>}
                </section>:<section onClick={()=>{setProToggler(true)}} className='client-prev-projects'>
                            <span style={{position:"relative", fontSize:"12pt", color:"#c1c1c1", textAlign:"center"}}>Active Projects</span>
                </section>}
                {statusSlider === true?<StatusConsole clientID = {clientID} orderid = {orderNum} progressive = {orderProgress}/>:""}
            </Fragment>
        )
    }
//}

export default EmpActiveListing