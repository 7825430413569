import React, {Fragment, useEffect, useState, useContext, Profiler} from 'react'
import { BrowserRouter as Router,
    Switch,
    Route, 
    useParams, 
    useRouteMatch,
    useHistory} from 'react-router-dom'


const MobileDashboardFooterStrip = ()=>{
    return(
        <Fragment>
            <section style={{position:"fixed", bottom:0, zIndex:10,  width:"100%", height:50, backgroundColor:"#000"}}>
                <section style={{margin:"auto", width:"96%"}}>
                    <span style={{color:"#16a068"}}><b>Any Issues?</b><br/>Email to <b>webbyjolme@jolme5d.com</b></span>
                </section>
            </section>
        </Fragment>
    )
}

export default MobileDashboardFooterStrip