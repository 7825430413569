//LIBRARIES
import React, {Fragment, useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/styles';
import EmojiPicker, { Picker, Emoji, EmojiStyle} from 'emoji-picker-react' 

//COMPONENTS
import {currPrimary, currBalanceView, defaultCurrBalance, proPicView} from '../../../redux/slices/counter'
import InquiryChannel from '../../../redux/viewslices/inquiryChannel'
import ChattyClientLoader from './clientChattyPlace'
import ClientActivityExchanges from './clientActivityExchanges'
import {orderen, querriedData} from '../../../redux/querries/userdataquery'
import { 
    SDAPIL, SDAPIW,
    OAPIL, OAPIW,
    ADUX_ALLAPIL, ADUX_ALLAPIW,
    BUDALOC_CL, BUDALOC_CW
} from '../../../config'
import {accessSwitch} from '../variables'

const ClientProjectActivity = (props)=>{
    const {projectID, user, userid, corders} = props //corders is a data object from JWT

    const pitch = accessSwitch //true to Web-based MongoDB
    const aduxSwitcher =(bol)=>{
        if(bol===true){
            return ADUX_ALLAPIW
        }else{
            return ADUX_ALLAPIL
        }
    }
    const [orders]= orderen //this data object is from Redux
    const [userItem, setUserItem] = useState([])
    const [orderSingle, setOrderSingle] = useState([])
    const [isOozing, setIsOozing] = useState(true)
    const singleProject = async()=>{
        setIsOozing(true)
        await fetch(aduxSwitcher(pitch)) //true to Web-based MongoDB
            .then((responsive)=>responsive.json())
            .then((data)=>{
                const wanton = data.data
                const drearies = []
                for(const key in wanton){
                    drearies.push({
                        id:key,
                        uid:wanton[key].userUniqueID,
                        username: wanton[key].username,
                        email: wanton[key].email,
                        role: wanton[key].role,
                        dateSince: wanton[key].dateCreated,
                        orders:wanton[key].orders, 
                        payments:wanton[key].paymentSources,
                        orders:wanton[key].orders,
                        logins:wanton[key].logins
                    })
                }
                setUserItem(drearies)
        })
        
        setIsOozing(false)
    }
    useEffect(()=>{
        singleProject()
        servicesPingAPI()
    },[]) 

    //SERVICES LIST LOADING
    const [dito, setDito] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const switcher =(bol)=>{
            if(bol===true){
                return SDAPIW
            }else{
                return SDAPIL
            }
    }
    const servicesPingAPI = async()=>{
        setIsLoading(true)
        await fetch(switcher(pitch)) //switcher(pitch) //'https://webbyjolme.com/api/j5d-servicesdisplay/'
        .then((responder)=>responder.json())
        .then((data)=>{
            const duga = data.data
            const doughnut = []
            for(const key in duga){
                doughnut.push({
                    id:key,
                    title: duga[key].title,
                    price: duga[key].price,
                    description: duga[key].description,
                    imageLarge: duga[key].imageLarge,
                    imageSmall: duga[key].imageSmall,
                    tags: duga[key].tags
                })
            }
            setDito(doughnut)
        })
        setIsLoading(false)
    } 
    
    const historian = useHistory()
    //query projectID into the DataBase
    const [activity, setActivity] = useState(true)
    const [details, setDetails] = useState(false)
    const [requirements, setRequirements] = useState(false)

    const [progressViewer, setProgressViewer] = useState(false)
    const [onHandler, setOnHandler] = useState(false)
    const [chatHead, setChatHead] = useState(false)
    const [chatClose, setChatClose] = useState(false)

    const LinearProgressWithLabel = (props)=>{
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1}}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="#16a068">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }
      
      LinearProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate and buffer variants.
         * Value between 0 and 100.
         */
        value: PropTypes.number.isRequired,
      };
      
      const LinearWithValueLabel = (props)=>{
        const {progression} = props
        const useStyles = makeStyles(() => ({
            root: {
                "& .MuiLinearProgress-colorPrimary": {
                    backgroundColor: "red",
                },
                "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: "#1ddd64",
                },
            },
        }))

        const [progress, setProgress] = useState(progression);
      
        useEffect(() => {
          const timer = setInterval(() => {
            setProgress(progress); //(prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10)
          }, 800);
          return () => {
            clearInterval(timer);
          };
        }, []);
        const classes = useStyles()
        return (
          <Box sx={{ width: '100%' }} className={classes.root} >
            <LinearProgressWithLabel value={progress} style={{backgroundColor:"#16a068"}}/>
          </Box>
        );
      }
    //ALLOCATE FUND
    const bamount = useSelector(state => state.counter.currBalance)
    const currencyFiat = useSelector(state => state.counter.currFiat)

    const [allocated, setAllocated] = useState(0)
    const [eachPrice, setEachPrice] = useState(0)
    const [eachOid, setEachOid] = useState('')
    const [itemQuantity, setItemQuantity] = useState(0)

    const [money, setMoney] = useState(0)
    const [allocateFund, setAllocateFund] = useState(false)
    const getMoneySticked = (num)=>(event)=>{
      event.preventDefault()
      let money = event.target.value
      setMoney(money)
      console.log("MyMoney: "+money)
    }
    const AllocateFloater = (props)=>{
        const {fiat, balance, each, nopcs, oid, alloc} = props
        //processing allocation
        
        const [currencyList, setCurrencyList] = useState({
            PHP:0,
            USD:0,
            EUR:0,
            GBP:0,
            AUD:0,
            CAD:0,
            CNY:0,
            RUB:0
        })
        const [processStatus, setProcessStatus] = useState(false)
        const [pushedSuccessful, setPushedSuccessful] = useState(false)
        const budgetAllocationSwitcher = (bol)=>{
            if(bol===true){
                return  BUDALOC_CW
            }else{
                return  BUDALOC_CL
            }
        }
        const processAllocation = async()=>{
            setProcessStatus(true)
            const pushAllocation = await fetch(budgetAllocationSwitcher(pitch), {
                method:"POST",
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify({
                    userUniqueID:userid, 
                    orderid:oid, 
                    balance:balance, 
                    amount:money, 
                    conversions:[
                        {USD : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['USD'])).toFixed(2)}, 
                        {PHP : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['PHP'])).toFixed(2)}, 
                        {EUR : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['EUR'])).toFixed(2)}, 
                        {GBP : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['GBP'])).toFixed(2)}, 
                        {AUD : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['AUD'])).toFixed(2)}, 
                        {CAD : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['CAD'])).toFixed(2)}, 
                        {CNY : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['CNY'])).toFixed(2)}, 
                        {RUB : (parseFloat(money)*parseFloat(currencyList[`${fiat}`])*parseFloat(currencyList['RUB'])).toFixed(2)}
                    ],  
                    currencyUsed:fiat})
            })
            const responsed = await pushAllocation.json()
            const {data, status} = responsed
            if(status === "Successful!"){
                setProcessStatus(false)
                setMoney(0.00)
                setPushedSuccessful(true)
                // console.log("Amount Updated!")
                // setTimeout(()=>{
                    
                // }, 2000)
            }
            
        }
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                </section>
                <section style={{position:"absolute", zIndex:2, top:-300, margin:"auto", left:"auto", width:"100%", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                    <button onClick={()=>{setAllocateFund(false)}} className='deposit-close-btn'>
                        X CLOSE
                    </button>
                    <section style={{position:"relative", margin:"auto", width:"75%", marginTop:50, marginBottom:50}}>
                        <section style={{position:"absolute", left:-40, top:-20, width:200, height:200, borderWidth:1, borderStyle:"solid", borderColor:"#f1f1f1", borderRadius:5, textAlign:"center"}}>
                            <h4 style={{color:"#16a068"}}>Current Balance</h4>
                            <span>USD10.00</span><br/>
                            <h4 style={{color:"#16a068"}}>Spent Since</h4>
                            <span>USD110.00</span>
                        </section>
                        {processStatus===false?
                            <section style={{position:"relative", left:250}}>
                                <h3>Allocate Fund For This Project</h3>
                                <label style={{position:"absolute", zIndex:2, top:45, left:20, fontSize:"10pt", color:"#d1d1d1"}}>Amount</label><input className='depo-placeholder' type="number" placeholder = {money} onMouseLeave={getMoneySticked("money")} style={{position:"relative", fontSize:"22pt", width:290, height:60, borderWidth:0.5, borderColor:"#d1d1d1", color:"#16a068", borderStyle:"solid", borderRadius:5,  padding:"5px 20px 5px 20px"}} /><br/><br/>
                                <button onClick={()=>{setMoney(0); }} className='depo-btn'>Clear</button>
                                <button onClick={processAllocation} className='depo-btn'>Allocate</button>
                            </section>
                        :
                            <section style={{position:"relative", left:250, width:350}}>
                                {pushedSuccessful===true?
                                <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                                    <h1>Fund Successfully Added!</h1>
                                </section>
                                :
                                <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                                    <span style={{color:"#16a068"}}>Processing...</span>
                                </section>
                                }
                                
                            </section>
                        }
                        
                    </section>
                </section>
            </Fragment>
        )
    }
    const [eol, setEol] = useState('')
    const getEol= ()=>{
        let stat = ''
        userItem.map((user)=>{
            user.orders.map((item)=>{
                stat=item.status
            })
        })
        setEol(stat)
    }
    useEffect(()=>{
        getEol()
    }, [])
    const [empname, setEmpname] = useState('')
    const [empmail, setEmpmail] = useState('')
    const [emppegged, setEmppegged] = useState([])
    const Activity = (props)=>{
        console.log(props.handler)
        return(
            <Fragment>
                <section className='activity-room'>
                    <section className='content-space'>
                    {!isOozing?
                        <Fragment>
                            {userItem.map(item=>{
                                // let empname = ''
                                // let empmail = ''
                                if(item.username===props.handler){
                                    setEmpname(item.username)
                                    setEmpmail(item.email)
                                    item.logins.map((log)=>{
                                        setEmppegged(log.logged)
                                    })
                                }
                                if(item.username === user){
                                    return(
                                        <Fragment>
                                            {item.orders.map(order=>{
                                                if(order.orderID === projectID){
                                                    return(
                                                        <Fragment>
                                                            <section style={{padding:"30px 30px 30px 30px", marginBottom:0}}>
                                                                <ClientActivityExchanges chatNoticeData={{empUsername:empname, empemail:empmail, empstatus:emppegged}} eol={order.status} ids = {{uid:userid, oid:order.orderID}} />
                                                            </section>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        </Fragment>
                                    )
                                }
                            })}
                        </Fragment>:
                        <Fragment>
                        {userItem.map(item=>{
                            if(item.username === user){
                                return(
                                    <Fragment>
                                        {item.orders.map(order=>{
                                            if(order.orderID === projectID){
                                                return(
                                                    <Fragment>
                                                        <section style={{padding:"30px 30px 30px 30px", marginBottom:0}}>
                                                            <ClientActivityExchanges eol={order.status} ids = {{uid:userid, oid:order.orderID}} />
                                                        </section>
                                                    </Fragment>
                                                )
                                            }
                                        })}
                                    </Fragment>
                                )
                            }
                        })}
                        </Fragment>}
                    </section>    
                </section>
            </Fragment>
        )
    }

    const Details = ()=>{
        return(
            <Fragment>
                <section className='activity-room'>
                    <section className='content-space'>
                    {!isOozing?
                        <Fragment>
                            {userItem.map(item=>{
                                if(item.username === user){
                                   return(
                                    <Fragment>
                                        {item.orders.map(order=>{
                                            if(order.orderID === projectID){
                                                const dParser = new Date(order.dateOrdered)
                                                return(
                                                    <Fragment>
                                                        <section style={{padding:"30px 30px 30px 30px"}}>
                                                            <h1>Date of Submission</h1>
                                                            <p>{dParser.getMonth()+1}/{dParser.getDate()}/{dParser.getFullYear()}</p> 
                                                            <h1>File Native Formats</h1>
                                                        </section>
                                                    </Fragment>
                                                )
                                            }
                                        })}
                                    </Fragment>
                                   ) 
                                }
                            })}
                        </Fragment>:
                        <Fragment>
                            {userItem.map(item=>{
                                if(item.orderID === projectID){
                                    return(
                                        <Fragment>
                                            <section>
                                                <p>{item.addedMsg}</p>
                                            </section>
                                        </Fragment>
                                    )
                                }
                            })}
                        </Fragment>}
                    </section>    
                </section>
            </Fragment>
        )
    }

    const Requirements = ()=>{
        return(
            <Fragment>
                <section className='activity-room'>
                    <section className='content-space'>
                    {!isOozing?
                        <Fragment>
                            {userItem.map(item=>{
                                if(item.username === user){
                                    return(
                                        <Fragment>
                                            {item.orders.map(order=>{
                                                if(order.orderID === projectID){
                                                    return(
                                                        <Fragment>
                                                            <section style={{padding:"30px 30px 30px 30px"}}>
                                                                <h1>Project Description</h1>
                                                                <p>{order.addedMsg}</p>
                                                            </section>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        </Fragment>
                                    )
                                }
                            })}
                        </Fragment>:
                        <Fragment>
                            {userItem.map(item=>{
                                if(item.username === user){
                                    return(
                                        <Fragment>
                                            {item.map(item=>{
                                                if(item.orderID === projectID){
                                                    return(
                                                        <Fragment>
                                                            <section style={{padding:"30px 30px 30px 30px"}}>
                                                                <h1>Project Description</h1>
                                                                <p>{item.addedMsg}</p>
                                                            </section>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        </Fragment>
                                    )
                                }
                            })}
                        </Fragment>}
                    </section>    
                </section>
            </Fragment>
        )
    }

    const ProgressAllViewer = ()=>{
        return(
            <Fragment>
                <section style={{width:"96%", backgroundColor:"#16a068", height:600, borderRadius:5, borderWidth:1, borderStyle:"solid", borderColor:"#f1f1f1", margin:"auto", marginTop:30, marginBottom:20, paddingTop:20}}>
                    <button onClick={(e)=>{e.preventDefault(); setProgressViewer(false)}} style={{position:"absolute", backgroundColor:"#000000", color:"white", borderWidth:0, borderRadius:5, padding:"5px 10px 5px 10px", right:40, cursor:"pointer"}}>
                        X CLOSE
                    </button>
                    <h3 style={{position:"relative", textAlign:"right", top:-22, right:115, color:"#000000"}}>Project Milestones</h3>
                </section>
            </Fragment>
        )
    }
    const [chatSpace, setChatSpace] = useState(false)
    const [orderIDForChat, setOrderIDForChat] = useState({user:'', uid:'', oid:''})
    const ChatChannelWithHandler = ()=>{ //channel for Admin & Manager
        return(
            <Fragment>
                <section onClick = {()=>{setChatHead(false);}} style={{position:"fixed", zIndex:2, width:"100%", height:"100%", top:0, left:0, margin:"auto", backgroundColor:"rgba(0,0,0,0.2)"}}>

                </section>
                {/* CHAT WITH MANAGER */}
                <section style={{position:"fixed", zIndex:2, width:"25%", height:"80%",  top:50, left:20, margin:"auto", backgroundColor:"rgba(255,255,255)", paddingBottom:35, borderWidth:1, borderColor:"#fff", borderStyle:"solid", borderRadius:5, boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 5px  5px 0 rgba(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", margin:"auto", top:-40,width:75, height:75, borderRadius:40, backgroundColor:"white", borderStyle:"solid", borderWidth:1,borderColor:"#f1f1f1"}}>
                        {chatClose===true?
                        <section onMouseOver={()=>{setChatClose(true)}}  style={{position:"relative", margin:"auto", top: 12, width:50, height:50, borderRadius:25, backgroundColor:"#f1f1f1", cursor:"pointer"}}>
                        </section>:
                        <section onClick={(e)=>{e.preventDefault(); setChatHead(false); setChatClose(false)}} onMouseOut={()=>{setChatClose(false)}} style={{position:"relative", margin:"auto", textAlign:"center", top: 12, width:50, height:50, borderRadius:25, backgroundColor:"#16a068", cursor:"pointer"}}>
                            <span onClick={(e)=>{e.preventDefault(); setChatHead(false)}}  style={{position:"relative", color:"white", top:12}}>X</span>
                            <button style={{position:"absolute", top:12, width:166, padding:"3px 5px 3px 5px", backgroundColor:"#16a068", color:"#fff", fontSize:"9pt", borderWidth:0, borderRadius:10, cursor:"pointer"}}>Channel with Manager</button>
                        </section>
                        }
                    </section>
                    {chatSpace === true?<InquiryChannel/>:''}
                </section>
                {/* CHAT WITH EMPLOYEE */}
                <section style={{position:"fixed", zIndex:2, width:"25%", height:"80%",  top:50, right:20, margin:"auto", backgroundColor:"rgba(255,255,255)", paddingBottom:35, borderWidth:1, borderColor:"#fff", borderStyle:"solid", borderRadius:5, boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 5px  5px 0 rgba(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", margin:"auto", top:-40,width:75, height:75, borderRadius:40, backgroundColor:"white", borderStyle:"solid", borderWidth:1,borderColor:"#f1f1f1"}}>
                        {chatClose===true?
                        <section onMouseOver={()=>{setChatClose(true)}}  style={{position:"relative", margin:"auto", top: 12, width:50, height:50, borderRadius:25, backgroundColor:"#f1f1f1", cursor:"pointer"}}>
                        </section>:
                        <section onClick={(e)=>{e.preventDefault(); setChatHead(false); setChatClose(false)}} onMouseOut={()=>{setChatClose(false)}} style={{position:"relative", margin:"auto", textAlign:"center", top: 12, width:50, height:50, borderRadius:25, backgroundColor:"#16a068", cursor:"pointer"}}>
                            <span onClick={(e)=>{e.preventDefault(); setChatHead(false)}}  style={{position:"relative", color:"white", top:12}}>X</span>
                            <button style={{position:"absolute", top:12, width:166, padding:"3px 5px 3px 5px", backgroundColor:"#16a068", color:"#fff", fontSize:"9pt", borderWidth:0, borderRadius:10, cursor:"pointer"}}>Chat with {orderIDForChat.handler}</button>
                        </section>
                        }
                    </section>
                    {chatSpace === true?<ChattyClientLoader ids = {orderIDForChat} />:''}
                </section>
            </Fragment>
        )
    }
    const [chatManagerHead, setChatManagerHead] = useState(false)
    const ChatChannelWithHandlerManagerOnly = ()=>{
        return(
            <Fragment>
                <section onClick = {()=>{setChatManagerHead(false);}} style={{position:"fixed", zIndex:2, width:"100%", height:"100%", top:0, left:0, margin:"auto", backgroundColor:"rgba(0,0,0,0.2)"}}>

                </section>
                {/* CHAT WITH MANAGER ONLY*/}
                <section style={{position:"fixed", zIndex:2, width:"25%", height:"80%",  top:50, right:20, margin:"auto", backgroundColor:"rgba(255,255,255)", paddingBottom:35, borderWidth:1, borderColor:"#fff", borderStyle:"solid", borderRadius:5, boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 5px  5px 0 rgba(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", margin:"auto", top:-40,width:75, height:75, borderRadius:40, backgroundColor:"white", borderStyle:"solid", borderWidth:1,borderColor:"#f1f1f1"}}>
                        {chatClose===true?
                        <section onMouseOver={()=>{setChatClose(true)}}  style={{position:"relative", margin:"auto", top: 12, width:50, height:50, borderRadius:25, backgroundColor:"#f1f1f1", cursor:"pointer"}}>
                        </section>:
                        <section onClick={(e)=>{e.preventDefault(); setChatHead(false); setChatManagerHead(false)}} onMouseOut={()=>{setChatClose(false)}} style={{position:"relative", margin:"auto", textAlign:"center", top: 12, width:50, height:50, borderRadius:25, backgroundColor:"#16a068", cursor:"pointer"}}>
                            <span onClick={(e)=>{e.preventDefault(); setChatHead(false)}}  style={{position:"relative", color:"white", top:12}}>X</span>
                            <button style={{position:"absolute", top:12, width:166, padding:"3px 5px 3px 5px", backgroundColor:"#16a068", color:"#fff", fontSize:"9pt", borderWidth:0, borderRadius:10, cursor:"pointer"}}>Channel with Manager</button>
                        </section>
                        }
                    </section>
                    {chatManagerHead === true?<InquiryChannel />:''}

                </section>
            </Fragment>
        )
    }
    const [currencyList, setCurrencyList] = useState({
        PHP:58.85,
        USD:1.0,
        EUR:0.92,
        GBP:1.32,
        AUD:0.76,
        CAD:0.72,
        CNY:6.26,
        RUB:60.88
    })
    const dispatch = useDispatch()
    const currencyPrimary = ()=>{
        dispatch(currPrimary)
    }
    const [handlerProject, setHandlerProject] = useState('')
    return(
        <Fragment>
            <section style={{position:"relative", marginTop:50, width:"100%"}}>
                <Link  className='goback-todash' to="/dashboard">
                        <svg style={{position:"relative", top:2.5, width:16}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg> Go Back to Dashboard
                </Link>
                {/* <Link  className='gonext-toproject' to="/dashboard/project/f12a8901df34dfa6a8b910ddcc78">
                        Go to the Next Project
                        <svg style={{position:"relative", top:2.5, width:16}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                </Link> */}
                <h1>Project Overview</h1>
            </section>
            {!isOozing?
            <Fragment>
                {userItem.map(zuser=>{
                    return(
                        <Fragment>
                            {zuser.orders.map(item=>{
                                if(item.orderID === projectID ){
                                    const dTimeGot = new Date(item.dateOrdered) 
                                    return(
                                        <Fragment>
                                            <section className='project-profile'>
                                            <section style={{position:"absolute", backgroundColor:"#ffffff", right:10, padding:"0px 10px 0px 10px", zIndex:2, top:-12}}>
                                                <span style={{color:"#16a068", fontSize:"10pt"}}>PROJECT #{projectID.toUpperCase()}</span>
                                            </section>
                                            <section className='project-in-the-details' style={{width:"96%", height:150, margin:"auto", marginTop:30, marginBottom:20}}>
                                                <section style={{position:"absolute", left:30, width:150, height:150, borderRadius:75, backgroundColor:"#f1f1f1"}}>
                                                    {dito.map(omg=>{
                                                        if(omg.title === item.categoryChosen){
                                                            let imageSSrc = omg.imageLarge.replace('www.dropbox.com','dl.dropboxusercontent.com')
                                                            let roestyle = {
                                                                    position:"absolute",
                                                                    height:150,
                                                                    width:150,
                                                                    clip: "rect(0px,150px,150px,0px)",
                                                                    borderRadius:"100%"
                                                            }
                                                            return(
                                                                <Fragment>
                                                                     <img onLoad={()=>{setHandlerProject(item.handledBy)}} src = {imageSSrc} style={roestyle} />
                                                                </Fragment>
                                                            )
                                                        }
                                                    })}
                                                </section>
                                                <section style={{position:"absolute", left:200, width:"auto", height:150}}>
                                                    {/* <button onClick={()=>{setAllocateFund(true)}} className='client-allocate-btn' style={{position:"absolute", top:12, left:10, padding:"2px 30px 2px 30px"}}>
                                                        Allocate Fund
                                                    </button> */}
                                                    <ul className='project-list-details'>
                                                        <li>
                                                            <section style={{}}>
                                                                <h3>{item.categoryChosen}</h3>
                                                                <span style={{position:"relative", top:-20, color:"#16a068", fontSize:"9pt"}}><b>Starts on</b> {dTimeGot.getMonth()+1}/{dTimeGot.getDate()}/{dTimeGot.getFullYear()}</span><br/>
                                                                <span style={{position:"relative", top:-20, color:"#16a068", fontSize:"9pt"}}><b>Ends on</b> {dTimeGot.getMonth()+1}/{dTimeGot.getDate()+3}/{dTimeGot.getFullYear()}</span><br/>
                                                                {item.status==="active"?
                                                                <button className='extend-btn'>EXTEND TIME</button>
                                                                :''}
                                                            </section>
                                                        </li>
                                                        <li>
                                                            <section style={{}}>
                                                                <h3>Breakdown</h3>
                                                                <span style={{position:"relative", top:-20, color:"#16a068", fontSize:"9pt"}}><b>Total:</b> {currencyFiat==='USD'?'$':''}
                                                                    {currencyFiat==='GBP'?'£':''}
                                                                    {currencyFiat==='PHP'?'₱':''}
                                                                    {currencyFiat==='EUR'?'€':''}
                                                                    {currencyFiat==='AUD'?'AU$':''}
                                                                    {currencyFiat==='CAD'?'CA$':''}
                                                                    {currencyFiat==='RUB'?'₽':''}
                                                                    {currencyFiat==='CNY'?'¥':''}
                                                                    {currPrimary}{(parseFloat(item.usdPrice)*parseFloat(currencyList[`${currencyFiat}`])).toFixed(2)*item.quantity}</span><br/>
                                                                <span style={{position:"relative", top:-20, color:"#16a068", fontSize:"9pt"}}><b>Each:</b> {currencyFiat==='USD'?'$':''}
                                                                    {currencyFiat==='GBP'?'£':''}
                                                                    {currencyFiat==='PHP'?'₱':''}
                                                                    {currencyFiat==='EUR'?'€':''}
                                                                    {currencyFiat==='AUD'?'AU$':''}
                                                                    {currencyFiat==='CAD'?'CA$':''}
                                                                    {currencyFiat==='RUB'?'₽':''}
                                                                    {currencyFiat==='CNY'?'¥':''}
                                                                    {currPrimary}{(parseFloat(item.usdPrice)*parseFloat(currencyList[`${currencyFiat}`])).toFixed(2)}
                                                                    </span><br/>
                                                            </section>
                                                        </li>
                                                        <li style={{textAlign:"center"}}>
                                                            <section style={{}}>
                                                                <h3>Ordered</h3>
                                                                <span style={{position:"relative", top:-20, color:"#16a068", fontSize:"9pt"}}>{item.quantity} pcs</span><br/>
                                                            </section>
                                                        </li>
                                                        <li style={{textAlign:"center"}}>
                                                            <section style={{}}>
                                                                <h3>Status</h3>
                                                                <section style={{position:"relative", top:-12, backgroundColor:"#16a068", padding:"2px 10px 3px 10px", borderRadius:20, color:"white", fontSize:"9pt"}}>
                                                                    {item.status.toUpperCase()}
                                                                </section>
                                                            </section>
                                                        </li>
                                                    </ul>
                                                </section>
                                                {item.status==='active'?
                                                    <section style={{position:"absolute", right:20, width:150, height:150, textAlign:"center"}}>
                                                    <span style={{position:"relative", top:-8,  color:"#16a068", fontSize:"9pt"}}>Handled By</span>
                                                    {item.handledBy === ''?
                                                    <section onClick = {()=>{setChatManagerHead(true);}} onMouseOver={(e)=>{e.preventDefault(); setOnHandler(true)}} className='handler-profile' style={{backgroundColor:"#f1f1f1"}}>
                                                        <svg width="100" height="100" viewBox="0 0 95 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_linear_918_48)"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M83.7361 78.213C90.762 69.9318 95 59.2108 95 47.5C95 36.3913 91.1866 26.1732 84.7975 18.0834C73.6691 31.4379 54.9652 49.1411 30.5 60.5C27.3915 61.9432 24.3959 63.1037 21.5143 64.013C13.3666 59.2997 6.155 53.4028 0.000634023 47.2519C0.000211511 47.3346 0 47.4172 0 47.5C0 54.3329 1.44276 60.8288 4.04027 66.6998C9.29816 66.8099 15.1263 66.0287 21.5143 64.013C27.3738 67.4027 33.7174 70.1802 40.5 72C55.0932 75.9152 70.2274 77.6662 83.7361 78.213Z" fill="#19C95B"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M94.9668 45.7079C94.9815 46.1025 94.9913 46.4983 94.9962 46.8953C82.8416 55.0917 69.0812 62.7183 53.9706 68.6691C49.7559 70.329 45.6961 71.6566 41.7905 72.6864C49.6321 77.7688 58.158 82.0597 67.3362 85.1178C69.4461 85.8208 71.5661 86.4917 73.6928 87.1318C73.3279 87.3735 72.9595 87.6102 72.5877 87.8419C70.7257 87.2741 68.869 86.6826 67.0201 86.0665C57.4517 82.8785 48.5928 78.3661 40.4771 73.0229C28.2462 76.0662 17.5532 76.175 8.37106 74.4368C8.10387 74.0494 7.84229 73.6579 7.58642 73.2623C16.6146 75.1229 27.1882 75.1698 39.3458 72.2695C28.1657 64.7389 18.4238 55.6487 10.2113 46.3226C7.08342 42.7706 4.17614 39.183 1.49457 35.6323C2.13441 33.1447 2.9707 30.7359 3.98591 28.4235C14.7994 49.215 33.0898 66.9236 40.661 71.9457C44.795 70.8969 49.1089 69.509 53.6042 67.7387C68.8626 61.7296 82.7392 54.0022 94.9668 45.7079Z" fill="#32F57B"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1211 85.017C19.072 83.2863 21.0695 81.858 23 81C32 77 32.5 75 32.5 68.5C32.5 65.2873 31.4006 63.6625 29.9868 61.5729C28.5402 59.4349 26.7643 56.8102 25.5 51.5C23 41 30 30 46.5 27C63 24 65.5 47.5 64.5 56C63.9983 60.2641 62.6159 61.5083 61.3626 62.6362C60.1175 63.7567 59 64.7625 59 68.5C59 76 62 80.5 69.5 82C71.82 82.464 74.3792 83.8849 76.8521 85.8483C68.7725 92.2068 58.5792 96 47.5 96C35.946 96 25.3556 91.8748 17.1211 85.017Z" fill="white"/>
                                                        <path d="M27.5 47C26.7 51.8 29.8333 54.6666 31.5 55.5C33.1 59.1 32.1667 65.3333 31.5 68L29.5 61.5L27.5 65C27.5 63.6667 27.1 59.9 25.5 55.5C23.5 49.9999 21.5 40 25.5 35C28.7 31 34.8333 26.3333 37.5 24.5L35.5 28C38 26 43.5 24.5 50.5 24.5C56.1 24.5 60.1667 22.8333 61.5 22C59.5 24.4 58 26 57.5 26.5L61.5 32C59.6667 30.6667 55.2 28 52 28C48.8 28 43.3333 29.3333 41 30L43.5 32C42.6667 32 40.3 32.8 37.5 36C34 40 34 49 33 47C32 45 28.5 41 27.5 47Z" fill="#118857"/>
                                                        <defs>
                                                        <linearGradient id="paint0_linear_918_48" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#16A068"/>
                                                        <stop offset="1" stop-color="#19C95B"/>
                                                        </linearGradient>
                                                        </defs>
                                                        </svg>
                                                    </section>:
                                                    <Fragment>
                                                        {chatHead === false?
                                                            <section onClick={(e)=>{
                                                                e.preventDefault(); 
                                                                setChatHead(true); 
                                                                setChatSpace(true); 
                                                                setOrderIDForChat({user:user, uid:userid, oid:item.orderID, handler:item.handledBy})}} onMouseOut={(e)=>{e.preventDefault(); setOnHandler(false)}} className='handler-profile' style={{backgroundColor:"#16a068"}}>
                                                                <svg style={{position:"relative", top:35, color:"white", width:30}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                                </svg>
                                                            </section>
                                                        :<section onClick={(e)=>{e.preventDefault(); setChatHead(true)}} onMouseOut={(e)=>{e.preventDefault(); setOnHandler(false)}} className='handler-profile' style={{backgroundColor:"#16a068"}}>
                                                            <svg style={{position:"relative", top:35, color:"white", width:30}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                                            </svg>
                                                        </section>
                                                        }
                                                    </Fragment>
                                                        
                                                    }
        
                                                    <span style={{position:"relative", top:0, color:"#000000", fontSize:"10pt", fontWeight:600}}>{item.handledBy===''?'Nobody':item.handledBy}</span><br/>
                                                    {item.handledBy===''?<button onClick={()=>{setChatManagerHead(true)}} className='talk-to-manager-btn'><span>Talk to Manager</span></button>:''}
                                                    {userItem.map(sole=>{
                                                        if(sole.username === item.handledBy){
                                                            let pegged = []
                                                            sole.logins.map((log)=>{
                                                                pegged.push(log.logged)
                                                            })
                                                            return(
                                                                <Fragment>
                                                                    {pegged.pop()==='out'?
                                                                    <span style={{position:"relative", top:-8,  color:"#dfdfdf", fontSize:"9pt"}}>Offline</span>
                                                                    :<span style={{position:"relative", top:-8, fontWeight:"bold", color:"lime", fontSize:"9pt"}}>Online</span>}
                                                                </Fragment>
                                                            )
                                                        }
                                                    })}
                                                </section>:
                                                <section style={{position:"absolute", top:70, right:30, width:150, height:150, textAlign:"center"}}> 
                                                    <span style={{fontWeight:"bolder", fontSize:"1.5em"}}>Artist</span><br/>
                                                    <span style={{color:"#16a068"}}>{item.handledBy.toUpperCase()}</span>
                                                </section>
                                                }
                                            </section>
                                            {progressViewer === false?<section onClick={()=>{setProgressViewer(true)}} style={{position:"relative", margin:"auto", width:"96%", cursor:"pointer", marginBottom:20}}>
                                                <span style={{fontSize:"10pt", textAlign:"center", color:"#16a068"}}>Project's Progress</span>
                                                <LinearWithValueLabel progression={item.progress} />
                                            </section>:<section onClick={()=>{setProgressViewer(false)}} style={{position:"relative", margin:"auto", width:"96%", cursor:"pointer", marginBottom:20}}>
                                                <span style={{fontSize:"10pt", textAlign:"center", color:"#16a068"}}>Project's Progress</span>
                                                <LinearWithValueLabel progression={item.progress} />
                                            </section>}
                                            {progressViewer===true?<ProgressAllViewer/>:''}
                                            </section>
                                        </Fragment>
                                    )                        
                            }})}
                        </Fragment>
                        ) 
                    }
                )}
            </Fragment>:''}

            
            {chatHead===true?<ChatChannelWithHandler />:''}
            {chatManagerHead===true?<ChatChannelWithHandlerManagerOnly />:''}
            <section className='activity-space'>
                <ul className='project-diverse'>
                    <li>
                        <Link onClick={(e)=>{e.preventDefault(); setActivity(true); setDetails(false); setRequirements(false);}}>
                            {activity===true?<h3 style={{color:"#000000"}}>Activity</h3>:<h3 style={{color:"#16a068"}}>Activity</h3>}
                        </Link>
                    </li>
                    <li>
                        <Link onClick={(e)=>{e.preventDefault(); setDetails(true); setActivity(false); setRequirements(false);}}>
                            {details===true?<h3 style={{color:"#000000"}}>Details</h3>:<h3 style={{color:"#16a068"}}>Details</h3>}
                        </Link>
                    </li>
                    <li>
                        <Link onClick={(e)=>{e.preventDefault(); setRequirements(true);  setDetails(false); setActivity(false);}}>
                            {requirements===true?<h3 style={{color:"#000000"}}>Requirements</h3>:<h3 style={{color:"#16a068"}}>Requirements</h3>}
                        </Link>
                    </li>
                </ul>
                {activity===true?<Activity handler={handlerProject}/>:''}
                {details===true?<Details />:''}
                {requirements===true?<Requirements />:''}
                {allocateFund === true?<AllocateFloater fiat = {currencyFiat} balance={bamount} alloc = {allocated} each = {eachPrice} nopcs ={itemQuantity} oid={eachOid}/>:''}
            </section>
        </Fragment>
    )
}

export default ClientProjectActivity