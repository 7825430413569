//LIBRARIES
import React from "react"    

//COMPONENTS
import {REDUX_APIL, REDUX_APIW, ADUX_ALLAPIL, ADUX_ALLAPIW} from '../../config'
import { pitch } from "../../screens/components/variables"

//OPERATIONS
export let mixen=[]
export let orderen = []
export let userslist = []
export let primeBalance = []
export let prophoto = ''

export const getAllUsers = async()=>{
  
  const reduxSwitcher = (pitch)=>{
    if(pitch===true){
      return ADUX_ALLAPIW
    }else{
      return ADUX_ALLAPIL
    }
  }
  const usersStop = await fetch(reduxSwitcher(pitch))
  const shadedUsers = await usersStop.json()
  const {status, data} = shadedUsers
  //console.log(data)

   userslist.push(data)
  // return orderen
}

export const querriedData = async()=>{
    var kraken = JSON.parse(localStorage.getItem('j5djwt'))
    const currentUserID = ()=>{
      if(!kraken){
        return ''
      }else{
        return kraken.userUID
      }
    }
    const reduxSwitcher = (pitch)=>{
      if(pitch===true){
        return REDUX_APIW
      }else{
        return REDUX_APIL
      }
    }
    const bishop = await fetch(reduxSwitcher(pitch),{ //webbyjolme.com
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body:JSON.stringify({userid:currentUserID()})
    })
    const jackpot = await bishop.json()
    console.log(jackpot)
    const {userUID, username, discount, mobileNumber, role, email, paypalEmail, shippingAddress, password, balance, orders, profilePhoto, penName, subdesignation, inquiries} = jackpot
    console.log(orders)

    const rubix = {
      userUID:userUID,
      username:username, 
      discount:discount,
      mobileNumber:mobileNumber,
      email:email, 
      paypalEmail:paypalEmail,
      shippingAddress:shippingAddress,
      password:password, 
      balance:balance, 
      role:role, 
      subrole:'',
      inquiries:inquiries
    }


    prophoto=profilePhoto
    console.log("profilePhoto: "+prophoto)
    mixen.push(rubix)
    //console.log(mixen)
    orderen.push(orders)
    return orderen
}

