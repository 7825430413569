//LIBRARIES
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { proPicView, viewUser, viewOrder, viewClientOrders, messageTabToggle} from '../../../redux/slices/counter'
import {querriedData, getAllUsers} from '../../../redux/querries/userdataquery'

//COMPONENTS
import {
  GCHATSYS_L, GCHATSYS_W,
  GCHATSYS_REL, GCHATSYS_REW,
  ADUX_ALLAPIL, ADUX_ALLAPIW
} from '../../../config'
import {accessSwitch} from '../variables'

//OPERATIONS
//THIS IS A MESSAGE COMPONENT
const ChattyBoxAdmin = (props)=>{ 
    const {chatWith, uid} = props
    const pitch = accessSwitch
    const user = useSelector(state => state.counter.userDetails)
    const photo = useSelector(state => state.counter.profilePic)

    const dispatch = useDispatch()
    
    dispatch(viewUser(), proPicView())
  
    const refreshUserDetails = ()=>{
      dispatch(proPicView())
    }
    //refreshUserDetails()
    const messageTabler = (top)=>{
      dispatch(messageTabToggle(top))
    }
  
    const chattySwitcher = (pitch)=>{
      if(pitch===true){
          return GCHATSYS_W
      }else{
          return GCHATSYS_L
      }
    }
    const chaReSwitcher = (pitch)=>{
      if(pitch===true){
          return GCHATSYS_REW
      }else{
          return GCHATSYS_REL
      }
    }
    //DEFAULT QUERY
    const [inquiries, setInquiries] = useState([])
    const defaultQueryMessageGetter = async(sing)=>{
      const defaultQRedux = await fetch(chaReSwitcher(pitch), {
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(sing)
      })
      const defcon = await defaultQRedux.json()
      const {status, data} = defcon
      //console.log(data)
      //console.log(data[0].inquiries[0]) //works
      let dashlings = [];
      data[0].inquiries.map(item=>{dashlings.push({uid:item.userUniqueID, username:item.uname, photo:item.profilePhoto, role:item.role, message:item.message, subrole: item.subrole, date:item.datePosted})})
      console.log(dashlings)
      setInquiries(dashlings)
    }
    useEffect(()=>{
      defaultQueryMessageGetter({userUniqueID:uid})
    },[])
    console.log(inquiries)

    const messageTabToggler = (tog)=>{
        dispatch(messageTabToggle(tog))
    }
    const [messageEntry, setMessageEntry] = useState({
      uname:'',
      role:'',
      subrole:'',
      message:''
    })
    const [refreshLoadedList, setRefreshLoadedList] = useState(false)
    const [newChatEntry, setNewChatEntry] = useState([])
  
    //RE-QUERY
    const reconnectReduxAPI = async(sing)=>{
      const reconRedux = await fetch(chaReSwitcher(pitch), {
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(sing)
      })
      const frecon = await reconRedux.json()
      const {status, data} = frecon
      console.log("Baki"+data)
      console.log(data[0].inquiries[0]) //works
      let dashlings = [];
      data[0].inquiries.map(item=>{dashlings.push({uid:item.userUniqueID, username:item.uname, photo:item.profilePhoto, role:item.role, message:item.message, subrole: item.subrole, date:item.datePosted})})
      console.log(dashlings)
      setNewChatEntry(dashlings)
    }
    //FIRST QUERY
    const processNewMessage = async()=>{
      const mEntries = {
        uname:user.username, 
        userUniqueID:uid, //<-- guest userID
        role:user.role,
        subrole:user.subrole,
        message:messageEntry.message, 
        unread:true
      }
      console.log(mEntries)
      const guestMessageQuery = await fetch(chattySwitcher(pitch), {
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body:JSON.stringify(mEntries)
      })
      const responseGchat = await guestMessageQuery.json()
      const {status, data, inquiry} = responseGchat
      if(status === 'Successful!'){
          console.log("Successfull Connection")
          reconnectReduxAPI({userUniqueID:uid}) //--> TO RE-QUERY
          //console.log(data.inquiry)
          //setNewChatEntry(inquiry)
          setRefreshLoadedList(true)
      }else{
        console.log("Something is Wrong!")
      }
      messageTabToggler('message');

    }

    const snapBackToDefault = ()=>{
      setRefreshLoadedList(false)
      defaultQueryMessageGetter({userUniqueID:uid})
    }
  
    //NON-REDUX-BASED CONTENT GENERATED
    //default message listing SHORTENER
    let display = 5
    let tailEnder = [];
    if(newChatEntry.length<5){
      let laster = newChatEntry.length
      tailEnder = newChatEntry.slice(0, laster)
    }else{
      let laster = newChatEntry.length
      tailEnder = newChatEntry.slice(newChatEntry.length-display, laster)
    }
    const RefreshChatContent = ()=>{
      console.log(newChatEntry)//<-checked
      return(
        <Fragment>
                {newChatEntry.length===0?
                <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                  <span style={{color:"#16a068"}}>loading data...</span>
                </section>
                :
                <Fragment>
                  {tailEnder.map(msg=>{
                  if(msg.length===0){
                    return(
                      <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>data loading...</span>
                      </section>
                    )
                  }else{
                        return(
                          <section style={{position:"relative", top:0, left:0, margin:"auto", width:"80%", marginBottom:0, padding:"10px 20px 10px 20px"}} onMouseOver={snapBackToDefault}>
                            {msg.role !== 'guest'?
                            <section style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            <span style={{color:"#fff", fontWeight:"bold"}}>
                              {msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}
                              <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.subrole}</span><br/>
                            </span>
                          </section>
                            :<section style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            {msg.photo?
                            <img src={msg.photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:30, width:30}}/>
                            :<span style={{color:"#16a068", fontWeight:"bold"}}>
                            {msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}
                          </span>}
                            <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>}
                            
                            <span style={{position:"relative", left:25, top:-10, color:"#16a068", fontSize:"8pt"}}>{msg.date}</span><br/>
                            <span style={{position:"relative", left:25, top:-10, color:"#16a068"}}>{msg.message}</span>
                          </section>
                        )
                  }
                })}
                </Fragment>
              }
        </Fragment>
      )
    }
  
    const  getNewMessage = message=>event=>{
      event.preventDefault()
      message = event.target.value //uname:user.username, role:user.role, subrole:'', message:message
      setMessageEntry({...messageEntry, message:message})
    }
    let limit = 5
    let tailInquiry = [];
    if(inquiries.length<5){
      let laster = inquiries.length
      tailInquiry = inquiries.slice(0, laster)
    }else{
      let laster = inquiries.length
      tailInquiry = inquiries.slice(inquiries.length-limit, laster)
    }
  
    console.log(tailInquiry)
    return (
      <Fragment>
          <section onMouseOver={snapBackToDefault}>
            <section style={{position:"relative", width:"100%", top:10, height:50, marginBottom:20, textAlign:"center"}}>
               <span style={{position:"relative", top:-17, color:"#16a068",  fontSize:"12pt", textAlign:"center", padding:"5px 20px 5px 20px", backgroundColor:"#fff"}}>Chatting with {chatWith}</span>
            </section>
            <section style={{position:"relative", top:-65, width:"100%", margin:"auto"}}>
              {inquiries.length===0?
              <section style={{position:"relative", top:20, margin:"auto", textAlign:"center", width:"100%"}}>
                <span style={{position:"relative", width:"90%", margin:"auto", fontSize:"11pt", color:"#16a068",  padding:"50px 20px 20px 20px"}}>There are no messages to load</span>
              </section>
              :''
              }
              {refreshLoadedList===true?
              <RefreshChatContent />
              :<Fragment>
                {inquiries?
                tailInquiry.map(msg=>{
                  if(msg.length===0){
                    return(
                      <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>data loading...</span>
                      </section>
                    )
                  }else{
                    return(
                      <section style={{position:"relative", top:0, left:10, margin:"auto", width:"90%", marginBottom:0, padding:"10px 20px 10px 20px"}}>
                        {msg.role !== 'guest'?
                        <section style={{position:"absolute", top:17, left:0, textAlign:"center", backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                        <span style={{color:"#fff", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>
                        <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.subrole}</span><br/>
                        </section>
                        :<section style={{position:"absolute", top:17, left:0, textAlign:"center", backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          {msg.photo?
                          <img src={msg.photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:30, width:30}}/>
                          :<span style={{color:"#16a068", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>} {/**/}
                        <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                        </section>}
                          <span style={{position:"relative", left:25, color:"#16a068", fontSize:"7pt"}}>{msg.date}</span><br/>
                          <span style={{position:"relative", left:25, color:"#16a068"}}>{msg.message}</span>
                      </section>
                    )
                  }
                })
                :''
              }
                </Fragment>}
  
            </section>
            <section style={{position:"absolute", width:"92%", bottom:90}}>
              <section style={{position:"relative", backgroundColor:"#f1f1f1", top:30, width:30, height:30, borderRadius:"50%", padding:"20px 20px 20px 20px"}}>
                {photo?
                <img src={photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:70, width:70}}/>:
                <b style={{position:"absolute", top:10, left:10, fontSize:"26pt"}}>{user.username.charAt(0).toUpperCase()}{user.username.charAt(1).toUpperCase()}</b>
                }
                {/* <span style={{position:"relative", fontWeight:"bold", top:55, left:-15}}>{user.username}</span> */}
              </section> 
              <section style={{position:"absolute", left:90, borderColor:"#16a068", top:30, width:"80%", height:70, borderRadius:5, borderWidth:1, borderStyle:"solid"}}>
                <textarea id='guest-message' onMouseLeave={getNewMessage('message')} style={{position:"relative", backgroundColor:"#fff", top:5, left:10, width:320, height:40, borderColor:"#fff", borderStyle:"solid", lineHeight: 1, borderWidth:0, borderRadius:5, padding:"10px 10px 10px 10px", color:"#16a068", fontFamily:"Baloo", fontSize:"11pt" }} type="text" row="20"></textarea>
                {!messageEntry.message?
                <button style={{position:"absolute", top:80, right:0, height:30, padding:"5px 20px 5px 20px", backgroundColor:"#c1c1c1", color:"white"}} className='guest-chat-btn'>
                  Send
                </button>
                :<button style={{position:"absolute", top:80, right:0, height:30, padding:"5px 20px 5px 20px"}} className='guest-chat-btn' onClick={(e)=>{
                  e.preventDefault();
                  setRefreshLoadedList(false);
                  document.getElementById("guest-message").value = "";
                  processNewMessage();
                  //refreshUserDetails()
                  }}>
                  Send
                </button>}
              </section>
              {!messageEntry.message?
              <span style={{position:"absolute", top:110, left:90, color:"#16a068", fontSize:"8pt"}}>The field is empty!</span>
              :''}
            </section>
          </section>
      </Fragment>
    )
  }

  export default ChattyBoxAdmin