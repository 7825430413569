//LIBRARIES
import React, {Fragment, useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'

//COMPONENTS
import ChattyBoxAdmin from './chattyBoxAdmin'
import {accessSwitch} from '../variables'
import {
    ADUX_ALLAPIL, 
    ADUX_ALLAPIW
} from '../../../config' 

//OPERATIONS

const GuestAndOtherInquiries = ()=>{
    
    const pitch = accessSwitch //true to Web-based MongoDB
    const switcher =(bol)=>{
        if(bol===true){
            return ADUX_ALLAPIW
        }else{
            return ADUX_ALLAPIL
        }
    }
    const [onliner, setOnliner] = useState([])

    const [userItem, setUserItem] = useState([])
    const usersListAPI = async()=>{
        await fetch(switcher(pitch)) //true to Web-based MongoDB
        .then((responsive)=>responsive.json())
        .then((data)=>{
            const wanton = data.data
            const drearies = []
            for(const key in wanton){
                drearies.push({
                    id:key,
                    uid:wanton[key].userUniqueID,
                    username: wanton[key].username,
                    email: wanton[key].email,
                    role: wanton[key].role,
                    dateSince: wanton[key].dateCreated,
                    orders:wanton[key].orders, 
                    payments:wanton[key].paymentSources,
                    orders:wanton[key].orders,
                    logins:wanton[key].logins
                })
            }
            setUserItem(drearies)
        })
    }
    useEffect(()=>{
        usersListAPI()
    },[])
    console.log(userItem)
    const [newTabColor0, setNewTabColor0] = useState(false)
    const [newTabColor1, setNewTabColor1] = useState(true)
    const [newTabColor2, setNewTabColor2] = useState(false)
    const [floaterChat, setFloaterChat] = useState(false)
    const [clickedLead, setClickedLead] = useState('')
    const [guestClientInfo, setGuestClientInfo] = useState('')
    const [guestUserUID, setGuestUserUID] = useState('')

    
    const ChattyBox = ()=>{
        return(
                <Fragment>
                    <section style={{position:"fixed", zIndex:3, top:-10, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.5)"}}>
                    </section>
                    <section style={{position:"fixed", zIndex:3, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,0.0)"}}>
                        <section style={{position:"relative", zIndex:3, top:10, margin:"auto", left:"auto", width:"40%", borderRadius:8, height:"95%", backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                            <section>
                                {clickedLead==='email'?
                                <button onClick={()=>{}} className='deposit-close-btn' style={{right:320, borderRadius:0, backgroundColor:"#0a5336"}}>
                                EMAIL
                                </button>
                                :<button onClick={()=>{setClickedLead('email')}} className='deposit-close-btn' style={{right:320, borderRadius:0}}>
                                EMAIL
                                </button>}

                                {clickedLead==='sms'?
                                <button onClick={()=>{}} className='deposit-close-btn' style={{right:198, borderRadius:0, backgroundColor:"#0a5336"}}>
                                SEND SMS
                                </button>
                                :<button onClick={()=>{setClickedLead('sms')}} className='deposit-close-btn' style={{right:198, borderRadius:0}}>
                                SEND SMS
                                </button>}

                                {clickedLead==='chat'?
                                <button onClick={()=>{}} className='deposit-close-btn' style={{right:110, borderRadius:0, backgroundColor:"#0a5336" }}>
                                CHAT
                                </button>
                                :<button onClick={()=>{setClickedLead('chat')}} className='deposit-close-btn' style={{right:110, borderRadius:0}}>
                                    CHAT
                                </button>}
                                <button onClick={()=>{setFloaterChat(false)}} className='deposit-close-btn' style={{right:0, borderRadius:0, backgroundColor:"black"}}>
                                    X CLOSE
                                </button>
                            </section>
                            <section style={{position:"absolute", bottom:0, margin:20, width:500, height:540, borderRadius:5,  borderColor:"#f0f0f0", borderWidth:1,  borderStyle:"solid"}}>
                                <section style={{margin:20}}>
                                    {clickedLead === 'email'?'Write Email here':''}
                                    {clickedLead === 'sms'?'Send SMS here':''}
                                    {clickedLead === 'chat'?<ChattyBoxAdmin chatWith = {guestClientInfo} uid = {guestUserUID} />:''}
                                </section>
                            </section>
                        </section>
                    </section>
                    
                </Fragment>
            )
    }
        
    return(
        <Fragment>
            <section style={{width:"100%"}}>
                {newTabColor0===false?
                <button className='inquiries-switcher-btn' onClick={()=>{setNewTabColor0(true); setNewTabColor1(false); setNewTabColor2(false)}}>
                    <span>Client Inquiries</span>
                </button>
                :<button className='inquiries-switcher-btn' style={{backgroundColor:"#000", color:"#fff"}}>
                    <span>Client Inquiries</span>
                </button>}

                {newTabColor1===false?
                <button className='inquiries-switcher-btn' onClick={()=>{setNewTabColor0(false); setNewTabColor1(true); setNewTabColor2(false)}}>
                    <span>Guest Inquiries</span>
                </button>
                :<button className='inquiries-switcher-btn' style={{backgroundColor:"#000", color:"#fff"}}>
                    <span>Guest Inquiries</span>
                </button>}

                {newTabColor2 === false?
                <button className='inquiries-switcher-btn' onClick={()=>{setNewTabColor0(false); setNewTabColor1(false); setNewTabColor2(true)}}>
                <span>Passerby Inquiries</span>
                </button>
                :<button className='inquiries-switcher-btn' style={{backgroundColor:"#000", color:"#fff"}}>
                <span>Passerby Inquiries</span>
                </button>}
            </section>
            <section style={{width:"100%", borderWidth:1, borderColor:"#f1f1f1", borderStyle:"solid", borderRadius:1}}>
                {newTabColor0===true?
                <Fragment>
                    <ul className='listed-users-in-chat-room'>
                        {userItem.map(user=>{
                            if(user.role==='client'){
                                let pegged = [] 
                                user.logins.map(item=>{//reverse() is weird, sorting array differently, only works offline, doesn't work Online
                                    pegged.push(item.logged)
                                    })
                                return(
                                    <li>
                                        <section style={{position:"relative", textAlign:"center", margin:"auto"}}>
                                            <svg style={{width:200, height:300, paddingBottom:30}} viewBox="0 0 276 371" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 0H276V371H0V0Z" fill="url(#paint0_linear_920_53)"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M274.916 0H276V177.104C194.587 268.13 76.2665 324.565 0 349.282L0 260.594C58.1441 280.366 125.138 281.859 180.172 231.235C246.803 169.945 270.065 75.9158 274.916 0Z" fill="#1DDD64"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 202.384V184.717C37.0272 202.617 79.1787 216.259 124.5 220C179.926 224.575 231.902 214.547 276 198.998V203.721C199.401 233.504 100.943 246.576 0 202.384Z" fill="#2FEC78"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 93.0503V90.0462C102.932 84.6707 184.313 93.7205 245.972 110.842C256.557 113.781 266.563 116.959 276 120.344V123.532C266.344 120.038 256.071 116.76 245.169 113.732C183.856 96.7071 102.758 87.6704 0 93.0503ZM0 248.793V245.509C6.81447 248.546 13.7402 251.542 20.765 254.483C98.9768 287.233 189.349 313.165 275.037 312.487C275.358 312.484 275.679 312.481 276 312.478V315.478C275.687 315.481 275.374 315.484 275.06 315.487C188.805 316.169 98.0063 290.079 19.6063 257.25C12.9797 254.476 6.44088 251.652 0 248.793Z" fill="#32F57B"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M121.519 172C152.413 171.618 173.982 156.959 184.54 135.57C195.069 114.242 194.546 86.4393 181.79 59.8512C171.11 37.5911 151.882 16.1872 123.345 0H117.087C147.812 16.206 168.136 38.3274 179.085 61.1488C191.517 87.0607 191.9 113.883 181.85 134.242C171.83 154.541 151.337 168.632 121.481 169C81.0547 169.499 38.6927 153.534 0 131.309L0 134.765C38.5518 156.696 80.8832 172.502 121.519 172Z" fill="#2FEC78"/>
                                                <path d="M90.2667 89.6487C81.8215 73.1256 83.3821 50.3299 85.218 40.9974C94.2444 42.2214 112.297 46.8723 112.297 55.6846C112.297 66.7 100.823 74.9615 90.2667 89.6487Z" fill="#696559"/>
                                                <path d="M191.7 74.0746C190.231 68.1997 184.662 71.6267 182.062 74.0746C178.849 87.0788 174.351 112.078 182.062 108.039C191.7 102.99 193.536 81.4182 191.7 74.0746Z" fill="#E7CE74"/>
                                                <path d="M79.8859 74.0746C81.3546 68.1997 86.9235 71.6267 89.5244 74.0746C92.7372 87.0788 97.2351 112.078 89.5244 108.039C79.8859 102.99 78.05 81.4182 79.8859 74.0746Z" fill="#E7CE74"/>
                                                <path d="M92.5615 180.985V129.579L178.849 121.318V180.985L138.459 221.833L92.5615 180.985Z" fill="#E7CE74"/>
                                                <path d="M0.307693 258.551V371H140.281L140.754 217.703L90.1554 150.692C90.7859 165.685 84.5754 198.058 54.6892 207.605C24.803 217.152 5.5094 245.7 0.307693 258.551Z" fill="#16A068"/>
                                                <path d="M275.692 258.551V371H135.719L135.246 217.703L185.845 150.692C185.214 165.685 191.425 198.058 221.311 207.605C251.197 217.152 270.491 245.7 275.692 258.551Z" fill="#16A068"/>
                                                <path d="M128.821 253.044L119.182 231.472L137.082 215.867L156.818 231.472L144.426 253.044L164.621 371H111.838L128.821 253.044Z" fill="#696559"/>
                                                <path d="M56.3026 195.883L90.2616 149.774L141.672 216.171L120.919 249.831L56.3026 195.883Z" fill="#49DA8D"/>
                                                <path d="M219.697 195.883L185.738 149.774L134.328 216.171L155.081 249.831L219.697 195.883Z" fill="#49DA8D"/>
                                                <path d="M88.4308 135.546V43.7513H183.438V135.546C183.438 145.644 154.064 169.969 139.377 173.641C124.69 177.313 88.4308 145.185 88.4308 135.546Z" fill="#FFE791"/>
                                                <path d="M129.752 13.6896C106.456 17.7941 90.4634 33.6419 85.379 41.0528C80.9416 62.9433 101.557 81.9075 108.028 85.518C102.019 72.4064 101.557 65.5656 105.255 57.0146C109.322 69.7841 119.275 77.157 123.743 79.2472L116.81 57.0146C138.257 74.2704 176.591 76.366 193.077 75.2568C193.077 66.1357 186.606 48.6537 183.37 41.0528L193.077 53.5942C187.53 33.0718 158.872 8.55897 129.752 13.6896Z" fill="#7D7768"/>
                                                <defs>
                                                <linearGradient id="paint0_linear_920_53" x1="138" y1="0" x2="138" y2="371" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#16A068"/>
                                                <stop offset="0.395833" stop-color="#19C95B"/>
                                                </linearGradient>
                                                </defs>
                                            </svg>

                                            <section style={{position:"relative", bottom:45}}>
                                                <span style={{position:"relative", bottom:100, fontSize:"22pt", zIndex:2, fontFamily:"Baloo", color:"white", cursor:"default"}}>
                                                    {user.username}
                                                </span>
                                                <svg onClick={()=>{setFloaterChat(true); setClickedLead('email')}} style={{position:"absolute", left:40, bottom:88, width:30, height:15, cursor:"pointer"}} viewBox="0 0 121 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="3" width="117" height="64" stroke="#49DA8D" stroke-width="4"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.08 4.18356C104.712 14.039 72.7093 34.6654 67.1625 38.6275C60.5988 43.3158 53.5648 40.5654 50.8557 38.6404L0.371811 4.15143L2.6282 0.848596L53.1455 35.3604L53.1625 35.3725C55.1334 36.7803 60.2159 38.6737 64.8375 35.3725C70.4907 31.3346 102.621 10.6277 117.92 0.816467L120.08 4.18356Z" fill="#49DA8D"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.69527 64.4842L41.1953 30.4842L43.8047 33.5158L4.30475 67.5158L1.69527 64.4842Z" fill="#49DA8D"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M116.24 67.5528L73.7395 33.0528L76.2605 29.9472L118.76 64.4472L116.24 67.5528Z" fill="#49DA8D"/>
                                                </svg>
                                                <svg onClick={()=>{setFloaterChat(true); setClickedLead('sms')}} style={{position:"absolute", left:85, bottom:78, width:30, height:25, cursor:"pointer"}} viewBox="0 0 118 103" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5422 11.6193C9.3864 16.3689 4 22.5322 4 28.657C4 32.5094 6.09723 36.3622 10.2846 39.9335C14.4693 43.5026 20.5748 46.6315 28.0942 48.9675C29.8337 49.5079 30.7366 51.4861 29.9103 53.1681L24.062 65.0737L44.2213 53.0229C44.7863 52.6852 45.4437 52.5443 46.0922 52.6158C50.2328 53.0722 54.5545 53.3141 59 53.3141C74.5147 53.3141 88.463 50.369 98.4578 45.6948C108.614 40.9452 114 34.7819 114 28.657C114 22.5322 108.614 16.3689 98.4578 11.6193C88.463 6.94509 74.5147 4 59 4C43.4853 4 29.537 6.94509 19.5422 11.6193ZM17.8477 7.996C28.4828 3.02229 43.0345 0 59 0C74.9655 0 89.5172 3.02229 100.152 7.996C110.627 12.8944 118 20.0596 118 28.657C118 37.2545 110.627 44.4197 100.152 49.3181C89.5172 54.2918 74.9655 57.3141 59 57.3141C54.5287 57.3141 50.1729 57.0771 45.9865 56.6279L23.2137 70.2409C20.6492 71.7739 17.6644 69.0249 18.9818 66.3432L25.8121 52.4387C18.4387 50.0338 12.1909 46.8166 7.68892 42.9769C2.96832 38.9508 0 34.0808 0 28.657C0 20.0596 7.37348 12.8944 17.8477 7.996Z" fill="#49DA8D"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.013 49.0911C112.274 52.2909 114 55.8825 114 59.5328C114 66.6083 107.441 73.4886 96.1585 77.5722L94.2139 78.2761L99.1102 90.5089L85.2122 80.3127L84.4083 80.4244C81.3957 80.8431 78.2449 81.0656 75 81.0656C63.9632 81.0656 54.0695 78.4936 47.0042 74.4384C43.0701 72.1803 40.124 69.5387 38.2751 66.7031L34.8806 68.8246C37.18 72.3657 40.7218 75.4445 45.013 77.9076C52.7868 82.3695 63.3931 85.0656 75 85.0656C78.1387 85.0656 81.2003 84.8685 84.1494 84.494L108.021 102.007L99.4493 80.5916C110.207 76.2051 118 68.6964 118 59.5328C118 54.699 115.813 50.3189 112.202 46.6298C111.145 47.473 110.069 48.3069 109.013 49.0911Z" fill="#49DA8D"/>
                                                </svg>


                                                <svg onClick={()=>{
                                                    setFloaterChat(true); 
                                                    setGuestClientInfo(user.username); 
                                                    setGuestUserUID(user.uid); 
                                                    setClickedLead('chat')}} style={{position:"absolute", left:127, bottom:81, width:30, height:25, cursor:"pointer"}} viewBox="0 0 126 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8181 13.4246C10.0454 19.2524 4 26.9708 4 35C4 40.0882 6.40377 45.0225 10.936 49.4732C15.4766 53.932 22.0717 57.8126 30.1606 60.6949C31.7683 61.2677 32.6149 63.0881 31.928 64.7057L25.038 80.9304L47.1384 65.6482C47.751 65.2246 48.4956 65.0464 49.2271 65.1395C53.6456 65.702 58.2569 66 63 66C79.5785 66 94.4884 62.3604 105.182 56.5754C115.955 50.7476 122 43.0292 122 35C122 26.9708 115.955 19.2524 105.182 13.4246C94.4884 7.63959 79.5785 4 63 4C46.4215 4 31.5116 7.63959 20.8181 13.4246ZM18.9149 9.90639C30.2991 3.74771 45.8892 0 63 0C80.1108 0 95.7009 3.74771 107.085 9.90639C118.39 16.0222 126 24.8038 126 35C126 45.1962 118.39 53.9778 107.085 60.0936C95.7009 66.2523 80.1108 70 63 70C58.2247 70 53.5723 69.7082 49.1001 69.155L24.4753 86.1827C22.0034 87.892 18.833 85.3088 20.0077 82.5426L27.8369 64.106C19.7948 61.1212 13.0009 57.1071 8.13341 52.3272C3.06099 47.3461 0 41.4414 0 35C0 24.8038 7.60995 16.0222 18.9149 9.90639Z" fill="#49DA8D"/>
                                                    <circle cx="34.5" cy="36.5" r="7.5" fill="#49DA8D"/>
                                                    <circle cx="62.5" cy="36.5" r="7.5" fill="#49DA8D"/>
                                                    <circle cx="91.5" cy="36.5" r="7.5" fill="#49DA8D"/>
                                                </svg>
                                                {pegged.pop()==='in'?
                                                        <section style={{position:"absolute", bottom:58, backgroundColor:"#f2f2f2"}}>
                                                            <section className="off-sign"></section>
                                                            <span style={{position:"absolute", bottom:2, left:80, fontSize:"10pt", color:"lime"}}>
                                                                Online
                                                            </span>
                                                        </section>:
                                                        <span style={{position:"absolute", bottom:60, left:80, fontSize:"10pt", color:"#d1d1d1"}}>
                                                            <section className="off-sign2"></section> Offline
                                                        </span>
                                                }
                                            </section>
                                        </section>
                                        
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </Fragment>
                :''}
                {newTabColor1===true?
                <Fragment>
                    <ul className='listed-users-in-chat-room' onMouseOver = {()=>{usersListAPI();}} >
                        {userItem.map(user=>{
                            if(user.role==='guest'){
                                let pegged = [] 
                                user.logins.map(item=>{//reverse() is weird, sorting array differently, only works offline, doesn't work Online
                                    pegged.push(item.logged)
                                    })
                                return(
                                    <li>
                                        <section style={{position:"relative", textAlign:"center", margin:"auto"}}>
                                            <svg style={{width:200, height:300, paddingBottom:30}} viewBox="0 0 276 371" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 0H276V371H0V0Z" fill="url(#paint0_linear_920_53)"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M274.916 0H276V177.104C194.587 268.13 76.2665 324.565 0 349.282L0 260.594C58.1441 280.366 125.138 281.859 180.172 231.235C246.803 169.945 270.065 75.9158 274.916 0Z" fill="#1DDD64"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 202.384V184.717C37.0272 202.617 79.1787 216.259 124.5 220C179.926 224.575 231.902 214.547 276 198.998V203.721C199.401 233.504 100.943 246.576 0 202.384Z" fill="#2FEC78"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 93.0503V90.0462C102.932 84.6707 184.313 93.7205 245.972 110.842C256.557 113.781 266.563 116.959 276 120.344V123.532C266.344 120.038 256.071 116.76 245.169 113.732C183.856 96.7071 102.758 87.6704 0 93.0503ZM0 248.793V245.509C6.81447 248.546 13.7402 251.542 20.765 254.483C98.9768 287.233 189.349 313.165 275.037 312.487C275.358 312.484 275.679 312.481 276 312.478V315.478C275.687 315.481 275.374 315.484 275.06 315.487C188.805 316.169 98.0063 290.079 19.6063 257.25C12.9797 254.476 6.44088 251.652 0 248.793Z" fill="#32F57B"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M121.519 172C152.413 171.618 173.982 156.959 184.54 135.57C195.069 114.242 194.546 86.4393 181.79 59.8512C171.11 37.5911 151.882 16.1872 123.345 0H117.087C147.812 16.206 168.136 38.3274 179.085 61.1488C191.517 87.0607 191.9 113.883 181.85 134.242C171.83 154.541 151.337 168.632 121.481 169C81.0547 169.499 38.6927 153.534 0 131.309L0 134.765C38.5518 156.696 80.8832 172.502 121.519 172Z" fill="#2FEC78"/>
                                                <path d="M90.2667 89.6487C81.8215 73.1256 83.3821 50.3299 85.218 40.9974C94.2444 42.2214 112.297 46.8723 112.297 55.6846C112.297 66.7 100.823 74.9615 90.2667 89.6487Z" fill="#696559"/>
                                                <path d="M191.7 74.0746C190.231 68.1997 184.662 71.6267 182.062 74.0746C178.849 87.0788 174.351 112.078 182.062 108.039C191.7 102.99 193.536 81.4182 191.7 74.0746Z" fill="#E7CE74"/>
                                                <path d="M79.8859 74.0746C81.3546 68.1997 86.9235 71.6267 89.5244 74.0746C92.7372 87.0788 97.2351 112.078 89.5244 108.039C79.8859 102.99 78.05 81.4182 79.8859 74.0746Z" fill="#E7CE74"/>
                                                <path d="M92.5615 180.985V129.579L178.849 121.318V180.985L138.459 221.833L92.5615 180.985Z" fill="#E7CE74"/>
                                                <path d="M0.307693 258.551V371H140.281L140.754 217.703L90.1554 150.692C90.7859 165.685 84.5754 198.058 54.6892 207.605C24.803 217.152 5.5094 245.7 0.307693 258.551Z" fill="#16A068"/>
                                                <path d="M275.692 258.551V371H135.719L135.246 217.703L185.845 150.692C185.214 165.685 191.425 198.058 221.311 207.605C251.197 217.152 270.491 245.7 275.692 258.551Z" fill="#16A068"/>
                                                <path d="M128.821 253.044L119.182 231.472L137.082 215.867L156.818 231.472L144.426 253.044L164.621 371H111.838L128.821 253.044Z" fill="#696559"/>
                                                <path d="M56.3026 195.883L90.2616 149.774L141.672 216.171L120.919 249.831L56.3026 195.883Z" fill="#49DA8D"/>
                                                <path d="M219.697 195.883L185.738 149.774L134.328 216.171L155.081 249.831L219.697 195.883Z" fill="#49DA8D"/>
                                                <path d="M88.4308 135.546V43.7513H183.438V135.546C183.438 145.644 154.064 169.969 139.377 173.641C124.69 177.313 88.4308 145.185 88.4308 135.546Z" fill="#FFE791"/>
                                                <path d="M129.752 13.6896C106.456 17.7941 90.4634 33.6419 85.379 41.0528C80.9416 62.9433 101.557 81.9075 108.028 85.518C102.019 72.4064 101.557 65.5656 105.255 57.0146C109.322 69.7841 119.275 77.157 123.743 79.2472L116.81 57.0146C138.257 74.2704 176.591 76.366 193.077 75.2568C193.077 66.1357 186.606 48.6537 183.37 41.0528L193.077 53.5942C187.53 33.0718 158.872 8.55897 129.752 13.6896Z" fill="#7D7768"/>
                                                <defs>
                                                <linearGradient id="paint0_linear_920_53" x1="138" y1="0" x2="138" y2="371" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#16A068"/>
                                                <stop offset="0.395833" stop-color="#19C95B"/>
                                                </linearGradient>
                                                </defs>
                                            </svg>

                                            <section style={{position:"relative", bottom:45}}>
                                                <span style={{position:"relative", bottom:100, fontSize:"22pt", zIndex:2, fontFamily:"Baloo", color:"white", cursor:"default"}}>
                                                    {user.username}
                                                </span>
                                                <svg onClick={()=>{setFloaterChat(true); setClickedLead('email')}} style={{position:"absolute", left:40, bottom:88, width:30, height:15, cursor:"pointer"}} viewBox="0 0 121 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="3" width="117" height="64" stroke="#49DA8D" stroke-width="4"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M120.08 4.18356C104.712 14.039 72.7093 34.6654 67.1625 38.6275C60.5988 43.3158 53.5648 40.5654 50.8557 38.6404L0.371811 4.15143L2.6282 0.848596L53.1455 35.3604L53.1625 35.3725C55.1334 36.7803 60.2159 38.6737 64.8375 35.3725C70.4907 31.3346 102.621 10.6277 117.92 0.816467L120.08 4.18356Z" fill="#49DA8D"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.69527 64.4842L41.1953 30.4842L43.8047 33.5158L4.30475 67.5158L1.69527 64.4842Z" fill="#49DA8D"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M116.24 67.5528L73.7395 33.0528L76.2605 29.9472L118.76 64.4472L116.24 67.5528Z" fill="#49DA8D"/>
                                                </svg>
                                                <svg onClick={()=>{setFloaterChat(true); setClickedLead('sms')}} style={{position:"absolute", left:85, bottom:78, width:30, height:25, cursor:"pointer"}} viewBox="0 0 118 103" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5422 11.6193C9.3864 16.3689 4 22.5322 4 28.657C4 32.5094 6.09723 36.3622 10.2846 39.9335C14.4693 43.5026 20.5748 46.6315 28.0942 48.9675C29.8337 49.5079 30.7366 51.4861 29.9103 53.1681L24.062 65.0737L44.2213 53.0229C44.7863 52.6852 45.4437 52.5443 46.0922 52.6158C50.2328 53.0722 54.5545 53.3141 59 53.3141C74.5147 53.3141 88.463 50.369 98.4578 45.6948C108.614 40.9452 114 34.7819 114 28.657C114 22.5322 108.614 16.3689 98.4578 11.6193C88.463 6.94509 74.5147 4 59 4C43.4853 4 29.537 6.94509 19.5422 11.6193ZM17.8477 7.996C28.4828 3.02229 43.0345 0 59 0C74.9655 0 89.5172 3.02229 100.152 7.996C110.627 12.8944 118 20.0596 118 28.657C118 37.2545 110.627 44.4197 100.152 49.3181C89.5172 54.2918 74.9655 57.3141 59 57.3141C54.5287 57.3141 50.1729 57.0771 45.9865 56.6279L23.2137 70.2409C20.6492 71.7739 17.6644 69.0249 18.9818 66.3432L25.8121 52.4387C18.4387 50.0338 12.1909 46.8166 7.68892 42.9769C2.96832 38.9508 0 34.0808 0 28.657C0 20.0596 7.37348 12.8944 17.8477 7.996Z" fill="#49DA8D"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.013 49.0911C112.274 52.2909 114 55.8825 114 59.5328C114 66.6083 107.441 73.4886 96.1585 77.5722L94.2139 78.2761L99.1102 90.5089L85.2122 80.3127L84.4083 80.4244C81.3957 80.8431 78.2449 81.0656 75 81.0656C63.9632 81.0656 54.0695 78.4936 47.0042 74.4384C43.0701 72.1803 40.124 69.5387 38.2751 66.7031L34.8806 68.8246C37.18 72.3657 40.7218 75.4445 45.013 77.9076C52.7868 82.3695 63.3931 85.0656 75 85.0656C78.1387 85.0656 81.2003 84.8685 84.1494 84.494L108.021 102.007L99.4493 80.5916C110.207 76.2051 118 68.6964 118 59.5328C118 54.699 115.813 50.3189 112.202 46.6298C111.145 47.473 110.069 48.3069 109.013 49.0911Z" fill="#49DA8D"/>
                                                </svg>


                                                <svg onClick={()=>{
                                                    setFloaterChat(true); 
                                                    setGuestClientInfo(user.username); 
                                                    setGuestUserUID(user.uid); 
                                                    setClickedLead('chat')}} style={{position:"absolute", left:127, bottom:81, width:30, height:25, cursor:"pointer"}} viewBox="0 0 126 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8181 13.4246C10.0454 19.2524 4 26.9708 4 35C4 40.0882 6.40377 45.0225 10.936 49.4732C15.4766 53.932 22.0717 57.8126 30.1606 60.6949C31.7683 61.2677 32.6149 63.0881 31.928 64.7057L25.038 80.9304L47.1384 65.6482C47.751 65.2246 48.4956 65.0464 49.2271 65.1395C53.6456 65.702 58.2569 66 63 66C79.5785 66 94.4884 62.3604 105.182 56.5754C115.955 50.7476 122 43.0292 122 35C122 26.9708 115.955 19.2524 105.182 13.4246C94.4884 7.63959 79.5785 4 63 4C46.4215 4 31.5116 7.63959 20.8181 13.4246ZM18.9149 9.90639C30.2991 3.74771 45.8892 0 63 0C80.1108 0 95.7009 3.74771 107.085 9.90639C118.39 16.0222 126 24.8038 126 35C126 45.1962 118.39 53.9778 107.085 60.0936C95.7009 66.2523 80.1108 70 63 70C58.2247 70 53.5723 69.7082 49.1001 69.155L24.4753 86.1827C22.0034 87.892 18.833 85.3088 20.0077 82.5426L27.8369 64.106C19.7948 61.1212 13.0009 57.1071 8.13341 52.3272C3.06099 47.3461 0 41.4414 0 35C0 24.8038 7.60995 16.0222 18.9149 9.90639Z" fill="#49DA8D"/>
                                                    <circle cx="34.5" cy="36.5" r="7.5" fill="#49DA8D"/>
                                                    <circle cx="62.5" cy="36.5" r="7.5" fill="#49DA8D"/>
                                                    <circle cx="91.5" cy="36.5" r="7.5" fill="#49DA8D"/>
                                                </svg>
                                                {pegged.pop()==='in'?
                                                        <section style={{position:"absolute", bottom:58, backgroundColor:"#f2f2f2"}}>
                                                            <section className="off-sign"></section>
                                                            <span style={{position:"absolute", bottom:2, left:80, fontSize:"10pt", color:"lime"}}>
                                                                Online
                                                            </span>
                                                        </section>:
                                                        <span style={{position:"absolute", bottom:60, left:80, fontSize:"10pt", color:"#d1d1d1"}}>
                                                            <section className="off-sign2"></section> Offline
                                                        </span>
                                                }
                                            </section>
                                        </section>
                                        
                                    </li>
                                )
                            }
                            
                        })}
                    </ul>
                    
                </Fragment>
                :''}
                {newTabColor2===true?
                    <ul className='listed-users-in-chat-room'>
                    {userItem.map(user=>{
                        if(user.role===''){
                            return(
                                <li>
                                    <span>There is nothing yet here...</span>
                                </li>
                            )
                        }
                    })}
                </ul>
                :''}
                
            </section>
            {floaterChat===true?<ChattyBox />:''}
        </Fragment>
    )
}

export default GuestAndOtherInquiries