//LIBRARIES
import { Fragment, useState, useEffect } from "react"
import { useHistory } from "react-router"

//COMPONENTS
import {pitch, userSwitcher} from '../variables'
import {
    GUESTAT_MSL, 
    GUESTAT_MSW
} from '../../../config' 

//OPERATIONS
const UsersDepositDetectorComp = (props)=>{
    const historian = useHistory()
    //makes a recurring database check about this person/email's deposit activity
    const depositUrlSwitcher =(bol)=>{
        if(bol===true){
            return 'https://salty-inlet-81949.herokuapp.com/api/paymongo/list' //GUESTAT_MSW
        }else{
            return 'http://localhost:8888/api/paymongo/list' //GUESTAT_MSL
        }
    }
    const [mongo, setMongo] = useState(false)
    const getListFromPaymongo = async(email)=>{
        const paymongoRecord = await fetch(depositUrlSwitcher(pitch), {
          method:"GET",
          headers:{"Content-Type":"application/json"}
        })
        const defcon = await paymongoRecord.json()
        const {data} = await defcon
        //console.log([data][0].data)
        
        let marder = []
        setTimeout(()=>{
            [data][0].data.map(item=>{
                marder.push(item.attributes.billing.email)
            })
        },500)
        //console.log("Email: "+email)
        setTimeout(()=>{
            if(marder.includes(email)){
                setMongo(true)
                historian.push("/dashboard/paymongo")
            }else{
                setMongo(false)
            }
        }, 500)
    }
    setInterval(()=>{
      getListFromPaymongo(props.email)
    }, 3000)
    
    const [copyEmail, setCopyEmail] = useState(false)
    return(
        <Fragment>
            <section onMouseOver={()=>{
                setTimeout(()=>{
                    getListFromPaymongo(props.email)
                }, 1200)
            }} style={{marginBottom:30, padding:"20px 20px 20px 20px", borderColor:"#16a068", borderRadius:4, borderWidth:1, borderStyle:"solid"}}>
                <span>You must use this email in the <b>Customer Information</b> below so your deposit can be successfully tracked and recorded in our system.</span><br/><br/>
                <input type="text" value={props.email} placeholder={props.email} style={{padding:"10px 10px 10px 10px", color:"#16a068"}}/>
                    {copyEmail===true?
                <input onClick={()=>{
                    setCopyEmail(false)
                }} type="button" value="Copied" style={{position:"relative", backgroundColor:"#16a068", borderWidth:0, color:"#fff", left:10, borderRadius:3,  padding:"12px 12px 12px 12px"}}/>:
                <input  onClick={(e)=>{
                    e.preventDefault();
                    var data =props.email;
                    navigator.clipboard.writeText(data);
                    setCopyEmail(true);
                }}type="button" value="Copy" style={{position:"relative", left:10, backgroundColor:"#000", color:"#fff", borderWidth:0, borderRadius:3,  padding:"12px 12px 12px 12px"}}/>}
            </section>
            {mongo=== true?
            <section style={{width:"60%", margin:"auto", textAlign:"center"}}>
                <h1 style={{color:"#16a068", fontSize:"16pt"}}>Successfully Deposited Your Fund!</h1>
            </section>
            :
            <section onMouseOver={()=>{
                setTimeout(()=>{
                    getListFromPaymongo(props.email)
                }, 1200)
            }} style={{width:"60%", margin:"auto", textAlign:"center"}}>
                <h1 style={{color:"#16a068", fontSize:"16pt", color:"red"}}>No Deposit Yet Made!</h1>
            </section>
            }
        </Fragment>
    )
}
export default UsersDepositDetectorComp