//LIBRARIES
import React, {Fragment, useState, useContext, useEffect, useRef} from "react"
import {Link, withRouter, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { createPortal } from 'react-dom'
import { viewUser} from '../../../redux/slices/counter'

//COOKIES
import {paypalCollaborate} from '../auth/auth'

//COMPONENTS
import {pitch, userSwitcher} from '../variables'
import UsersDepositDetectorComp from "./userDepositDetector"
import {ProfileDetails, PaymentDetails, DepositSlots, MessageChannel, OrderDetails, TipsToReachClienthood, PrivacyReminder} from './profileDetails' //Includes PAY DEPOSIT UPDATE
import {
    GUESTAT_MSL, 
    GUESTAT_MSW
} from '../../../config' 
//OPERATIONS

const UsersCompProfile = (props)=>{
    const dispatch = useDispatch()
    const details = useSelector(state => state.counter.userDetails)
    const currencyPrimary = ()=>{
        setInterval(()=>{
            dispatch(viewUser())
            
        },1000)
    }
    currencyPrimary()

    const historian = useHistory()
    const mtabler = useSelector(state=>state.counter.messageTabTrigger)
    const {username, password, profileImage, userID, userrole, email} = props

    //READING MESSAGE STATUS
    const statusMsgSwitcher =(bol)=>{
        if(bol===true){
            return GUESTAT_MSW
        }else{
            return GUESTAT_MSL
        }
    }
    const [unreadFound, setUnreadFound] = useState(false)
    const getToReadMessageStatus = async(sing, song)=>{
        const defaultQRedux = await fetch(statusMsgSwitcher(pitch), {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(sing)
        })
        const defcon = await defaultQRedux.json()
        const {status, data} = defcon
        //console.log(data)
        //console.log(data[0].inquiries[0]) //works
        let currentUser = [];
        let otherSideUser = [];
        data[0].inquiries.map(item=>{
            //otherSideUser.push(item)
            if(item.uname !== song.username){
                otherSideUser.push(item.unread)
                //setUnreadFound(true)
            }
            if(item.uname == song.username){
                currentUser.push(item.unread)
                //setUnreadFound(true)
            }
        })
        let really = otherSideUser.includes(true)
        if(really === true){
            setUnreadFound(true)
        }else{
            setUnreadFound(false)
        }

      }
    useEffect(()=>{
        getToReadMessageStatus({userUniqueID:userID}, {username:username})
    }, [])
    const getReturnedData = async(uGatos)=>{
        const userGetsData = await fetch(userSwitcher(pitch),{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({userid:uGatos})
        })
        const responded = await userGetsData.json()
        console.log(responded)
    }
    const viewDetails=(e)=>{
        e.preventDefault()
        getReturnedData(userID)
    }
    const [profileTabColorProps, setprofileTabColorProps] = useState({ //color props are set to default
        backgroundColor:'#ffffff', 
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        color:'black',
    })
    const [paymentsTabColorProps, setPaymentsTabColorProps] = useState({
        backgroundColor:'black',
        color:'white'
    })
    const [depositTabColorProps, setDepositTabColorProps] = useState({
        backgroundColor:'black',
        color:'white'
    })
    const [messageTabColorProps, setMessageTabColorProps] = useState({
        backgroundColor:'black',
        color:'white'
    })
    const [orderTabColorProps, setOrderTabColorProps] = useState({
        backgroundColor:'black',
        color:'white'
    })

    const [profileData, setProfileData] = useState('This is profile Data space') //only one with default content
    const changeProfileTabColor =tabname=>e=>{
        e.preventDefault()
        if(tabname ==="profile"){
            setprofileTabColorProps({...profileTabColorProps, backgroundColor:'#ffffff', color:'black', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'})
            setPaymentsTabColorProps({...paymentsTabColorProps, backgroundColor:'#16A068', color:'white'})
            setDepositTabColorProps({...depositTabColorProps, backgroundColor:'black', color:'white'})
            setMessageTabColorProps({...messageTabColorProps, backgroundColor:'black', color:'white'})
            setOrderTabColorProps({...profileTabColorProps, backgroundColor:'black', color:'white'})
            
            setProfileData('This is profile Data space')
            console.log("profile")
            console.log(window.location.pathname)

            //emptying other data
            setPaymentData('')
            setDepositData('')
            setMessageData('')
            setOrderData('')
        }
    }
    const [paymentData, setPaymentData] = useState('')
    const changePaymentsTabColor =tabname=>e=>{
        e.preventDefault()
        if(tabname ==="payments"){
            setPaymentsTabColorProps({...paymentsTabColorProps, backgroundColor:'#ffffff', color:'black', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'})
            setprofileTabColorProps({...profileTabColorProps, backgroundColor:'#16A068', color:'white'})
            setDepositTabColorProps({...depositTabColorProps, backgroundColor:'#16A068', color:'white'})
            setMessageTabColorProps({...messageTabColorProps, backgroundColor:'black', color:'white'})
            setOrderTabColorProps({...profileTabColorProps, backgroundColor:'black', color:'white'})

            setPaymentData('This is a payment section')
            console.log("payments")
            console.log(window.location.pathname)

            //setting or resetting data
            setProfileData('')
            setDepositData('')
            setOrderData('')
            setMessageData('')
        }
    }
    const [depositData, setDepositData] = useState('')
    const changeDepositTabColor =tabname=>e=>{
        e.preventDefault()
        if(tabname ==="deposit"){
            setDepositTabColorProps({...depositTabColorProps, backgroundColor:'#ffffff', color:'black', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'})
            setPaymentsTabColorProps({...paymentsTabColorProps, backgroundColor:'#16A068', color:'white'})
            setprofileTabColorProps({...profileTabColorProps, backgroundColor:'black', color:'white'})
            setMessageTabColorProps({...messageTabColorProps, backgroundColor:'#16A068', color:'white'})
            setOrderTabColorProps({...profileTabColorProps, backgroundColor:'black', color:'white'})

            setDepositData('This is a Deposit Area')
            console.log("deposit")
            console.log(window.location.pathname)

            //emptying other data
            setProfileData('')
            setPaymentData('')
            setMessageData('')
            setOrderData('')
        }
    }  
    const [messageData, setMessageData] = useState('')
    const changeMessageTabColor =tabname=>e=>{
        e.preventDefault()
        const mTabler = mtabler
        if(tabname ==='message'){
            setMessageTabColorProps({...messageTabColorProps, backgroundColor:'#ffffff', color:'black', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'})
            setDepositTabColorProps({...depositTabColorProps, backgroundColor:'#16A068', color:'white'})
            setPaymentsTabColorProps({...paymentsTabColorProps, backgroundColor:'black', color:'white'})
            setprofileTabColorProps({...profileTabColorProps, backgroundColor:'black', color:'white'})
            setOrderTabColorProps({...profileTabColorProps, backgroundColor:'#16A068', color:'white'})

            setMessageData('These are messages from the Channel')
            console.log("message")
            console.log(window.location.pathname)

            //emptying other data
            setProfileData('')
            setPaymentData('')
            setDepositData('')
            setOrderData('')
        }
    }  
    const [orderData, setOrderData] = useState('')
    const changeOrderTabColor =tabname=>e=>{
        e.preventDefault()
        if(tabname ==="order"){
            setOrderTabColorProps({...orderTabColorProps, backgroundColor:'#ffffff', color:'black', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'})
            setMessageTabColorProps({...messageTabColorProps, backgroundColor:'#16A068', color:'white'})
            setDepositTabColorProps({...depositTabColorProps, backgroundColor:'black', color:'white'})
            setPaymentsTabColorProps({...paymentsTabColorProps, backgroundColor:'black', color:'white'})
            setprofileTabColorProps({...profileTabColorProps, backgroundColor:'black', color:'white'})

            setOrderData('Order Data')
            console.log("message")
            console.log(window.location.pathname)

            //emptying other data
            setProfileData('')
            setPaymentData('')
            setDepositData('')
            setMessageData('')
        }
    }    
    console.log("where?: "+window.location.pathname)

    const liRef = useRef(null)
    const firstTabCorrector = ()=>{
            setProfileData('This is profile Data space')
            
            // var initPath = window.location.pathname
            // var splitThem = initPath.split("")
            // var popFirst = splitThem.shift()
            // console.log("kill pop: "+splitThem.join(""))
            
            // setprofileTabColorProps({...profileTabColorProps, backgroundColor:'rgb(233,233,233)', color:'black'})
            // setPaymentsTabColorProps({...paymentsTabColorProps, backgroundColor:'#16A068', color:'white'})
            // setDepositTabColorProps({...depositTabColorProps, backgroundColor:'black', color:'white'})
            // setMessageTabColorProps({...messageTabColorProps, backgroundColor:'black', color:'white'})
            //mazes.push({name:"Edward Snowden"})
            return 'Sopas'
            //console.log("onload: "+window.location.pathname)       
    }
    const [payChoices, setPayChoices] = useState(false)
    const [international, setInternational] = useState(false)
    const [local, setLocal] = useState(false)
    return(
        <Fragment>
                <ul className="profile_display" onLoad={firstTabCorrector}>
                    
                    <li ref={liRef} className="profile_directory" onClick={changeProfileTabColor("profile")} style={{...profileTabColorProps}}>
                        <Link to="/dashboard/profile" onClick={changeProfileTabColor("profile")} style={{...profileTabColorProps, color:profileTabColorProps.color, borderWidth:0, boxShadow: ''}}>Profile</Link>
                    </li>
                    <li className="profile_directory" onClick={changePaymentsTabColor("payments")} style={{...paymentsTabColorProps}}>
                        <Link to="/dashboard/payments" onClick={changePaymentsTabColor("payments")} style={{...paymentsTabColorProps, color:paymentsTabColorProps.color, boxShadow: ''}}>Payments</Link>
                    </li>
                    <li className="profile_directory" onClick={changeDepositTabColor("deposit")} style={{...depositTabColorProps}}>
                        <Link to="/dashboard/deposit" onClick={changePaymentsTabColor("deposit")} style={{...depositTabColorProps, color:depositTabColorProps.color, boxShadow: ''}}>Deposit</Link>
                    </li>
                    <li className="profile_directory" onClick={changeMessageTabColor("message")} style={{...messageTabColorProps}}>
                        <Link to="/dashboard/message" onClick={changeMessageTabColor("message")} style={{...messageTabColorProps, color:messageTabColorProps.color, boxShadow: ''}}>Message</Link>
                        {unreadFound === true?
                        <span className='mailer-blinken' style={{position:"absolute", right:120, top:-10,  borderRadius:"50%", width:25, height:25, padding:"5px 5px 5px 5px", backgroundColor:"rgba(200,22,0)", fontWeight:"300", color:"#fff", fontSize:"8pt"}}>+new
                            <svg style={{position:"absolute", color:"#fff", left:0, bottom:0, width:35, height:15, cursor:"pointer", animation:"rotate1 1s cubic-bezier(0.11, 1.22, 0.74, -0.29) infinite"}} viewBox="0 0 121 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="2" y="3" width="117" height="64" stroke="#fff" stroke-width="4"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M120.08 4.18356C104.712 14.039 72.7093 34.6654 67.1625 38.6275C60.5988 43.3158 53.5648 40.5654 50.8557 38.6404L0.371811 4.15143L2.6282 0.848596L53.1455 35.3604L53.1625 35.3725C55.1334 36.7803 60.2159 38.6737 64.8375 35.3725C70.4907 31.3346 102.621 10.6277 117.92 0.816467L120.08 4.18356Z" fill="#fff"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.69527 64.4842L41.1953 30.4842L43.8047 33.5158L4.30475 67.5158L1.69527 64.4842Z" fill="#fff"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M116.24 67.5528L73.7395 33.0528L76.2605 29.9472L118.76 64.4472L116.24 67.5528Z" fill="#fff"/>
                            </svg>
                        </span>
                        :''}
                    </li>
                    <li className="profile_directory" onClick={changeOrderTabColor("order")} style={{...orderTabColorProps}}>
                        <Link to="/dashboard/order" onClick={changeOrderTabColor("order")} style={{...orderTabColorProps, color:orderTabColorProps.color, boxShadow: ''}}>Order</Link>
                    </li>
                </ul>
                <section className="detail_content">    
                    <section className="container">
                        <h1>{console.log(window.location.pathname)} </h1>
                        {profileData?
                        <ProfileDetails username={username} password={password} profileImage={profileImage} uid={userID} urole={userrole} email={email}/>
                        :''}
                        {paymentData?<PaymentDetails/>:''}
                        {/* {depositData?<DepositSlots uid={userID}/>:''} */}
                        {depositData?
                        <Fragment>
                            <section>
                                <section style={{marginBottom:20}}>
                                    <span style={{color:"#000"}}>
                                        You are about to make a commitment deposit of <b>P100.00 or US$2.00</b>. The amount however will be added to your account balance. We have to make sure that who finally gets through are committed clients - not spammers. <b><span style={{color:"#16a068"}}>If you are from the Philippines you must choose Philippine local payments like GCash, Maya, GrabPay, etc.</span> If you are not from Philippines you should choose Paypal. Or if you'd like to pay in US Dollar choose for Paypal.</b>
                                    </span>
                                </section>
                                {payChoices===false?
                                <Fragment>
                                    <button className='payment-choice-btn' onClick={()=>{
                                    setLocal(true);
                                    setInternational(false);
                                    setPayChoices(true)
                                }}><b>GCash, Maya, GrabPay and BPI Online</b> (Philippines Payments)</button><br/>
                                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                    <input type="hidden" name="cmd" value="_s-xclick"/>
                                    <input type="hidden" name="hosted_button_id" value="5VKTWNDQEUS6Y"/>
                                    <input onMouseOver={paypalCollaborate({amountInUSD:2, amountInPHP:100})} className="off_paypal_btn" border="0" type="submit" value="PayPal (Visa, Mastercards, UnionPay, etc.)" alt="PayPal - The safer, easier way to pay online!"/>
                                    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
                                    </form>

                                </Fragment>
                                :''}
                                {local === true?
                                <section style={{width:"100%"}} >
                                    <button className='back-payment-btn' onClick={()=>{
                                       setLocal(false);
                                       setInternational(false);
                                       setPayChoices(false); 
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg><span style={{position:"absolute", top:15, left:50, width:100, fontSize:"13pt", fontWeight:"bolder", color:"#a1a1a1"}}>Go Back</span>
                                    </button>
                                    <UsersDepositDetectorComp email={details.email}/>
                                    <Fragment>
                                        <iframe id="paymongo_iframe" style={{width:"100%", height:400, border:"none"}} src="https://paymongo.page/l/jolme5d-entry-payment" title="jolme5d-entry-payment">
                                        </iframe>
                                    </Fragment>
                                </section>:
                                <Fragment>
                                    
                                </Fragment>
                                }
                                {international===true?
                                <Fragment>
                                    <button className='back-payment-btn' onClick={()=>{
                                       setLocal(false);
                                       setInternational(false);
                                       setPayChoices(false); 
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    </button>
                                    <DepositSlots uid={userID}/>
                                </Fragment>
                                :
                                <Fragment>                                    
                                </Fragment>
                                }
                            </section>
                        </Fragment>
                        :''}
                        {messageData?<MessageChannel/>:''}
                        {orderData?<OrderDetails/>:''}
                    </section>
                </section>
                <TipsToReachClienthood />
                <PrivacyReminder />
        </Fragment>
    )
}

export default UsersCompProfile