//LIBRARIES
import React, {Fragment, useState, useRef} from 'react'
import { useHistory } from 'react-router'
import {emptyReduxInitialState} from '../../../redux/slices/counter'
import { useSelector, useDispatch } from 'react-redux'
import { viewUser, proPicView } from '../../../redux/slices/counter'
import { stringify } from 'querystring'
import { Widget } from '@uploadcare/react-widget'

//COMPONENTS
import { pitch } from '../variables'
import {
    UPPHOTOL,
    UPPHOTOW
    } from '../../../config'
import {
    BUDALOC_CL, 
    LOGOUTER_BQW, LOGOUTER_BQL,
    FIRSTDEPO_CL, FIRSTDEPO_CW,
    DELEACC_CL, DELEACC_CW,
    UPDACCUNAME_CL, UPDACCUNAME_CW,
    UPDACCEMAIL_CL, UPDACCEMAIL_CW
    } from '../../../config'

//APPLICATION
const ClientProfileUpdater = (props)=>{
    const {user, userid, email} = props //as application matured, these will not be in use anymore
    
    var touken = JSON.parse(localStorage.getItem('j5djwt'))
    const historyBuff = useHistory()
    const [token, setToken] = useState({
        username:touken.username,
        userUID:touken.userUID, 
        email: touken.email,
        role: touken.role
    })
    const dispatch = useDispatch()
    const loadProfilePic = ()=>{
        setInterval(()=>{
            dispatch(proPicView())
            dispatch(viewUser())
        },1000)
    }
    loadProfilePic()

    const photo = useSelector(state => state.counter.profilePic)
    const upuser = useSelector(state => state.counter.userDetails.username)
    const upmobile = useSelector(state => state.counter.userDetails.mobileNumber)
    const upemail = useSelector(state => state.counter.userDetails.email)
    const uppayemail = useSelector(state => state.counter.userDetails.paypalEmail)

    const [changeProImage, setChangeProImage] = useState(false)
    const [changeUsername, setChangeUsername] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    const [changeEmail, setChangeEmail] = useState(false)
    const [changePaypalEmail, setChangePaypalEmail] = useState(false)
    const [changeMobile, setChangeMobile] = useState(false)
    const [deleteAccount, setDeleteAccount] = useState(false)
    const [processDeleteAcc, setProcessDeleteAcc] = useState(false)
    const [deleteSuccess, setDeleteSuccess] = useState(false)
    const [updatePhoto, setUpdatePhoto] = useState(false)

    //NEW USERNAME
    const updateUnameSwitcher = (bol)=>{
        if(bol===true){
            return "https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-username/" //UPDACCUNAME_CW
        }else{
            return "http://localhost:8888/api/j5d-update-client-account-username/" //UPDACCUNAME_CL
        }
    }
    const cuname = useRef()
    const getUsernameUpdated = async()=>{
        console.log("New Username: "+cuname.current.value)
        const processUnameChange = await fetch(updateUnameSwitcher(pitch), { //updateUnameSwitcher(pitch)
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userid, newUserName:cuname.current.value})
        })
        const parseUnameRes = await processUnameChange.json()
        const {status} = parseUnameRes
        if(status===true){
            setTimeout(()=>{window.location.reload(true)},1000)
        }
    }
    //NEW PASSWORD
    const updatePasswSwitcher = (bol)=>{
        if(bol===true){
            return "https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-password/" //UPDACCUNAME_CW
        }else{
            return "http://localhost:8888/api/j5d-update-client-account-password/" //UPDACCUNAME_CL
        }
    }
    const cpw = useRef()
    const getPasswordUpdated = async()=>{
        console.log("New Password: "+cpw.current.value)
        const processPasswordChange = await fetch(updatePasswSwitcher(pitch), { //updateUnameSwitcher(pitch)
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userid, newPassword:cpw.current.value})
        })
        const parsePasswRes = await processPasswordChange.json()
        const {status} = parsePasswRes
        if(status===true){
            setTimeout(()=>{window.location.reload(true)},1000)
        }
    }

    //NEW EMAIL
    const updateEmailSwitcher = (bol)=>{
        if(bol===true){
            return "https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-email/" //UPDACCEMAIL_CW
        }else{
            return "http://localhost:8888/api/j5d-update-client-account-email/" //UPDACCEMAIL_CL
        }
    }
    const cemail = useRef()
    const getEmailUpdated = async()=>{
        console.log("New Email: "+cemail.current.value)
        const processEmailChange = await fetch(updateEmailSwitcher(pitch), { //updateUnameSwitcher(pitch)
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userid, newEmail:cemail.current.value})
        })
        const parseEmailRes = await processEmailChange.json()
        const {status} = parseEmailRes
        if(status===true){
            setTimeout(()=>{window.location.reload(true)},1000)
        }
    }
    //NEW PAYPAL ADDRESS
    const updatePayEmailSwitcher = (bol)=>{
        if(bol===true){
            return "https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-paypalemail/" //
        }else{
            return "http://localhost:8888/api/j5d-update-client-account-paypalemail/" //
        }
    }
    const nppemail = useRef()
    const getPaypalEmailUpdated = async()=>{
        console.log("New Paypal Email: " + nppemail.current.value)
        const processPayEmailChange = await fetch(updatePayEmailSwitcher(pitch), { //updateUnameSwitcher(pitch)
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userid, newPaypalEmail:nppemail.current.value})
        })
        const parsePayEmailRes = await processPayEmailChange.json()
        const {status} = parsePayEmailRes
        if(status===true){
            setTimeout(()=>{window.location.reload(true)},1000)
        }
    }
    //NEW MOBILE NUMBER
    const updateMobileNumSwitcher = (bol)=>{
        if(bol===true){
            return "https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-mobilenumber/" //
        }else{
            return "http://localhost:8888/api/j5d-update-client-account-mobilenumber/" //
        }
    }
    const cmonum = useRef()
    const getMobileNumberUpdated = async()=>{
        console.log("New Mobile Number: "+cmonum.current.value)
        const processMobileNumberChange = await fetch(updateMobileNumSwitcher(pitch), { //updateUnameSwitcher(pitch)
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userid, newMobileNumber:cmonum.current.value})
        })
        const parseMobileNumberRes = await processMobileNumberChange.json()
        const {status} = parseMobileNumberRes
        if(status===true){
            setTimeout(()=>{window.location.reload(true)},1000)
        }
    }
    //NEW SHIPPING ADDRESS
    const updateShippingAdSwitcher = (bol)=>{
        if(bol===true){
            return "https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-shipaddress/" //
        }else{
            return "http://localhost:8888/api/j5d-update-client-account-shipaddress/" //
        }
    }
    const cshipad = useRef()
    const getShipAddressUpdated = async()=>{
        console.log("New Shipping Address: "+cshipad.current.value)
        const processShipAddressChange = await fetch(updateShippingAdSwitcher(pitch), { //updateUnameSwitcher(pitch)
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userid, newShippingAddress:cshipad.current.value})
        })
        const parseShipAddressRes = await processShipAddressChange.json()
        const {status} = parseShipAddressRes
        if(status===true){
            setTimeout(()=>{window.location.reload(true)},1000)
        }
    }
    //DELETE ACCOUNT
    const deleteAccSwitcher = (pitch)=>{
        if(pitch===true){
            return "https://salty-inlet-81949.herokuapp.com/api/j5d-delete-client-account/"//DELEACC_CW
        }else{
            return "http://localhost:8888/api/j5d-delete-client-account/"//DELEACC_CL
        }
    }
    const bookLogOutSwitcher =(bol)=>{
        if(bol===true){
            return LOGOUTER_BQW
        }else{
            return LOGOUTER_BQL
        }
    }

    const getLogOutRecorded = async(keys)=>{
        const loggerRes = await fetch(bookLogOutSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(keys)
        })
        const trackedLoggerRec = await loggerRes.json()
        const {status, data} = trackedLoggerRec
        if(data.modifiedCount>0){
            console.log("<<Getting Self Out Of Town>>")
        }
    }
    const logOutProcess = async()=>{
        await getLogOutRecorded({userUniqueID:token.userUID})
        .then(dispatch(emptyReduxInitialState()))
        .then(()=>{
            if(typeof window !== 'undefined'){
                setToken({...token, username:'', email: '', role: ''})
                localStorage.removeItem("j5djwt")
                setTimeout(()=>{
                    historyBuff.push('/deactivation')
                    window.location.reload(true)
                }, 2200)  
            }
         }
        ) 
    }
    const getAccountDeleted = async()=>{
        setProcessDeleteAcc(true) //process on-going
        console.log(`We will delete: ${userid}`)
        console.log(`ENV.ID: ${FIRSTDEPO_CL}`)
        const deleteAcc = await fetch(deleteAccSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userid})
        })
        const accDeletedRes = await deleteAcc.json()
        const {data, status} = accDeletedRes
        if(status === true){
            logOutProcess()
        }else{
            console.log("Something is Wrong!")
        }
    }
    const DeleteAccountConfirm = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, left:0, top:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                </section>
                <section style={{position:"fixed", zIndex:2, top:200, margin:"auto", left:0, width:"100%", height:150, backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", zIndex:2, top:0, margin:"auto", left:0, width:"30%", height:150, backgroundColor:"white", boxShadow:"0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 2px  10px 0 rgba(0, 0, 0, 0.19)", borderRadius:5}}>
                        <span style={{position:"absolute", left:42, top:25, textAlign:"center", fontWeight:"bold", fontSize:"8pt", color:"#16a068"}}>Account No. {userid.toUpperCase()}</span>
                        <h3 style={{position:"relative", top:40, textAlign:"center", fontSize:"14pt", color:"#000"}}>Are you sure to delete your account?</h3>
                        <section className="profile-delete-space" style={{position:"absolute", bottom:40, margin:"auto", width:"100%", textAlign:"center"}}>
                            <button 
                            onClick={getAccountDeleted} style={{marginLeft:5, padding:"10px 30px 10px 30px"}}>
                                YES
                            </button>
                            <button onClick={()=>{setDeleteAccount(false)}} style={{marginLeft:5, padding:"10px 30px 10px 30px"}}>
                                CANCEL
                            </button>
                        </section>
                    </section>
                </section>
            </Fragment>
        )
    }
    const DeleteProcess = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, left:0, top:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                </section>
                <section style={{position:"fixed", zIndex:2, top:0, margin:"auto", left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,1.0)"}}>
                    <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>Deleting account ...</span>
                    </section>
                </section>
            </Fragment>
        )
    }
    const DeleteSuccess = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, left:0, top:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                </section>
                <section style={{position:"fixed", zIndex:2, top:200, margin:"auto", left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,1.0)"}}>
                    <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <h1 style={{color:"#16a068"}}>Account Deleted Successfully</h1>
                        <button 
                        onClick={()=>{historyBuff.push('/')}} >
                            GOODBYE
                        </button>
                    </section>
                </section>
            </Fragment>
        )
    }
    //UPLOAD PHOTO
    //UPLOADING PROFILE PHOTO
    const [photoUploadTrigger, setPhotoUploadTrigger] = useState(false)
    const [fileUploaded, setFileUploaded] = useState('')//client's uploaded image url
    const [imageData, setImageData] = useState({width:0, height:0})
    const [processUpload, setProcessUpload] = useState(false)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [uploadFailed, setUploadFailed] = useState(false)

    let diff = 0
    let multiplyer = 0
    let minLeft = 0
    let minimum = 25
    if(imageData.width>imageData.height){
        diff+=imageData.width-imageData.height
        multiplyer+=imageData.width*0.01
        minLeft += diff/multiplyer
    }else{
        diff+=imageData.height-imageData.width
        multiplyer+=imageData.height*0.01
        minLeft += diff/multiplyer
    }

    const uploadPhotoSwitcher = (pitch)=>{
        if(pitch===true){
            return UPPHOTOW
        }else{
            return UPPHOTOL
        }
    }
    const uploadProfileImage = async()=>{
        setPhotoUploadTrigger(false)
        setProcessUpload(true)
        const uploadImageQuery = await fetch(uploadPhotoSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userid, profileImage:fileUploaded})
        })
        const uploadGetsContact = await uploadImageQuery.json()
        const {status, data} = uploadGetsContact
        if(data.modifiedCount>0){
            setProcessUpload(false)
            setUploadSuccess(true)
        }else{
            setProcessUpload(false)
            setUploadFailed(true)
        }
    }
    const UploadingProcess = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:10, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessUpload(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                            <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                            <span style={{position:"absolute", color:"#fff", top:35, right:85}}>Image Upload</span> <br />
                            <span style={{position:"absolute", color:"#fff", fontSize:"22pt", fontWeight:"bolder", top:50, right:85}}>PROCESSING</span>
                    </section>
                </section>
            </Fragment>
        )
    }
    const SuccessfulUpload = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:11, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                </section>
                <section style={{position:"absolute", zIndex:12, top:0, margin:"auto", left:"auto", width:"100%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,0.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", backgroundColor:"rgba(255,255,255,1.0)", margin:"auto", top:20, width:"35%",  padding:"30px 30px 30px 30px", borderRadius:5}}>
                        <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                            <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image Successful!</span><br/>
                        </section>
                        <button onClick={()=>{setUploadSuccess(false); window.location.reload()}} style={{position:"absolute", top:30, right:30, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                            EXIT
                        </button>
                    </section>
                    
                </section>
            </Fragment>
        )
    }
    const FailedUpload = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                </section>
                <section style={{position:"relative", zIndex:2, top:-400, margin:"auto", left:"auto", width:"60%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", margin:"auto", top:20, width:400, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"10px 10px 10px 10px"}}>
                        <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                            <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image has failed!</span><br/>
                        </section>
                        <button onClick={()=>{setUploadFailed(false)}} style={{position:"absolute", top:10, right:20, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                            EXIT
                        </button>
                    </section>
                    
                </section>
            </Fragment>
        )
    }
    const UploadPhotoFloater = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:10, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                </section>
                <section style={{position:"absolute", zIndex:11, top:20, left:0, margin:"auto", width:"100%", borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,0.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", margin:"auto", left:-100, top:0,  width:"40%"}}>
                        
                        <section style={{position:"relative", margin:"auto", top:0, width:500, backgroundColor:"#fff", padding:"20px 20px 20px 20px", margin:20, borderRadius:10}}>
                            <section style={{position:"relative", margin:"auto", zIndex:12, top:0, width:200, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                                    <span style={{fontWeight:"bolder", color:"#16a068"}}>Upload A Profile Picture</span><br/>
                            </section> <br/>
                            <button onClick={()=>{setUpdatePhoto(false);}} className='deposit-close-btn' style={{position:"absolute",backgroundColor:"white", color:"#16a068", padding:"16px 20px 16px 20px", top:0, right:-55, borderRadius:10}}>
                                <span style={{fontWeight:"bold"}}>X</span>
                            </button>
                            <section style={{position:"relative", top:0}}>
                                <Widget style={{backgroundColor:"black", top:0}}
                                                publicKey='c1f6acc2c018005bfb0c' 
                                                id='file' 
                                                name='file'
                                                tabs='file url'
                                                previewStep='true'
                                                onDialogClose={(file) => {
                                                    console.log('File changed: ', file)
                                                    if (file) {
                                                    file.progress(info => console.log('File progress: ', info.progress))
                                                    file.done(info => console.log('File uploaded: ', info))
                                                    }
                                                    }}
                                                onChange={(info) => {
                                                    setFileUploaded(info.cdnUrl); /*console.log('Upload completed:', info)*/
                                                    setImageData({width:info.originalImageInfo.width, height:info.originalImageInfo.height})} 
                                                }
                                />
                            </section>
                            {fileUploaded !=='' && minLeft<minimum?
                            <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                <b>You can upload this picture</b>
                            </section>
                            :
                            <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                <b>Cannot be uploaded! Must be nearly equal in both sides.</b>
                            </section>
                            }


                            <section>
                                <img src={fileUploaded} id="client_pic" width="500"/>
                            </section>
                            {fileUploaded==='' || minLeft>minimum?
                            <button className='guest-upload-btn' style={{top:62, backgroundColor:"#a1a1a1"}}>
                                No Image or Image Have Problems
                            </button>:
                            <button className='guest-upload-btn' style={{top:62}} onClick={uploadProfileImage}>
                            Upload Image
                            </button>}
                        </section>
                    </section>
                </section>
            </Fragment>
        )
    }
    return(
        <Fragment>
            <section className='updater-profile-space'>
                <section style={{position:"relative", margin:"auto", left:"auto", width:200, top:-45, textAlign:"center", backgroundColor:"white"}}>
                        <h1 style={{padding:"10px 10px 10px 10px"}}>Your Profile Details</h1>
                </section>
                <section style={{position:"absolute", margin:"auto", left:"auto", width:"100%", top:10, textAlign:"center", color:"#16a068"}}>
                    <span style={{fontSize:"9pt", textDecoration:"center" }}>#{userid.toUpperCase()}</span>
                </section>
                <section id='border' style={{position:'relative', margin:"auto", width:"60%"}}>
                    {changeProImage===true?
                        <section onClick={()=>{setUpdatePhoto(true)}} onMouseOut={()=>{setChangeProImage(false)}} id='profile-picture' style={{position:'relative', margin:"auto", width:200, height:200, borderRadius:100, backgroundColor:"#16a068", textAlign:"center", cursor:"pointer"}}>
                            <svg style={{position:"relative", color:"white", width:100, top:30, verticalAlign:"sub" }} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg><br/>
                            <span style={{position:"relative", top:20, color:"white", fontSize:"18pt", fontWeight:600}}>Update</span>
                        </section>:
                        <section onMouseOver={()=>{setChangeProImage(true); setChangePassword(false); setChangeUsername(false); setChangeEmail(false); setChangePaypalEmail(false); setChangeMobile(false); }} id='profile-picture' style={{position:'relative', margin:"auto", width:200, height:200, borderRadius:100, backgroundColor:"#dfdfdf"}}>
                            {!photo?
                            <section style={{position:"relative", top:"38%",  margin:"auto", width:"50%", textAlign:"center"}}>
                                <span style={{position:"relative", fontWeight:"300", color:"#16a068"}}>You have no profile photo</span>
                            </section>
                            :
                            <img style={{width:200, height:200, borderRadius:"50%", borderWidth:5, borderColor:"#16a068", borderStyle:"solid"}} src={photo}/>}
                        </section>
                    }
                    <section onMouseOver={()=>{setChangeUsername(true); setChangePassword(false); setChangeEmail(false); setChangePaypalEmail(false); setChangeMobile(false); }} id='profile-username' style={{position:'relative', margin:"auto", width:"80%", marginTop:50, marginBottom:20, textAlign:"center"}}>
                            <h3 style={{padding:"5px 20px 5px 20px"}}>Username <span style={{color:"#16a068"}}>{upuser}</span></h3>
                    </section>
                    {changePassword===true?
                        <section id='profile-username' style={{position:'relative', margin:"auto", marginTop:20, marginBottom:20, textAlign:"center"}}>
                            <label><b>CREATE A NEW PASSWORD</b> </label><br/><input ref={cpw} className='profile-input-field' type="text" placeholder='**********' style={{width:200, fontSize:"14pt", height:40, paddingLeft:10, color:"#16a068"}} /><button onClick={getPasswordUpdated} onMouseOver={()=>{setChangePassword(true); setChangeUsername(false); setChangeEmail(false); setChangePaypalEmail(false); setChangeMobile(false)}} className='update-prof-u-btn' style={{position:"absolute", top:21, height:45, borderWidth:0, marginLeft:2, padding:"0px 20px 0px 20px"}}>UPDATE</button><button onClick={()=>{setChangePassword(false)}} className='update-prof-c-btn' style={{position:"absolute", top:21, right:-21, height:45, borderWidth:0, marginLeft:2, padding:"10px 20px 10px 20px", cursor:"pointer"}}>CANCEL</button><br/><br/>
                        </section>:
                        <section onMouseOver={()=>{setChangePassword(true); setChangeUsername(false); setChangeEmail(false); setChangePaypalEmail(false); setChangeMobile(false); }} id='profile-username' style={{position:'relative', margin:"auto", width:"80%", marginTop:20, marginBottom:20, textAlign:"center"}}>
                            <h3 style={{padding:"5px 20px 5px 20px"}}>Password <span style={{color:"#16a068"}}>******</span></h3>
                        </section>
                    }
                    {changeEmail===true?
                        <section id='profile-username' style={{position:'relative', margin:"auto", marginTop:20, marginBottom:20, textAlign:"center"}}>
                            <label><b>REPLACE WITH NEW EMAIL</b> </label><br/><input ref={cemail} className='profile-input-field' type="email" placeholder='Email Address' style={{width:200, fontSize:"14pt", height:40, paddingLeft:10, color:"#16a068"}} /><button onClick={getEmailUpdated} onMouseOver={()=>{setChangePassword(false); setChangeUsername(false); setChangeEmail(true); setChangePaypalEmail(false); setChangeMobile(false)}} className='update-prof-u-btn' style={{position:"absolute", top:21, height:45, borderWidth:0, marginLeft:2, padding:"0px 20px 0px 20px"}}>UPDATE</button><button onClick={()=>{setChangeEmail(false)}} className='update-prof-c-btn' style={{position:"absolute", top:21, right:-21, height:45, borderWidth:0, marginLeft:2, padding:"10px 20px 10px 20px", cursor:"pointer"}}>CANCEL</button><br/><br/>
                        </section>:
                        <section onMouseOver={()=>{setChangePassword(false); setChangeUsername(false); setChangeEmail(true); setChangePaypalEmail(false); setChangeMobile(false); }} id='profile-username' style={{position:'relative', margin:"auto", width:"80%", marginTop:20, marginBottom:20, textAlign:"center"}}>
                            <h3 style={{padding:"5px 20px 5px 20px"}}>Email <span style={{color:"#16a068"}}>{upemail}</span></h3>
                        </section>
                    }
                    {changePaypalEmail===true?
                        <section id='profile-username' style={{position:'relative', margin:"auto", marginTop:20, marginBottom:20, textAlign:"center"}}>
                            <label><b>NEW PAYPAL EMAIL</b> </label><br/><input ref={nppemail} className='profile-input-field' type="email" placeholder='Paypal Email' style={{width:200, fontSize:"14pt", height:40, paddingLeft:10, color:"#16a068"}} /><button onClick={getPaypalEmailUpdated} onMouseOver={()=>{setChangePassword(false); setChangeUsername(false); setChangeEmail(false); setChangePaypalEmail(true); setChangeMobile(false)}} className='update-prof-u-btn' style={{position:"absolute", top:21, height:45, borderWidth:0, marginLeft:2, padding:"0px 20px 0px 20px"}}>UPDATE</button><button onClick={()=>{setChangePaypalEmail(false)}} className='update-prof-c-btn' style={{position:"absolute", top:21, right:-21, height:45, borderWidth:0, marginLeft:2, padding:"10px 20px 10px 20px", cursor:"pointer"}}>CANCEL</button><br/><br/>
                        </section>:
                        <section onMouseOver={()=>{setChangePassword(false); setChangeUsername(false); setChangeEmail(false); setChangePaypalEmail(true); setChangeMobile(false); }} id='profile-username' style={{position:'relative', margin:"auto", width:"80%", marginTop:20, marginBottom:20, textAlign:"center"}}>
                            <h3 style={{padding:"5px 20px 5px 20px"}}>Paypal Email <span style={{color:"#16a068"}}>{!uppayemail?<span style={{fontSize:"11pt", fontWeight:"lighter"}}>None</span>:<span>{uppayemail}</span>}</span></h3>
                        </section>
                    }
                    {changeMobile===true?
                        <section id='profile-username' style={{position:'relative', margin:"auto", marginTop:20, marginBottom:20, textAlign:"center"}}>
                            <label><b>CHANGE MOBILE NUMBER</b> </label><br/><input ref={cmonum} className='profile-input-field' type="number" placeholder='Mobile Number' style={{width:200, fontSize:"14pt", height:40, paddingLeft:10, color:"#16a068"}} /><button onClick={getMobileNumberUpdated} onMouseOver={()=>{setChangePassword(false); setChangeUsername(false); setChangeEmail(false); setChangePaypalEmail(false); setChangeMobile(true); }} className='update-prof-u-btn' style={{position:"absolute", top:21, height:45, borderWidth:0, marginLeft:2, padding:"0px 20px 0px 20px"}}>UPDATE</button><button onClick={()=>{setChangeMobile(false)}} className='update-prof-c-btn' style={{position:"absolute", top:21, right:-21, height:45, borderWidth:0, marginLeft:2, padding:"10px 20px 10px 20px", cursor:"pointer"}}>CANCEL</button><br/><br/>
                        </section>:
                        <section onMouseOver={()=>{setChangePassword(false); setChangeUsername(false); setChangeEmail(false); setChangePaypalEmail(false); setChangeMobile(true); }} id='profile-username' style={{position:'relative', margin:"auto", width:"80%", marginTop:20, marginBottom:20, textAlign:"center"}}>
                            <h3 style={{padding:"5px 20px 5px 20px"}}>Mobile Number <span style={{color:"#16a068"}}>{!upmobile?<span style={{fontSize:"11pt", fontWeight:"lighter"}}>None</span>:<span>{upmobile}</span>}</span></h3>
                        </section>
                    }
                    <section className='field-blank-space'>
                    </section>
                    <section className='profile-delete-space' style={{position:'relative', margin:"auto", width:"80%", marginTop:20, marginBottom:20, textAlign:"center"}}>
                        <button 
                        onClick={()=>{setDeleteAccount(true)}} 
                        onMouseOver={()=>{setChangePassword(false); setChangeUsername(false); setChangeEmail(false); setChangePaypalEmail(false); setChangeMobile(false); }}>
                            DELETE ACCOUNT
                        </button>
                    </section>
                </section>
            </section>
            <section className='updater-profile-space'>
                <section style={{position:"relative", margin:"auto", left:"auto", width:250, top:-45, textAlign:"center", backgroundColor:"white"}}>
                        <h1 style={{padding:"10px 10px 10px 10px"}}>Read Our Privacy Policy</h1>
                </section>
                <section style={{position:"absolute", margin:"auto", left:"auto", width:"100%", top:10, textAlign:"center", color:"#16a068"}}>
                    <span style={{fontSize:"10pt", textDecoration:"center" }}>It's important for you to know exactly what we do to your data</span>
                </section>
            </section>
            {deleteAccount=== true?<DeleteAccountConfirm />:''}
            {updatePhoto === true?<UploadPhotoFloater />:''}
            {processDeleteAcc===true?<DeleteProcess/>:''}
            {deleteSuccess===true?<DeleteSuccess />:''}
            {processUpload === true?<UploadingProcess />:''}
            {uploadSuccess === true?<SuccessfulUpload />:''}
            {uploadFailed === true?<FailedUpload />:''}
        </Fragment>
    )
}

export default ClientProfileUpdater