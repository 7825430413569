//LIBRARIES
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { proPicView, viewUser, viewOrder, viewClientOrders, messageTabToggle} from '../slices/counter'
import {querriedData, getAllUsers} from '../querries/userdataquery'
import EmojiPicker, { Picker, Emoji, EmojiStyle} from 'emoji-picker-react' 
//COMPONENTS
import {
  GCHATSYS_L, GCHATSYS_W,
  GCHATSYS_REL, GCHATSYS_REW,
  GUEST_MG_INDL, GUEST_MG_INDW
} from '../../config'
import { pitch } from '../../screens/components/variables'
//OPERATIONS
//THIS IS A MESSAGE COMPONENT
const CounterView = ()=>{ 
  const user = useSelector(state => state.counter.userDetails)
  const photo = useSelector(state => state.counter.profilePic)

  const inq = user.inquiries
  //console.log(inq[3])
  const dispatch = useDispatch()
  dispatch(proPicView())

  // const refreshUserDetails = ()=>{
  //   setInterval(dispatch(viewUser()),500)
  // }
  const messageTabler = (top)=>{
    dispatch(messageTabToggle(top))
  }

  const chattySwitcher = (pitch)=>{
    if(pitch===true){
        return GCHATSYS_W
    }else{
        return GCHATSYS_L
    }
  }
  const chaReSwitcher = (pitch)=>{
    if(pitch===true){
        return GCHATSYS_REW
    }else{
        return GCHATSYS_REL
    }
  }
  const messageTabToggler = (tog)=>{
      dispatch(messageTabToggle(tog))
  }
  //DEFAULT QUERY
  const [inquiries, setInquiries] = useState([])
  const defaultQueryMessageGetter = async(sing)=>{
    const defaultQRedux = await fetch(chaReSwitcher(pitch), {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body: JSON.stringify(sing)
    })
    const defcon = await defaultQRedux.json()
    const {status, data} = defcon
    //console.log(data)
    //console.log(data[0].inquiries[0]) //works
    let dashlings = [];
    data[0].inquiries.map(item=>{dashlings.push({uid:item.userUniqueID, unread:item.unread, username:item.uname, role:item.role, message:item.message, subrole: item.subrole, date:item.datePosted})})
    //console.log(dashlings)
    setInquiries(dashlings)
  }
  useEffect(()=>{
    defaultQueryMessageGetter({userUniqueID:user.userUniqueID})
  }, [])
  
  //console.log(inquiries)

  const [messageEntry, setMessageEntry] = useState({
    uname:'',
    role:'',
    subrole:'',
    message:''
  })
  const [refreshLoadedList, setRefreshLoadedList] = useState(false)
  const [newChatEntry, setNewChatEntry] = useState([])

  const snapBackToDefault = ()=>{
    setRefreshLoadedList(false)
    defaultQueryMessageGetter({userUniqueID:user.userUniqueID})
  }
  //RE-QUERY
  const reconnectReduxAPI = async(sing)=>{
    const reconRedux = await fetch(chaReSwitcher(pitch), {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body: JSON.stringify(sing)
    })
    const frecon = await reconRedux.json()
    const {status, data} = frecon
    //console.log(data[0].inquiries[0]) //works
    let dashlings = [];
    data[0].inquiries.map(item=>{dashlings.push({
      uid:item.userUniqueID, 
      username:item.uname, 
      role:item.role, 
      message:item.message, 
      subrole: item.subrole, 
      date:item.datePosted
    })})
    console.log(dashlings)
    setNewChatEntry(dashlings)
  }
  //FIRST QUERY
  const processNewMessage = async()=>{
    //console.log({userUniqueID:user.userUniqueID, uname:user.username, role:user.role, subrole:user.subrole, message:messageEntry.message})
    const mEntries = {
      uname:user.username, 
      userUniqueID:user.userUniqueID,
      role:user.role,
      subrole:user.subrole,
      message:messageEntry.message, 
      unread:true
    }

    const guestMessageQuery = await fetch(chattySwitcher(pitch), {
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },
      body:JSON.stringify(mEntries)
    })
    const responseGchat = await guestMessageQuery.json()
    const {status, data, inquiry} = responseGchat
    if(status === 'Successful!'){
        console.log("Successfull Connection")
        reconnectReduxAPI({userUniqueID:user.userUniqueID}) //--> TO RE-QUERY
        //console.log(data.inquiry)
        //setNewChatEntry(inquiry)
        setRefreshLoadedList(true)
    }else{
      console.log("Something is Wrong!")
    }
    messageTabToggler('message')
    setInputStr('')
  }

  //NON-REDUX-BASED CONTENT GENERATED - REACTIVE MESSAGE LIST
  //default message listing SHORTENER
  // let start = newChatEntry.length-6
  // let last = newChatEntry.length
  // let tailEnder = newChatEntry.slice(start,last)

  let display = 6
  let tailEnder = [];
  if(newChatEntry.length<6){
    let laster = newChatEntry.length
    tailEnder = newChatEntry.slice(0, laster)
  }else{
    let laster = newChatEntry.length
    tailEnder = newChatEntry.slice(newChatEntry.length-display, laster)
  }

  const RefreshChatContent = ()=>{
    //console.log(newChatEntry)//<-checked
    return(
      <Fragment>
              {newChatEntry.length===0?
              <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                <span style={{color:"#16a068"}}>loading data...</span>
              </section>
              :
              <Fragment>
                {tailEnder.map(msg=>{
                if(msg.length===0){
                  return(
                    <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                      <span style={{color:"#16a068"}}>data loading...</span>
                    </section>
                  )
                }else{
                      return(
                        <section style={{position:"relative", top:0, left:20, margin:"auto", width:"80%", marginBottom:0, padding:"20px 20px 20px 20px"}} onMouseOver={snapBackToDefault}>
                          {msg.role !== 'guest'?
                          <section onMouseOver = {getToMarkReadAllMsg({userUniqueID:user.userUniqueID, otherSide:msg.username})} style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          <span style={{color:"#fff", fontWeight:"bold"}}>
                            {msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}
                            <span style={{position:"absolute", top:30, left:3, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </span>
                        </section>
                          :<section style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          {photo?
                          <img src={photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:30, width:30}}/>
                          :<span style={{color:"#16a068", fontWeight:"bold"}}>
                          {msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}
                        </span>}
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                        </section>}
                          
                          <span style={{position:"relative", left:25, top:-10, color:"#16a068", fontSize:"8pt"}}>{msg.date}</span><br/>
                          <span style={{position:"relative", left:25, top:-10, color:"#16a068"}}>{msg.message}</span>
                        </section>
                      )
                }
              })}
              </Fragment>
            }
      </Fragment>
    )
  }

  const  getNewMessage = message=>event=>{
    event.preventDefault()
    message = event.target.value //uname:user.username, role:user.role, subrole:'', message:message
    setMessageEntry({...messageEntry, message:message})
  }

  //MARKING UNREAD to be come being READ
  const readIndicatorSwitcher =(bol)=>{
    if(bol===true){
        return GUEST_MG_INDW
    }else{
        return GUEST_MG_INDL
    }
  }
  const getToMarkReadAllMsg = async(sing)=>{
        //console.log("Ping the world")
        const defaultQRedux = await fetch(readIndicatorSwitcher(pitch), {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(sing)
        })
        const defcon = await defaultQRedux.json()
        const {status, data} = defcon
        // if(data.modifiedCount>0){
        //   console.log("Marking Unread as Read - Success!")
        // }
        //console.log(data)
        //console.log(data[0].inquiries[0]) //works
        // //console.log(dashlings)
        // setInquiries(dashlings)
  }
  //default message listing SHORTENER

    let starter = 4
    let tailInquiry = [];
    if(inquiries.length<4){
      let laster = inquiries.length
      tailInquiry = inquiries.slice(0, laster)
    }else{
      let laster = inquiries.length
      tailInquiry = inquiries.slice(laster-starter, laster) //inquiries.length-starter
    }
  //PICKER STATE AND METHOD
  const [inputStr, setInputStr] = useState('')
  const [showPicker, setShowPicker] = useState(false)
  const onEmojiClick = (emojiObject)=>{
    setInputStr(prevInput => prevInput + emojiObject.emoji);
  }
  return (
    <Fragment>
        <section onMouseOver={snapBackToDefault}>
          <section style={{position:"relative", top:-20,  width:"93%", margin:"auto", marginBottom:20 }}>
            {inq.includes('')?
            <section style={{position:"relative", top:20, margin:"auto", width:"30%"}}>
              <span style={{position:"relative", width:120, margin:"auto", fontSize:"11pt", color:"#16a068", textAlign:"center", padding:"50px 20px 20px 20px"}}>No Messages to Load</span>
            </section>
            :''
            }
            {refreshLoadedList===true?
            <RefreshChatContent />
            :<Fragment>
              {inquiries?
              tailInquiry.map(msg=>{
                if(msg.length<1){
                  return(
                    <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                      <span style={{color:"#16a068"}}>data loading...</span>
                    </section>
                  )
                }else{
                      return(
                        <Fragment>
                        {msg.role === 'client'?
                        <section className = 'guest-entry-chat' onMouseOver={()=>{
                          getToMarkReadAllMsg({userUniqueID:user.userUniqueID, otherSide:msg.message})
                        }} style={{position:"relative", top:-10, left:0, backgroundColor:"#dfdfdf", margin:"auto", width:"85%", marginBottom:5, padding:"12px 30px 15px 20px"}}>
                          {msg.role !== 'client' && msg.unread === true?<button style={{position:"absolute", borderRadius:20, borderColor:"#16a068",borderWidth:1, right:20, backgoundColor:"#16a068", color:"#16a068", borderWidth:1, borderStyle:"solid", padding:"5px 10px 5px 10px"}}>Unread</button>:'' }
                          {msg.role !== 'client'?
                          <section onMouseOver = {getToMarkReadAllMsg({userUniqueID:user.userUniqueID, otherSide:msg.message})} style={{position:"absolute", top:26, left:10, textAlign:"center", backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          <span style={{color:"#fff", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>
                          :<section style={{position:"absolute", top:26, left:10, textAlign:"center", backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            {photo?
                            <img src={photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:30, width:30}}/>
                            :<span style={{color:"#16a068", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>}
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>}
                              <span style={{position:"relative", left:25, color:"#16a068", fontSize:"6pt"}}>{msg.date}</span><br/>
                              <span style={{position:"relative", left:25, color:"#16a068"}}>{msg.message}</span>
                        </section>
                        :
                        <section className = 'guest-entry-chat' onMouseOver={()=>{
                          getToMarkReadAllMsg({userUniqueID:user.userUniqueID, otherSide:msg.message})
                        }} style={{position:"relative", top:-10, left:0, margin:"auto", width:"85%", marginBottom:5, padding:"12px 30px 15px 20px"}}>
                           {msg.role !== 'client' && msg.unread === true?<button style={{position:"absolute", borderRadius:20, borderColor:"#16a068",borderWidth:1, right:20, backgoundColor:"#16a068", color:"#16a068", borderWidth:1, borderStyle:"solid", padding:"5px 10px 5px 10px"}}>Unread</button>:'' }
                          {msg.role !== 'client'?
                          <section onMouseOver = {getToMarkReadAllMsg({userUniqueID:user.userUniqueID, otherSide:msg.message})} style={{position:"absolute", top:26, left:10, textAlign:"center", backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          <span style={{color:"#fff", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>
                          :<section style={{position:"absolute", top:26, left:10, textAlign:"center", backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            {photo?
                            <img src={photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:30, width:30}}/>
                            :<span style={{color:"#16a068", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>}
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>}
                              <span style={{position:"relative", left:25, color:"#16a068", fontSize:"6pt"}}>{msg.date}</span><br/>
                              <span style={{position:"relative", left:25, color:"#16a068"}}>{msg.message}</span>
                        </section>
                        }
                      </Fragment>
                      )
                }
              })
              :''
            }
              </Fragment>}

          </section>
          <section style={{position:"absolute", bottom:125, width:"100%" }} onMouseOver={snapBackToDefault}>
              {/* 
              <section style={{position:"absolute", top:15, left:15, zIndex:2, backgroundColor:"#fff", width:4, height:4, borderRadius:"50%", padding:"20px 20px 20px 20px"}}>
              {photo?
              <img src={photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:40, width:40}}/>:
              <b style={{position:"absolute", left:10, fontSize:"26pt"}}>{user.username.charAt(0).toUpperCase()}{user.username.charAt(1).toUpperCase()}</b>
              }
              </section> 
              */}
            <section style={{position:"absolute", left:15, borderColor:"#16a068", top:0, width:"93%", height:77, borderRadius:5, borderWidth:1, borderStyle:"solid"}} >
              <textarea value={inputStr} onChange={(e)=>{setInputStr(e.target.value)}} onMouseDown={()=>{setShowPicker(true)}} id='guest-message' onMouseLeave={getNewMessage('message')} style={{position:"relative", backgroundColor:"#fff", top:5, left:5, width:"90%", height:33, borderColor:"#fff", borderStyle:"solid", lineHeight: 1, borderWidth:0, borderRadius:5, padding:"10px 10px 10px 10px", color:"#16a068", fontFamily:"Baloo", fontSize:"11pt" }} type="text" row="20"></textarea>
              {!messageEntry.message?
              <button className='guest-chat-btn' style={{position:"absolute",  right:-1, top:85, width:80, height:30, backgroundColor:"#c1c1c1", color:"#16a068"}}>
                <span style={{position:"absolute", top:5, left:18, color:"#fff",  textAlign:"center", verticalAlign:"center"}}>Send</span>
              </button>
              :<button style={{position:"absolute", right:-1,  top:85, width:80, height:30}} className='guest-chat-btn' onMouseOver={()=>{setShowPicker(false)}} onClick={(e)=>{
                e.preventDefault();
                setRefreshLoadedList(false);
                document.getElementById("guest-message").value = "";
                processNewMessage();
                //refreshUserDetails()
                }}>
                <span style={{position:"absolute", top:5, left:18,  textAlign:"center", verticalAlign:"center"}}>Send</span>
              </button>}
              {!messageEntry.message?<span style={{position:"absolute", bottom:-20, left:10, fontSize:"9pt", color:"#16a068"}}>The field is empty!</span>:''}
              {showPicker?
                <Fragment>
                <section style={{position:"relative", top:-380, right:400,   zIndex:11}}>
                    <EmojiPicker 
                    pickerStyle={{width:'100%'}} 
                    onEmojiClick={onEmojiClick}
                    />
                </section>
                </Fragment>
              :''}
            </section>
          </section>
        </section>
    </Fragment>
  )
}

//ORDER TAB COMPONENT
export const CounterOrderView = ()=>{
  const user = useSelector(state => state.counter.userDetails)
  const order = useSelector(state => state.counter.orderDetails)

  console.log(order)

  const dispatch = useDispatch()
  dispatch(viewUser())
  dispatch(viewOrder())

  const [openDetails, setOpenDetails] = useState(false)
  const viewOrderDetails = (e)=>{
    e.preventDefault()
    setOpenDetails(true)
  }

  if(querriedData()){
    return (
      <div>
        <div>
          {order.orderid?
          <Fragment>
            <section style={{position:"relative", top:-20}}>
              <span style={{fontSize:"16pt"}}>Your First Order<br/></span>
              <span style={{fontSize:"11pt", color:"#16a068"}}>You can only place order more than once after your profile is fully updated</span>
            </section>
            <section className="orderpick">
            <section className="orderdetailsintro">
              <svg width="60" height="43" viewBox="0 0 60 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="59.125" height="43" rx="10" fill="#C4C4C4"/>
                <rect x="9.5" y="8.5" width="39" height="26" fill="#C4C4C4" stroke="black"/>
                <line x1="20" y1="13.5" x2="44" y2="13.5" stroke="black"/>
                <line x1="14" y1="18.5" x2="44" y2="18.5" stroke="black"/>
                <line x1="14" y1="23.5" x2="44" y2="23.5" stroke="black"/>
                <line x1="14" y1="28.5" x2="44" y2="28.5" stroke="black"/>
              </svg>
              <b style={{position:"absolute", left:90}}>{order.categorychosen===""?'':order.categorychosen}</b><br/>
              <span style={{position:"absolute", top:25,  left:90, fontSize:"10pt"}}>ORDER ID: {order.orderid===""?'':order.orderid.toUpperCase()}</span>
            </section>
            
            {openDetails?<Fragment><button className="user_view_btn" onClick={()=>{setOpenDetails(false)}}>Hide</button><section className="orderdetailspace">Instruction<br/> <b>{order.clientmsg===""?'':order.clientmsg}</b></section></Fragment>:<button className="user_view_btn" onClick={viewOrderDetails}>View</button>}
            </section>
          </Fragment>:
      <section>
        <span style={{fontSize:"33pt", color:"#16a068"}}>Hey, {user.username}! You have no orders</span>
        <p>Because you are still a guest. But no worries. As soon as you have completed the profile update process you will be redirected automatically to the client dashboard.
          As a full pledge client you will then be able to order as many as you like and as your money can afford.
        </p>
        <p>Good Luck in your journey with us!
        </p><br/>
        <span style={{fontSize:"16pt", color:"#16a068"}}>JOLME5D TEAM</span>
      </section>}
          
        </div>
        
      </div>
    )
  }else{
    return (
      <div>
        <div>
          <section><b>Refresh to load data...</b></section>
        </div>
        
      </div>
    )    
  }
}
//Yahoo!
export const CounterClientOrderView = ()=>{
  const historian = useHistory()
  const [proToggler, setProToggler] = useState(true)

  const corder = useSelector(state => state.counter.allOrders)
  const dispatch = useDispatch()
  dispatch(viewClientOrders())

  // const [openDetails, setOpenDetails] = useState(false)
  // const viewOrderDetails = (e)=>{
  //   e.preventDefault()
  //   setOpenDetails(true)
  // }
  // if(getAllUsers){
  //   //console.log("baki")
  // }
//   const [orderTime, setOrderTime] = useState()

// setInterval(() => {
//       orderLooper()
//   }, 2000)

// const orderLooper = ()=>{
//     setOrderTime(corder)
// }
  if(querriedData()){
    return (
      <Fragment>
        {corder?()=>{{corder.map(item=>{
          if(item.length>0){
            return(
              <Fragment>
                {item.orderID}<br/>
              </Fragment>
            )
          }
        })}}:<h3>You sucked!</h3>}
      </Fragment>
      // <Fragment>
      //         {corder.map((item)=>{
      //           return(<Fragment>
      //           {proToggler===true?<section className='client-dash'> 
                    
      //               <button onClick={()=>{setProToggler(false)}} style={{position:"absolute", right:20, marginTop:8, backgroundColor:"#16a068", borderWidth:0, padding:"5px 15px 5px 15px", borderRadius:25, color:"white", cursor:"pointer"}}>Minimize</button>
      //               <span style={{position:"absolute", left:30, marginTop:8, fontSize:"12pt", color:"#16a068", textAlign:"center"}}>ACTIVE PROJECTS</span><br/><br/>
                    
      //               {item.length>0?<Fragment>
      //                                   <section className='project-single'>
      //                                       <ul className='single-spanned'>
      //                                           <li>
      //                                               <div style={{width:75, height:75, backgroundColor:"#16a068", borderRadius:50}}>
                                                        
      //                                               </div>
      //                                           </li>
      //                                           <li>
      //                                               <div>
      //                                                   <h3 style={{position:"relative", top:0, left:-20}}>{item.categoryChosen}</h3>
      //                                                   <span style={{position:"relative", top:-20, left:-20, fontSize:"9pt", color:"#16a068"}}>80% Complete</span>
      //                                               </div>
      //                                           </li>
      //                                           <li>
      //                                               <div>
      //                                                   <h3>Price</h3>
      //                                                   <span style={{position:"relative", top:-20, fontSize:"9pt", color:"#16a068"}}>${item.usdPrice}</span>
      //                                               </div>
      //                                           </li>
      //                                           <li>
      //                                               <div>
      //                                                   <h3>Handled By</h3>
      //                                                   <span style={{position:"relative", top:-20, fontSize:"9pt", color:"#16a068"}}>Hanx Robilon</span>
      //                                               </div>
      //                                           </li>
      //                                           <li>
      //                                               <div>
      //                                                   <Link onClick={()=>{historian.push(`/dashboard/project/${item.orderID}`)}} style={{textDecoration:"none"}}><h3 style={{color:"#16a068"}}>View</h3></Link>
      //                                               </div>
      //                                           </li>
      //                                       </ul>
      //                                   </section>
      //                               </Fragment>:<Fragment>
      //                                   <h3>You have no active orders</h3>
      //                               </Fragment>}
                  
                       
      //           </section>:<section onClick={()=>{setProToggler(true)}} className='client-prev-projects'>
      //                       <span style={{position:"relative", fontSize:"12pt", color:"#c1c1c1", textAlign:"center"}}>Active Projects</span>
      //           </section>}
      //           </Fragment>)
      //         })}
      //       </Fragment> 
    )
  }
}

export default CounterView