//LIBRARIES
import React, {Fragment, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import DropboxChooser from 'react-dropbox-chooser'
import { useSelector, useDispatch } from 'react-redux'
import {userListsViewToggle} from '../../../redux/slices/counter'
//MUI
import Dialog, { DialogProps } from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'

//COMPONENTS
import {accessSwitch,
    emailMessengerLinkLocal,
    emailMessengerLinkWeb
} from '../variables'
import {DSAPIL, DSAPIW } from '../../../config'
import {SDAPIL, SDAPIW, DFAPIL, DFAPIW, DELDUX_APIL, DELDUX_APIW, UPDUX_APIL, UPDUX_APIW } from '../../../config'
import { json } from 'body-parser'
import { stringify } from 'querystring'

//OPERATIONS
const Jolme5DTheme = createTheme({
    palette: {
      primary: {
        main: "#16A068"
      },
    },
})
const DashboardOutputDisplay = (props)=>{
    const usersListToggle = useSelector(state => state.counter.userListsView)

    const dispatch = useDispatch()
    const xUsersViewer = (bole)=>{
        dispatch(userListsViewToggle(bole))
    }
    //EMAIL DIALOG
    const [emailDialog, setEmailDialog] = useState(false)
    const [mailboxComposer, setMailboxComposer] = useState(false)
    const [mailboxRead, setMailboxRead] = useState(false)
    const [mailboxDeleted, setMailboxDeleted] = useState(false)
    const [targetEmail, setTargetEmail] = useState('')
    const [mailerInfo, setMailerInfo] = useState({
        username:'',
        targetemail:'',
        fromemail:'',
        subject:'',
        textbodyplain:'',
        textbodyhtml:''
    })
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    const nodemailerswitcher = (bol)=>{
        if(bol===true){
            return emailMessengerLinkWeb
        }else{
            return emailMessengerLinkLocal
        }
    }
    const pipeOutEmailMsg = async(mailer)=>{
        console.log(mailer)
        const pipedMail = await fetch(nodemailerswitcher(accessSwitch), {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(mailer)
        })
        const parcel = await pipedMail.json()
        const {status, data} = parcel
        console.log(data)
        if(data === "success"){
            setEmailDialog(false)
        }
      }
    
    const sendingEmail = ()=>{
        pipeOutEmailMsg({
            username: props.loggeduser,
            targetemail: targetEmail, 
            fromemail: 'webbyjolme@jolme5d.com', 
            subject: mailerInfo.subject,
            textbodyplain: mailerInfo.textbodyplain,
            textbodyhtml:mailerInfo.textbodyplain
         })
    }
    const handleFieldChange = whatever=>event=>{
        setMailerInfo({...mailerInfo, [whatever]:event.target.value})
    }
    const cancelAndClear = ()=>{
        setEmailDialog(false); 
        setTargetEmail('')
        setMailerInfo({...mailerInfo, targetemail:'', subject:'', textbodyplain:''})
    }
    
    const {datascraped} = props
    const [activeFirst, setActiveFirst] = useState(true)
    const [activeSecond, setActiveSecond] = useState(false)
    const [activeThird, setActiveThird] = useState(false)
    const [activeFourth, setActiveFourth] = useState(false)
    const [activeFifth, setActiveFifth] = useState(false)

    const [userFloater, setUserFloater] = useState(false) 
    const [deleteSpinner, setDeleteSpinner] = useState(false)
    //console.log(datascraped)
    const [userDeleter, setUserDeleter] = useState(false)
    const [scrappedInfo, setScrappedInfo] = useState({
        username:'',
        email:''
    })
    const [deleteStatus, setDeleteStatus] = useState(false)

    const userDeleteAPISwitcher =(bol)=>{
        if(bol===true){
            return DELDUX_APIW
        }else{
            return DELDUX_APIL
        }
    }
    const deleteAUser = async(userid)=>{
        const fdelete = await fetch(userDeleteAPISwitcher(accessSwitch),{
            method:"POST",
            body:JSON.stringify({uid:userid}),
            headers:{"Content-Type":"application/json"}
        });
        const pitchDelete = await fdelete.json()
        const {status} = pitchDelete
        setDeleteStatus(status)
        setUserFloater(false)
        setDeleteSpinner(false)
    }
    const [newRole, setNewRole] = useState({role:''})
    const getNewRole = name=>event=>{
        event.preventDefault()
        setNewRole({...newRole, [name]:event.target.value})
    }

    const assignToEmployee = (fieldname)=>(event)=>{
        event.preventDefault()

    }
    console.log("New Role:"+newRole.role)
    
    const [updateStatus, setUpdateStatus] = useState(false)
    const userUpdateRoleSwitcher =(bol)=>{
        if(bol===true){
            return UPDUX_APIW
        }else{
            return UPDUX_APIL
        }
    }
    const [successUpdate, setSuccessUpdate] = useState(false) //usersListToggle; <-issues found
    const updateUserRole = async(userid, newrole)=>{
        console.log(userid+" and "+ newrole)
        const updator = await fetch(userUpdateRoleSwitcher(accessSwitch),{
            method:"POST",
            body:JSON.stringify({uid:userid, newrole:newrole}),
            headers:{"Content-Type":"application/json"}
        });
        const pitchUpdate = await updator.json()
        const {status} = pitchUpdate
        setSuccessUpdate(status)
        setUserFloater(false)
        setUpdateStatus(false)
    }
    const UpdateSuccess = ()=>{
        return(
            <Fragment>
                <section className='user-floater' onClick={(e)=>{e.preventDefault();}}>
                    
                </section>
                <section style={{width:"30%", margin:"auto"}} className='user-floater-pad'>
                        <section style={{textAlign:"center", width:"30%",  margin:"auto"}} className='user-floats-center'>
                            <span style={{fontSize:"18pt"}}><b>{scrappedInfo.username}</b>'s role updated successfully!</span><br/>
                            <button style={{backgroundColor:"#16a068", borderWidth:0, borderRadius:5, padding:"5px 40px 5px 40px", marginRight:2, marginTop:20}}
                            onClick={(e)=>{e.preventDefault(); 
                            setDeleteStatus(false);
                            setSuccessUpdate(false);
                            //window.location.reload(true);
                            xUsersViewer(null);
                            xUsersViewer(true);
                            }}>EXIT</button>
                        </section> 
                </section> 
            </Fragment>
        )
    }

    const DeleteSuccess = ()=>{
        return(
            <Fragment>
                <section className='user-floater' onClick={(e)=>{e.preventDefault();}}>
                    
                </section>
                <section style={{width:"30%", margin:"auto"}} className='user-floater-pad'>
                        <section style={{textAlign:"center", width:"30%",  margin:"auto"}} className='user-floats-center'>
                            <span style={{fontSize:"18pt"}}><b>{scrappedInfo.user}{scrappedInfo.username}</b> is deleted successfully!</span><br/>
                            <button style={{backgroundColor:"#16a068", borderWidth:0, borderRadius:5, padding:"5px 40px 5px 40px", marginRight:2, marginTop:20}}
                            onClick={(e)=>{e.preventDefault(); 
                            setDeleteStatus(false);
                            window.location.reload(true);
                            }}>EXIT</button>
                        </section> 
                </section> 
            </Fragment>
        )
    }

    const DeleteProgressSpinner = ()=>{
        return(
            <Fragment>
                <section className='user-floater' onClick={(e)=>{e.preventDefault();}}>
                    
                </section>
                <section  style={{position:"fixed", top:200, left:500, width:"8%", height:"8%", backgroundColor:"rgba(0,0,0,0.0)", margin:"auto"}} className='user-floater-pad'>
                    <div class="lds-ripple"><div></div><div></div></div> <br/>
                    <span style={{position:"absolute", color:"white", top:25, left:85}}>Deleting...</span>    
                </section>  
            </Fragment>
        )
    }

    const UpdateProgressSpinner = ()=>{
        return(
            <Fragment>
                <section className='user-floater' onClick={(e)=>{e.preventDefault();}}>
                    
                </section>
                <section  style={{position:"fixed", top:200, left:500, width:"8%", height:"8%", backgroundColor:"rgba(0,0,0,0.0)", margin:"auto"}} className='user-floater-pad'>
                    <div class="lds-ripple"><div></div><div></div></div> <br/>
                    <span style={{position:"absolute", color:"white", top:25, left:85}}>Updating...</span>    
                </section>  
            </Fragment>
        )
    }
    
    const DeleteFloaterUser = ()=>{
        return(
            <Fragment>
                <section className='user-floater' onClick={(e)=>{e.preventDefault(); setUserDeleter(false);}}>
                </section>
                <section style={{width:"30%", margin:"auto"}} className='user-floater-pad'>
                            {newRole}
                        <section style={{textAlign:"center", width:"30%",  margin:"auto"}} className='user-floats-center'>
                            <span style={{fontSize:"18pt"}}>Are you sure to delete <b>{scrappedInfo.user}</b>?</span><br/>
                            {/* {scrappedInfo.uid.toUpperCase()} */}
                            <button onClick={(e)=>{e.preventDefault(); setUserDeleter(false);}} style={{backgroundColor:"#16a068", borderWidth:0, borderRadius:5, padding:"5px 10px 5px 10px", marginRight:2, marginTop:20}}>CANCEL</button>
                            <button style={{backgroundColor:"#16a068", borderWidth:0, borderRadius:5, padding:"5px 10px 5px 10px", marginRight:2, marginTop:20}}
                            onClick={(e)=>{e.preventDefault(); 
                                deleteAUser(scrappedInfo.uid);
                                setUserDeleter(false); 
                                setDeleteSpinner(true);
                            }}>CONFIRM</button>
                        </section> 
                </section>  
            </Fragment>
        )
    }
    
    const FloatOutUserInfo = ()=>{
        return(
            <Fragment>
                <section className='user-floater' onClick={(e)=>{e.preventDefault(); setUserFloater(false);  setNewRole({role:''})}}>
                    
                </section>
                <section className='user-floater-pad'>
                        <section className='user-floats-center'>
                            <section className='user_f_frontier'>
                                <section className='id_f_space'>
                                    <Link style={{textDecoration:"none", color:"black"}} to="" onClick={(e)=>{e.preventDefault();}}>
                                        <span style={{fontSize:"40pt", fontWeight:800}}>
                                            {scrappedInfo.username.toUpperCase().charAt(0)}{scrappedInfo.username.toUpperCase().charAt(1)}
                                        </span>
                                    </Link>
                                </section><br/>
                                <b>{scrappedInfo.username}</b><br/>
                                <span style={{fontSize:"7pt"}}>ID: {scrappedInfo.uid.toUpperCase()}</span><br/>
                                <span style={{fontSize:"7pt"}}>{scrappedInfo.membership}</span><br/>
                                {scrappedInfo.email}<br/>
                            </section>
                            <form className='user_f_editables'>
                               <label style={{fontSize:"10pt", color:"#16a068"}}>Designate Role 
                                    </label> <select onChange={getNewRole('role')} style={{width:"100%"}}>
                                        <option value="">{newRole.role===""?scrappedInfo.role.toUpperCase():newRole.role.toUpperCase()}</option>
                                        <option value="guest">Guest</option>
                                        <option value="client">Client</option>
                                        <option value="employee">Employee</option>
                                        <option value="manager">Manager</option>
                                    </select>
                                {scrappedInfo.role==="client"?
                                    <Fragment><br/>
                                    <label style={{fontSize:"10pt", color:"#16a068"}}>Assign to </label>
                                    <select onChange={assignToEmployee('role')} style={{width:"100%"}}>
                                    <option value="">EMPLOYEE</option>
                                    <option value="guest">Each Mann</option>
                                </select></Fragment>:''}   
                                {scrappedInfo.role==="client"?
                                    <Fragment><br/>
                                    <label style={{fontSize:"10pt", color:"#16a068"}}>Assign to </label>
                                    <select onChange={assignToEmployee('role')} style={{width:"100%"}}>
                                    <option value="">TEAM</option>
                                    <option value="guest">Dummies</option>
                                    <option value="guest">Etc</option>
                                </select></Fragment>:''} 
                                <input type="submit" value="UPDATE" style={{backgroundColor:"#16a068", borderWidth:0, borderRadius:5, padding:"5px 10px 5px 10px", marginRight:2, marginTop:20}} onClick={(e)=>{e.preventDefault(); 
                                    setUserFloater(false);
                                    setUpdateStatus(true);
                                    updateUserRole(scrappedInfo.uid, newRole.role)
                                    }}/>   
                                <input type="submit" value="DELETE" style={{backgroundColor:"#16a068", borderWidth:0, borderRadius:5, padding:"5px 10px 5px 10px", marginRight:2, marginTop:20}} onClick={(e)=>{e.preventDefault(); 
                                    setDeleteSpinner(true);
                                    deleteAUser(scrappedInfo.uid);
                                    }}/>  
                                <input type="submit" value="CANCEL" onClick={(e)=>{e.preventDefault(); setUserFloater(false); setNewRole({role:''})}} style={{backgroundColor:"#16a068", borderWidth:0, borderRadius:5, padding:"5px 10px 5px 10px", marginRight:2, marginTop:20}}/> 
                            </form>
                        </section> 
                </section>  
            </Fragment>
        )
    }
    return(
        <Fragment>
            <section className='output-room'>
                <ul className='output-controls'>
                    {activeFirst===true?<li style={{backgroundColor:"#fff", padding:"5px 43px 10px 43px", color:"#000", borderWidth:2, borderColor:"#e8e8e8", borderStyle:"solid", borderBottomWidth:0, cursor:"pointer"}}>All Users</li>:<li onClick={(e)=>{e.preventDefault(); setActiveFirst(true);setActiveSecond(false);setActiveThird(false); setActiveFourth(false); setActiveFifth(false);}}>All Users</li>}
                    {activeSecond===true?<li style={{backgroundColor:"#fff", padding:"5px 43px 10px 43px", color:"#000", borderWidth:2, borderColor:"#e8e8e8", borderStyle:"solid", borderBottomWidth:0, cursor:"pointer"}}>Guests</li>:<li onClick={(e)=>{e.preventDefault(); setActiveFirst(false); setActiveSecond(true);setActiveThird(false); setActiveFourth(false); setActiveFifth(false);}}>Guests</li>}
                    {activeThird===true?<li style={{backgroundColor:"#fff", padding:"5px 43px 10px 43px", color:"#000", borderWidth:2, borderColor:"#e8e8e8", borderStyle:"solid", borderBottomWidth:0, cursor:"pointer"}}>Clients</li>:<li onClick={(e)=>{e.preventDefault(); setActiveFirst(false); setActiveSecond(false);setActiveThird(true); setActiveFourth(false); setActiveFifth(false);}}>Clients</li>}
                    {activeFourth===true?<li style={{backgroundColor:"#fff", padding:"5px 43px 10px 43px", color:"#000", borderWidth:2, borderColor:"#e8e8e8", borderStyle:"solid", borderBottomWidth:0, cursor:"pointer"}}>Employees</li>:<li onClick={(e)=>{e.preventDefault(); setActiveFirst(false); setActiveSecond(false);setActiveThird(false); setActiveFourth(true); setActiveFifth(false);}}>Employees</li>}
                    {activeFifth===true?<li style={{backgroundColor:"#fff", padding:"5px 43px 10px 43px", color:"#000", borderWidth:2, borderColor:"#e8e8e8", borderStyle:"solid", borderBottomWidth:0, cursor:"pointer"}}>Managers</li>:<li onClick={(e)=>{e.preventDefault(); setActiveFirst(false); setActiveSecond(false);setActiveThird(false); setActiveFourth(false); setActiveFifth(true);}}>Managers</li>}
                </ul>
            </section>
            {activeFirst===true?<section className='user-classes-room'>
                <ul style={{width:"96%"}}>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>USERNAME</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ID</li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>EMAIL</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ROLE</li>
                </ul>
                <ul>
                    {datascraped.map((item)=>{
                        return(
                            <li className="user_strip" style={{display:"block"}}>
                                <ul style={{width:"94%"}}>
                                    <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><b>{item.username}</b></li>
                                    <li style={{display:"inline", height:30, marginRight:20, borderWidth:1, fontSize:"8pt"}}><span style={{position:"absolute", right:550}}>{item.uid.toUpperCase()}</span></li>
                                    <li onClick={()=>{setEmailDialog(true); setTargetEmail(item.email)}} style={{display:"inline", height:30, marginRight:20, borderWidth:1}}>
                                        {item.email.length>18?<b style={{position:"absolute", right:330, fontSize:"10pt"}}>{item.email}</b>:<b style={{position:"absolute", right:330}}>{item.email}</b>}
                                    </li>
                                    <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><span style={{position:"absolute", right:240}}>{item.role}</span></li>
                                    <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:140, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserFloater(true);setScrappedInfo({
                                        username:item.username, 
                                        uid:item.uid,
                                        email:item.email,
                                        role:item.role,
                                        membership:item.dateSince,
                                        orders:item.orders,
                                        payments:item.paymentSources
                                        })})}>UPDATE</button></li>
                                    <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:60, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserDeleter(true);setScrappedInfo({
                                        user:item.username,
                                        uid:item.uid
                                        })})}>DELETE</button></li>
                                </ul>
                            </li>
                        )
                    })}
                </ul>
            </section>:''}
            {activeSecond===true?<section className='user-classes-room'>
                <ul style={{width:"96%"}}>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>USERNAME</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ID</li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>EMAIL</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ROLE</li>
                </ul>
                <ul>
                    
                    {datascraped.map((item)=>{
                        if(item.role==="guest"){
                            return(
                                <li className="user_strip" style={{display:"block"}}>
                                    <ul style={{width:"94%"}}>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><b>{item.username}</b></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1, fontSize:"8pt"}}><span style={{position:"absolute", right:550}}>{item.uid.toUpperCase()}</span></li>
                                        <li onClick={()=>{setEmailDialog(true); setTargetEmail(item.email)}} style={{display:"inline", height:30, marginRight:20, borderWidth:1}}>{item.email.length>20?<b style={{position:"absolute", right:330, fontSize:"10pt"}}>{item.email}</b>:<b style={{position:"absolute", right:330}}>{item.email}</b>}</li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><span style={{position:"absolute", right:240}}>{item.role}</span></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:140, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserFloater(true);setScrappedInfo({
                                            username:item.username, 
                                            uid:item.uid,
                                            email:item.email,
                                            role:item.role,
                                            membership:item.dateSince,
                                            orders:item.orders,
                                            payments:item.paymentSources
                                            })})}>UPDATE</button></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:60, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserDeleter(true);setScrappedInfo({
                                            user:item.username,
                                            uid:item.uid
                                            })})}>DELETE</button></li>
                                    </ul>
                                </li>
                            )
                        }
                    })}
                </ul>
            </section>:''}
            {activeThird===true?<section className='user-classes-room'>
                <ul style={{width:"96%"}}>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>USERNAME</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ID</li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>EMAIL</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ROLE</li>
                </ul>
                <ul>
                    
                    {datascraped.map((item)=>{
                        if(item.role==="client"){
                            return(
                                <li className="user_strip" style={{display:"block"}}>
                                    <ul style={{width:"94%"}}>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><b>{item.username}</b></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1, fontSize:"8pt"}}><span style={{position:"absolute", right:550}}>{item.uid.toUpperCase()}</span></li>
                                        <li onClick={()=>{setEmailDialog(true); setTargetEmail(item.email)}}  style={{display:"inline", height:30, marginRight:20, borderWidth:1}}>{item.email.length>20?<b style={{position:"absolute", right:330, fontSize:"10pt"}}>{item.email}</b>:<b style={{position:"absolute", right:330}}>{item.email}</b>}</li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><span style={{position:"absolute", right:240}}>{item.role}</span></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:140, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserFloater(true);setScrappedInfo({
                                            username:item.username, 
                                            uid:item.uid,
                                            email:item.email,
                                            role:item.role,
                                            membership:item.dateSince,
                                            orders:item.orders,
                                            payments:item.paymentSources
                                            })})}>UPDATE</button></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:60, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserDeleter(true);setScrappedInfo({
                                            user:item.username,
                                            uid:item.uid
                                            })})}>DELETE</button></li>
                                    </ul>
                                </li>
                            )
                        }
                    })}
                </ul>
            </section>:''}
            {activeFourth===true?<section className='user-classes-room'>
                <ul style={{width:"96%"}}>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>USERNAME</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ID</li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>EMAIL</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ROLE</li>
                </ul>
                <ul>
                    
                    {datascraped.map((item)=>{
                        if(item.role==="employee"){
                            return(
                                <li className="user_strip" style={{display:"block"}}>
                                    <ul style={{width:"94%"}}>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><b>{item.username}</b></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1, fontSize:"8pt"}}><span style={{position:"absolute", right:550}}>{item.uid.toUpperCase()}</span></li>
                                        <li onClick={()=>{setEmailDialog(true); setTargetEmail(item.email)}} style={{display:"inline", height:30, marginRight:20, borderWidth:1}}>{item.email.length>20?<b style={{position:"absolute", right:330, fontSize:"10pt"}}>{item.email}</b>:<b style={{position:"absolute", right:330}}>{item.email}</b>}</li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><span style={{position:"absolute", right:240}}>{item.role}</span></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:140, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserFloater(true);setScrappedInfo({
                                            username:item.username, 
                                            uid:item.uid,
                                            email:item.email,
                                            role:item.role,
                                            membership:item.dateSince,
                                            orders:item.orders,
                                            payments:item.paymentSources
                                            })})}>UPDATE</button></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:60, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserDeleter(true);setScrappedInfo({
                                            user:item.username,
                                            uid:item.uid
                                            })})}>DELETE</button></li>
                                    </ul>
                                </li>
                            )
                        }
                    })}
                </ul>
            </section>:''}
            {activeFifth===true?<section className='user-classes-room'>
                <ul style={{width:"96%"}}>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>USERNAME</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ID</li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}><b>EMAIL</b></li>
                                    <li style={{display:"inline", width:"25%", height:30, paddingRight:155, color:"#b7b7b7"}}>ROLE</li>
                </ul>
                <ul>
                    
                    {datascraped.map((item)=>{
                        if(item.role==="manager"){
                            return(
                                <li className="user_strip" style={{display:"block"}}>
                                    <ul style={{width:"94%"}}>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><b>{item.username}</b></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1, fontSize:"8pt"}}><span style={{position:"absolute", right:550}}>{item.uid.toUpperCase()}</span></li>
                                        <li onClick={()=>{setEmailDialog(true); setTargetEmail(item.email)}} style={{display:"inline", height:30, marginRight:20, borderWidth:1}}>{item.email.length>20?<b style={{position:"absolute", right:330, fontSize:"10pt"}}>{item.email}</b>:<b style={{position:"absolute", right:330}}>{item.email}</b>}</li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><span style={{position:"absolute", right:240}}>{item.role}</span></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:140, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserFloater(true);setScrappedInfo({
                                            username:item.username, 
                                            uid:item.uid,
                                            email:item.email,
                                            role:item.role,
                                            membership:item.dateSince,
                                            orders:item.orders,
                                            payments:item.paymentSources
                                            })})}>UPDATE</button></li>
                                        <li style={{display:"inline", height:30, marginRight:20, borderWidth:1}}><button style={{position:"absolute", right:60, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, height:22, fontSize:"9pt", color:"#fff"}} onClick={(e=>{e.preventDefault(); setUserDeleter(true);setScrappedInfo({
                                            user:item.username,
                                            uid:item.uid
                                            })})}>DELETE</button></li>
                                    </ul>
                                </li>
                            )
                        }
                    })}
                </ul>
            </section>:''}
            {userFloater===true?<FloatOutUserInfo />:''}
            {userDeleter===true?<DeleteFloaterUser/>:''}
            {deleteSpinner===true?<DeleteProgressSpinner />:''}
            {deleteStatus===true?<DeleteSuccess />:''}
            {updateStatus===true?<UpdateProgressSpinner />:''}
            {successUpdate===true?<UpdateSuccess />:''}
            {emailDialog === true?
            <Dialog open={true} maxWidth="xl">
                <ThemeProvider theme={Jolme5DTheme}>
                    <section style={{position:"relative", width:"900px", height:"800px",  padding:50, zIndex:10}}>
                        <span style={{position:"absolute", top:10, right:100, fontSize:"1.5em", fontWeight:"bolder",  color:"#16A068"}}>COMPOSE A NEW EMAIL</span><br/><br/>
                        <Button variant='contained' style={{position:"absolute", right:10, top:10}} onClick={cancelAndClear}>X</Button>
                        <section style={{position:"relative", padding:50}}>
                            <label></label><input value={targetEmail} onMouseOut={handleFieldChange('targetemail')} style={{width:"100%", height:20, padding:10, fontSize:"1.2em"}} /><br/><br/>
                            <label></label><input onChange={handleFieldChange('subject')} placeholder="Subject" style={{width:"100%", height:20, padding:10, fontSize:"1.2em"}} /><br/><br/>
                            <textarea onChange={handleFieldChange('textbodyplain')} placeholder="Write your message..." style={{width:"100%", fontSize:"1.2em", padding:10}} rows={20} cols="100%"></textarea> <br/><br/>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                >
                                Attach file
                                <VisuallyHiddenInput type="file" />
                            </Button>
                            <Button onClick={cancelAndClear} variant='contained' style={{position:"relative", float:"right", width:"20%", right:165, top:0}} >CANCEL</Button>
                            {mailerInfo.targetemail==='' || mailerInfo.subject==='' || mailerInfo.textbodyplain===''?
                            <Button style={{position:"relative", backgroundColor:"#000", color:"#fff",  float:"right", width:"20%", right:-160, top:0}}>SEND</Button> 
                            :<Button variant='contained' style={{position:"relative", float:"right", width:"20%", right:-160, top:0}} onClick={sendingEmail}>SEND</Button> }
                            
                        </section>
                    </section>
                </ThemeProvider>
            </Dialog>
            :''}
        </Fragment>
    )
}

export default DashboardOutputDisplay