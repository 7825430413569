//LIBRARIES
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { proPicView, viewUser, viewOrder, viewClientOrders, messageTabToggle} from '../slices/counter'
import {querriedData, getAllUsers} from '../querries/userdataquery'
import EmojiPicker, { Picker, Emoji, EmojiStyle} from 'emoji-picker-react' 

//COMPONENTS
import {
  GCHATSYS_L, GCHATSYS_W,
  GCHATSYS_REL, GCHATSYS_REW,
  GUEST_MG_INDL, GUEST_MG_INDW
} from '../../config'
import { pitch } from '../../screens/components/variables'
//OPERATIONS
//THIS IS A MESSAGE COMPONENT
const EmployeeChannelRecourse = (props)=>{ 
  const {uid} = props
  const user = useSelector(state => state.counter.userDetails)
  const photo = useSelector(state => state.counter.profilePic)

  const inq = user.inquiries
  //console.log(inq[3])
  const dispatch = useDispatch()
  dispatch(proPicView())

  //PICKER STATE AND METHOD
  const [inputStr, setInputStr] = useState('')
  const [showPicker, setShowPicker] = useState(false)
  const onEmojiClick = (emojiObject)=>{
    setInputStr(prevInput => prevInput + emojiObject.emoji);
  }

  // const refreshUserDetails = ()=>{
  //   setInterval(dispatch(viewUser()),500)
  // }
  const messageTabler = (top)=>{
    dispatch(messageTabToggle(top))
  }

  const chattySwitcher = (pitch)=>{
    if(pitch===true){
        return GCHATSYS_W
    }else{
        return GCHATSYS_L
    }
  }
  const chaReSwitcher = (pitch)=>{
    if(pitch===true){
        return GCHATSYS_REW
    }else{
        return GCHATSYS_REL
    }
  }
  const messageTabToggler = (tog)=>{
      dispatch(messageTabToggle(tog))
  }
  //DEFAULT QUERY
  const [inquiries, setInquiries] = useState([])
  const defaultQueryMessageGetter = async(sing)=>{
    const defaultQRedux = await fetch(chaReSwitcher(pitch), {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body: JSON.stringify(sing)
    })
    const defcon = await defaultQRedux.json()
    const {status, data} = defcon
    //console.log(data)
    //console.log(data[0].inquiries[0]) //works
    let dashlings = [];
    data[0].inquiries.map(item=>{dashlings.push({uid:item.userUniqueID, unread:item.unread, username:item.uname, role:item.role, message:item.message, subrole: item.subrole, date:item.datePosted})})
    //console.log(dashlings)
    setInquiries(dashlings)
  }
  useEffect(()=>{
    defaultQueryMessageGetter({userUniqueID:uid})
  }, [])
  
  //console.log(inquiries)

  const [messageEntry, setMessageEntry] = useState({
    uname:'',
    role:'',
    subrole:'',
    message:''
  })
  const [refreshLoadedList, setRefreshLoadedList] = useState(false)
  const [newChatEntry, setNewChatEntry] = useState([])

  const snapBackToDefault = ()=>{
    setRefreshLoadedList(false)
    defaultQueryMessageGetter({userUniqueID:uid})
  }
  //RE-QUERY
  const reconnectReduxAPI = async(sing)=>{
    const reconRedux = await fetch(chaReSwitcher(pitch), {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body: JSON.stringify(sing)
    })
    const frecon = await reconRedux.json()
    const {status, data} = frecon
    //console.log(data[0].inquiries[0]) //works
    let dashlings = [];
    data[0].inquiries.map(item=>{dashlings.push({
      uid:item.userUniqueID, 
      username:item.uname, 
      role:item.role, 
      message:item.message, 
      subrole: item.subrole, 
      date:item.datePosted
    })})
    console.log(dashlings)
    setNewChatEntry(dashlings)
  }
  //FIRST QUERY
  const processNewMessage = async()=>{
    //console.log({userUniqueID:user.userUniqueID, uname:user.username, role:user.role, subrole:user.subrole, message:messageEntry.message})
    const mEntries = {
      uname:user.username, 
      userUniqueID:uid,
      role:user.role,
      subrole:user.subrole,
      message:messageEntry.message, 
      unread:true
    }

    const guestMessageQuery = await fetch(chattySwitcher(pitch), {
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },
      body:JSON.stringify(mEntries)
    })
    const responseGchat = await guestMessageQuery.json()
    const {status, data, inquiry} = responseGchat
    if(status === 'Successful!'){
        console.log("Successfull Connection")
        reconnectReduxAPI({userUniqueID:uid}) //--> TO RE-QUERY
        //console.log(data.inquiry)
        //setNewChatEntry(inquiry)
        setRefreshLoadedList(true)
        setMessageEntry({...messageEntry, 
          uname:'',
          role:'',
          subrole:'',
          message:''})
        setInputStr('')
        snapBackToDefault()
    }else{
      console.log("Something is Wrong!")
    }
    messageTabToggler('message');
  }

  //NON-REDUX-BASED CONTENT GENERATED - REACTIVE MESSAGE LIST
  //default message listing SHORTENER
  // let start = newChatEntry.length-6
  // let last = newChatEntry.length
  // let tailEnder = newChatEntry.slice(start,last)
  useEffect(()=>{
    snapBackToDefault()
  },[])
  let display = 6
  let tailEnder = [];
  if(newChatEntry.length<6){
    let laster = newChatEntry.length
    tailEnder = newChatEntry.slice(0, laster)
  }else{
    let laster = newChatEntry.length
    tailEnder = newChatEntry.slice(newChatEntry.length-display, laster)
  }

  const RefreshChatContent = ()=>{
    //console.log(newChatEntry)//<-checked
    return(
      <Fragment>
              {newChatEntry.length===0?
              <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                <span style={{color:"#16a068"}}>loading data...</span>
              </section>
              :
              <Fragment>
                {tailEnder.map(msg=>{
                if(msg.length===0){
                  return(
                    <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                      <span style={{color:"#16a068"}}>data loading...</span>
                    </section>
                  )
                }else{
                      return(
                        <section style={{position:"relative", top:0, left:20, margin:"auto", width:"80%", marginBottom:0, padding:"20px 20px 20px 20px"}} onMouseOver={snapBackToDefault}>
                          {msg.role !== 'guest'?
                          <section onMouseOver = {getToMarkReadAllMsg({userUniqueID:uid, otherSide:msg.username})} style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          <span style={{color:"#fff", fontWeight:"bold"}}>
                            {msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}
                            <span style={{position:"absolute", top:30, left:3, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </span>
                        </section>
                          :<section style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          {photo?
                          <img src={photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:30, width:30}}/>
                          :<span style={{color:"#16a068", fontWeight:"bold"}}>
                          {msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}
                        </span>}
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                        </section>}
                          
                          <span style={{position:"relative", left:25, top:-10, color:"#16a068", fontSize:"8pt"}}>{msg.date}</span><br/>
                          <span style={{position:"relative", left:25, top:-10, color:"#16a068"}}>{msg.message}</span>
                        </section>
                      )
                }
              })}
              </Fragment>
            }
      </Fragment>
    )
  }

  const  getNewMessage = message=>event=>{
    event.preventDefault()
    message = event.target.value //uname:user.username, role:user.role, subrole:'', message:message
    setMessageEntry({...messageEntry, message:message})
  }

  //MARKING UNREAD to be come being READ
  const readIndicatorSwitcher =(bol)=>{
    if(bol===true){
        return GUEST_MG_INDW
    }else{
        return GUEST_MG_INDL
    }
  }
  const getToMarkReadAllMsg = async(sing)=>{
        //console.log("Ping the world")
        const defaultQRedux = await fetch(readIndicatorSwitcher(pitch), {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(sing)
        })
        const defcon = await defaultQRedux.json()
        const {status, data} = defcon
        // if(data.modifiedCount>0){
        //   console.log("Marking Unread as Read - Success!")
        // }
        //console.log(data)
        //console.log(data[0].inquiries[0]) //works
        // //console.log(dashlings)
        // setInquiries(dashlings)
  }
  //default message listing SHORTENER

    let starter = 4
    let tailInquiry = [];
    if(inquiries.length<4){
      let laster = inquiries.length
      tailInquiry = inquiries.slice(0, laster)
    }else{
      let laster = inquiries.length
      tailInquiry = inquiries.slice(laster-starter, laster) //inquiries.length-starter
    }

  return (
    <Fragment>
        <section onMouseOver={snapBackToDefault}>
          <section style={{position:"relative", top:-40,  width:"93%", margin:"auto", marginBottom:20 }}>
            {inq.includes('')?
            <section style={{position:"relative", top:20, margin:"auto", width:"30%"}}>
              <span style={{position:"relative", width:120, margin:"auto", fontSize:"11pt", color:"#16a068", textAlign:"center", padding:"50px 20px 20px 20px"}}>No Messages to Load</span>
            </section>
            :''
            }
            {refreshLoadedList===true?
            <RefreshChatContent />
            :<Fragment>
              {inquiries?
              tailInquiry.map(msg=>{
                if(msg.length<1){
                  return(
                    <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                      <span style={{color:"#16a068"}}>data loading...</span>
                    </section>
                  )
                }else{
                      return(
                        <Fragment>
                        {msg.role === 'client'?
                        <section className = 'guest-entry-chat' onMouseOver={()=>{
                          getToMarkReadAllMsg({userUniqueID:uid, otherSide:msg.message})
                        }} style={{position:"relative", top:-10, left:0, backgroundColor:"#dfdfdf", margin:"auto", width:"85%", marginBottom:5, padding:"12px 30px 15px 20px"}}>
                          {msg.role !== 'client' && msg.unread === true?<button style={{position:"absolute", borderRadius:20, borderColor:"#16a068",borderWidth:1, right:20, backgoundColor:"#16a068", color:"#16a068", borderWidth:1, borderStyle:"solid", padding:"5px 10px 5px 10px"}}>Unread</button>:'' }
                          {msg.role !== 'client'?
                          <section onMouseOver = {getToMarkReadAllMsg({userUniqueID:uid, otherSide:msg.message})} style={{position:"absolute", top:26, left:10, textAlign:"center", backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          <span style={{color:"#fff", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>
                          :<section style={{position:"absolute", top:26, left:10, textAlign:"center", backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            {photo?
                            <img src={photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:30, width:30}}/>
                            :<span style={{color:"#16a068", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>}
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>}
                              <span style={{position:"relative", left:25, color:"#16a068", fontSize:"6pt"}}>{msg.date}</span><br/>
                              <span style={{position:"relative", left:25, color:"#16a068"}}>{msg.message}</span>
                        </section>
                        :
                        <section className = 'guest-entry-chat' onMouseOver={()=>{
                          getToMarkReadAllMsg({userUniqueID:uid, otherSide:msg.message})
                        }} style={{position:"relative", top:-10, left:0, margin:"auto", width:"85%", marginBottom:5, padding:"12px 30px 15px 20px"}}>
                           {msg.role !== 'client' && msg.unread === true?<button style={{position:"absolute", borderRadius:20, borderColor:"#16a068",borderWidth:1, right:20, backgoundColor:"#16a068", color:"#16a068", borderWidth:1, borderStyle:"solid", padding:"5px 10px 5px 10px"}}>Unread</button>:'' }
                          {msg.role !== 'client'?
                          <section onMouseOver = {getToMarkReadAllMsg({userUniqueID:uid, otherSide:msg.message})} style={{position:"absolute", top:26, left:10, textAlign:"center", backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                          <span style={{color:"#fff", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>
                          :<section style={{position:"absolute", top:26, left:10, textAlign:"center", backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            {photo?
                            <img src={photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:30, width:30}}/>
                            :<span style={{color:"#16a068", fontWeight:"bold"}}>{msg.username.charAt(0).toUpperCase()}{msg.username.charAt(1).toUpperCase()}</span>}
                          <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>}
                              <span style={{position:"relative", left:35, color:"#16a068", fontSize:"6pt"}}>{msg.date}</span><br/>
                              <span style={{position:"relative", left:35, color:"#16a068"}}>{msg.message}</span>
                        </section>
                        }
                      </Fragment>
                      )
                }
              })
              :''
            }
              </Fragment>}

          </section>
          <section style={{position:"absolute", bottom:125, width:"100%" }} onMouseOver={snapBackToDefault}>
            {/* <section style={{position:"absolute", top:-15, left:5, zIndex:2, backgroundColor:"#fff", width:4, height:4, borderRadius:"50%", padding:"20px 20px 20px 20px"}}>
              {photo?
              <img src={photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:40, width:40}}/>:
              <b style={{position:"absolute", left:5, top:0, fontSize:"18pt"}}>{user.username.charAt(0).toUpperCase()}{user.username.charAt(1).toUpperCase()}</b>
              }
            </section>  */}
            <section style={{position:"absolute", left:10, borderColor:"#16a068", top:0, width:"93%", height:77, borderRadius:5, borderWidth:1, borderStyle:"solid"}} >
              <textarea value={inputStr} onChange={(e)=>{setInputStr(e.target.value)}} onMouseDown={()=>{setShowPicker(true)}} id='guest-message' onMouseLeave={getNewMessage('message')} style={{position:"relative", backgroundColor:"#fff", top:5, left:0, width:"92%", height:38, borderColor:"#fff", borderStyle:"solid", lineHeight: 1, borderWidth:0, borderRadius:5, padding:"10px 10px 10px 10px", color:"#16a068", fontFamily:"Baloo", fontSize:"11pt" }} type="text" row="20"></textarea>
              {!messageEntry.message?
              <button onMouseOver={()=>{setShowPicker(false)}} className='guest-chat-btn' style={{position:"absolute",  right:-1, top:85, width:80, height:30, backgroundColor:"#c1c1c1", color:"#16a068"}}>
                <span style={{position:"absolute", top:5, left:18, color:"#fff",  textAlign:"center", verticalAlign:"center"}}>Send</span>
              </button>
              :<button onMouseOver={()=>{setShowPicker(false)}} style={{position:"absolute", right:-1,  top:85, width:80, height:30}} className='guest-chat-btn' onClick={(e)=>{
                e.preventDefault();
                setRefreshLoadedList(false);
                document.getElementById("guest-message").value = "";
                processNewMessage();
                //refreshUserDetails()
                }}>
                <span style={{position:"absolute", top:5, left:18,  textAlign:"center", verticalAlign:"center"}}>Send</span>
              </button>}
              {showPicker?
                  <Fragment>
                  <section style={{position:"absolute", bottom:0, left:-370,   zIndex:11}}>
                      <EmojiPicker 
                      pickerStyle={{width:'100%'}} 
                      onEmojiClick={onEmojiClick}
                      />
                  </section>
                  </Fragment>
              :''}
              {!messageEntry.message?<span style={{position:"absolute", bottom:-20, left:10, fontSize:"9pt", color:"#16a068"}}>The field is empty</span>:''}
            </section>
          </section>
        </section>
    </Fragment>
  )
}
//--<ENDS of CounterView

//ORDER TAB COMPONENT
//Yahoo!

export default EmployeeChannelRecourse