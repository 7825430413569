//LIBRARIES
import React, {Fragment, useState, createRef} from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Widget } from '@uploadcare/react-widget'
import { currBalanceView, defaultCurrBalance, proPicView, viewUser} from '../../../redux/slices/counter'

//COMPONENTS
import EmployeeChannel from '../../../redux/viewslices/employeeChannel'
import {mixen, orderen, userslist} from '../../../redux/querries/userdataquery'
import { pitch } from '../variables'
import {
    UPPHOTOL,
    UPPHOTOW
    } from '../../../config'
import { width } from '@mui/system';

//OPERATIONS
const EmpProfileFader = (props)=>{
            const {user, cbalance, userid, email} = props

            const historian = useHistory()
            const [introProfile, setIntroProfile] = useState(true)
            const [depositTrigger, setDepositTrigger] = useState(false)
            
            //const user = useSelector(state => state.counter.userDetails)
            const upuser = useSelector(state => state.counter.userDetails.username)
            const upemail = useSelector(state => state.counter.userDetails.email)

            const bamount = useSelector(state => state.counter.currBalance)
            const currencyFiat = useSelector(state => state.counter.currFiat)
            const photo = useSelector(state => state.counter.profilePic)
            const dispatch = useDispatch()
            const loadProfilePic = ()=>{
                setInterval(()=>{
                    dispatch(proPicView())
                    dispatch(viewUser())
                },1000)
            }
            loadProfilePic() //triggering to reload every 1 second

            const currencySwitcher = (curr)=>{
                dispatch(currBalanceView(curr))
            }
            //console.log([mixen][0][0].balance[0].PHP)
            const DepositFloater = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                        </section>
                        <section style={{position:"absolute", zIndex:2, top:0, margin:"auto", left:"auto", width:"100%", borderRadius:5, height:500, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                            <button onClick={()=>{setDepositTrigger(false)}} className='deposit-close-btn'>
                                X CLOSE
                            </button>
                        </section>
                    </Fragment>
                )
            }
            //CURRENCY FLOATER
            const exchangeCurrencies = currency=>event=>{
                    currency = event.target.value
                    currencySwitcher(currency)
            }
            const [currencyBTN, setCurrencyBTN] = useState(false)
            const CurrencyFloater = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                        </section>
                        <section style={{position:"absolute", zIndex:2, top:10, margin:"auto", right:230, width:"30%", borderRadius:5, height:35, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                            <button onClick={()=>{setCurrencyBTN(false)}} className='deposit-close-btn' style={{borderBottomLeftRadius:0, borderBottomRightRadius:5}}>
                                X CLOSE
                            </button>
                            <span style={{position:"relative", top:5, left:10, fontWeight:600}}>Currency</span>
                            <select onChange={exchangeCurrencies('currency')} style={{position:"relative", top:5, left:20, fontWeight:600, width:80, textAlign:"center"}}>
                                <option value={currencyFiat}>{currencyFiat}</option>
                                <option value='PHP'>PHP</option>
                                <option value='USD'>USD</option>
                                <option value='EUR'>EURO</option>
                                <option value='GBP'>GBP</option>
                                <option value='AUD'>AUD</option>
                                <option value='CAD'>CAD</option>
                                <option value='YUAN'>YUAN</option>
                                <option value='RUB'>RUBLE</option>
                            </select>
                        </section>
                    </Fragment>
                )
            }
            //UPLOADING PROFILE PHOTO
            const [photoUploadTrigger, setPhotoUploadTrigger] = useState(false)
            const [fileUploaded, setFileUploaded] = useState('')//client's uploaded image url
            const [imageData, setImageData] = useState({width:0, height:0})
            const [processUpload, setProcessUpload] = useState(false)
            const [uploadSuccess, setUploadSuccess] = useState(false)
            const [uploadFailed, setUploadFailed] = useState(false)

            let diff = 0
            let multiplyer = 0
            let minLeft = 0
            let minimum = 25
            if(imageData.width>imageData.height){
                diff+=imageData.width-imageData.height
                multiplyer+=imageData.width*0.01
                minLeft += diff/multiplyer
            }else{
                diff+=imageData.height-imageData.width
                multiplyer+=imageData.height*0.01
                minLeft += diff/multiplyer
            }

            const uploadPhotoSwitcher = (pitch)=>{
                if(pitch===true){
                    return UPPHOTOW
                }else{
                    return UPPHOTOL
                }
            }
            const uploadProfileImage = async()=>{
                setPhotoUploadTrigger(false)
                setProcessUpload(true)
                const uploadImageQuery = await fetch(uploadPhotoSwitcher(pitch), {
                    method:"POST",
                    headers:{"Content-Type":"application/json"},
                    body:JSON.stringify({userUniqueID:userid, profileImage:fileUploaded})
                })
                const uploadGetsContact = await uploadImageQuery.json()
                const {status, data} = uploadGetsContact
                if(data.modifiedCount>0){
                    setProcessUpload(false)
                    setUploadSuccess(true)
                }else{
                    setProcessUpload(false)
                    setUploadFailed(true)
                }
            }
            const UploadingProcess = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                        </section>
                        <section onClick = {()=>{setProcessUpload(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                            <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                                    <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                                    <span style={{position:"absolute", color:"#fff", top:35, right:85}}>Image Upload</span> <br />
                                    <span style={{position:"absolute", color:"#fff", fontSize:"22pt", fontWeight:"bolder", top:50, right:85}}>PROCESSING</span>
                            </section>
                        </section>
                    </Fragment>
                )
            }
            const SuccessfulUpload = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                        </section>
                        <section style={{position:"absolute", zIndex:2, top:0, margin:"auto", left:"auto", width:"100%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,0.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                            <section style={{position:"relative", backgroundColor:"rgba(255,255,255,1.0)", margin:"auto", top:20, width:"35%",  padding:"30px 30px 30px 30px", borderRadius:5}}>
                                <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                                    <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image Successful!</span><br/>
                                </section>
                                <button onClick={()=>{setUploadSuccess(false); window.location.reload()}} style={{position:"absolute", top:30, right:30, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                                    EXIT
                                </button>
                            </section>
                            
                        </section>
                    </Fragment>
                )
            }
            const FailedUpload = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                        </section>
                        <section style={{position:"relative", zIndex:2, top:-400, margin:"auto", left:"auto", width:"60%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                            <section style={{position:"relative", margin:"auto", top:20, width:400, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"10px 10px 10px 10px"}}>
                                <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                                    <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image has failed!</span><br/>
                                </section>
                                <button onClick={()=>{setUploadFailed(false)}} style={{position:"absolute", top:10, right:20, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                                    EXIT
                                </button>
                            </section>
                            
                        </section>
                    </Fragment>
                )
            }
            const PhotoUploader = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:10, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                        </section>
                        <section style={{position:"absolute", zIndex:11, top:20, left:0, margin:"auto", width:"100%", borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,0.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                            <section style={{position:"relative", margin:"auto", left:-100, top:0,  width:"40%"}}>
                                
                                <section style={{position:"relative", margin:"auto", top:0, width:500, backgroundColor:"#fff", padding:"20px 20px 20px 20px", margin:20, borderRadius:10}}>
                                    <section style={{position:"relative", margin:"auto", zIndex:12, top:0, width:200, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                                            <span style={{fontWeight:"bolder", color:"#16a068"}}>Upload A Profile Picture</span><br/>
                                    </section> <br/>
                                    <button onClick={()=>{setPhotoUploadTrigger(false);}} className='deposit-close-btn' style={{position:"absolute",backgroundColor:"white", color:"#16a068", padding:"16px 20px 16px 20px", top:0, right:-55, borderRadius:10}}>
                                        <span style={{fontWeight:"bold"}}>X</span>
                                    </button>
                                    <section style={{position:"relative", top:0}}>
                                        <Widget style={{backgroundColor:"black", top:0}}
                                                        publicKey='c1f6acc2c018005bfb0c' 
                                                        id='file' 
                                                        name='file'
                                                        tabs='file url'
                                                        previewStep='true'
                                                        onDialogClose={(file) => {
                                                            console.log('File changed: ', file)
                                                            if (file) {
                                                            file.progress(info => console.log('File progress: ', info.progress))
                                                            file.done(info => console.log('File uploaded: ', info))
                                                            }
                                                            }}
                                                        onChange={(info) => {
                                                            setFileUploaded(info.cdnUrl); /*console.log('Upload completed:', info)*/
                                                            setImageData({width:info.originalImageInfo.width, height:info.originalImageInfo.height})} 
                                                        }
                                        />
                                    </section>
                                    {fileUploaded !=='' && minLeft<minimum?
                                    <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                        <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                        <b>You can upload this picture</b>
                                    </section>
                                    :
                                    <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                        <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                        <b>Cannot be uploaded! Must be nearly equal in both sides.</b>
                                    </section>
                                    }


                                    <section>
                                        <img src={fileUploaded} id="client_pic" width="500"/>
                                    </section>
                                    {fileUploaded==='' || minLeft>minimum?
                                    <button className='guest-upload-btn' style={{top:62, backgroundColor:"#a1a1a1"}}>
                                        No Image or Image Have Problems
                                    </button>:
                                    <button className='guest-upload-btn' style={{top:62}} onClick={uploadProfileImage}>
                                    Upload Image
                                    </button>}
                                </section>
                            </section>
                        </section>
                    </Fragment>
                )
            }
            const [chatHead, setChatHead] = useState(false)
            const [chatClose, setChatClose] = useState(false)
            const [chatManagerHead, setChatManagerHead] = useState(false)
            const ChatChannelOfEmployeeAndManagement = ()=>{
                return(
                    <Fragment>
                        <section onClick = {()=>{setChatManagerHead(false);}} style={{position:"fixed", zIndex:9, width:"100%", height:"100%", top:0, left:0, margin:"auto", backgroundColor:"rgba(0,0,0,0.4)"}}>
                            
                        </section>
                        {/* CHAT WITH MANAGER ONLY*/}
                        <section style={{position:"fixed", zIndex:9, width:"23%", height:"80%",  top:50, right:20, margin:"auto", backgroundColor:"rgba(255,255,255)", paddingBottom:35, borderWidth:1, borderColor:"#fff", borderStyle:"solid", borderRadius:5, boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 5px  5px 0 rgba(0, 0, 0, 0.19)"}}>
                            <section style={{position:"relative", margin:"auto", top:-40,width:75, height:75, borderRadius:40, backgroundColor:"white", borderStyle:"solid", borderWidth:1,borderColor:"#f1f1f1"}}>
                                {chatClose===true?
                                <section onMouseOver={()=>{setChatClose(true)}}  style={{position:"relative", margin:"auto", top: 12, width:50, height:50, borderRadius:25, backgroundColor:"#f1f1f1", cursor:"pointer"}}>
                                </section>:
                                <section onClick={(e)=>{e.preventDefault(); setChatTrigger(false); setChatManagerHead(false)}} onMouseOut={()=>{setChatClose(false)}} style={{position:"relative", margin:"auto", textAlign:"center", top: 12, width:50, height:50, borderRadius:25, backgroundColor:"#16a068", cursor:"pointer"}}>
                                    <span onClick={(e)=>{e.preventDefault(); setChatTrigger(false)}}  style={{position:"relative", color:"white", top:12}}>X</span>
                                    <button style={{position:"absolute", top:12, width:166, padding:"3px 5px 3px 5px", backgroundColor:"#16a068", color:"#fff", fontSize:"9pt", borderWidth:0, borderRadius:10, cursor:"pointer"}}>Channel with Manager</button>
                                </section>
                                }
                            </section>
                            <EmployeeChannel uid = {userid} />
                        </section>
                    </Fragment>
                )
            }
            const [changeProImage, setChangeProImage] = useState(false)
            const [chatTrigger, setChatTrigger] = useState(false)
            return(
                <Fragment>
                    
                    {introProfile===true?<section className='client-fader'>
                        <button style={{position:"relative", borderRadius:50, padding:"10px 10px 10px 10px", width:40, height:40, borderWidth:2, borderStyle:"solid", backgroundColor:"rgba(0,0,0,0.0)",borderColor:"#16A068", cursor:'pointer'}} onClick={()=>{setIntroProfile(false)}}><b style={{width:30, height:30}}>X</b></button>
                        <section onClick = {()=>{setPhotoUploadTrigger(true)}} className='profile-picture-space'>
                            {!photo?
                            <section id='profile-picture' style={{position:'relative', margin:"auto", width:148, height:148, borderRadius:"50%", backgroundColor:"#16a068", textAlign:"center", cursor:"pointer"}}>
                                <svg style={{position:"relative", color:"white", width:50, top:30, verticalAlign:"sub" }} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg><br/>
                                <span style={{position:"relative", top:20, color:"white", fontSize:"18pt", fontWeight:600}}>Update</span>
                            </section>:
                            <section id='profile-picture' style={{position:'relative', margin:"auto", width:148, height:148, borderRadius:"50%", backgroundColor:"#f1f1f1"}}>
                                <img style={{width:138, height:138, borderRadius:"50%", borderWidth:5, borderColor:"#16a068", borderStyle:"solid"}} src={photo}/>
                            </section>} 
                        </section>
                        <section className='profile-semi-details'>
                                <h1>{upuser}</h1>
                                <span style={{fontSize:"9pt", color:"#16a068"}}>ID: {userid.toUpperCase()}</span><br/>
                                <span style={{fontSize:"9pt", color:"#16a068"}}>{upemail}</span><br/>
                        </section>
                        <section className='profile-semi-details-2'>
                                {/* <Fragment>
                                    <span><b>Earnings</b> <button onClick={()=>{setCurrencyBTN(true)}} className='current-btn'>{currencyFiat}</button> <span style={{color:"#16a068", fontStyle:"italic"}}>{bamount===0?'':bamount}</span></span> <br/> 
                                </Fragment>
                                <span style={{fontSize:"9pt", color:"#16a068"}}>Total: $1080.00</span><br/> */}
                        </section>
                        <section className='profile-semi-details-3'>
                                <button onClick={()=>{setChatTrigger(true)}} style={{width:200, height:35}} className='employee-chatman-btn'>
                                    <svg style={{position:"absolute", left:11, top:5, width:27, color:"#fff"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                                    </svg>

                                    <span style={{position:"absolute", top:10, right:16 }}>CHAT THE MANAGER</span>
                                </button>
                                <button onClick={()=>{setDepositTrigger(true)}} className='employee-withdraw-btn' disabled>WITHDRAWAL</button>
                                <button onClick={()=>{historian.push("/dashboard/profile")}} className='client-updateinfo-btn'>UPDATE INFO</button>
                        </section>
                        {chatTrigger === true?<ChatChannelOfEmployeeAndManagement/>:""}        
                    </section>:<section className='intro-prof' style={{cursor:'pointer'}} onClick={()=>{setIntroProfile(true)}}>
                                    <b style={{position:"relative", padding:"1px 1px 1px 1px", textAlign:"center"}}>{upuser.charAt(0).toUpperCase()}{upuser.charAt(1).toUpperCase()}</b>
                    <i style={{position:"absolute", left:50, top:10}}>{upuser}</i></section>}
                    {depositTrigger===true?<DepositFloater />:''}
                    {currencyBTN === true? <CurrencyFloater />:''}
                    {photoUploadTrigger === true? <PhotoUploader />:''}
                    {processUpload === true?<UploadingProcess />:''}
                    {uploadSuccess === true?<SuccessfulUpload />:''}
                    {uploadFailed === true?<FailedUpload />:''}
                    
                </Fragment>
            )  
}

export default EmpProfileFader