//LIBRARIES
import React, {Fragment, useContext, useState} from 'react';
import {Link, withRouter, useHistory} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

//COMPONENTS
import DefaultLoginStateContext from '../../controllers/loginContext'
import {
    LOGIN_BOOKL, LOGIN_BOOKW,
    LOGOUTER_BQL, LOGOUTER_BQW
} from '../../config'
import {emptyReduxInitialState} from '../../redux/slices/counter'
import {accessSwitch} from '../components/variables'

//OPERATION
const isActive = (history, path)=>{
    if(history.location.pathname === path){
        return {color:"#ffffff", backgroundColor:"#16A068", padding:"20px 20px 13px 20px"};
    }else{
        return {color:"#16A068"};
    }
}

let MainHeader = ({history})=>{
    const historyBuff = useHistory()
    const dispatch = useDispatch()
    var kraken = JSON.parse(localStorage.getItem('j5djwt'))
    var touken = ()=>{
        if(!kraken){
            return {username:'', email:'', role:''}
        }else{
            return {username:kraken.username, userUID:kraken.userUID,  email:kraken.email, role:kraken.role}
        }
    }
    // const adminThere = useContext(DefaultLoginStateContext)
    // const adminToLogout = adminThere
    const [token, setToken] = useState({
        username: touken().username,
        userUID: touken().userUID, 
        email: touken().email,
        role: touken().role
    })

    const dashboardAdmin = (bokog)=>{
        if(bokog === 'admin'){
            return 'admin'
        }else if(bokog === 'client'){
            return 'client'
        }else if(bokog === 'guest'){
                return 'guest'    
        }else if(bokog === 'employee'){
            return 'employee'    
        }else if(bokog === ''){
            return ''
        }else{
            return ''
        }
    }
    //LOGOUTS BOOK 
    const pitch = accessSwitch //true to Web-based MongoDB
    const bookLogOutSwitcher =(bol)=>{
        if(bol===true){
            return LOGOUTER_BQW
        }else{
            return LOGOUTER_BQL
        }
    }
    const getLogOutRecorded = async(keys)=>{
        const loggerRes = await fetch(bookLogOutSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(keys)
        })
        const trackedLoggerRec = await loggerRes.json()
        const {status, data} = trackedLoggerRec
        if(data.modifiedCount>0){
            console.log("<<Getting Self Out Of Town>>")
        }
    }

    const logOutBtn = async()=>{
        await getLogOutRecorded({userUniqueID:token.userUID})
        .then(dispatch(emptyReduxInitialState()))
        .then(()=>{
            if(typeof window !== 'undefined'){
                setToken({...token, username:'', email: '', role: ''})
                localStorage.removeItem("j5djwt")
                historyBuff.push('/login')
                window.location.reload(true)
            }
         }
        )   
    }
    return(
        <Fragment>
            <div id="HEADER-SPACE">
                <section className="header-box">
                    <div className="top-header-logo">
                        <Link to="/"><img src="../logo_jolme5d.png"></img></Link>
                    </div>
                    <ul className="top-header-first-menu">
                        <li><Link style={isActive(history, "/")} to="/">HOME</Link></li>
                        <li><Link style={isActive(history, "/services")} to="/services">SERVICES</Link></li>
                        <li><Link style={isActive(history, "/portfolio")} to="/portfolio">PORTFOLIO</Link></li>
                        <li><Link style={isActive(history, "/aboutus")} to="/aboutus">ABOUT US</Link></li>
                    </ul>
                    <ul className="top-header-second-menu">
                        {dashboardAdmin(token.role)?<li><Link style={isActive(history, "/dashboard")} to="/dashboard">DASHBOARD</Link></li>:''}
                        <li><Link style={isActive(history, "/faqs")} to="/faqs">FAQs</Link></li>
                        {dashboardAdmin(token.role || touken.role)?'':<li><Link style={isActive(history, "/register")} to="/register">REGISTER</Link></li>}
                        {dashboardAdmin(token.role)?<li><Link style={isActive(history, "/logout")} 
                        onClick={logOutBtn}>LOGOUT</Link></li>:<li><Link onClick={()=>{localStorage.removeItem("j5djwt")}} style={isActive(history, "/login")} to="/login">LOGIN</Link></li>}
                    </ul>
                </section>
            </div>
        </Fragment>
    )
}

export default withRouter(MainHeader);