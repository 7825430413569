import React, {Fragment, useState} from 'react'

const ClientMessageBoard = (props)=>{
    const {user} = props
    const [proToggler, setProToggler] = useState(false)
    return(
        <Fragment>
            {proToggler===true?<section style={{marginBottom:70}} onClick={()=>{setProToggler(false)}} className='client-dash'>
                <span style={{position:"relative", fontSize:"12pt", color:"#000000", textAlign:"center"}}>SUBMITTED TICKETS</span>
            </section>:<section style={{marginBottom:70}} onClick={()=>{setProToggler(true)}} className='client-prev-projects'>
                        <span style={{position:"relative", fontSize:"12pt", color:"#c1c1c1", textAlign:"center"}}>Message Board</span>
            </section>}
        </Fragment>
    )
}

export default ClientMessageBoard