//LIBRARIES
import React, {Fragment, useState} from 'react'
import {useHistory} from 'react-router-dom'
//COMPONENTS
import MobilePagesHeader from './mobilePagesHeader'
import MobileFooter from '../components/mobileFooter'

//OPERATIONS
let MobileErrorPage = ()=>{
    const historian = useHistory()
    return(
        <Fragment>
            <div id="MOBILE-MAIN-BODY">
                    <MobilePagesHeader />  
                    <section className="mobile-main-space">
                        <section style={{margin:"auto", width:"80%", textAlign:"center"}}>
                            <span style={{fontFamily:"Baloo", fontSize:"22pt"}}>Page Error!</span><br/>
                            <span style={{color:"#16a068"}}>Access not authorized to this page. To get access consider registering or login with your credentials.</span><br/><br/><br/>
                            <button className="mobile_btn_error" onClick={()=>{historian.push("/")}} >Go To Home Page</button>
                        </section>
                    </section>
                    <MobileFooter />
            </div>
        </Fragment>
    )
}

export default MobileErrorPage