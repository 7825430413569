//LIBRARIES
import React, {Fragment, useState} from 'react'
import {Link} from 'react-router-dom'

//COMPONENTS
import Year from './deskYear' //importing custom code and inserted at JSX
import {NEWSLETTER_APIL, NEWSLETTER_APIW} from '../../config'
import {accessSwitch} from './variables'

//OPERATIONS
let MainFooter = ()=>{
    //this is Footer's mailing-list manipulator
    const [emailRead, setEmailRead] = useState('')
    const [emailError, setEmailError] = useState("")
    const [noError, setNoError] = useState(false)

    const pitch = accessSwitch //true to Web-based MongoDB

    const nswitcher =(bol)=>{
        if(bol===true){
            return NEWSLETTER_APIW
        }else{
            return NEWSLETTER_APIL
        }
    }

    const displayEmailEntryError = ()=>{
        if(!emailRead.includes("@")){
            setEmailError("Don't forget to include '@'")
            setNoError(false)
        }else if(!emailRead.includes(".")){
            setEmailError("Don't forget to include '.'")
            setNoError(false)
        }else if(!emailRead.includes("")){
            setEmailError("Don't forget to type your email")
            setNoError(false)
        }else if(emailRead.includes("@") && emailRead.includes(".")){
            setEmailError("Email format of "+emailRead+" is correct")
            setNoError(true) //allows encoding of new client email to API
        }
    }
    const getEmail = (name)=>(event)=>{
        setEmailRead([name]=event.target.value)
        if(emailRead.length>1){
            displayEmailEntryError()
        }else if(emailRead.length === 0){
            setEmailError("")
        }else{
            setEmailError("")
        }
    }
    const newsletterEmailProcessor = async(email)=>{
                            //https://webbyjolme.com/api/j5d-newsletter/
        const newsletterTransport = await fetch(nswitcher(pitch),{ //switcher(pitch)
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(email)
        })
        const resulting = await newsletterTransport.json()
        const {status} = resulting
        setEmailError("We received your email. Thank you!")
        //console.log(status)
        setTimeout(()=>{setEmailRead("")}, 500)
    }
    const fbuttonClick = (e)=>{
        e.preventDefault()
        if(noError === true){
            newsletterEmailProcessor({email:emailRead})
        }else{
            console.log("Something is wrong...")
        }
    }
    //this is Day Teller
    const Dayot = ()=>{
        var dtime = new Date();
        var dDay = dtime.getDay();
        var karon = "";
        if(dDay === 0){
            karon = "Dominggo"
        }else if(dDay === 1){
            karon = "Lunes"
        }else if(dDay === 2){
            karon = "Martes"
        }else if(dDay === 3){
            karon = "Miyerkules"
        }else if(dDay === 4){
            karon = "Huebes"
        }else if(dDay === 5){
            karon = "Biernes"
        }else if(dDay === 6){
            karon = "Sabado"
        }
        return(
            <Fragment>
            <h3 className="theDay">Maayong {karon}!</h3>
            </Fragment>
        )
    }
    return(
        <Fragment>
            <div id="FOOTER-SPACE">
                <section className="footer-box">
                    <div className="first-part-f">
                        <div className="logo-space"><img src="../footer_logo.png"></img></div>
                    </div>
                    <div className="second-part-f">
                        <div className="fslogan-space">
                            <p>
                            We are a Web, Graphics and Illustration Agency based in Cebu Philippines. Our goal is to make your digital and printing needs easily accessible from any points in the Philippines and delivered in your doorsteps. We excelled in everything we do.
                            </p>
                        </div>
                    </div>
                    <div className="third-part-f">
                        <section className="mail_list">
                            {emailError===""?<b style={{color:"#21cc88", fontSize:"8pt"}}>{emailError}</b>:<b style={{color:"#21cc88"}}>{emailError}</b>}
                            <form>
                            <input className="prime_field" onChange={getEmail('email')} type="text" placeholder="Enlist your email to receive" ></input>
                            <input className="prime_button" onClick={fbuttonClick} type="button" value="Updates & Offer" ></input>
                            </form>
                        </section>
                    </div>
                    <div className="fourth-part-f">
                        <div className="f-widget-1"> 
                            <ul className="f1-list">
                                <li className="list-item"><Link className="llink" to=""> <img className="limage"src="../fb_logo.png"/>  <img className="blimage"/>facebook</Link></li>
                                <li className="list-item"><Link className="llink" to=""> <img className="limage"src="../twitter_logo.png"/>  <img className="blimage"/>twitter</Link></li>
                                <li className="list-item"><Link className="llink" to=""> <img className="limage"src="../instagram_logo.png"/>  <img className="blimage"/>instagram</Link></li>
                            </ul>
                        </div> 
                        <div className="f-widget-2"> 
                            <ul className="f2-list">
                                <li className="f2-listitem"><Link className="f2link" to="/disclaimer">Disclaimer</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/terms">Terms of Use</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/faqs">FAQs</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/howtos">Howtos</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/payments">Payments</Link></li>
                            </ul>
                        </div>
                        <div className="f-widget-3"> 
                            <ul className="f3-list">
                                    <li className="f3-listitem"><Link className="f3link" to="/accessibility">Accebility</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/">Home</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/graphics">Graphics</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/illustration">Illustration</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/web">Web</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/services">Services</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/prices">Pricelist</Link></li>
                            </ul>
                        </div>
                        <div className="f-widget-4"> 
                            <ul className="f4-list">
                                        <li className="f4-listitem"><Link className="f4link" to="/testimony">Testimony</Link></li>
                                        <li className="f4-listitem"><Link className="f4link" to="/concerns">Concerns</Link></li>
                                        <li className="f4-listitem"><Link className="f4link" to="/contacts">Contacts</Link></li>
                                        <li className="f4-listitem"><Link className="f4link" to="/about">About Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="fifth-part-f" style={{height:70, paddingTop:20}}>
                        <section style={{position:"absolute", top:37, left:120, width:250, height:100}}> 
                            <h2 style={{color:"#16a068", fontSize:"16pt"}}>Payments Accepted</h2>
                        </section>  
                        <section style={{position:"absolute", left:350, width:453, height:104, borderRadius:5, borderColor:"#16a068", borderWidth:0.1, borderStyle:"solid"}}> 
                            <img style={{position:"absolute", left:10, width:434}} className="limage"src="../localpayments.png"/>
                            <img style={{position:"absolute", top:55, left:10, width:434}} className="limage"src="../abroadpayments.png"/>
                        </section>  
                    </div>
                    <div className="fifth-part-f"> 
                        <div className="copyright-space"> 
                            <Dayot />
                            <Year />
                        </div>    
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default MainFooter;