//LIBRARIES
import React, {Fragment} from 'react';
//import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.css";
import {Carousel} from 'react-responsive-carousel';
import { Link, useHistory, Redirect } from 'react-router-dom';

//COMPONENTS
const DemoCarousel = ()=>(
            //Carousel probs: showArrows, showStatus, showIndicators, infiniteLoop, showThumbs, useKeyboardArrows, autoPlay, stopOnHover, swipeable, dynamicHeight, emulateTouch
            //thumbWidth={100}, selectedItem={0}, interval={3000}, transitionTime={150}, swipeScrollTolerance={5}
            //source: http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--base
            //source: https://morioh.com/p/e1effe342241
            //<img src="../animated/color1.png" />
            <Carousel showThumbs={false} showArrows={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={3000} transitionTime={200}>
               <div className="Carousel1">
                    <img src="../animated/bg_defaultb.png" />
                    <Link to="/services"><button className="bakiBTN1"><b>CHECK OUT OUR OFFER</b></button></Link>
                    <h1 className="baki_statement1">JOLME5D</h1> <h1 className="baki_statement1-b">has come with</h1>
                    <h1 className="baki_statement1-c">GREATNESS!</h1>
                </div> 
                <div className="Carousel2">
                    <img src="../animated/bg_defaultc.png" />
                    <Link to="/register"><button className="bakiBTN2"><b>SEE OUR HUBS NEAR YOU</b></button></Link>
                    <h1 className="baki_statement2">WE DELIVER</h1> <h1 className="baki_statement2-b">anywhere in the</h1>
                    <h1 className="baki_statement2-c">UNIVERSE</h1>
                </div>                               
                {/* <div className="Carousel">
                    <img src="../animated/bg_default.png" />
                    <button className="bakiBTN2"><Link to="/register">Get Even?</Link></button>
                    <h1 className="baki_statement">Get ready!</h1>
                </div>
                <div className="Carousel">
                    <img src="../animated/bg_default.png" />
                    <button className="bakiBTN3"><Link to="/register">Sing Loud!</Link></button>
                    <h1 className="baki_statement">Freak not!</h1>
                </div>
                <div className="Carousel">
                    <img src="../animated/bg_default.png" />
                    <button className="bakiBTN4"><Link to="/register">Prepare</Link></button>
                    <h1 className="baki_statement">Let's start!</h1>
                </div> */}
            </Carousel>  
);  
let upSize = window.innerHeight;
let doHeight = parseFloat(upSize)
console.log(doHeight)
let TabletLanding = ()=>{
    const historian = useHistory()
    return(
        <Fragment>
            <div id="LANDING-SPACE">
                {/*height is derived from screen height*/} {/*style={{height:doHeight-doHeight*0.25}}*/}
                {/* <section className="landing-box" > 
                    <div className="" style={{position:"relative", width:"100%", height:doHeight-doHeight*0.25}}>
                        <img style={{position:"absolute", top:0, width:"100%", clip:"rect(0px,2000px,500px,0px)"}} src='https://images.unsplash.com/photo-1517697471339-4aa32003c11a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=876&q=80' />
                    </div>
                    <section style={{position:"absolute", margin:"auto", right:205, top:-50,  width:600, height:600, borderRadius:300}}>
                        <section style={{position:"relative", top:150, width:600,  margin:"auto", textAlign:"right"}}>
                            <span style={{position:"relative", color:"#16a068", textIndent:20, fontSize:"33pt", fontFamily:"Baloo"}}>
                                We serve you with all our<br/>
                                <span style={{position:"relative",top:-30, fontSize:"58pt"}}>CREATIVE MIGHT</span>
                            </span>
                            <button onClick={()=>{
                                historian.push("/services")
                            }} style={{position:"relative", top:-20}} className='landing_btn'>
                                See Our Creative Services
                            </button>
                        </section>
                    </section>
                </section> */}
                <section className="landing-box-footer" style={{height:130}}>
                    <section style={{position:"relative", top:10, margin:"auto", height:50, width:"92%", padding:"0px 20px 0px 20px"}}> {/*, borderColor:"pink", borderWidth:1, borderStyle:"dashed"*/}
                        <ul style={{position:"absolute", top:0, left:0}}>
                            <li style={{display:"inline-block", paddingRight:20}}>
                                <span style={{color:"#16a068", fontWeight:"bold"}}>Email Address</span> <br/>
                                <span style={{color:"#16a068"}}>webbyjolme@jolme5d.com</span> 
                            </li>
                        </ul>
                        <ul style={{position:"absolute", top:0, right:0}}>
                            <li style={{display:"inline-block", paddingLeft:20, paddingRight:20}}>
                                <span style={{color:"#16a068", fontWeight:"bold"}}>Mobile No.</span> <br/>
                                <span style={{color:"#16a068"}}>(+63) 956 835 2577</span> 
                            </li>
                            <li style={{display:"inline-block", paddingLeft:20, paddingRight:20}}>
                                <span style={{color:"#16a068", fontWeight:"bold"}}>Telephone No.</span> <br/>
                                <span style={{color:"#16a068"}}>(032) 421 5246</span> 
                            </li>
                            <li style={{display:"inline-block", paddingLeft:20 }}>
                                <a href="https://www.facebook.com/jolme5d" target="_blank">
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM10.188 11.308C10.188 10.56 10.3127 9.922 10.562 9.394C10.826 8.85133 11.178 8.41133 11.618 8.074C12.058 7.722 12.5713 7.46533 13.158 7.304C13.7593 7.14266 14.3973 7.062 15.072 7.062C16.1573 7.062 16.9567 7.20866 17.47 7.502C17.9833 7.78066 18.24 8.26466 18.24 8.954C18.24 9.24733 18.174 9.51133 18.042 9.746C17.9247 9.98066 17.7927 10.1567 17.646 10.274C17.3673 10.2007 17.074 10.142 16.766 10.098C16.4727 10.0393 16.128 10.01 15.732 10.01C15.1013 10.01 14.61 10.12 14.258 10.34C13.906 10.5453 13.73 10.912 13.73 11.44V12.144H17.338C17.426 12.3053 17.5067 12.5107 17.58 12.76C17.668 12.9947 17.712 13.2587 17.712 13.552C17.712 14.0653 17.5947 14.4393 17.36 14.674C17.14 14.894 16.8393 15.004 16.458 15.004H13.928V21.978C13.7813 22.022 13.5467 22.066 13.224 22.11C12.916 22.154 12.5933 22.176 12.256 22.176C11.9333 22.176 11.64 22.154 11.376 22.11C11.1267 22.066 10.914 21.978 10.738 21.846C10.562 21.714 10.4227 21.538 10.32 21.318C10.232 21.0833 10.188 20.7827 10.188 20.416V15H8.75C8 15 8 14.2 8 14V12H10.188V11.308Z" fill="#16A068"/>
                                    </svg>
                                </a>    
                            </li>
                            <li style={{display:"inline-block", paddingLeft:20 }}>
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6 11C6 9.34315 7.34315 8 9 8H20C21.6569 8 23 9.34315 23 11V17C23 18.6569 21.6569 20 20 20H9C7.34315 20 6 18.6569 6 17V11ZM20 12.5C20 13.8807 18.8807 15 17.5 15C16.1193 15 15 13.8807 15 12.5C15 11.1193 16.1193 10 17.5 10C18.8807 10 20 11.1193 20 12.5Z" fill="#16A068"/>
                                </svg>
                            </li>
                            <li style={{display:"inline-block", paddingLeft:20 }}>
                                <a href = "https://twitter.com/jolme5d" target="_blank">
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM24 10.1043H21.8824C21.1765 9.63778 19.4824 8.77476 18.3529 9.05466C17.2235 9.33456 16.2353 10.5708 15.8824 11.1539C13.4118 11.3872 7.97647 11.2939 6 9.05466C6.47059 11.6204 8.4 16.7519 12.3529 16.7519L8.82353 22C10.5882 20.9504 14.4706 18.7112 15.8824 18.1514C17.6471 17.4516 19.0588 16.0521 20.4706 13.603C21.6 11.6437 23.2941 10.4542 24 10.1043Z" fill="#16A068"/>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </section>
                </section>
            </div>
        </Fragment>
    )
}

export default TabletLanding;