//LIBRARIES
import React, {Fragment, useEffect, useState, useContext, Profiler} from 'react'
import {useParams, useRouteMatch, useHistory} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Widget } from '@uploadcare/react-widget'
import {
    LOGOUTER_BQL, LOGOUTER_BQW,
    UPPHOTOW, UPPHOTOL
} from '../../config'
import {accessSwitch} from '../components/variables'
import {emptyReduxInitialState, defaultCurrBalance, currBalanceView, currPrimary, viewUser, proPicView} from '../../redux/slices/counter'
import MobileClientMongoDepositProgress from './mobileClientMongoDepositProgress'
//OPERATIONS
const MobileDashboardHeaderStrip = (props)=>{
    // First Letter Capital
    let nord = props.role.split("")
    let fOne = nord.slice(0,1)
    let rest = nord.splice(1)
    let nerd = fOne.join("").toUpperCase()+rest.join("")
    // <- First Letter Capital
    
    const historian = useHistory()
    const dispatch = useDispatch()
    var touken = JSON.parse(localStorage.getItem('j5djwt'))
    const historyBuff = useHistory()
    const details = useSelector(state => state.counter.userDetails)
    const pic = useSelector(state => state.counter.profilePic)
    const bamount = useSelector(state => state.counter.currBalance)
    const primeCurrency = useSelector(state => state.counter.currFiat)
    const currencyPrimaryTrigger = ()=>{
        dispatch(viewUser())
        dispatch(currBalanceView())
        dispatch(defaultCurrBalance())
    }
    const currencyPrimary = ()=>{
        setInterval(()=>{
            dispatch(currPrimary())
            dispatch(viewUser())
            dispatch(proPicView())
            dispatch(currBalanceView())
        },1000)
    }
    const currencyPrimary2 = ()=>{
        setTimeout(()=>{
            dispatch(currPrimary())
            dispatch(viewUser())
            dispatch(currBalanceView())
        },300)
    }
    currencyPrimary()
    currencyPrimary2()
    // const adminThere = useContext(DefaultLoginStateContext)
    // const adminToLogout = adminThere
    const [token, setToken] = useState({
        username:touken.username,
        userUID:touken.userUID, 
        email:touken.email,
        role:touken.role
    })
    const [clickedMenu, setClickedMenu] = useState(false)

    const bookLogOutSwitcher =(bol)=>{
        if(bol===true){
            return LOGOUTER_BQW
        }else{
            return LOGOUTER_BQL
        }
    }
    //LOGOUTS BOOK 
    const pitch = accessSwitch //true to Web-based MongoDB
    const getLogOutRecorded = async(keys)=>{
        const loggerRes = await fetch(bookLogOutSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(keys)
        })
        const trackedLoggerRec = await loggerRes.json()
        const {status, data} = trackedLoggerRec
        if(data.modifiedCount>0){
            console.log("<<Getting Self Out Of Town>>")
        }
    }
    const [logoutProgress, setLogoutProgress] = useState(false)
    const logOutBtn = async()=>{
        await getLogOutRecorded({userUniqueID:token.userUID})
        .then(dispatch(emptyReduxInitialState()))
        .then(()=>{
            if(typeof window !== 'undefined'){
                setLogoutProgress(true)
                setToken({...token, username:'', email: '', role: ''})
                localStorage.removeItem("j5djwt")
                setTimeout(()=>{
                    historyBuff.push('/')
                    window.location.reload(true)
                }, 800)
            }
         }
        )   
    }
    const MenusPad = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", top:50,  zIndex:11, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0, 0.8)"}}>
                    <section>
                        <ul className="mobile_header_menu">
                            <li onClick={logOutBtn} style={{color:"#16a068"}}>Logout</li>
                            <li onClick={()=>{historian.push("/dashboard")}} style={{color:"#16a068"}}>Dashboard</li>
                            <li onClick={()=>{historian.push("/")}} style={{color:"#16a068"}}>HomePage</li>
                            <li onClick={()=>{historian.push("/services")}} style={{color:"#16a068"}}>Services</li>
                            <li onClick={()=>{historian.push("/portfolio")}} style={{color:"#16a068"}}>Portfolio</li>
                            <li onClick={()=>{historian.push("/aboutus")}} style={{color:"#16a068"}}>About</li>
                            <li onClick={()=>{historian.push("/faqs")}} style={{color:"#16a068"}}>FAQs</li>
                        </ul>
                    </section>
                </section>
            </Fragment>
        )
    }
    const [profileView, setProfileView] = useState(false)
    const [picChange, setPicChange] = useState(false)
    const [balanceChange, setBalanceChange] = useState(false)
    const [updateProfile, setUpdateProfile] = useState(false)
    const ProfilePad = (props)=>{
        return(
            <Fragment>
                <section style={{position:"fixed", top:50,  zIndex:11, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0, 0.8)"}}>
                    <section style={{margin:"auto", width:"100%", height:"100%", borderWidth:1, borderStyle:"solid", borderColor:"#16a068"}}>
                        {details.role==="client" || props.role==="client"?
                        <section style={{margin:"auto", width:"80%", height:"75%", paddingTop:20}}>
                            <section style={{margin:"auto", width:"60%", height:220}}>
                                <section onClick={()=>{setPicChange(true)}} style={{margin:"auto", backgroundColor:"#16a068", borderRadius:60,  width:100, height:100}}>
                                    {pic?
                                    <Fragment>
                                        <img src={pic}  style={{width:100, height:100, borderRadius:50}}/>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <section style={{margin:"auto", width:"50%", paddingTop:"35%"}}>
                                            <span style={{position:"relative", left:-5, color:"#fff", fontSize:"14pt", fontWeight:"bolder"}}>Upload</span>
                                        </section>
                                    </Fragment>
                                    }
                                </section>
                                <section style={{margin:"auto", marginTop:10, width:"100%", textAlign:"center"}}>
                                    <span style={{color:"#fff", fontSize:"18pt"}}>{props.user}</span>
                                </section>
                                <section style={{margin:"auto", marginTop:10, width:"100%", textAlign:"center"}}>
                                    <button onClick={()=>{setBalanceChange(true)}} style={{padding:"5px 10px 5px 10px", borderWidth:0, borderRadius:20}}>
                                        <span style={{color:"#16a068"}}>Wallet Balance <b>{primeCurrency} {bamount}</b></span>
                                    </button>
                                </section>
                            </section>
                            <section style={{margin:"auto", marginTop:0, width:"92%", padding:"10px 10px 10px 10px", borderRadius:5, borderWidth:1, borderStyle:"solid", borderColor:"#16a068"}}>
                                <span style={{color:"#fff"}}>Lifetime Discount <b>{props.discount}%</b></span><br/>
                                <span style={{color:"#fff"}}>Email <b>{props.email}</b></span><br/>
                                <span style={{color:"#fff"}}>Status <b>{props.role}</b></span><br/>
                                <section style={{margin:"auto", marginTop:20, width:"99%"}}>
                                    <button onClick={()=>{setUpdateProfile(true)}} style={{width:"100%", backgroundColor:"#16a068", color:"#fff",  padding:"10px 0px 10px 0px", borderWidth:0, borderRadius:3}}>UPDATE PROFILE DATA</button>
                                </section>
                            </section>
                        </section>
                        :
                        <section style={{margin:"auto", width:"80%", height:"75%"}}>
                            <section style={{marginTop:30, textAlign:"center"}}>
                                <span style={{color:"#16a068"}}>Your account is gifted with a lifetime <span style={{color:"white", fontWeight:"bolder",  fontSize:"33pt"}}>{props.discount}% Discount</span></span><br/>
                                <span style={{color:"#16a068"}}>
                                    All your future orders will have {props.discount}% discount applied to it.
                                </span>
                            </section>
                            <section style={{margin:"auto", marginTop:30, width:"92%", padding:"10px 10px 10px 10px", borderRadius:5, borderWidth:1, borderStyle:"solid", borderColor:"#16a068"}}>
                                <span style={{color:"#fff"}}>Username: {props.user}</span><br/>
                                <span style={{color:"#fff"}}>Email: {props.email}</span><br/>
                                <span style={{color:"#fff"}}>Status: {props.role}</span><br/>
                            </section>
                        </section>
                        }
                        <section style={{margin:"auto",  width:"80%"}}>
                                <button onClick={()=>{setProfileView(false); window.location.reload(true)}} style={{margin:"auto", width:"100%",  backgroundColor:"#16a068", marginTop:20, borderRadius:4, borderWidth:0, padding:"10px 10px 10px 10px", color:"#fff"}}>
                                    EXIT PROFILE
                                </button>
                        </section>
                    </section>
                </section>
            </Fragment>
        )
    }
    //UPLOADING PROFILE PHOTO
    const [photoUploadTrigger, setPhotoUploadTrigger] = useState(false)
    const [fileUploaded, setFileUploaded] = useState('')//client's uploaded image url
    const [imageData, setImageData] = useState({width:0, height:0})
    const [processUpload, setProcessUpload] = useState(false)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [uploadFailed, setUploadFailed] = useState(false)

    let diff = 0
    let multiplyer = 0
    let minLeft = 0
    let minimum = 25
    if(imageData.width>imageData.height){
        diff+=imageData.width-imageData.height
        multiplyer+=imageData.width*0.01
        minLeft += diff/multiplyer
    }else{
        diff+=imageData.height-imageData.width
        multiplyer+=imageData.height*0.01
        minLeft += diff/multiplyer
    }
    const uploadPhotoSwitcher = (pitch)=>{
        if(pitch===true){
            return UPPHOTOW
        }else{
            return UPPHOTOL
        }
    }
    const uploadProfileImage = async()=>{
        setPhotoUploadTrigger(false)
        setProcessUpload(true)
        const uploadImageQuery = await fetch(uploadPhotoSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:props.uid, profileImage:fileUploaded})
        })
        const uploadGetsContact = await uploadImageQuery.json()
        const {status, data} = uploadGetsContact
        if(data.modifiedCount>0){
            setProcessUpload(false)
            setUploadSuccess(true)
        }else{
            setProcessUpload(false)
            setUploadFailed(true)
        }
    }
    const UploadingProcess = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:16, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                            <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                            <span style={{position:"absolute", color:"#fff", top:35, right:85}}>Image Upload</span> <br />
                            <span style={{position:"absolute", color:"#fff", fontSize:"22pt", fontWeight:"bolder", top:50, right:85}}>PROCESSING</span>
                    </section>
                </section>
            </Fragment>
        )
    }
    const SuccessfulUpload = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:16, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                        </section>
                        <section style={{position:"relative", zIndex:16, top:100, margin:"auto", left:"auto", width:"90%", height:0, borderRadius:5, paddingBottom:100, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                            <section style={{position:"relative", margin:"auto", top:20, width:300, paddingBottom:50}}>
                                <section style={{position:"relative", margin:"auto", top:0, textAlign:"center", backgroundColor:"white"}}>
                                    <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image Successful!</span><br/>
                                    <button onClick={()=>{setUploadSuccess(false); setPicChange(false); window.location.reload(true)}} style={{marginTop:10, backgroundColor:"#16a068", color:"white", width:"70%", height:30,  borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                                    EXIT
                                </button>
                                </section>
                            </section>
                            
                        </section>
                    </Fragment>
                )
    }
    const ChangeProfilePic = ()=>{
        return(
            <Fragment>
            <section style={{position:"fixed", top:50,  zIndex:14, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255, 1.0)"}}>
                <section style={{position:"relative", margin:"auto", width:"100%", height:"100%", borderWidth:1, borderStyle:"solid", borderColor:"#16a068"}}>
                    <section style={{margin:"auto", marginTop:30,  width:"95%",height:"100%"}}>
                        {/* PHOTO PROCESSOR*/}
                        <section style={{margin:"auto", top:45, width:"80%", borderWidth:1, borderStyle:"solid", borderColor:"#d1d1d1", padding:"20px 20px 20px 20px", textAlign:"center"}}>
                            <section style={{position:"relative", margin:"auto", zIndex:2, top:-32, width:200, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                                    <span style={{fontWeight:"bolder", color:"#16a068"}}>Upload A Profile Picture</span><br/>
                                    <span style={{fontWeight:"500", fontSize:"7pt", color:"#16a068"}}>Only upload image with or nearly 1:1 scale</span>
                            </section>
                            <section style={{margin:"auto", width:"75%", textAlign:"center"}}>
                                <Widget style={{backgroundColor:"black", top:0, width:"100%"}}
                                    publicKey='c1f6acc2c018005bfb0c' 
                                    id='file' 
                                    name='file'
                                    tabs='file url'
                                    previewStep='true'
                                    onDialogClose={(file) => {
                                        console.log('File changed: ', file)
                                        if (file) {
                                        file.progress(info => console.log('File progress: ', info.progress))
                                        file.done(info => console.log('File uploaded: ', info))
                                        }
                                        }}
                                    onChange={(info) => {
                                        setFileUploaded(info.cdnUrl); /*console.log('Upload completed:', info)*/
                                        setImageData({width:info.originalImageInfo.width, height:info.originalImageInfo.height})} 
                                    }
                                />
                            </section>
                                {fileUploaded !=='' && minLeft<minimum?
                                <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                    <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                    <b>You can upload this picture</b>
                                </section>
                                :
                                <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                    <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                    <b>Cannot be uploaded! Must be nearly equal in both sides.</b>
                                </section>
                                }


                                <section>
                                    <img src={fileUploaded} id="client_pic" width="100%"/>
                                </section>
                                {fileUploaded==='' || minLeft>minimum?
                                <button style={{width:"100%", backgroundColor:"rgba(22, 50, 98)", color:"#fff", padding:"10px 10px 10px 10px", borderRadius:4, borderWidth:0}}>
                                    No Image or Image Has Problems
                                </button>:
                                <button onClick={uploadProfileImage} style={{width:"100%", backgroundColor:"rgba(22, 50, 98)", color:"#fff", padding:"10px 10px 10px 10px", borderRadius:4, borderWidth:0}}>
                                Upload Image
                                </button>}
                        </section>
                        <section style={{margin:"auto",  width:"80%"}}>
                            <button onClick={()=>{setPicChange(false)}} style={{margin:"auto", width:"100%",  backgroundColor:"#16a068", marginTop:10, borderRadius:4, borderWidth:0, padding:"10px 10px 10px 10px", color:"#fff"}}>
                                EXIT
                            </button>
                        </section>
                    </section>
                </section>
            </section>
            {processUpload === true?<UploadingProcess />:''}
            {uploadSuccess === true?<SuccessfulUpload />:''}
        </Fragment>
        )
    }
    const ProfileUpdate = ()=>{
        return(
            <Fragment>
            <section style={{position:"fixed", top:50,  zIndex:14, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255, 1.0)"}}>
                <section style={{margin:"auto", width:"100%", height:"100%", borderWidth:1, borderStyle:"solid", borderColor:"#16a068"}}>
                    <section style={{margin:"auto", marginTop:10, width:"95%",height:"90%"}}>
                        <section style={{margin:"auto", marginTop:20,  width:"80%",  textAlign:"center"}}>
                            <span style={{color:"#16a068", fontFamily:"Baloo", fontSize:"18pt"}}>Update Profile Data</span>
                        </section>
                        <section style={{margin:"auto", marginTop:10,  width:"96%", height:"70%", borderRadius:5, borderWidth:1, borderColor:"#dfdfdf",  borderStyle:"solid"}}>

                        </section>
                        <section style={{margin:"auto",  width:"96%"}}>
                            <button onClick={()=>{setUpdateProfile(false)}} style={{margin:"auto", width:"100%",  backgroundColor:"#16a068", marginTop:10, borderRadius:4, borderWidth:0, padding:"10px 10px 10px 10px", color:"#fff"}}>
                                EXIT
                            </button>
                        </section>
                    </section>
                </section>
            </section>
        </Fragment>
        )        
    }
    const [selectedPay, setSelectedPay] = useState([])
    const ChangeBalance = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", top:50,  zIndex:14, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255, 1.0)"}}>
                    <section style={{margin:"auto", width:"100%", height:"100%", borderWidth:1, borderStyle:"solid", borderColor:"#16a068"}}>
                        <section style={{margin:"auto", marginTop:10, width:"95%",height:"90%"}}>
                            <section style={{margin:"auto", marginTop:20,  width:"50%",  textAlign:"center"}}>
                                <span style={{color:"#16a068", fontFamily:"Baloo", fontSize:"18pt"}}>Deposit Fund</span>
                            </section>
                            <section style={{margin:"auto", marginTop:10,  width:"96%", borderRadius:5, borderWidth:1, borderColor:"#dfdfdf",  borderStyle:"solid"}}>
                            {!selectedPay.includes('mongo') && !selectedPay.includes("paypal")?
                            <Fragment>
                                <section style={{margin:"auto", width:"90%", paddingTop:20, paddingBottom:20}}>
                                    <button style={{margin:"auto", width:"100%", padding:"20px 0px 20px 0px", backgroundColor:"#000", color:"#fff",   borderWidth:0, borderRadius:5}} onClick={()=>{setSelectedPay([...selectedPay, "mongo"])}}>
                                        <span>Pay With Gcash, Maya, GrabPay, etc</span>
                                    </button>
                                    <button style={{margin:"auto", width:"100%", padding:"20px 0px 20px 0px", backgroundColor:"#000", color:"#fff",   borderWidth:0, borderRadius:5, marginTop:20}} onClick={()=>{
                                        setSelectedPay([...selectedPay, "paypal"])
                                        }}>
                                        <span>Pay With Paypal</span>
                                    </button>
                                </section>
                            </Fragment>:
                            ''
                            }
                            {selectedPay.includes('mongo')?
                            <Fragment>
                                <section style={{margin:"auto", width:"90%", paddingTop:20, paddingBottom:20}}>
                                    <MobileClientMongoDepositProgress />
                                </section>    
                            </Fragment>:
                            ''
                            }
                            {selectedPay.includes('paypal')?
                            <Fragment>
                                <section style={{margin:"auto", width:"90%", paddingTop:20, paddingBottom:20}}>
                                    <span>Paypal Is Not Yet Ready!</span>
                                </section>
                            </Fragment>:
                            ''
                            }
                            </section>
                            <section style={{margin:"auto",  width:"86%"}}>
                                <button onClick={()=>{setBalanceChange(false); setSelectedPay([])}} style={{margin:"auto", width:"100%",  backgroundColor:"#16a068", marginTop:10, borderRadius:4, borderWidth:0, padding:"10px 10px 10px 10px", color:"#fff"}}>
                                    EXIT
                                </button>
                            </section>
                        </section>
                    </section>
                </section>
            </Fragment>
        )
    }
    const LogoutProgress = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", top:50,  zIndex:11, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0, 1.0)"}}>
                    <section style={{position:"relative", zIndex:12, margin:"auto", top:"25%", backgroundColor:"rgba(0,0,0, 1.0)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>logging you out...</span>
                    </section>
                </section>
            </Fragment>
        )
    }
    return(
        <Fragment>
            <section style={{position:"fixed", top:0,  zIndex:10, width:"100%", height:50, backgroundColor:"#000"}}>
                <section style={{width:"96%", height:50,  margin:"auto"}}>
                    {!profileView?
                    <section onClick={()=>{setProfileView(true); setClickedMenu(false); currencyPrimaryTrigger();}} style={{width:"30%", height:50}}>
                        {pic?
                        <Fragment>
                            <section style={{position:"absolute", top:8, width:35, height:35, backgroundColor:"#16a068", borderRadius:25}}>
                                <img src={pic}  style={{width:35, height:35, borderRadius:25}}/>
                            </section>
                        </Fragment>
                        :
                        <section style={{position:"absolute", top:8, width:35, height:35, backgroundColor:"#16a068", borderRadius:25}}>
                            <svg style={{position:"relative", margin:"auto", left:2.9, top:2, color:"#fff", width:30}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                        </section>
                        }
                    <section style={{position:"absolute", left:48, top:0,  width:"50%", height:50}}>
                        <span style={{position:"absolute", fontWeight:"bolder",  color:"#fff", top:13, left:0, color:"#16a068"}}>{details.username?details.username:props.user} </span>
                    </section>
                    </section>
                    :
                    <section onClick={()=>{setProfileView(false); setPicChange(false); setBalanceChange(false); window.location.reload(true); }} style={{width:"30%", height:50}}>
                        {pic?
                            <Fragment>
                                <section style={{position:"absolute", top:8, width:35, height:35, backgroundColor:"#16a068", borderRadius:25}}>
                                    <img src={pic}  style={{width:35, height:35, borderRadius:25}}/>
                                </section>
                            </Fragment>
                            :
                            <section style={{position:"absolute", top:8, width:35, height:35, backgroundColor:"#16a068", borderRadius:25}}>
                                <svg style={{position:"relative", margin:"auto", left:2.9, top:2, color:"#fff", width:30}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                            </section>
                            }
                        <section style={{position:"absolute", left:48, top:0,  width:"50%", height:50}}>
                            <span style={{position:"absolute", fontWeight:"bolder",  color:"#fff", top:13, left:0, color:"#16a068"}}>Exit <span style={{color:"#dfdfdf"}}>Profile</span></span>
                        </section>
                    </section>
                    }
                    
                    {/*MENU STARTS HERE*/}
                    {!clickedMenu?
                    <section onClick={()=>{setClickedMenu(true); setProfileView(false);}} style={{position:"absolute", right:0, top:0,  width:"40%", height:50, textAlign:"right"}}>
                        <span style={{position:"absolute", top:13, right:45, color:"#16a068"}}>MENU</span>
                        <svg style={{position:"absolute", top:7, right:4,  width:35, color:"#16a068"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                        </svg>
                    </section>
                    :
                    <section onClick={()=>{setClickedMenu(false)}} style={{position:"absolute", right:0, top:0,  width:"40%", height:50, textAlign:"right"}}>
                        <span style={{position:"absolute", top:13, right:45, color:"#16a068"}}>CLOSE</span>
                        <svg style={{position:"absolute", top:7, right:4,  width:35, color:"#16a068"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </section>
                    }
                </section>
            </section>
            {clickedMenu?<MenusPad />:''}
            {logoutProgress?<LogoutProgress />:''}
            {profileView?<ProfilePad user={details.username?details.username:token.username} email={details.email?details.email:token.email} role={details.role?details.role:token.role} discount = {details.discount?details.discount:0}/>:''}
            {picChange?<ChangeProfilePic />:''}
            {balanceChange?<ChangeBalance />:''}
            {updateProfile?<ProfileUpdate />:''}
        </Fragment>
    )
}

export default MobileDashboardHeaderStrip