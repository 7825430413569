//LIBRARY
import React from 'react' 
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

//COMPONENTS
import DeskServicesPage from './desktopServicesPage'
import DeskPortfolioPage from './desktopPortfolioPage'
import DeskAboutPage from './desktopAboutUs'
import DeskHomePage from './desktopHomePage'
import DeskRegisterPage from './desktopRegisterPage'
import DeskLoginPage from './desktopLoginPage'
import DesktopFaqsPage from './desktopFaqsPage'
import DesktopLoginErrorPage from './desktopLoginErrorPage'
import DesktopPasswordResetPage from './desktopPasswordReset'
import DesktopDashboard from './desktopDashboard'
import PortfolioSingle from './desktopPortfolioSinglePage'
import DeskDeactivationPage from './desktopDeactivation'
import PasswordConfiguration from './desktopPwConfigure'

//:pwresetid
const MenuRoutes = ()=>{
    return(
        <Router>
            <Switch>
                <Route path="/" exact component = {DeskHomePage}/>
                <Route path="/services" component = {DeskServicesPage}/>
                <Route path="/portfolio" component = {DeskPortfolioPage}/>
                <Route path="/aboutus" component = {DeskAboutPage}/>
                <Route path="/faqs" component = {DesktopFaqsPage}/>
                <Route path="/register" component = {DeskRegisterPage}/>
                <Route path="/login" component = {DeskLoginPage}/>
                <Route path="/login-error" component = {DesktopLoginErrorPage}/>
                <Route path="/password-reset" component = {DesktopPasswordResetPage}/>
                <Route path="/dashboard" component = {DesktopDashboard}/>   
                <Route path="/dashboard/profile" component = {DesktopDashboard}/> 
                <Route path="/dashboard/paypal" component = {DesktopDashboard}/>
                <Route path="/deactivation" component = {DeskDeactivationPage}/>   
                <Route path="/pwresetid/:pwresetid" component = {PasswordConfiguration}/>            
                <Route path="/:portfolio" component = {PortfolioSingle}/>
            </Switch>
        </Router>
    )
}

export default MenuRoutes;