//LIBRARIES
import { current } from '@reduxjs/toolkit'
import { Fragment } from 'react'
import {React, useState, useRef} from 'react'
import axios from 'axios'
import { useHistory } from 'react-router'

//COMPONENTS
import {
    pitch,
    mongoDepositLinkLocal,
    mongoDepositLinkWeb,
    clientPaymongoPaytypeLocal,
    clientPaymongoPaytypeWeb,
    clientPaymongoAttachIntentLocal,
    clientPaymongoAttachIntentWeb,
    clientPaymongoFinishLocal,
    clientPaymongoFinishWeb
 } from '../variables'

//COOKIES
import {mongoCollaborate} from '../auth/auth'

//MUI

//OPERATIONS
const ClientMongoDepositProgressPage = (props)=>{
    const historian = useHistory()
    //STEP#1: Creating a Payment INTENT from the server-side
    const [depoValue, setDepoValue] = useState({amount:0})
    const [payments, setPayments] = useState([])
    const [dataid, setDataid] = useState('') //Intent ID
    const intentSwitcher = (pitch)=>{
        if(pitch===true){
            return mongoDepositLinkWeb
        }else{
            return mongoDepositLinkLocal
        }
    }
    const pingMongoIntent = async(deposit)=>{
        //console.log(deposit)
        const pinger = await fetch(intentSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({amount:deposit})
        })
        const listenPinger = await pinger.json()
        const {data} = listenPinger
        if(data){
            setPayments([data][0].data.attributes.payment_method_allowed)
            setDataid([data][0].data.id)
        }else{
            console.log("Failed to ping!")
        }
    }
    const listenAmount = (amount)=>(event)=>{
        setDepoValue({...depoValue, [amount]:event.target.value})
    }
    const triggerIntent = (e)=>{
        e.preventDefault()
         setTimeout(()=>{
            let paddedNum = depoValue.amount.toString()+"00"
            pingMongoIntent(parseFloat(paddedNum))
        }, 600)
        
    }
    
    //STEP#2: Create a PAYMENT METHOD from the client-side
    const [rePaymentID, setRePaymentID] = useState('') //Payment ID
    const [payData, setPayData] = useState({
        name:'',
        email:'',
        phone: 0,
        paytype:''
    })
    const paytypeSwitcher = (pitch)=>{
        if(pitch===true){
            return clientPaymongoPaytypeWeb
        }else{
            return clientPaymongoPaytypeLocal
        }    
    }
    const pingMongoPaymentmethod = async(paymentData)=>{
        const getPaymentID = await fetch(paytypeSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(paymentData)
        })
        const listenPID = await getPaymentID.json()
        const {data} = listenPID
        if(data){
            //console.log([data][0].data)
            setRePaymentID([data][0].data.id)
            attachMongoIntentAndPaymentIds({intentid:dataid, paymentid:[data][0].data.id})
        }
        
    }
    const listenData = name=>event=>{
        setPayData({...payData, [name]:event.target.value})
    }
    const submitData = (e)=>{
        e.preventDefault()
        pingMongoPaymentmethod(payData)
    }
    //STEP#3: ATTACH the Payment Intent to the Payment Method
    const mongoIDSwitcher = (pitch)=>{
        if(pitch===true){
            return clientPaymongoAttachIntentWeb
        }else{
            return clientPaymongoAttachIntentLocal
        }    
    }
    const [gcashPage, setGcashPage] = useState('')
    const attachMongoIntentAndPaymentIds = async(ids)=>{
        //console.log("IID: "+ids.intentid)
        //console.log("PID: "+ids.paymentid)
        const attachMongoIDs = await fetch(mongoIDSwitcher(pitch), {
            method:"POST",
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(ids)
        })
        const respondedIDsQuery = await attachMongoIDs.json()
        const {data} = respondedIDsQuery
        if(data){
            console.log([data][0].data)
            setGcashPage([data][0].data.attributes.next_action.redirect.url) //STEP#4, Redirect URL in an <iFrame />
            setInterval(()=>{
                getListFromPaymongo(dataid)
            }, 1500) //Initiating STEP#5
        }
    }
    //STEP#5, FINISH
    const depositUrlSwitcher =(bol)=>{
        if(bol===true){
            return clientPaymongoFinishWeb
        }else{
            return clientPaymongoFinishLocal
        }
    }
    const getListFromPaymongo = async(pi_id)=>{
        const paymongoRecord = await fetch(depositUrlSwitcher(pitch), {
          method:"GET",
          headers:{"Content-Type":"application/json"}
        })
        const defcon = await paymongoRecord.json()
        const {data} = await defcon
        //console.log([data][0].data)
        
        let marder = []
        let depAmount = 0
        setTimeout(()=>{
            [data][0].data.map(item=>{
                marder.push(item.attributes.payment_intent_id)
                if(item.attributes.payment_intent_id === pi_id){
                    depAmount += item.attributes.amount
                }
            })
        },500)
        console.log(marder)
        setTimeout(()=>{
            if(marder.includes(pi_id)){
                mongoCollaborate({mongoDeposit:depAmount})
                historian.push("/dashboard/paymongo")
            }else{
                return
            }
        }, 500)
    }

    return(
        <Fragment>
            <section style={{position:"relative", width:"100%", height:"100%"}}>
                {rePaymentID.length===0?
                <Fragment>
                    {!dataid?
                        <Fragment>
                            <h1 style={{color:"#16a068"}}>Enter the amount you intend to deposit</h1>
                            <form>
                                <input style={{marginBottom:10, height:30,  width:"50%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenAmount('amount')} placeholder="₱0.00"/><br/><br/>
                                {depoValue.amount>99?
                                    <input onClick={triggerIntent} type="button" value="Continue" className="mongo_btn"/>
                                :
                                    <input type="button" value="Continue" className="mongo_btn_dead"/>
                                }
                            </form>
                        </Fragment>
                        :
                        <Fragment>
                            <section style={{textAlign:"left"}}>
                                {/* 
                                Collect billing Information from the client-side with the use of forms. We do not recommend storing this information on your server.
                                */}
                                {/* <span style={{color:"#dfdfdf"}}>Transaction ID# <span>{dataid.toUpperCase()}</span></span><br/> */}
                                <h1 style={{fontSize:"13pt",color:"#16a068"}}>Fill up the billing information fields below</h1>
                                {/* {payData.name}, {payData.email}, {payData.phone}, {payData.paytype} */}
                                <form>
                                    <input style={{marginBottom:10, height:30,  width:"50%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenData('name')} type="text" placeholder="Name"/><br/>
                                    <input style={{marginBottom:10, height:30,  width:"50%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenData('email')} type="text" placeholder="Email"/><br/>
                                    <input style={{marginBottom:10, height:30,  width:"50%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenData('phone')} type="number" placeholder="Phone"/><br/>
                                    <select style={{marginBottom:10, height:30,  width:"51.6%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenData('paytype')}>
                                        <option>PAY OPTIONS</option>
                                        {payments.map((item)=>{
                                            return(
                                                <option value={item}>{item.toUpperCase()}</option>
                                            )
                                        })}
                                    </select><br/>
                                    <br/>
                                    {payData.name.length>5 && payData.email.includes('@') && payData.phone.length>10 && payData.paytype!==''?
                                    <input className="mongo_btn" type="button" value="Submit Data" onClick={submitData} />
                                    :
                                    <input className="mongo_btn_dead" type="button" value="Submit Data"/>
                                    }
                                </form>
                            </section>
                            <section style={{position:"relative", width:"90%", marginTop:20, marginBottom:15}}>
                                <span style={{fontSize:"9pt"}}>
                                    Note: JOLME5D, Inc. will not be storing the information you entered above. <a href="https://www.paymongo.com">Paymongo</a> a Philippine Central Back regulated entity is the one that stores these financial information. 
                                </span>
                            </section>
                        </Fragment>
                        }
                </Fragment>
                :
                <Fragment>
                    <section style={{marginTop:20}}>
                        <iframe style={{width:"100%", height:420}} src={gcashPage} title="Gcash Payment Gateway"></iframe>
                    </section>
                </Fragment>
                }
            </section>
        </Fragment>
    )
}

export default ClientMongoDepositProgressPage