import react, {Fragment} from 'react'
import {Link, useHistory } from 'react-router-dom'

const MobileHLanding = ()=>{
    const historian = useHistory()
    return(
        <Fragment>
            <section className="mobile-features">
                    <img style={{borderRadius:30}}width="100%" src = "../animated/mobile_landing_feature_4.svg"/>
                    <section style={{position:"absolute", top:200, margin:"auto", left:"auto", width:"100%"}}>
                        <h1 style={{position:"relative", top:10, margin:"auto", color:"white", fontSize:"33pt", fontFamily:"Segoe UI", fontWeight:600}}>BROWSE OUR</h1>
                        <h1 style={{position:"relative", top:-10, margin:"auto", color:"white", fontSize:"38pt", fontFamily:"Segoe UI", fontWeight:700}}>PORTFOLIO</h1>
                        <h1 style={{position:"relative", top:-10, margin:"auto", color:"white", fontSize:"16pt", fontFamily:"Segoe UI", fontWeight:600}}>SEE OUR AWESOME WORKS</h1>
                        <section style={{position:"relative", bottom:-20, margin:"auto", height:50}}>
                            <a href="/portfolio/#portfolio-top">
                            <button style={{position:"relative", backgroundColor:"black", color:"white", width:"80%",  padding:"10px 20px 10px 20px", margin:"auto", left:"auto", borderRadius:30, borderWidth:0, fontFamily:"Segoe UI", fontWeight:"500", fontSize:"22pt", cursor:"pointer"}}>
                            VIEW
                            </button>
                            </a>
                        </section>
                    </section>
            </section>
        </Fragment>        
    )
}

export default MobileHLanding