import React, {Fragment, useEffect, useState, useContext, Profiler} from 'react'
import { 
    useParams, 
    useRouteMatch,
    useHistory} from 'react-router-dom'


const MobileDashboardFooterHelpers = ()=>{
    const [helpChatter, setHelpChatter] = useState(false)
    const ChatterBox = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:8, margin:"auto", marginBottom:0,  width:"100%", paddingTop:5, height:"100%", backgroundColor:"#16a068", bottom:0}}>
                    <section style={{margin:"auto", width:"90%", marginTop:70}}>
                        <span style={{color:"#fff"}}>Need Help? Talk To Us!</span>
                    </section>
                    <section style={{margin:"auto", width:"90%", marginTop:20, height:"76%", borderColor:"rgba(255, 255, 255, 0.5)", borderWidth:0.5, borderStyle:"solid"}}>
                    </section>
                </section>
            </Fragment>
        )
    }
    return(
        <Fragment>
            <section style={{position:"fixed", bottom:0, zIndex:9,  width:"100%", height:80, backgroundColor:"rgba(255,255,255, 0.0)"}}>
                {!helpChatter?
                <section onClick={()=>{setHelpChatter(true)}} style={{position:"absolute", zIndex:10, bottom:20, right:20,  margin:"auto", width:"96%", backgroundColor:"#16a068",  height:60, width:60, borderRadius:40}}>
                    <svg style={{position:"absolute", margin:"auto", left:5, top:5,   color:"#fff", width:50}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </section>
                :
                <section onClick={()=>{setHelpChatter(false)}} style={{position:"absolute", zIndex:10, bottom:20, right:20,  margin:"auto", width:"96%", backgroundColor:"#16a068",  height:60, width:60, borderRadius:40}}>
                <svg style={{position:"absolute", margin:"auto", left:5, top:5,   color:"#fff", width:50}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                </section>
                }
            </section>
            {helpChatter?<ChatterBox />:''}
        </Fragment>
    )
}

export default MobileDashboardFooterHelpers