//LIBRARIES
import React, {Fragment, useState} from 'react'
import {Link} from 'react-router-dom'

//COMPONENTS
import Year from './deskYear' //importing custom code and inserted at JSX
import {NEWSLETTER_APIL, NEWSLETTER_APIW} from '../../config'
import {accessSwitch} from './variables'

//OPERATIONS
let MainFooter = ()=>{
    //this is Footer's mailing-list manipulator
    const [emailRead, setEmailRead] = useState('')
    const [emailError, setEmailError] = useState("")
    const [noError, setNoError] = useState(false)

    const pitch = accessSwitch //true to Web-based MongoDB

    const nswitcher =(bol)=>{
        if(bol===true){
            return NEWSLETTER_APIW
        }else{
            return NEWSLETTER_APIL
        }
    }

    const displayEmailEntryError = ()=>{
        if(!emailRead.includes("@")){
            setEmailError("Don't forget to include '@'")
            setNoError(false)
        }else if(!emailRead.includes(".")){
            setEmailError("Don't forget to include '.'")
            setNoError(false)
        }else if(!emailRead.includes("")){
            setEmailError("Don't forget to type your email")
            setNoError(false)
        }else if(emailRead.includes("@") && emailRead.includes(".")){
            setEmailError("Email format of "+emailRead+" is correct")
            setNoError(true) //allows encoding of new client email to API
        }
    }
    const getEmail = (name)=>(event)=>{
        setEmailRead([name]=event.target.value)
        if(emailRead.length>1){
            displayEmailEntryError()
        }else if(emailRead.length === 0){
            setEmailError("")
        }else{
            setEmailError("")
        }
    }
    const newsletterEmailProcessor = async(email)=>{
                            //https://webbyjolme.com/api/j5d-newsletter/
        const newsletterTransport = await fetch(nswitcher(pitch),{ //switcher(pitch)
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(email)
        })
        const resulting = await newsletterTransport.json()
        const {status} = resulting
        setEmailError("We received your email. Thank you!")
        //console.log(status)
        setTimeout(()=>{setEmailRead("")}, 500)
    }
    const fbuttonClick = (e)=>{
        e.preventDefault()
        if(noError === true){
            newsletterEmailProcessor({email:emailRead})
        }else{
            console.log("Something is wrong...")
        }
    }
    //this is Day Teller
    const Dayot = ()=>{
        var dtime = new Date();
        var dDay = dtime.getDay();
        var karon = "";
        if(dDay === 0){
            karon = "Dominggo"
        }else if(dDay === 1){
            karon = "Lunes"
        }else if(dDay === 2){
            karon = "Martes"
        }else if(dDay === 3){
            karon = "Miyerkules"
        }else if(dDay === 4){
            karon = "Huebes"
        }else if(dDay === 5){
            karon = "Biernes"
        }else if(dDay === 6){
            karon = "Sabado"
        }
        return(
            <Fragment>
            <h3 className="theDay">Maayong {karon}!</h3>
            </Fragment>
        )
    }
    return(
        <Fragment>
            <div style={{position:"relative", width:"100%", height:"100%", paddingTop:50}} id="FOOTER-SPACE">
                <section style={{width:"100%"}}>
                    <div style={{position:"relative", top:-50, width:"100%"}}>
                        <div style={{width:"60%"}} className="logo-space"><img src="../footer_logo.png"></img></div>
                    </div>
                    <div style={{width:"100%", marginTop:20, marginBottom:20}} >
                        <div style={{width:"80%", textAlign:"center", color:"#16a068"}} className="fslogan-space">
                            <p>
                            We are a Web, Graphics and Illustration Agency based in Cebu Philippines. Our goal is to make your digital and printing needs easily accessible from any points in the Philippines and delivered in your doorsteps. We excelled in everything we do.
                            </p>
                        </div>
                    </div>
                    <div style={{margin:"auto", width:"100%"}}>
                        <section style={{position:"relative", width:"90%"}} className="mail_list">
                            {emailError===""?<b style={{top:-10, left:50, color:"#fff", fontSize:"8pt"}}>{emailError}</b>:<b style={{top:-10, left:50, color:"#fff"}}>{emailError}</b>}
                            <form style={{position:"relative", margin:"auto", width:"90%", textAlign:"center"}}>
                            <input className="prime_field" onChange={getEmail('email')} type="text" placeholder="Receive eNewsletter" style={{borderRadius:30, top:10,width:"90%"}} onBlur={{fbuttonClick}}/>
                            {/* <input style={{position:"absolute", top:50, fontFamily:"Segoe UI"}} className="prime_button" onClick={fbuttonClick} type="button" value="Send" style={{marginTop:10, width:"30%"}}></input> */}
                            </form>
                        </section>
                    </div>
                    <div style={{position:"relative", top:20, width:"90%", margin:"auto", textAlign:"center", marginTop:0, fontFamily:"Segoe UI"}}>
                        <li style={{marginBottom:20, paddingBottom:20, display:"block"}}> 
                            <ul style={{fontFamily:"Segoe UI", left:-20}} className="f1-list">
                                <li className="list-item"><Link className="llink" to=""> <img className="limage"src="../fb_logo.png"/>  <img className="blimage"/>facebook</Link></li>
                                <li className="list-item"><Link className="llink" to=""> <img className="limage"src="../twitter_logo.png"/>  <img className="blimage"/>twitter</Link></li>
                                <li className="list-item"><Link className="llink" to=""> <img className="limage"src="../instagram_logo.png"/>  <img className="blimage"/>instagram</Link></li>
                            </ul>
                        </li> 
                        <li style={{marginBottom:20, paddingBottom:20, display:"block"}}> 
                            <ul style={{fontFamily:"Segoe UI", left:-20}} className="f2-list">
                                <li className="f2-listitem"><Link className="f2link" to="/disclaimer">Disclaimer</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/terms">Terms of Use</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/faqs">FAQs</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/howtos">Howtos</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/payments">Payments</Link></li>
                            </ul>
                        </li>
                        <li style={{marginBottom:20, paddingBottom:20, display:"block"}}> 
                            <ul style={{fontFamily:"Segoe UI", left:-20}} className="f3-list">
                                    <li className="f3-listitem"><Link className="f3link" to="/accessibility">Accebility</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/">Home</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/graphics">Graphics</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/illustration">Illustration</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/web">Web</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/services">Services</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/prices">Pricelist</Link></li>
                            </ul>
                        </li>
                        <li style={{marginBottom:20, paddingBottom:20, display:"block"}}> 
                            <ul style={{fontFamily:"Segoe UI", left:-20}} className="f4-list">
                                        <li className="f4-listitem"><Link className="f4link" to="/testimony">Testimony</Link></li>
                                        <li className="f4-listitem"><Link className="f4link" to="/concerns">Concerns</Link></li>
                                        <li className="f4-listitem"><Link className="f4link" to="/contacts">Contacts</Link></li>
                                        <li className="f4-listitem"><Link className="f4link" to="/about">About Us</Link></li>
                            </ul>
                        </li>
                        <li style={{marginBottom:20, paddingBottom:20, display:"block"}}>
                            <div style={{color:"white"}}> 
                                <Dayot />
                                <Year />
                            </div> 
                        </li>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default MainFooter;