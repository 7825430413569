//LIBRARIES
import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

//COMPONENTS
import { pitch } from '../variables'
import {emptyReduxInitialState} from '../../../redux/slices/counter'
import { 
    GUESTAT_PL, GUESTAT_PW,
    LOGOUTER_BQL, LOGOUTER_BQW,
    UPDUX_APIL, UPDUX_APIW
    } from '../../../config'

const UpdatesStatuses = (props)=>{
    const dispatch = useDispatch()
    const historyBuff = useHistory()

    const {uid} = props
    const [usernameUpdated, setUsernameUpdated] = useState(false)
    const [passwordUpdated, setPasswordUpdated] = useState(false)
    const [emailUpdated, setEmailUpdated] = useState(false)
    const [depositFound, setDepositFound] = useState(false)
    const [clientAsNewRole, setClientAsNewRole] = useState(false)
    const statusUpdateSwitcher = (pitch)=>{
        if(pitch===true){
            return GUESTAT_PW
        }else{
            return GUESTAT_PL
        }
    }
    const revealQualifyingActions = async(uid)=>{
        //console.log("sending these: "+uid)
        const checkProStatus = await fetch(statusUpdateSwitcher(pitch), { //
            method:'POST',
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({uid:uid})
        })
        const deconStatus = await checkProStatus.json()
        const {status, data} = deconStatus

        
        data[0].qualifyingActions.map(update=>{
            if(update.usernameUpdated === true){
                setUsernameUpdated(true)
            }
            if(update.passwordUpdated === true){
                setPasswordUpdated(true)
            }
            if(update.emailUpdated === true){
                setEmailUpdated(true)
            }
            if(update.depositMade === true){
                setDepositFound(true)
            }
        })
        if(data[0].role === 'client'){
            setClientAsNewRole(true)
        }
    }
    useEffect(()=>{
        revealQualifyingActions(uid)
    },[])

    //const [triggerLogout, setTriggerLogout] = useState(false)
    

    const KnowUsernameUpdated  =()=>{
        if(usernameUpdated === true){
            return(
                <Fragment>
                    <section style={{position:"relative", margin:"auto", width:100, height:100, textAlign:"center"}}>
                        <svg width="50" height="50" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_linear_926_42)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M83.7361 78.213C90.762 69.9318 95 59.2108 95 47.5C95 36.3912 91.1866 26.1732 84.7975 18.0834C73.6691 31.4379 54.9652 49.1411 30.5 60.5C27.3915 61.9432 24.3959 63.1037 21.5143 64.013C13.3666 59.2997 6.155 53.4028 0.000634023 47.2519C0.000211511 47.3346 0 47.4172 0 47.5C0 54.3329 1.44276 60.8288 4.04027 66.6998C9.29816 66.8099 15.1263 66.0287 21.5143 64.013C27.3738 67.4027 33.7174 70.1802 40.5 72C55.0932 75.9152 70.2274 77.6662 83.7361 78.213Z" fill="#19C95B"/>
                        <path d="M13 49.9357C13 47.1318 17.6645 44.3279 20.4631 42.4587C23.2618 40.5894 25.1275 39.094 26.9933 44.3279C29.3255 50.8703 33.5235 61.1513 39.5873 58.3474C45.6511 55.5435 81.5672 22.3641 82.9665 25.168C84.3659 27.9719 41.4531 77.04 36.7886 77.9747C32.1242 78.9093 13 53.7034 13 49.9357Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_926_42" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#16A068"/>
                        <stop offset="1" stop-color="#19C95B"/>
                        </linearGradient>
                        </defs>
                        </svg>
                        <span style={{color:"#16a068"}}>USERNAME</span>
                    </section>
                </Fragment>
            )
        }else{
            return(
                <Fragment>
                    <section style={{position:"relative", margin:"auto", width:100, height:100, textAlign:"center"}}>
                        <svg width="50" height="50" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_linear_926_41)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M83.7361 78.213C90.762 69.9318 95 59.2108 95 47.5C95 36.3912 91.1866 26.1732 84.7975 18.0834C73.6691 31.4379 54.9652 49.1411 30.5 60.5C27.3915 61.9432 24.3959 63.1037 21.5143 64.013C13.3666 59.2997 6.155 53.4028 0.000634023 47.2519C0.000211511 47.3346 0 47.4172 0 47.5C0 54.3329 1.44276 60.8288 4.04027 66.6998C9.29816 66.8099 15.1263 66.0287 21.5143 64.013C27.3738 67.4027 33.7174 70.1802 40.5 72C55.0932 75.9152 70.2274 77.6662 83.7361 78.213Z" fill="#9C9C9C"/>
                            <path d="M16.6112 40.3912C12.5001 37.1819 17.2263 28.6765 20.1032 24.825C25.4667 16.0225 71.7201 68.5737 75.6127 73.5584C79.5053 78.5432 21.75 44.4028 16.6112 40.3912Z" fill="white"/>
                            <path d="M63.2655 24.0882C67.3467 20.841 74.512 27.424 77.5846 31.1213C84.8899 38.3934 22.9845 71.0849 17.2281 73.7048C11.4717 76.3247 58.1641 28.1472 63.2655 24.0882Z" fill="white"/>
                            <defs>
                            <linearGradient id="paint0_linear_926_41" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#C1C1C1"/>
                            <stop offset="1" stop-color="#9C9C9C"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        <span style={{color:"#a1a1a1"}}>USERNAME</span>
                    </section>
                </Fragment>
            )
        }
    }

    const KnowPasswordUpdated  =()=>{
        if(passwordUpdated === true){
            return(
                <Fragment>
                    <section style={{position:"relative", margin:"auto", width:100, height:100, textAlign:"center"}}>
                        <svg width="50" height="50" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_linear_926_42)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M83.7361 78.213C90.762 69.9318 95 59.2108 95 47.5C95 36.3912 91.1866 26.1732 84.7975 18.0834C73.6691 31.4379 54.9652 49.1411 30.5 60.5C27.3915 61.9432 24.3959 63.1037 21.5143 64.013C13.3666 59.2997 6.155 53.4028 0.000634023 47.2519C0.000211511 47.3346 0 47.4172 0 47.5C0 54.3329 1.44276 60.8288 4.04027 66.6998C9.29816 66.8099 15.1263 66.0287 21.5143 64.013C27.3738 67.4027 33.7174 70.1802 40.5 72C55.0932 75.9152 70.2274 77.6662 83.7361 78.213Z" fill="#19C95B"/>
                        <path d="M13 49.9357C13 47.1318 17.6645 44.3279 20.4631 42.4587C23.2618 40.5894 25.1275 39.094 26.9933 44.3279C29.3255 50.8703 33.5235 61.1513 39.5873 58.3474C45.6511 55.5435 81.5672 22.3641 82.9665 25.168C84.3659 27.9719 41.4531 77.04 36.7886 77.9747C32.1242 78.9093 13 53.7034 13 49.9357Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_926_42" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#16A068"/>
                        <stop offset="1" stop-color="#19C95B"/>
                        </linearGradient>
                        </defs>
                        </svg>
                        <span style={{color:"#16a068"}}>PASSWORD</span>
                    </section>
                </Fragment>
            )
        }else{
            return(
                <Fragment>
                    <section style={{position:"relative", margin:"auto", width:100, height:100, textAlign:"center"}}>
                        <svg width="50" height="50" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_linear_926_41)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M83.7361 78.213C90.762 69.9318 95 59.2108 95 47.5C95 36.3912 91.1866 26.1732 84.7975 18.0834C73.6691 31.4379 54.9652 49.1411 30.5 60.5C27.3915 61.9432 24.3959 63.1037 21.5143 64.013C13.3666 59.2997 6.155 53.4028 0.000634023 47.2519C0.000211511 47.3346 0 47.4172 0 47.5C0 54.3329 1.44276 60.8288 4.04027 66.6998C9.29816 66.8099 15.1263 66.0287 21.5143 64.013C27.3738 67.4027 33.7174 70.1802 40.5 72C55.0932 75.9152 70.2274 77.6662 83.7361 78.213Z" fill="#9C9C9C"/>
                            <path d="M16.6112 40.3912C12.5001 37.1819 17.2263 28.6765 20.1032 24.825C25.4667 16.0225 71.7201 68.5737 75.6127 73.5584C79.5053 78.5432 21.75 44.4028 16.6112 40.3912Z" fill="white"/>
                            <path d="M63.2655 24.0882C67.3467 20.841 74.512 27.424 77.5846 31.1213C84.8899 38.3934 22.9845 71.0849 17.2281 73.7048C11.4717 76.3247 58.1641 28.1472 63.2655 24.0882Z" fill="white"/>
                            <defs>
                            <linearGradient id="paint0_linear_926_41" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#C1C1C1"/>
                            <stop offset="1" stop-color="#9C9C9C"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        <span style={{color:"#a1a1a1"}}>PASSWORD</span>
                    </section>
                </Fragment>
            )
        }
    }

    const KnowEmailUpdated  =()=>{
        if(emailUpdated === true){
            return(
                <Fragment>
                    <section style={{position:"relative", margin:"auto", width:100, height:100, textAlign:"center"}}>
                        <svg width="50" height="50" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_linear_926_42)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M83.7361 78.213C90.762 69.9318 95 59.2108 95 47.5C95 36.3912 91.1866 26.1732 84.7975 18.0834C73.6691 31.4379 54.9652 49.1411 30.5 60.5C27.3915 61.9432 24.3959 63.1037 21.5143 64.013C13.3666 59.2997 6.155 53.4028 0.000634023 47.2519C0.000211511 47.3346 0 47.4172 0 47.5C0 54.3329 1.44276 60.8288 4.04027 66.6998C9.29816 66.8099 15.1263 66.0287 21.5143 64.013C27.3738 67.4027 33.7174 70.1802 40.5 72C55.0932 75.9152 70.2274 77.6662 83.7361 78.213Z" fill="#19C95B"/>
                        <path d="M13 49.9357C13 47.1318 17.6645 44.3279 20.4631 42.4587C23.2618 40.5894 25.1275 39.094 26.9933 44.3279C29.3255 50.8703 33.5235 61.1513 39.5873 58.3474C45.6511 55.5435 81.5672 22.3641 82.9665 25.168C84.3659 27.9719 41.4531 77.04 36.7886 77.9747C32.1242 78.9093 13 53.7034 13 49.9357Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_926_42" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#16A068"/>
                        <stop offset="1" stop-color="#19C95B"/>
                        </linearGradient>
                        </defs>
                        </svg>
                        <span style={{color:"#16a068"}}>EMAILS</span>
                    </section>
                </Fragment>
            )
        }else{
            return(
                <Fragment>
                    <section style={{position:"relative", margin:"auto", width:100, height:100, textAlign:"center"}}>
                        <svg width="50" height="50" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_linear_926_41)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M83.7361 78.213C90.762 69.9318 95 59.2108 95 47.5C95 36.3912 91.1866 26.1732 84.7975 18.0834C73.6691 31.4379 54.9652 49.1411 30.5 60.5C27.3915 61.9432 24.3959 63.1037 21.5143 64.013C13.3666 59.2997 6.155 53.4028 0.000634023 47.2519C0.000211511 47.3346 0 47.4172 0 47.5C0 54.3329 1.44276 60.8288 4.04027 66.6998C9.29816 66.8099 15.1263 66.0287 21.5143 64.013C27.3738 67.4027 33.7174 70.1802 40.5 72C55.0932 75.9152 70.2274 77.6662 83.7361 78.213Z" fill="#9C9C9C"/>
                            <path d="M16.6112 40.3912C12.5001 37.1819 17.2263 28.6765 20.1032 24.825C25.4667 16.0225 71.7201 68.5737 75.6127 73.5584C79.5053 78.5432 21.75 44.4028 16.6112 40.3912Z" fill="white"/>
                            <path d="M63.2655 24.0882C67.3467 20.841 74.512 27.424 77.5846 31.1213C84.8899 38.3934 22.9845 71.0849 17.2281 73.7048C11.4717 76.3247 58.1641 28.1472 63.2655 24.0882Z" fill="white"/>
                            <defs>
                            <linearGradient id="paint0_linear_926_41" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#C1C1C1"/>
                            <stop offset="1" stop-color="#9C9C9C"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        <span style={{color:"#a1a1a1"}}>EMAILS</span>
                    </section>
                </Fragment>
            )
        }
    }

    const KnowDepositMade  =()=>{
        if(depositFound === true){
            return(
                <Fragment>
                    <section style={{position:"relative", margin:"auto", width:100, height:100, textAlign:"center"}}>
                        <svg width="50" height="50" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_linear_926_42)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M83.7361 78.213C90.762 69.9318 95 59.2108 95 47.5C95 36.3912 91.1866 26.1732 84.7975 18.0834C73.6691 31.4379 54.9652 49.1411 30.5 60.5C27.3915 61.9432 24.3959 63.1037 21.5143 64.013C13.3666 59.2997 6.155 53.4028 0.000634023 47.2519C0.000211511 47.3346 0 47.4172 0 47.5C0 54.3329 1.44276 60.8288 4.04027 66.6998C9.29816 66.8099 15.1263 66.0287 21.5143 64.013C27.3738 67.4027 33.7174 70.1802 40.5 72C55.0932 75.9152 70.2274 77.6662 83.7361 78.213Z" fill="#19C95B"/>
                        <path d="M13 49.9357C13 47.1318 17.6645 44.3279 20.4631 42.4587C23.2618 40.5894 25.1275 39.094 26.9933 44.3279C29.3255 50.8703 33.5235 61.1513 39.5873 58.3474C45.6511 55.5435 81.5672 22.3641 82.9665 25.168C84.3659 27.9719 41.4531 77.04 36.7886 77.9747C32.1242 78.9093 13 53.7034 13 49.9357Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear_926_42" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#16A068"/>
                        <stop offset="1" stop-color="#19C95B"/>
                        </linearGradient>
                        </defs>
                        </svg>
                        <span style={{color:"#16a068"}}>DEPOSIT</span>
                    </section>
                </Fragment>
            )
        }else{
            return(
                <Fragment>
                    <section style={{position:"relative", margin:"auto", width:100, height:100, textAlign:"center"}}>
                        <svg width="50" height="50" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_linear_926_41)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M83.7361 78.213C90.762 69.9318 95 59.2108 95 47.5C95 36.3912 91.1866 26.1732 84.7975 18.0834C73.6691 31.4379 54.9652 49.1411 30.5 60.5C27.3915 61.9432 24.3959 63.1037 21.5143 64.013C13.3666 59.2997 6.155 53.4028 0.000634023 47.2519C0.000211511 47.3346 0 47.4172 0 47.5C0 54.3329 1.44276 60.8288 4.04027 66.6998C9.29816 66.8099 15.1263 66.0287 21.5143 64.013C27.3738 67.4027 33.7174 70.1802 40.5 72C55.0932 75.9152 70.2274 77.6662 83.7361 78.213Z" fill="#9C9C9C"/>
                            <path d="M16.6112 40.3912C12.5001 37.1819 17.2263 28.6765 20.1032 24.825C25.4667 16.0225 71.7201 68.5737 75.6127 73.5584C79.5053 78.5432 21.75 44.4028 16.6112 40.3912Z" fill="white"/>
                            <path d="M63.2655 24.0882C67.3467 20.841 74.512 27.424 77.5846 31.1213C84.8899 38.3934 22.9845 71.0849 17.2281 73.7048C11.4717 76.3247 58.1641 28.1472 63.2655 24.0882Z" fill="white"/>
                            <defs>
                            <linearGradient id="paint0_linear_926_41" x1="47.5" y1="0" x2="47.5" y2="95" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#C1C1C1"/>
                            <stop offset="1" stop-color="#9C9C9C"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        <span style={{color:"#a1a1a1"}}>DEPOSIT</span>
                    </section>
                </Fragment>
            )
        }
    }

    //FORCED LOGOUT
    var kraken = JSON.parse(localStorage.getItem('j5djwt'))
    var touken = ()=>{
        if(!kraken){
            return {username:'', email:'', role:''}
        }else{
            return {username:kraken.username, userUID:kraken.userUID,  email:kraken.email, role:kraken.role}
        }
    }
    // const adminThere = useContext(DefaultLoginStateContext)
    // const adminToLogout = adminThere
    const [token, setToken] = useState({
        username: touken().username,
        userUID: touken().userUID, 
        email: touken().email,
        role: touken().role
    })

    const bookLogOutSwitcher =(bol)=>{
        if(bol===true){
            return LOGOUTER_BQW
        }else{
            return LOGOUTER_BQL
        }
    }
    const getLogOutRecorded = async(keys)=>{
        const loggerRes = await fetch(bookLogOutSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(keys)
        })
        const trackedLoggerRec = await loggerRes.json()
        const {status, data} = trackedLoggerRec
        if(data.modifiedCount>0){
            console.log("<<Getting Self Out Of Town>>")
        }
    }

    const logOutBtn = async()=>{
        await getLogOutRecorded({userUniqueID:uid})
        .then(dispatch(emptyReduxInitialState()))
        .then(()=>{
            if(typeof window !== 'undefined'){
                setToken({...token, username:'', email: '', role: ''})
                localStorage.removeItem("j5djwt")
                historyBuff.push('/login')
                //window.location.reload(true)
            }
         }
        )   
    }

    const [numCount, setNumCount] = useState(9)
    const counterPuncher =()=>{
        if(numCount>0){
            setInterval(()=>{setNumCount(numCount-1)},1000)
        }
        if(numCount===0){
            logOutBtn()
        }
        return numCount
    }
    const Congratulations = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:3, top:-10, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.5)"}}>
                </section>
                <section  style={{position:"fixed", zIndex:3, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255,0.6)"}}>
                    <section style={{position:"relative", textAlign:"center", zIndex:3, top:20, margin:"auto", left:"auto", width:"40%", borderRadius:8, height:"85%", backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                        {/* <button onClick ={()=>{setDepositFound(false);}} style={{position:"absolute", right:-35, top:0, borderRadius:"5px", borderWidth:0, padding:"10px 10px 10px 10px", backgroundColor:"#ffff", boxShadow:"0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                            X
                        </button> */}
                        <section style={{position:"relative", top:60}}>
                            <span style={{fontSize:"44pt", fontFamily:"Baloo"}}>Contratulations!</span> <br/>
                            <span style={{color:"#16a068", fontSize:"22pt"}}>You are now a Client.</span><br/> 
                            <span>You will be logged out in...</span>
                        </section>
                        <section style={{position:"relative", margin:"auto", top:100, width:200, height:200, backgroundColor:"#16a068", borderRadius:"50%"}}>
                                <span style={{fontSize:"92pt", fontFamily:"Baloo", color:"white", verticalAlign:"middle"}}>{counterPuncher()}</span>
                        </section>
                    </section>
                </section>
                
            </Fragment>
        )
    }

    return(
        <Fragment>
            <section style={{position:"relative", margin:"auto", marginTop:30, marginBottom:40}}>   
                <section style={{position:"absolute", top:24, left:60, zIndex:-1, width:"86%", height:85, borderRadius:50, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", textAlign:"center"}}>
                    <span style={{position:"relative", top:73, fontWeight:600, color:"#000", backgroundColor:"#d1d1d1", borderRadius:15, padding:"2px 50px 5px 50px"}}>Profile Status Progress</span>
                </section>
                <ul className='status-progress'>
                    <li>
                        <KnowUsernameUpdated />
                    </li>
                    <li>
                        <KnowPasswordUpdated />
                    </li>
                    <li>
                        <KnowEmailUpdated />
                    </li>
                    <li>
                        <KnowDepositMade />
                    </li>
                </ul>
                
            </section>
            {usernameUpdated===true && emailUpdated===true && passwordUpdated === true && depositFound === true && clientAsNewRole === true?<Congratulations />:''}
        </Fragment>
    )
}

export default UpdatesStatuses