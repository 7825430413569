//LIBRARY
import React, {Fragment, useEffect, useState, useContext, Profiler} from 'react';
import { BrowserRouter as Router,
    Switch,
    Route, 
    useHistory,
    useParams, 
    useLocation,
    useRouteMatch } from 'react-router-dom';

import {accessSwitch,
    pushNewPasswordLocal,
    pushNewPasswordWeb,
    resetIDLinkLocal,
    resetIDLinkWeb
} from '../components/variables'
//clients
import DeskHeader from './deskHeader';
import DeskFooter from './deskFooter';

//MUI
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
const Jolme5DTheme = createTheme({
    palette: {
      primary: {
        main: "#16A068"
      },
    },
})
const Jolme5DDark = createTheme({
    palette: {
      primary: {
        main: "#000"
      },
    },
})
const PasswordConfiguration = ()=>{
    const historian = useHistory()
    const pitch = accessSwitch
    //let matchConfigView = useRouteMatch("pwresetid/:pwresetid") //conditions
    const { pathname } = useLocation()
    const { id } = useParams();
    let matchConfigView = useRouteMatch({
        path:"pwresetid/:pwresetid",
        strict:true,
        sensitive:true
    }) 
    let {path} = useParams()
    // const crack = matchConfigView.params.pwresetid.split("_").join(" ");
    //console.log(pathname)
    let cole = pathname.split("/")
    //console.log(cole[2])

    //querying reset id list
    const resetIDListswitcher =(bol)=>{
        if(bol===true){
            return resetIDLinkWeb
        }else{
            return resetIDLinkLocal
        }
    }
    const [expiryDate, setExpiryDate] = useState([])
    const [requestingEmail, setRequestingEmail] = useState([])
    const getResetIDRecord = async(resetid)=>{
        //console.log(resetid)
        const pipedMail = await fetch(resetIDListswitcher(pitch), {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(resetid)
        })
        const parcel = await pipedMail.json()
        const {status, data} = parcel
        if(status === "success"){
            const [datei] = data
            console.log(datei)
            setExpiryDate(datei.dateToExpire)
            setRequestingEmail(datei.requestingEmail)
        }else{
            //setPasswordResetError(true)
        }
      }
    useEffect(()=>{
        getResetIDRecord({resetid:cole[2]})
    },[])

    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const [dateExpired, setDateExpired] = useState(false)
    setTimeout(()=>{
        const targetDate = new Date(expiryDate)
        const nextDate = new Date(targetDate)
        nextDate.setDate(targetDate.getDate())
        var end = nextDate.setDate(targetDate.getDate())
        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;
        var _day = _hour * 24;
        var timer 
        //var timeremain = ""
        function showRemaining() {
            var now = new Date();
            var distance = end - now;
            if (distance < 0) {
                clearInterval(timer);
                setDateExpired(true)
                return;
            }
            var days = Math.floor(distance / _day);
            var hours = Math.floor((distance % _day) / _hour);
            var minutes = Math.floor((distance % _hour) / _minute);
            var seconds = Math.floor((distance % _minute) / _second);
            try{
                setDays(days); setHours(hours); setMinutes(minutes); setSeconds(seconds)
            }catch(e){
                console.log(e)
                //setXTimer({...xtimer, days:0, hours:0, minutes:0, seconds:0})
            }
        
        }
        timer = setTimeout(showRemaining, 1000)
    },1000)

    const [newPassword, setNewPassword] = useState({
        new:'',
        retype:''
    })
    const [npasswordSubmitted, setNPasswordSubmitted] = useState(false)
    const newpasswordswitcher =(bol)=>{
        if(bol===true){
            return pushNewPasswordWeb
        }else{
            return pushNewPasswordLocal
        }
    }
    const pushNewPassword = async(emailer)=>{
        console.log(emailer)
        const pipedMail = await fetch(newpasswordswitcher(pitch), {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(emailer)
        })
        const parcel = await pipedMail.json()
        const {status, data} = parcel
        if(status === "success"){
            setNPasswordSubmitted(true)
        }else{
        }
      }
    const processNewPassword = (e)=>{
        e.preventDefault()
        pushNewPassword({emailrequester:requestingEmail, newpassword:newPassword.new})
    }
    const handleNewPassword = whatever=>event=>{
        setNewPassword({...newPassword, [whatever]:event.target.value})
    }
    return (
        <Fragment>
        {!matchConfigView?
            <Fragment>
                <DeskHeader />
                <div id="MAIN-DASHBOARD">
                {dateExpired===false?
                    <ThemeProvider theme={Jolme5DTheme}>
                        {npasswordSubmitted ===true?
                        <Fragment>
                            <section style={{textAlign:"center", padding:"20px 20px 100px 20px"}}>
                                <h1>Your Password Is Updated!</h1>
                                <p>You can now use your newly created password to signin.</p><br/>
                                <Button variant='contained' style={{borderRadius:20}} onClick={()=>{historian.push("/login")}}>Get To Login Page</Button>
                            </section>
                        </Fragment>
                        :
                        <Fragment>
                            <section style={{textAlign:"center", padding:"20px 20px 100px 20px"}}>
                            <h1 style={{fontFamily:"", fontSize:"3em"}}>Create New Password</h1>
                            <span style={{fontSize:"0.8em"}}>This page will expire in</span><br/>
                            <h1 style={{color:"#16a068"}}>{hours} HR | {minutes} MIN | {seconds} SEC</h1><br/>
                            <input onChange={handleNewPassword('new')} type='password' placeholder='Create New Password' style={{width:"30%", fontSize:"1.5em", height:30, padding:5, textAlign:"center"}}/> <br/><br/>
                            <input onChange={handleNewPassword('retype')} type='password' placeholder='Retype New Password' style={{width:"30%", fontSize:"1.5em", height:30, padding:5, textAlign:"center"}}/> <br/><br/><br/>
                            {newPassword.new === newPassword.retype && newPassword.new.length>5 && newPassword.retype.length>5?
                            <Button variant='contained' style={{borderRadius:20}} onClick={processNewPassword}>Submit New Password</Button>
                            :
                            <ThemeProvider theme={Jolme5DDark}>
                                <Button variant='contained' style={{borderRadius:20}}>Make it at least 5 characters</Button>
                            </ThemeProvider>
                            }
                    </section>
                        </Fragment>
                        }

                    </ThemeProvider>
                :
                    <ThemeProvider theme={Jolme5DTheme}>
                    <section style={{textAlign:"center", padding:"20px 20px 100px 20px"}}>
                        <h1 style={{fontFamily:"", fontSize:"3em"}}>Page Has Expired!</h1>
                        <p>Your previous permission ID has expired. <br/>You can request a new one by following the button below.</p> <br/>
                        <Button variant='contained' style={{borderRadius:20}} onClick={()=>{historian.push("/password-reset")}}>Request New Permission</Button>
                    </section>
                    </ThemeProvider>
                }
                </div>
                <DeskFooter />
            </Fragment>:
            <h1>Nothing here!{id}</h1>
        }
        </Fragment>
    )
  }

  export default PasswordConfiguration