//LIBRARY
import React, {Fragment, useEffect, useState, useRef, Profiler} from 'react';
import {useParams, 
    useRouteMatch,
    useHistory} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { viewGuestStatusIntro} from '../../../src/redux/slices/counter'
import { querriedData } from '../../redux/querries/userdataquery';
import {destroyMongoCollaborator} from './auth/auth'
//import Paymongo from 'paymongo' 

//COMPONENTS
import MobileMongoDepositProgressPage from './mobileMongoDepositProgress'
import MobileDashboardHeaderStrip from './mobileDashboardHeader'
import MobileDashboardFooterStrip from './mobileDashboardFooter'
import MobileErrorPage from './mobileErrorPage'
import { pitch } from '../../screens/components/variables'
import {emptyReduxInitialState, currPrimary, viewUser} from '../../../src/redux/slices/counter'
import MobileDashboardFooterHelpers from './mobileDashboardFooterHelpers'
import MobileClientActiveProjects from './mobileClientActiveProjects'
import MobileProjectActivity from './mobileProjectActivity'
import MobileClientProjectMarketplace from './mobileClientBuysMarketplace'
import MobileClientPaymongoSuccessPage from './mobileClientPaymongoSuccess'
//ENV
import { 
    GUESTAT_PL, GUESTAT_PW,
    CHUSER_PAPIL, CHUSER_PAPIW,
    GUE_TOCLIL, GUE_TOCLIW,
    CHUSERNAME_PAPIL,
    CHUSERNAME_PAPIW,
    CHUSERPASSW_PAPIL,
    CHUSERPASSW_PAPIW,
    CHUSERNEMAIL_PAPIL,
    CHUSERNEMAIL_PAPIW,
    LOGOUTER_BQL, LOGOUTER_BQW,
    UPDUX_APIL, UPDUX_APIW
    } from '../../../src/config'

//OPERATIONS
const DeskDashboardPage = (props)=>{
        const historyBuff = useHistory()
        const guestViewStatus = useSelector(state => state.counter.guestIntro)
        //console.log([mixen][0][0].balance[0].amount)
       

        const dispatch = useDispatch()
        const xguestIntroStatus = (bole)=>{
            dispatch(viewGuestStatusIntro(bole))
        }
        const details = useSelector(state => state.counter.userDetails)
        const currencyPrimary = ()=>{
            setInterval(()=>{
                dispatch(currPrimary())
                dispatch(viewUser())
            },1000)
        }
        const currencyPrimary2 = ()=>{
            setTimeout(()=>{
                dispatch(currPrimary())
                dispatch(viewUser())
            },300)
        }
        currencyPrimary()
        currencyPrimary2()
        var userBalance = 0
        console.log("Balance: "+userBalance)
        var token = JSON.parse(localStorage.getItem('j5djwt'))
        //console.log(token.username)
        //const [introCaution, setIntroCaution] = useState(guestViewStatus) <--replaced with Redux-based state
        let {id} = useParams()
        let { path, url } = useRouteMatch()
        let matchPaymongo = useRouteMatch("/dashboard/paymongo") //FOR CLIENT
        let matchProjectView = useRouteMatch({
            path:"/dashboard/project/:projectid",
            strict:true,
            sensitive:true
        }) //conditions
        const [switchToBuy, setSwitchToBuy] = useState(false)
        try{
            if(token.status === "Success" && token.role === "client"){
                querriedData()
                return(
                    <Fragment>
                        {!matchProjectView && !matchPaymongo?
                        <Fragment>
                            {switchToBuy===true?
                            <Fragment>
                                <section style={{width:"100%", marginTop:60}}>
                                    <section style={{margin:"auto", width:"94%"}}>
                                        <button onClick={()=>{setSwitchToBuy(false)}} className='switch-account' style={{position:"relative", float:"right"}}><svg style=   {{position:"relative", top:2.2, left:-3, width:16}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"     stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                        </svg>EXIT MARKETPLACE</button><br/>
                                    </section>    
                                </section>
                            </Fragment>:
                            <Fragment>
                                <section style={{width:"100%", marginTop:60}}>
                                    <section style={{margin:"auto", width:"94%"}}>
                                        <button onClick={()=>{setSwitchToBuy(true)}} className='switch-buyer' style={{position:"relative", float:"right"}}>BUY NEW PROJECT</button><br/>
                                    </section>
                                </section>
                            </Fragment>}
                            {switchToBuy===true?
                                <Fragment>
                                    <MobileDashboardHeaderStrip user={token.username} uid={token.userUID} role={token.role} email={token.email}/>
                                    <section style={{zIndex:7, margin:"auto", width:"94%", paddingTop:30}}>
                                        <MobileClientProjectMarketplace />
                                    </section>
                                    <MobileDashboardFooterHelpers /> 
                                </Fragment>
                                :
                                <Fragment>
                                <MobileDashboardHeaderStrip user={token.username} uid={token.userUID} role={token.role} email={token.email}/>
                                <section style={{zIndex:7, margin:"auto", width:"94%", paddingTop:30}}>
                                    <MobileClientActiveProjects user={token.username} userid={token.userUID} />
                                </section>
                                <MobileDashboardFooterHelpers /> 
                                </Fragment>  
                            }
                        </Fragment>:''}
                        {matchProjectView?
                        <Fragment>
                            <MobileDashboardHeaderStrip user={token.username} role={token.role} email={token.email}/>
                            <section style={{zIndex:7, margin:"auto", width:"94%", height:700,  marginTop:60}}>
                                <MobileProjectActivity uid={token.userUID} projectid = {matchProjectView.params.projectid}/>
                            </section>
                            <MobileDashboardFooterHelpers /> 
                        </Fragment>
                        :''}
                        {matchPaymongo?
                        <Fragment>
                            <MobileDashboardHeaderStrip user={token.username} role={token.role} email={token.email}/>
                             <MobileClientPaymongoSuccessPage />
                            <MobileDashboardFooterHelpers /> 
                        </Fragment>
                        :''}   
                    </Fragment>
                )                
            }else if(token.status === "Success" && token.role === "guest"){
                querriedData()
                const GuestPages = (props)=>{
                    const usernamefvalue = useRef() 
                    const passwordfvalue = useRef()
                    const emailfvalue = useRef()
                    let matchMongoSuccess= useRouteMatch("/dashboard/mongo")
                    const mongoRecord = JSON.parse(localStorage.getItem("mongo_deposit"))
                    //let matchGuestPaypal = useRouteMatch("/dashboard/paypal")
                    //let matchGuestPaymongo = useRouteMatch("/dashboard/paymongo")

                    const {uid} = props
                    const [usernameUpdated, setUsernameUpdated] = useState(false)
                    const [passwordUpdated, setPasswordUpdated] = useState(false)
                    const [emailUpdated, setEmailUpdated] = useState(false)
                    const [depositFound, setDepositFound] = useState(false)
                    const [clientAsNewRole, setClientAsNewRole] = useState(false)
                    const [updateEvents, setUpdateEvents] = useState([])
                    //updating username
                    const chuserNameSwitcher = (pitch)=>{
                        if(pitch===true){
                            return CHUSERNAME_PAPIW
                        }else{
                            return CHUSERNAME_PAPIL
                        }
                    }
                    const updatingUsername = async(uid, newUsername)=>{
                        console.log("sending these: "+uid +" "+newUsername)
                        const checkUsn = await fetch(chuserNameSwitcher(pitch),{
                            method:'POST',
                            headers:{"Content-Type":"application/json"},
                            body:JSON.stringify({userUniqueID:uid, username:newUsername})
                        })
                        const deconstUsn = await checkUsn.json()
                        const {status, error, udata} = deconstUsn
                        if(status){
                            //setUpdateEvents([...updateEvents, updateEvents.push("username")])
                            console.log(updateEvents)
                            window.location.reload(true)
                        }
                        
                    }
                    const updateUsername = (e)=>{
                        e.preventDefault()
                        updatingUsername(uid, usernamefvalue.current.value)
                        
                    }
                    //updating password
                    const chuserNewPassWSwitcher = (pitch)=>{
                        if(pitch===true){
                            return CHUSERPASSW_PAPIW
                        }else{
                            return CHUSERPASSW_PAPIL
                        }
                    }
                    const resetPassword = async(uid, newPassword)=>{
                        const resetPassword = await fetch(chuserNewPassWSwitcher(pitch),{
                            method:'POST',
                            headers:{"Content-Type":"application/json"},
                            body:JSON.stringify({userUniqueID:uid, password:newPassword})
                        })
                        const deconstNewPass = await resetPassword.json()
                        const {status, error, udata} = deconstNewPass
                        if(status){
                            window.location.reload(true)
                        }
                    }
                    const updatePassword = (e)=>{
                        e.preventDefault()
                        console.log(passwordfvalue.current.value)
                        resetPassword(uid, passwordfvalue.current.value)
                    }
                    //updating email
                    const chuserNewEmailSwitcher = (pitch)=>{
                        if(pitch===true){
                            return CHUSERNEMAIL_PAPIW
                        }else{
                            return CHUSERNEMAIL_PAPIL
                        }
                    }
                    const updatingEmail = async(uid, newEmail)=>{
                        const resetEmail = await fetch(chuserNewEmailSwitcher(pitch),{
                            method:'POST',
                            headers:{"Content-Type":"application/json"},
                            body:JSON.stringify({userUniqueID:uid, email:newEmail})
                        })
                        const deconstNewEmail = await resetEmail.json()
                        const {status, error, udata} = deconstNewEmail
                        if(status){
                            window.location.reload(true)
                        }
                    }
                    const updateEmail = (e)=>{
                        e.preventDefault()
                        updatingEmail(uid, emailfvalue.current.value) 
                    }

                    const statusUpdateSwitcher = (pitch)=>{
                        if(pitch===true){
                            return GUESTAT_PW
                        }else{
                            return GUESTAT_PL
                        }
                    }
                    const revealQualifyingActions = async(uid)=>{
                        //console.log("sending these: "+uid)
                        const checkProStatus = await fetch(statusUpdateSwitcher(pitch), { //
                            method:'POST',
                            headers:{"Content-Type":"application/json"},
                            body:JSON.stringify({uid:uid})
                        })
                        const deconStatus = await checkProStatus.json()
                        const {status, data} = deconStatus

                        
                        data[0].qualifyingActions.map(update=>{
                            if(update.usernameUpdated === true){
                                setUpdateEvents([...updateEvents, updateEvents.push("username")])
                            }
                            if(update.passwordUpdated === true){
                                setUpdateEvents([...updateEvents, updateEvents.push("password")])
                            }
                            if(update.emailUpdated === true){
                                setUpdateEvents([...updateEvents, updateEvents.push("email")])
                            }
                            if(update.depositMade === true){
                                setUpdateEvents([...updateEvents, updateEvents.push("deposit")])
                            }
                        })
                        if(data[0].role === 'client'){
                            setClientAsNewRole(true)
                        }
                    }
                    console.log(updateEvents)
                    useEffect(()=>{
                        revealQualifyingActions(uid)
                    },[])

                    const [payments, setPayments] = useState([])
                    const [fieldContents, setFieldContents] = useState({username:'', password:'', email:''})
                    const watchEntries = (name)=>(event)=>{
                        event.preventDefault()
                        setFieldContents({...fieldContents, [name]:event.target.value})
                    }
                    //FORCED LOGOUT
                    var kraken = JSON.parse(localStorage.getItem('j5djwt'))
                    var touken = ()=>{
                        if(!kraken){
                            return {username:'', email:'', role:''}
                        }else{
                            return {username:kraken.username, userUID:kraken.userUID,  email:kraken.email, role:kraken.role}
                        }
                    }
                    // const adminThere = useContext(DefaultLoginStateContext)
                    // const adminToLogout = adminThere
                    const [token, setToken] = useState({
                        username: touken().username,
                        userUID: touken().userUID, 
                        email: touken().email,
                        role: touken().role
                    })
                    const [numCount, setNumCount] = useState(9)
                    console.log(updateEvents)
                    const logOutBtn = async()=>{
                        if(typeof window !== 'undefined'){
                            localStorage.removeItem("j5djwt")
                            historyBuff.push('/')
                            window.location.reload(true)
                        }  
                    }
                    if(!matchMongoSuccess){
                        return (
                            <Fragment>
                                <div style={{width:"100%"}}>
                                        <section style={{width:"96%", margin:"auto", height:49, backgroundColor:"red", marginBottom:0}}></section> 
    
                                        <section style={{width:"96%", margin:"auto", paddingBottom:20,  marginTop:10, backgroundColor:"#dfdfdf", borderRadius:5}}>
                                            <section style={{width:"100%", margin:"auto", backgroundColor:"#16a068", marginTop:10, borderTopRightRadius:5, borderTopLeftRadius:5}}>
                                                <section style={{width:"90%", margin:"auto", padding:"10px 20px 10px 20px"}}>
                                                    <span style={{color:"#fff"}}>To become a fully-pledged client please update your profile data below</span>
                                                </section>
                                            </section>
                                            
                                            {updateEvents.length===0? 
                                            <section style={{width:"96%", margin:"auto", marginTop:10}}>
                                                <section style={{position:"relative", marginTop:100, margin:"auto", width:"100%", textAlign:"center", paddingTop:20}}>
                                                    <section style={{margin:"auto", width:70, height:70, borderRadius:35, backgroundColor:"#000", color:"#fff", fontFamily:"Baloo", fontSize:"33pt",textAlign:"center"}}>1</section><br/>
                                                    <span style={{fontSize:"13.5pt", fontWeight:"bolder", color:"#16a068"}}>Update Your Username</span><br/><br/>
                                                    <input ref={usernamefvalue} onChange={watchEntries('username')} className="mobile_data_update_fields" style={{width:"80%", height:50, fontSize:"22pt", borderRadius:5,  borderWidth:0, padding:"10px 20px 10px 20px"}} type="text" placeholder={details.username?details.username:props.user}/>
                                                    {fieldContents.username.length>4?
                                                    <input onClick={updateUsername} className='mobile_d_u_f_btn' type="submit" value="SUBMIT" style={{width:"80%", height:40}}/>
                                                    :
                                                    <input className='mobile_d_u_f_btn' type="submit" value="UPDATE" style={{backgroundColor:"#afafaf", width:"80%", height:40}}/>
                                                    }
                                                </section> 
                                            </section>
                                            :
                                            ''
                                            }
                                            {updateEvents.length===1? 
                                                <section style={{width:"96%", margin:"auto", marginTop:10}}>
                                                    <section style={{position:"relative", marginTop:100, margin:"auto", width:"100%", textAlign:"center", paddingTop:20}}>
                                                        <section style={{margin:"auto", width:70, height:70, borderRadius:35, backgroundColor:"#000", color:"#fff", fontFamily:"Baloo", fontSize:"33pt",textAlign:"center"}}>2</section><br/>
                                                        <span style={{fontSize:"13.5pt", fontWeight:"bolder", color:"#16a068"}}>Update Your Password</span><br/><br/>
                                                        <input ref={passwordfvalue} onChange={watchEntries('password')} className="mobile_data_update_fields" style={{width:"80%", height:50, fontSize:"22pt", borderRadius:5,  borderWidth:0, padding:"10px 20px 10px 20px"}} type="password" placeholder="Password"/>
                                                        {fieldContents.password.length>6?
                                                        <input onClick={updatePassword} className='mobile_d_u_f_btn' type="submit" value="SUBMIT" style={{width:"80%", height:40}}/>
                                                        :
                                                        <input className='mobile_d_u_f_btn' type="submit" value="UPDATE" style={{backgroundColor:"#afafaf", width:"80%", height:40}}/>
                                                        }
                                                    </section> 
                                                </section>
                                            :
                                            ''
                                            }
                                            {updateEvents.length===2? 
                                                    <section style={{width:"96%", margin:"auto", marginTop:10}}>
                                                        <section style={{position:"relative", marginTop:100, margin:"auto", width:"100%", textAlign:"center", paddingTop:20}}>
                                                            <section style={{margin:"auto", width:70, height:70, borderRadius:35, backgroundColor:"#000", color:"#fff", fontFamily:"Baloo", fontSize:"33pt",textAlign:"center"}}>3</section><br/>
                                                            <span style={{fontSize:"13.5pt", fontWeight:"bolder", color:"#16a068"}}>Update Your Email</span><br/><br/>
                                                            <input ref={emailfvalue} onChange={watchEntries('email')} className="mobile_data_update_fields" style={{width:"80%", height:50, fontSize:"22pt", borderRadius:5,  borderWidth:0, padding:"10px 20px 10px 20px"}} type="text" placeholder="Email"/>
                                                            {fieldContents.email.includes("@") && fieldContents.email.includes(".")?
                                                            <input onClick={updateEmail} className='mobile_d_u_f_btn' type="submit" value="SUBMIT" style={{width:"80%", height:40}}/>
                                                            :
                                                            <input className='mobile_d_u_f_btn' type="submit" value="UPDATE" style={{backgroundColor:"#afafaf", width:"80%", height:40}}/>
                                                            }
                                                        </section> 
                                                    </section>
                                            :''
                                            }
                                            {updateEvents.length===3? 
                                                            <section style={{width:"96%", margin:"auto", marginTop:10}}>
                                                                <section style={{position:"relative", marginTop:100, margin:"auto", width:"100%", textAlign:"center", paddingTop:20}}>
                                                                    <section style={{margin:"auto", width:70, height:70, borderRadius:35, backgroundColor:"#000", color:"#fff", fontFamily:"Baloo", fontSize:"33pt",textAlign:"center"}}>4</section><br/>
                                                                    <span style={{fontSize:"13.5pt", fontWeight:"bolder", color:"#16a068"}}>Deposit For Membership</span><br/><br/>
    
                                                                    {!payments.includes("ph") && !payments.includes("paypal")?
                                                                    <Fragment>
                                                                        {/* <input className="mobile_data_update_fields" style={{width:"80%", height:50, fontSize:"22pt", textAlign:"center",  borderRadius:5,  borderWidth:0, padding:"10px 20px 10px 20px"}} type="text" value="₱100.00"/> */}
                                                                        <input onClick={(e)=>{e.preventDefault(); setTimeout(()=>{setPayments([...payments, "ph"]); console.log(payments)}, 800)}} className='mobile_d_u_f_btn' type="submit" value="Pay with PH Payments" style={{width:"80%", height:40, marginTop:20, marginBottom:10}}/><br/>
                                                                        <input onClick={(e)=>{e.preventDefault(); setTimeout(()=>{setPayments([...payments, "paypal"]); console.log(payments)}, 800)}} className='mobile_d_u_f_btn' type="submit" value="Pay with Paypal" style={{width:"80%", height:40}}/><br/><br/>
                                                                        <section style={{margin:"auto", width:"80%", textAlign:"left"}}>
                                                                            <span style={{color:"#16a068"}}>
                                                                                Note: A Membership fee is intended to prevent spamming and at the same time to make sure you are serious at doing business with us.
                                                                            </span>
                                                                        </section>
                                                                    </Fragment>
                                                                    :''}
                                                                    {payments.includes("ph")?
                                                                    <Fragment>
                                                                        <section style={{margin:"auto", width:"96%", marginBottom:10}}>
                                                                            <button style={{backgroundColor:"#16a068", borderRadius:"50%", borderWidth:0,  width:50, height:50}} onClick={()=>{setPayments([])}}>
                                                                                <svg style={{width:25, color:"#fff"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                                                                </svg>
                                                                            </button>
                                                                        </section>
                                                                        <section style={{margin:"auto", width:"96%", marginBottom:10, borderRadius:3,    backgroundColor:"#fff", paddingTop:20, paddingBottom:20}}>
                                                                            <span style={{fontSize:"10pt", color:"#16a068"}}>Pay with GCash, GrabPay, Maya & BPI Online</span>
                                                                            <MobileMongoDepositProgressPage uid = {uid}/>
                                                                        </section>
                                                                    </Fragment>
                                                                    :
                                                                     ''
                                                                    }
                                                                    {payments.includes("paypal")?
                                                                    <Fragment>
                                                                        <section style={{margin:"auto", width:"96%", marginBottom:10}}>
                                                                            <button style={{backgroundColor:"#16a068", borderRadius:"50%", borderWidth:0, width:50, height:50}} onClick={()=>{setPayments([])}}>
                                                                                <svg style={{width:25, color:"#fff"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                                                                </svg>
                                                                            </button>
                                                                        </section>
                                                                        <section style={{margin:"auto", width:"96%", height:200, marginBottom:10, borderRadius:3,    backgroundColor:"#fff"}}>
                                                                            <span style={{fontSize:"10pt", color:"#16a068"}}>Pay with Paypal & Credit Cards, etc.</span>
                                                                            <h1 style={{color:"red", fontSize:"24pt"}}>This Part Is Still Under Development</h1>
                                                                            {/* PAYPAL COMPONENTS */}
                                                                        </section>
                                                                    </Fragment>
                                                                    :''}
                                                                </section> 
                                                            </section>
                                            :''}
                                            {updateEvents.includes('deposit')? 
                                                    <section style={{width:"96%", margin:"auto", marginTop:10}}>
                                                        <section style={{position:"relative", marginTop:100, margin:"auto", width:"100%", textAlign:"center", paddingTop:20}}>
                                                            <span style={{fontSize:"17pt"}}>You have finished updating your profile and paid for the membership fee.</span><br/><br/>
                                                            <button onClick={()=>{logOutBtn()}} style={{backgroundColor:"#000", fontSize:"14pt",  color:"#fff",  width:"60%", height:40, borderWidth:0, borderRadius:4}}>Logout</button>
                                                        </section> 
                                                    </section>
                                            :''
                                            }
                                        </section>
                                        {/* Privacy Policy */}
                                        <section style={{width:"96%", margin:"auto", paddingBottom:20,  marginTop:10, backgroundColor:"#dfdfdf", borderRadius:5}}>
                                            <section style={{width:"100%", margin:"auto", backgroundColor:"#16a068", marginTop:10, borderTopRightRadius:5, borderTopLeftRadius:5}}>
                                                <section style={{width:"90%", margin:"auto", padding:"10px 20px 10px 20px"}}>
                                                    <span style={{color:"#fff"}}>Jolme5D Privacy Policy</span>
                                                </section>
                                            </section>
                                            <section style={{width:"96%", margin:"auto", marginTop:10}}>
                                                <section style={{textAlign:"left", padding:"0px 20px 50px 20px"}}>
                                                    <span style={{color:"#16a068"}}>
                                                        <p>
                                                            1. The trustworthiness of the information you provided depends to you alone. The only piece of information we needed to be legally correct will be your phone number and home address for the delivery of parcel such as printed materials.
                                                    </p>
                                                    <p>
                                                            2. We don't ask your real name. However you can share it at your own free will. As a company we don't have a policy to collect your personal information.
                                                    </p>
                                                    <p>
                                                            3. We don't collect phone number either. This kind of information will only be provided from your part if ever you need something delivered in your doorstep.
                                                    </p>
                                                    <p>
                                                            4. We don't need to know about your gender. We treated everyone with dignity. If you identified yourself to any gender classifications we don't want to be wrong at making communication with you.
                                                    </p>
                                                    <p>
                                                            5. We don't do inferior works. The reason why there is not a need to collect your personal infomation is rooted primarily in our philosohpy of rendering the highest quality services. The veracity of information you provided has nothing to do with our works' quality.
                                                    </p>
                                                    <p>
                                                            6. We don't accept ads placement in our web and mobile applications. That's to say we don't sell your information or your behavior online to the advertisers.
                                                    </p>
                                                    </span>
                                                    <span style={{color:"#16a068"}}>
                                                        <p>
                                                            7. We do ask for commitment deposit in an amount equivalent to $1.00 or PHP50.00. This is so to really see your intention in dealing with us.
                                                    </p>
                                                    <p>
                                                            8. We do assure you our financial transaction to be secured. Your activity and your financial activity with us are encrypted through SSL or TSL Handshake. Our policy of not collecting vital information from your part is a first step in being secured in the first place.
                                                    </p>
                                                    <p>
                                                            9. We do transact or send printed materials to any parts of the world. We don't descriminate anyone solely on a particular race origins or based on a comtemporary geopolitical tensions.  
                                                    </p>
                                                    <p>
                                                            10. We do allow you to directly communicate with project's service provider. These providers are our very own. You communicate directly to him/her and at the same time you can communicate to our managers. The company however is monitoring every bits of infomation you pass through the chat system to ensure decency of communication is in place.
                                                    </p>
                                                    <p>
                                                            11. We do constantly make a system updates. The integrity of our existence depends on our company-tailored applications. Our competition is well ahead in terms of financial standing. We however try to circumvent this downsides by being ahead in the systems we put up.
                                                    </p>
                                                    </span>
                                                </section>
                                            </section>
                                        </section>
                                </div>
                            </Fragment>
                        )
                    }else{
                        try{
                            if(mongoRecord.mongoDeposit){
                                const statusUpdateSwitcher = (pitch)=>{
                                    if(pitch===true){
                                        return GUESTAT_PW
                                    }else{
                                        return GUESTAT_PL
                                    }
                                }
                                const revealQualifyingActions = async(uid)=>{
                                    //console.log("sending these: "+uid)
                                    const checkProStatus = await fetch(statusUpdateSwitcher(pitch), { //
                                        method:'POST',
                                        headers:{"Content-Type":"application/json"},
                                        body:JSON.stringify({uid:uid})
                                    })
                                    const deconStatus = await checkProStatus.json()
                                    const {status, data} = deconStatus
                                    if(data[0].role === 'client'){
                                        setClientAsNewRole(true)
                                    }
                                }
                                revealQualifyingActions(uid)

                                const bookLogOutSwitcher =(bol)=>{
                                    if(bol===true){
                                        return LOGOUTER_BQW
                                    }else{
                                        return LOGOUTER_BQL
                                    }
                                }
                                const logOutBtn = async()=>{
                                    if(typeof window !== 'undefined'){
                                        localStorage.removeItem("j5djwt")
                                        historyBuff.push('/')
                                        window.location.reload(true)
                                    }  
                                }
                                
                                const counterPuncher =()=>{
                                    // if(numCount>0){
                                    //     setInterval(()=>{setNumCount(numCount-1)},1000)
                                    // }
                                    // if(numCount===0){
                                    //     logOutBtn()
                                    // }
                                    setTimeout(()=>{logOutBtn()}, 6000)
                                    return numCount
                                }
                                setTimeout(()=>{logOutBtn()},6000)
                                const Congratulations = ()=>{
                                    return(
                                        <Fragment>
                                            <section style={{position:"fixed", zIndex:14, top:-10, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.5)"}}>
                                            </section>
                                            <section  style={{position:"fixed", zIndex:15, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(255,255,255, 0.6)"}}>
                                                <section style={{position:"relative", textAlign:"center", zIndex:3, top:20, margin:"auto", left:"auto", width:"90%", borderRadius:8, height:"85%", backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                                                    {/* <button onClick ={()=>{setDepositFound(false);}} style={{position:"absolute", right:-35, top:0, borderRadius:"5px", borderWidth:0, padding:"10px 10px 10px 10px", backgroundColor:"#ffff", boxShadow:"0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                                                        X
                                                    </button> */}
                                                    <section style={{position:"relative", top:60}}>
                                                        <span style={{fontSize:"30pt", fontFamily:"Baloo"}}>Contratulations!</span> <br/>
                                                        <span style={{color:"#16a068", fontSize:"22pt"}}>You are now a Client.</span><br/> 
                                                        <span>You will be logged out in...</span>
                                                    </section>
                                                    <section style={{position:"relative", margin:"auto", top:100, width:200, height:200, backgroundColor:"#16a068", borderRadius:"50%"}}>
                                                            <span style={{fontSize:"92pt", fontFamily:"Baloo", color:"white", verticalAlign:"middle"}}>{counterPuncher()}</span>
                                                    </section>
                                                </section>
                                            </section>
                                            
                                        </Fragment>
                                    )
                                }
                                return(
                                    <Fragment>
                                        <div style={{width:"100%"}}>
                                                <section style={{width:"96%", margin:"auto", height:15, backgroundColor:"red", marginBottom:0}}></section> 
            
                                                <section style={{width:"96%", margin:"auto", paddingBottom:50,  marginTop:50, borderWidth:1, borderColor:"#dfdfdf", borderStyle:"solid",  borderRadius:5}}>
                                                        <section style={{margin:"auto", width:"60%", textAlign:"center", paddingTop:25}}>
                                                            <section style={{margin:"auto", borderRadius:"50%", width:"50%",  backgroundColor:"#dfdfdf"}}>
                                                                <svg style={{color:"#16a068", width:100}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                                </svg>
                                                            </section><br/>
                                                            <span style={{color:"#16a068", fontFamily:"Baloo",  fontSize:"22pt"}}>
                                                                You just made a payment of <span style={{color:"#000", fontSize:"33pt"}}>P100.00</span>
                                                            </span><br/>
                                                            <span style={{color:"#16a068",  fontSize:"12pt"}}>
                                                                You are now a CLIENT. You'll be logged out in a few seconds. Then log back again to be redirected to your client Dashboard.
                                                            </span><br/><br/>
                                                            <button onClick={()=>{ setTimeout(()=>{logOutBtn()}, 800); }} style={{width:"100%", height:50, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, fontSize:"14pt", color:"#fff"}}>Exit Now</button>
                                                        </section>
                                                </section>
                                        </div>
                                        {/*                                         
                                        {clientAsNewRole?
                                        <Congratulations />
                                        :''}
                                        */}
                                    </Fragment>
                                )
                            }
                        }catch(e){
                            return(
                                <Fragment>
    
                                    <div style={{width:"100%"}}>
                                            <section style={{width:"96%", margin:"auto", height:49, backgroundColor:"red", marginBottom:0}}></section> 
        
                                            <section style={{width:"96%", margin:"auto", paddingBottom:50,  marginTop:50, borderWidth:1, borderColor:"#dfdfdf", borderStyle:"solid",  borderRadius:5}}>
                                                    <section style={{margin:"auto", width:"60%", textAlign:"center", paddingTop:40}}>
                                                        <section style={{margin:"auto", borderRadius:"50%", width:"50%",  backgroundColor:"#dfdfdf"}}>
                                                            <svg style={{color:"red", width:100}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </section><br/>
                                                        <span style={{color:"#16a068", fontFamily:"Baloo",  fontSize:"22pt"}}>
                                                            You haven't made any payment yet! 
                                                        </span><br/><br/>
                                                        <button onClick={()=>{historyBuff.push("/dashboard")}} style={{width:"100%", height:50, backgroundColor:"#16a068", borderWidth:0, borderRadius:5, fontSize:"14pt", color:"#fff"}}>Back To Dashboard</button>
                                                    </section>
                                            </section>
                                    </div>
                                </Fragment>
                            )
                        }
                        
                    }
                    
                  }
                return(
                    <Fragment>
                        <MobileDashboardHeaderStrip user={token.username} role={token.role} email={token.email}/>
                        <GuestPages user={token.username} uid={token.userUID}/>
                        <MobileDashboardFooterStrip />  
                    </Fragment>
                )                
            }
        }catch(e){ //IF THERE IS ERROR THIS PAGE WILL SAVE THE DAY
            historyBuff.push("/error")
           return(
            <Fragment>
                <MobileErrorPage />
            </Fragment>
           )
        }
}
export default DeskDashboardPage;