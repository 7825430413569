//LIBRARIES
import React, {Fragment, useState} from 'react'

//COMPONENTS
import MobilePagesHeader from './mobilePagesHeader'
import MobileFooter from '../components/mobileFooter'
import MobileDashboardHeaderStrip from './mobileDashboardHeader'

//OPERATIONS
let MobileFAQsPage = ()=>{
    const loggedman = JSON.parse(localStorage.getItem('j5djwt'))
    return(
        <Fragment>
            <div id="MOBILE-MAIN-BODY">
                    {!loggedman || loggedman === null?
                    <MobilePagesHeader />
                    :
                    <MobileDashboardHeaderStrip user={loggedman.username} role={loggedman.role}/>
                    }
                    <section className="mobile-main-space">
                        <div style={{width:"95%"}} className="faqs-space">
                            <h1 style={{fontSize:"30pt"}}>FAQs & Informations</h1>

                            <h2>How long have Jolme5D been doing business?</h2>
                            <p>We have been in business since the 90s but we are only entrenched in Cebu in the first 2 decades of our existence. But now due to emergence of new technologies we are able to reach any nook and corner of the Philippines.</p>

                            <h2>Are your prices flexible to cater to large volume?</h2>
                            <p>We believed on 'Sabut-sabut lang' words. And our prices are pretty flexible. In fact returning clients can enjoy big discount.</p>
                        
                            <h2>What payment methods you accepted?</h2>
                            <p>We accept all Philippine Local Pera Padala. We also accept Coins.ph, GCash, dragonpay, Paypal and CreditCards</p>
                        
                            <h2>How long each project takes to finish?</h2>
                            <p>Each project is unique and may pose different level of complexities as we progress onward. Please take note that you are able to trackdown project status.</p>

                            <h2>What if I am dissatisfied with the outcome of the project?</h2>
                            <p>You'll get 80% of the fund deposited. But this is seldom going to happen due to our advancement in technology. For project with printing already underway there is a matter to be discussed about that.</p>
                        
                            <h2>Who will deliver our packaged printed materials?</h2>
                            <p>We have it delivered to Parcel Companies depending on weight limit and we will determine which Parcel Company to do the delivery for a particular package.</p>
                        </div>
                    </section>
                    <MobileFooter/>
            </div>
        </Fragment>
    )
}

export default MobileFAQsPage;