//LIBRARIES
import React, {Fragment, useState, useEffect} from 'react'
import DropboxChooser from 'react-dropbox-chooser'
import { useSelector, useDispatch } from 'react-redux'
import {projectXViewToggle, userListsViewToggle} from '../../../redux/slices/counter'
import { Mailer } from 'nodemailer-react'

//MUI 
import Dialog, { DialogProps } from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'

//COMPONENTS
import {accessSwitch,
    emailMessengerLinkLocal,
    emailMessengerLinkWeb
} from '../variables'
import {DSAPIL, DSAPIW } from '../../../config'
import {SDAPIL, SDAPIW, DFAPIL, DFAPIW,  ADUX_ALLAPIL, ADUX_ALLAPIW} from '../../../config'
//import DashboardControlPanels from './daskPanels'
import DashboardOutputDisplay from './daskOutputDisplay'
import ProjectListings from './projectListings'
import {ServicesPortfolioPanel} from '../daskTempComps/servicesPortfolioPanel'
import GuestAndOtherInquiries from './daskAllInquiries'
import InternalComms from './daskInternals'

//OPERATIONS
const Jolme5DTheme = createTheme({
    palette: {
      primary: {
        main: "#16A068"
      },
    },
})
const DashboardSearchControl = (props)=>{
    const projectXView = useSelector(state => state.counter.projectXView)
    const usersXView = useSelector(state => state.counter.userListsView)

    const [oDisplay, setODisplay] = useState(false)
    const [galItem, setGalItem] = useState([])

    const pitch = accessSwitch //true to Web-based MongoDB
    const switcher =(bol)=>{
        if(bol===true){
            return ADUX_ALLAPIW
        }else{
            return ADUX_ALLAPIL
        }
    }
    const usersListAPI = async()=>{
        await fetch(switcher(pitch)) //true to Web-based MongoDB
        .then((responsive)=>responsive.json())
        .then((data)=>{
            const wanton = data.data
            const drearies = []
            for(const key in wanton){
                drearies.push({
                    id:key,
                    uid:wanton[key].userUniqueID,
                    username: wanton[key].username,
                    email: wanton[key].email,
                    role: wanton[key].role,
                    dateSince: wanton[key].dateCreated,
                    orders:wanton[key].orders, 
                    payments:wanton[key].paymentSources
                })
            }
            setGalItem(drearies)
        })
    }
    useEffect(()=>{
        usersListAPI()
    },[])

    const [searched, setSearched] = useState({
        uid:'',
        username:''
    })
    const handleSearch = search=>event=>{
        event.preventDefault()
        const catched = {[search]:event.target.value}
        setSearched()
        
        galItem.map(item=>{
            if(item.username.toLowerCase() === catched.searched.toLowerCase()){
                setSearched({...searched, uid:item.uid, username:item.username, email:item.email})
            }
        })
        galItem.map(item=>{
            if(item.email.toLowerCase() === catched.searched.toLowerCase()){
                setSearched({...searched, uid:item.uid, username:item.username, email:item.email})
            }
        })
        setODisplay(false)
    }
    //MAILBOX
    const [mailboxComposer, setMailboxComposer] = useState(false)
    const [mailboxRead, setMailboxRead] = useState(false)
    const [mailboxDeleted, setMailboxDeleted] = useState(false)
    const [mailerInfo, setMailerInfo] = useState({
        username:'',
        targetemail:'',
        fromemail:'',
        subject:'',
        textbodyplain:'',
        textbodyhtml:''
    })
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });
    const nodemailerswitcher = (bol)=>{
        if(bol===true){
            return emailMessengerLinkWeb
        }else{
            return emailMessengerLinkLocal
        }
    }
    const pipeOutEmailMsg = async(mailer)=>{
        console.log(mailer)
        const pipedMail = await fetch(nodemailerswitcher(accessSwitch), {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(mailer)
        })
        const parcel = await pipedMail.json()
        const {status, data} = parcel
        console.log(data)
        if(data === "success"){
            setMailboxComposer(false)
        }
      }
    
    const sendingEmail = ()=>{
        pipeOutEmailMsg({
            username: props.loggeduser,
            targetemail: mailerInfo.targetemail, 
            fromemail: "webbyjolme@jolme5d.com", 
            subject: mailerInfo.subject,
            textbodyplain: mailerInfo.textbodyplain,
            textbodyhtml:mailerInfo.textbodyplain
         })
    }
    const handleFieldChange = whatever=>event=>{
        setMailerInfo({...mailerInfo, [whatever]:event.target.value})
    }
    //TABS
    const [activeFirst, setActiveFirst] = useState(usersXView)
    const [activeSecond, setActiveSecond] = useState(false)
    const [activeThird, setActiveThird] = useState(projectXView)  //<- boolean is derived from Redux State
    const [activeFourth, setActiveFourth] = useState(false)
    const [activeFifth, setActiveFifth] = useState(false)
    const [activeSixth, setActiveSixth] = useState(false)
    const DashboardControlPanels = (props)=>{
        const {uresult} = props
        const refreshAdminDefaultPage = (e)=>{
            e.preventDefault()
            window.location.reload()
        }
        return(
            <Fragment>
                <section style={{marginBottom:50}}>
                    <section className='control-buttons'>
                        <ul className='controller'>
                            {activeFirst===true?<li style={{backgroundColor:"#16a068", color:"#fff"}}>Users</li>:<li onClick={(e)=>{e.preventDefault(); setActiveFirst(true);setActiveSecond(false);setActiveThird(false); setActiveFourth(false); setActiveFifth(false); setActiveSixth(false); setODisplay(false);}}>Users</li>}
                            {activeSecond===true?<li style={{backgroundColor:"#16a068", color:"#fff"}}>System</li>:<li onClick={(e)=>{e.preventDefault(); setActiveFirst(false); setActiveSecond(true);setActiveThird(false); setActiveFourth(false); setActiveFifth(false); setActiveSixth(false); setODisplay(false);}}>System</li>}
                            {activeThird===true?<li style={{backgroundColor:"#16a068", color:"#fff"}}>Projects</li>:<li onClick={(e)=>{e.preventDefault();  setActiveFirst(false); setActiveSecond(false); setActiveFourth(false); setActiveFifth(false);setODisplay(false); setActiveThird(true); setActiveSixth(false);}}>Projects</li>}
                            {activeFourth===true?<li style={{backgroundColor:"#16a068", color:"#fff"}}>Services</li>:<li onClick={(e)=>{e.preventDefault(); setActiveFirst(false); setActiveSecond(false);setActiveThird(false); setActiveFourth(true); setActiveFifth(false); setActiveSixth(false); setODisplay(false)}}>Services</li>}
                            {activeFifth===true?<li style={{backgroundColor:"#16a068", color:"#fff"}}>Internal</li>:<li onClick={(e)=>{e.preventDefault(); setActiveFirst(false); setActiveSecond(false);setActiveThird(false); setActiveFourth(false); setActiveFifth(true); setActiveSixth(false); setODisplay(false);}}>Internal</li>}
                        </ul>
                        {activeSixth === true?
                        <button style={{backgroundColor:"#16a068", color:"#fff"}} className='inquirybtn' onClick={(e)=>{e.preventDefault(); setActiveFirst(false); setActiveSecond(false);setActiveThird(false); setActiveFourth(false); setActiveFifth(false);setODisplay(false); setActiveSixth(true)}}>Inquiries</button>
                        :<button className='inquirybtn' onClick={(e)=>{e.preventDefault(); setActiveFirst(false); setActiveSecond(false);setActiveThird(false); setActiveFourth(false); setActiveFifth(false);setODisplay(false); setActiveSixth(true)}}>Inquiries</button>}
                        
                        
                    </section>
                    <section className='control-room' style={{borderTopRightRadius:0}}>
                        {/*User Account Details Section*/}
                        {activeFirst===true?
                        <section className='search_u_result' style={{left:-30, width:"74%"}} >
                            <section className='inquiries-series'>
                                    {!uresult.username || uresult.username === null?
                                    <section style={{position:"relative", width:"96%", top:-10, margin:"auto", textAlign:"center", padding:"64px 0px 64px 0px"}}>
                                        <svg style={{position:"relative", top:8, left:-10,  width:30, height:30, color:"#16a068"}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                                        </svg>
                                        <span style={{color:"#16a068"}}>Search <b>Guests</b>, <b>Employees</b> in the <b>Search Bar</b> Above<br/></span>
                                    </section>    
                                    :<Fragment>
                                        <section style={{position:"relative", right:20, top:15, width:"100%", height:40}}>
                                            <button className='usersearch-single-result-btn' onClick={refreshAdminDefaultPage}>
                                                <span style={{position:"relative", top:-10, right:7}}>Refresh</span>
                                                <svg style={{width:30, height:30}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                </svg>
                                            </button>
                                            <button className='usersearch-single-result-btn' style={{marginRight:2}}>
                                                <span style={{position:"relative", top:-10, right:7}}>Delete User</span>
                                                <svg style={{width:30, height:30}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg>
                                            </button>
                                            <button className='usersearch-single-result-btn'  style={{marginRight:2}}>
                                                <span style={{position:"relative", top:-10, right:7}} >Chat with User</span>
                                                <svg style={{width:30, height:30}} xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                                <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                                                </svg>
                                            </button>
                                        </section>
                                        <section style={{position:"relative", width:"92%", top:0, margin:"auto", marginTop:30, padding:"20px 0px 20px 0px"}}>
                                            <section style={{position:"relative", height:400, marginBottom:20, borderWidth:1, borderColor:"#dfdfdf", borderStyle:"solid", borderRadius:5}}>
                                                {!uresult.uid?'':<span style={{position:"absolute", top:-10, left:150, color:"#16a068", fontSize:"9pt", backgroundColor:"white", padding:"0px 10px 0px 10px"}}>USER #{uresult.uid.toUpperCase()} </span>} <br/>
                                                <section style={{margin:20}}>
                                                    <section style={{width:75, height:75, backgroundColor:"#d1d1d1", borderRadius:"50%"}}>
                                                        {!uresult.username?'':<span style={{position:"relative",top:12, left:90, color:"#16a068"}}>{uresult.username}</span>} <br/>
                                                        {!uresult.email?'':<span style={{position:"relative",top:12, left:90, color:"#16a068"}}>{uresult.email} </span>}
                                                    </section>
                                                    
                                                </section>
                                            </section>
                                        </section>
                                    </Fragment>}
                                
                            </section>    
                        </section>:''}
                        {activeFirst===true?
                        <section style={{backgroundColor:"rgba(20,22,105,0.0)", width:"100%", height:"100%"}}>
                            {!uresult.username || uresult.username === null?
                            <Fragment>
                            <section style={{position:"absolute", top:30, right:30, backgroundColor:"rgba(0,0,0,1.0)", textAlign:"center", width:193, height:162, borderRadius:7, boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                                <span style={{position:"relative", top:10, fontFamily:"Baloo", fontSize:"20pt", color:"white", cursor:"crosshair"}}>MAILBOX</span><br/>
                                <span className='init-activity-links-btn' onClick={()=>{setMailboxRead(true)}}>Unread</span><br/>
                                <span className='init-activity-links-btn' onClick={()=>{setMailboxComposer(true)}}>Compose</span><br/>
                                <span className='init-activity-links-btn' onClick={()=>{setMailboxDeleted(true)}}>Deleted</span>
                            </section>
                            </Fragment>
                            :<Fragment>
                                <section style={{position:"absolute", top:30, right:30, textAlign:"center",backgroundColor:"rgba(255,255,255,1.0)", width:193, height:150, borderRadius:7, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                                    <span style={{position:"absolute", color:"#16a068", top:16, left:50,fontFamily:"Baloo", fontSize:"18pt"}}>Activity</span> <br/>
                                    <span className='activity-links-btn'>Messages</span> <br/>
                                    <span className='activity-links-btn'>Logins</span><br/>
                                    <span className='activity-links-btn'>Deposits</span><br/>
                                </section>
                                <section style={{position:"absolute", top:215, right:30, textAlign:"center",backgroundColor:"rgba(255,255,255,1.0)", width:193, height:150, borderRadius:7, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                                    <span style={{position:"relative", color:"#16a068", top:16, fontFamily:"Baloo", fontSize:"18pt"}}>Files Added</span> <br/>
                                    <span className='activity-links-btn' style={{top:15}}>Images</span> <br/>
                                    <span className='activity-links-btn' style={{top:15}}>Docs</span><br/>
                                    <span className='activity-links-btn' style={{top:15}}>PDF</span><br/>
                                </section>
                            </Fragment>}
                        </section>:''}
                        {activeSecond===true?
                        <section className='project-listing-space'>
                            <section className='project-lists'>
                                <section style={{position:"relative", width:"96%", margin:"auto", paddingTop:10}}>
                                    Web Traffic Reports
                                </section>
                            </section>
                        </section>:
                        ''}
                        {/*Project Listing Section*/}
                        {activeThird===true?
                        <section className='project-listing-space'>
                            <section className='project-lists'>
                                <section style={{position:"relative", width:"96%", margin:"auto", paddingTop:10}}>
                                    <ProjectListings />
                                </section>
                            </section>
                        </section>:
                        ''}
                        {activeFourth===true?
                        <section className='portfolio-viewer-space'>
                            <section className='portfolio-lists'>
                                <ServicesPortfolioPanel />
                            </section>
                        </section>:
                        ''}
                        {activeFifth===true?
                        <section className='services-viewer-space'>
                            <section className='internal-comms'>
                                <section style={{position:"relative", width:"96%", margin:"auto", paddingTop:20, paddingBottom:20}}>
                                    <InternalComms />
                                </section>
                            </section>
                        </section>:
                        ''}
                        {activeSixth===true?
                        <section className='services-viewer-space'>
                            <section className='inquiries-series'>
                                <section style={{position:"relative", width:"96%", margin:"auto", paddingTop:20, padding:20}}>
                                    <GuestAndOtherInquiries />
                                </section>
                            </section>
                        </section>:
                        ''}
                    </section> 
                </section>
                
            </Fragment>
        )
    }

    return(
        <Fragment>
            <section className='namesearchspace'>
                <svg style={{position:"relative", top:8, left:8}} width="615" height="29" viewBox="0 0 615 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.503662 5.72168C0.503662 2.96026 2.74224 0.72168 5.50366 0.72168H614.213L602.316 28.9727H5.50366C2.74224 28.9727 0.503662 26.7341 0.503662 23.9727V5.72168Z" fill="white"/>
                </svg>
                <input className='searchfield' type="text" placeholder='Search by Username, Email, etc' onChange={handleSearch('searched')}/>
                {!oDisplay?<button className='recordedBTN' style={{position:"absolute", top:10, right:33, backgroundColor:"#16A068", fontSize:"16pt", padding:"0px 20px 0px 20px", borderWidth:0, cursor:"pointer"}} onClick={(e)=>{e.preventDefault(); setODisplay(true); }}>Show All Users</button>:<button className='recordedBTN' style={{position:"absolute", top:10, right:33, backgroundColor:"#16A068", fontSize:"16pt", padding:"0px 20px 0px 20px", borderWidth:0}} onClick={(e)=>{e.preventDefault(); setActiveFirst(usersXView); setActiveThird(false); setActiveFourth(false); setActiveFifth(false); setODisplay(false);}}>Hide User Listing</button>}
            </section>
            <DashboardControlPanels uresult = {searched?searched:galItem[0]}/>
            {oDisplay === true?<DashboardOutputDisplay loggeduser={props.loggeduser} datascraped = {galItem} />:''}
            {/* {oDisplay===true?<DashboardOutputDisplay datascraped = {galItem} />:''} */}
            {mailboxComposer===true?
            <Dialog open={true} maxWidth="xl">
                <ThemeProvider theme={Jolme5DTheme}>
                    <section style={{position:"relative", width:"900px", height:"800px",  padding:50, zIndex:10}}>
                        <span style={{position:"absolute", top:10, right:100, fontSize:"1.5em", fontWeight:"bolder",  color:"#16A068"}}>COMPOSE A NEW EMAIL</span><br/><br/>
                        <Button variant='contained' style={{position:"absolute", right:10, top:10}} onClick={()=>{setMailboxComposer(false)}}>X</Button>
                        <section style={{position:"relative", padding:50}}>
                            <label></label><input onChange={handleFieldChange('targetemail')} placeholder="Email" style={{width:"100%", height:20, padding:10, fontSize:"1.2em"}} /><br/><br/>
                            <label></label><input onChange={handleFieldChange('subject')} placeholder="Subject" style={{width:"100%", height:20, padding:10, fontSize:"1.2em"}} /><br/><br/>
                            <textarea onChange={handleFieldChange('textbodyplain')} placeholder="Write your message..." style={{width:"100%", fontSize:"1.2em", padding:10}} rows={20} cols="100%"></textarea> <br/><br/>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                >
                                Attach file
                                <VisuallyHiddenInput type="file" />
                            </Button>
                            <Button onClick={()=>{setMailboxComposer(false)}} variant='contained' style={{position:"relative", float:"right", width:"20%", right:165, top:0}} >CANCEL</Button>
                            {mailerInfo.targetemail==='' || mailerInfo.subject==='' || mailerInfo.textbodyplain===''?
                            <Button style={{position:"relative", backgroundColor:"#000", color:"#fff",  float:"right", width:"20%", right:-160, top:0}}>SEND</Button> 
                            :<Button variant='contained' style={{position:"relative", float:"right", width:"20%", right:-160, top:0}} onClick={sendingEmail}>SEND</Button> }
                            
                        </section>
                    </section>
                </ThemeProvider>
            </Dialog>
            :''}
            {mailboxRead===true?
            <Dialog open={true} maxWidth="xl">
                <ThemeProvider theme={Jolme5DTheme}>
                    <section style={{position:"relative", width:"900px", height:"800px",  padding:"50px 50px 50px 50px", zIndex:10}}>
                        <span style={{fontSize:"1.5em", fontWeight:"bolder",  color:"#16A068"}}>ALL NEW EMAILS</span><br/><br/>
                        <Button variant='contained' style={{position:"absolute", right:5, top:5}} onClick={()=>{setMailboxRead(false)}}>CLOSE</Button>
                    </section>
                </ThemeProvider>
            </Dialog>
            :''}
            {mailboxDeleted===true?
            <Dialog open={true} maxWidth="xl">
                <ThemeProvider theme={Jolme5DTheme}>
                    <section style={{position:"relative", width:"900px", height:"800px",  padding:"50px 50px 50px 50px", zIndex:10}}>
                        <span style={{fontSize:"1.5em", fontWeight:"bolder",  color:"#16A068"}}>DELETED EMAILS</span><br/><br/>
                        <Button variant='contained' style={{position:"absolute", right:5, top:5}} onClick={()=>{setMailboxDeleted(false)}}>CLOSE</Button>
                    </section>
                </ThemeProvider>
            </Dialog>
            :''}
        </Fragment>
    )
}

export default DashboardSearchControl