//LIBRARIES
import React, {Fragment, useState, useRef, useEffect} from 'react'
import { useHistory } from 'react-router'
import {emptyReduxInitialState} from '../../../redux/slices/counter'
import { viewUser, proPicView } from '../../../redux/slices/counter'
import { stringify } from 'querystring'
import { Widget } from '@uploadcare/react-widget'
import { useSelector, useDispatch } from 'react-redux'

//COOKIES
import {destroyMongoCollaborator} from '../auth/auth'

//MUI
import { Button } from '@mui/material/'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'

//COMPONENTS
import { 
    DEPO_MONCL, DEPO_MONCW,
    GUE_TOCLIL, GUE_TOCLIW,
    UPDUX_APIL, UPDUX_APIW,
    PESODEP_CL, PESODEP_CW
    } from '../../../config'

import { currPrimary, currBalanceView, viewOrder, defaultCurrBalance} from '../../../redux/slices/counter'
import { pitch,
    clientPaymongoUpdateBalanceLocal,
    clientPaymongoUpdateBalanceWeb
 } from '../variables'
import {
    BUDALOC_CL, 
    UPPHOTOL, UPPHOTOW,
    LOGOUTER_BQW, LOGOUTER_BQL,
    FIRSTDEPO_CL, FIRSTDEPO_CW,
    DELEACC_CL, DELEACC_CW,
    UPDACCUNAME_CL, UPDACCUNAME_CW,
    UPDACCEMAIL_CL, UPDACCEMAIL_CW
    } from '../../../config'
    

//APPLICATION
const j5dtheme = createTheme({
    palette: {
      primary: {
        main: '#16a068',
      }
    },
  })
const ClientDepositMongoSuccessPage = (props)=>{
    const dispatch = useDispatch()
    const historyBuff = useHistory()
    const paidAmount = localStorage.getItem("mongo_deposit")
    const collab =JSON.parse(paidAmount)
     
    const bamount = useSelector(state => state.counter.currBalance)
    const primeCurrency = useSelector(state => state.counter.currFiat)
    const currencyFiat = useSelector(state => state.counter.currFiat)
    const details = useSelector(state => state.counter.userDetails)

    const currencyPrimary = ()=>{
        setInterval(()=>{
            dispatch(currPrimary())
            dispatch(viewUser())
            
        },1000)
    }
    currencyPrimary()
    const depositBalanceSwitcher = (pitch)=>{
        if(pitch===true){
            return clientPaymongoUpdateBalanceWeb
        }else{
            return clientPaymongoUpdateBalanceLocal
        }
    }
    const addDepositAmount = async(params)=>{
        //console.log(PESODEP_CW)
        const changeRole = await fetch(depositBalanceSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(params)
        })
        const depositResponse = await changeRole.json()
        const {status, data} = depositResponse
        if(!data){
            console.log("Error Found")
        }
    }

    let numpy = collab.mongoDeposit //<-- Converting Figure for System account balance update
    let numpy2 = numpy.toString()
    let numpy3 = numpy2.split("")
    numpy3.reverse()
    numpy3.splice(2,0,".") 
    numpy3.reverse()
    let fnum = parseFloat(numpy3.join(""))
    const addedUpBalance = parseFloat(fnum)+parseFloat(bamount) //<--Ends

    addDepositAmount({userUniqueID:details.userUniqueID, currentDeposit:parseFloat(fnum), balanceTotal:addedUpBalance})
    useEffect(()=>{
        destroyMongoCollaborator()
    },[])
    return(
        <Fragment>
            <section className='updater-profile-space' onMouseOver={()=>{
                    //update DATABASE record about Client's Balance
                    
                }}>
                    <section style={{position:"relative", margin:"auto", left:"auto", width:450, height:30, top:-45, textAlign:"center", backgroundColor:"white"}}>
                            <h1 style={{width:"100%", color:"#16a068", padding:"10px 10px 10px 10px"}}>Payment Has Just Been Made via Paymongo</h1>
                    </section>
                    <section style={{position:"relative", margin:"auto", left:"auto", width:700, height:250, marginBottom:40, top:-45, textAlign:"center"}}>
                        <h1 style={{fontSize:"22pt"}}>Thank you, {details.username}!</h1>
                        <h1 style={{fontSize:"33pt"}}><span>You just made a payment.</span></h1> 
                        <h1 style={{position:"relative", margin:"auto", width:400}}>Check your Account Wallet to check whether the recent deposit reflects or not.</h1><br/><br/>
                        <ThemeProvider theme={j5dtheme}>
                            <Button variant='contained' color='primary' onClick={()=>{
                                setTimeout(()=>{
                                    historyBuff.push('/dashboard');
                                    window.location.reload();
                                }, 200)
                                //refresh
                            }}>GO BACK TO DASHBOARD</Button>
                        </ThemeProvider>
                    </section><br/>
            </section>
        </Fragment>
    )
}

export default ClientDepositMongoSuccessPage