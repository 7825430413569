//LIBRARIES
import React, {Fragment, useState, useRef} from 'react'
import { useHistory } from 'react-router'
import {emptyReduxInitialState} from '../../../redux/slices/counter'
import { viewUser, proPicView } from '../../../redux/slices/counter'
import { stringify } from 'querystring'
import { Widget } from '@uploadcare/react-widget'
import {destroyPaypalCollaborator} from '../auth/auth'
import { useSelector, useDispatch } from 'react-redux'

//MUI
import { Button } from '@mui/material/'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'

//COMPONENTS
import { 
    DEPO_MONCL, DEPO_MONCW
    } from '../../../config'

import { currPrimary, currBalanceView, viewOrder, defaultCurrBalance} from '../../../redux/slices/counter'
import { pitch } from '../variables'
import {
    BUDALOC_CL, 
    UPPHOTOL, UPPHOTOW,
    LOGOUTER_BQW, LOGOUTER_BQL,
    FIRSTDEPO_CL, FIRSTDEPO_CW,
    DELEACC_CL, DELEACC_CW,
    UPDACCUNAME_CL, UPDACCUNAME_CW,
    UPDACCEMAIL_CL, UPDACCEMAIL_CW
    } from '../../../config'
    

//APPLICATION
const j5dtheme = createTheme({
    palette: {
      primary: {
        main: '#16a068',
      }
    },
  })
const ClientDepositPaypalSuccessPage = (props)=>{
    const {user} = props //as application matured, these will not be in use anymore
    const dispatch = useDispatch()
    const historyBuff = useHistory()
    const paidAmount = localStorage.getItem("pcollaborator")
    const collab =JSON.parse(paidAmount)
     
    const bamount = useSelector(state => state.counter.currBalance)
    const primeCurrency = useSelector(state => state.counter.currFiat)
    const currencyFiat = useSelector(state => state.counter.currFiat)
    const details = useSelector(state => state.counter.userDetails)

    const currencyPrimary = ()=>{
        setInterval(()=>{
            dispatch(currPrimary())
            dispatch(viewUser())
        },1000)
    }
    currencyPrimary()
    const depositBalanceSwitcher = (pitch)=>{
        if(pitch===true){
            return DEPO_MONCW
        }else{
            return DEPO_MONCL
        }
    }
    const addDepositAmount = async(params)=>{
        const changeRole = await fetch(depositBalanceSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(params)
        })
        const depositResponse = await changeRole.json()
        const {status} = depositResponse
        if(status === true){
            console.log(status)
        }else{
            console.log("Something is not right!")
        }
    }
    
    return(
        <Fragment>
            {!paidAmount || paidAmount===null || paidAmount === undefined?
            <section className='updater-profile-space'>
                <section style={{position:"relative", margin:"auto", left:"auto", width:400, height:30, top:-45, textAlign:"center", backgroundColor:"white"}}>
                        <h1 style={{color:"#16a068", padding:"10px 10px 10px 10px"}}>No Pending Paypal Payment</h1>
                </section>
                <section style={{position:"relative", margin:"auto", left:"auto", width:600, height:130, marginBottom:0, top:-45, textAlign:"center"}}>
                    <h1 style={{fontSize:"22pt"}}>{user}, You haven't made an attempt to pay through Paypal.</h1><br/><br/>
                    <ThemeProvider theme={j5dtheme}>
                        <Button variant='contained' color='primary' onClick={()=>{
                            historyBuff.push('/dashboard');
                        }}>GO BACK TO DASHBOARD</Button>
                    </ThemeProvider>
                </section><br/>
            </section>
            :
            <Fragment>
                {collab.amount===0?
                <section className='updater-profile-space'>
                    <section style={{position:"relative", margin:"auto", left:"auto", width:400, height:30, top:-45, textAlign:"center", backgroundColor:"white"}}>
                        <h1 style={{color:"#16a068", padding:"10px 10px 10px 10px"}}>No Pending Paypal Payment</h1>
                    </section>
                    <section style={{position:"relative", margin:"auto", left:"auto", width:600, height:130, marginBottom:0, top:-45, textAlign:"center"}}>
                        <h1 style={{fontSize:"22pt"}}>{user}, You haven't made an attempt to pay through Paypal.</h1><br/><br/>
                        <ThemeProvider theme={j5dtheme}>
                            <Button variant='contained' color='primary' onClick={()=>{
                                destroyPaypalCollaborator();
                                historyBuff.push('/dashboard');
                            }}>GO BACK TO DASHBOARD</Button>
                        </ThemeProvider>
                </section><br/>
                </section>
            :''
            }
            {collab.amount>0?
                <section className='updater-profile-space' onMouseOver={()=>{
                    //update DATABASE record about Client's Balance
                    const realAmount = collab.amount;
                    const addUpDepositAndExisting = parseFloat(realAmount)+parseFloat(bamount);
                    addDepositAmount({userUniqueID:details.userUniqueID, amount:addUpDepositAndExisting});
                    destroyPaypalCollaborator();
                }}>
                    <section style={{position:"relative", margin:"auto", left:"auto", width:400, height:30, top:-45, textAlign:"center", backgroundColor:"white"}}>
                            <h1 style={{color:"#16a068", padding:"10px 10px 10px 10px"}}>Payment Has Just Been Made to Paypal</h1>
                    </section>
                    <section style={{position:"relative", margin:"auto", left:"auto", width:700, height:250, marginBottom:40, top:-45, textAlign:"center"}}>
                        <h1 style={{fontSize:"22pt"}}>Thank you, {user}!</h1>
                        <h1 style={{fontSize:"33pt"}}>You just paid <span style={{color:"#16a068"}}>US${collab.amount}.00</span></h1> 
                        <h1 style={{position:"relative", margin:"auto", width:400}}>Please check your Paypal account or your email associated with Paypal for further confirmation.</h1><br/><br/>
                        <ThemeProvider theme={j5dtheme}>
                            <Button variant='contained' color='primary' onClick={()=>{
                                setTimeout(()=>{
                                    historyBuff.push('/dashboard');
                                    window.location.reload();
                                }, 200)
                                //refresh
                            }}>GO BACK TO DASHBOARD</Button>
                        </ThemeProvider>
                    </section><br/>
                </section>
                :''}
            </Fragment>
            }
            
            
        </Fragment>
    )
}

export default ClientDepositPaypalSuccessPage