//LIBRARIES
import React, {Fragment, useState} from 'react'

//MATERIAL UI
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

//COMPONENTS
import MobilePagesHeader from './mobilePagesHeader'
import MobileFooter from '../components/mobileFooter'
import MobileDashboardHeaderStrip from './mobileDashboardHeader'

//OPERATIONS
let MobileAboutUsPage = ()=>{
    const loggedman = JSON.parse(localStorage.getItem('j5djwt'))
    return(
        <Fragment>
            {!loggedman || loggedman === null?
            <MobilePagesHeader />
            :
            <MobileDashboardHeaderStrip user={loggedman.username} role={loggedman.role}/>
            }
            <div className="mobile-main-space">
                        <section style={{position:"relative", margin:"auto", left:-20, width:"100%"}}>
                        
                            <ul className='mobile_about_people'>  
                            <span style={{position:"relative", margin:"auto", left:80, width:"400px", fontSize:"18pt", textAlign:"center",fontWeight:"bold", fontFamily:"Baloo"}}>We are a cadre of<br/></span> 
                            <span style={{position:"relative", top:-15, margin:"auto", left:36, width:"100%", fontSize:"34pt", textAlign:"center",fontWeight:"bold", fontFamily:"Baloo"}}>Great Talents<br/></span> 
                                    <li>
                                        <div style={{width: "100%", margin:"auto", textAlign:"center"}}>
                                            <section style={{position:"relative", left:-20}}className="ceo">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Connie Suerte</span> <br/>CEO</h5>
                                        </div>    
                                    </li>    
                                    <li>
                                        <div style={{width: "100%", margin:"auto", textAlign:"center"}}>
                                            <section className="vice-ceo">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Jolme Limvilla</span><br/>COO</h5>
                                        </div>
                                    </li>   
                                    <li>
                                        <div style={{position:"relative",width: "100%", margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular1">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Mario Barabas</span><br/>Art Director</h5>
                                        </div>
                                    </li> 
                                    <li>
                                        <div style={{position:"relative",width: "50%", margin:"auto", textAlign:"center"}}>
                                            <section style={{position:"relative", left:-20}} className="profsingular2">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Jena Ompisa</span><br/>Lead Developer</h5>
                                        </div>
                                    </li> 
                                    <li>
                                        <div style={{position:"relative",width: "50%", margin:"auto", textAlign:"center"}}>
                                            <section style={{position:"relative", left:-20}} className="profsingular3">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Frank Gitlin</span><br/>Accounting</h5>
                                        </div>
                                    </li>   
                                    <li>
                                        <div style={{position:"relative",width: "50%", margin:"auto", textAlign:"center"}}>
                                            <section style={{position:"relative", left:-20}} className="profsingular4">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Dimple Subarin</span><br/>HR Head</h5>
                                        </div>
                                    </li>
                                    <li>
                                        <div style={{position:"relative",width: "50%", margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular5">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Dino Amparo</span><br/>Supply Officer</h5>
                                        </div>
                                    </li> 
                                    <li>
                                        <div style={{position:"relative",width: "50%", margin:"auto", textAlign:"center"}}>
                                            <section style={{position:"relative", left:-20}} className="profsingular6">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Shasha Mosa</span><br/>Marketing Head</h5>
                                        </div>
                                    </li> 
                                    <li>
                                        <div style={{position:"relative",width: "50%", margin:"auto", textAlign:"center"}}>
                                            <section style={{position:"relative", left:-20}} className="profsingular7">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Lev De Nostre</span><br/>Quality Control</h5>
                                        </div>
                                    </li>   
                                    <li>
                                        <div style={{position:"relative",width: "50%", margin:"auto", textAlign:"center"}}>
                                            <section style={{position:"relative", left:-20}} className="profsingular8">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Carly Conelio</span><br/>AfterSale</h5>
                                        </div>
                                    </li>                                                                                                                                                                                         
                            </ul>
                        </section>
                        {/* <h3 className="">Our Brief History</h3> */}
                    </div>
                    <MobileFooter />
        </Fragment>
    )
}

export default MobileAboutUsPage

