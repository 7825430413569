//LIBRARIES

//COMPONENTS
import {APIL1, APIL2, API1, API2} from '../../config'
import {accessSwitch} from '../components/variables'

//OPERATIONS
export class Logger{
    pitch = accessSwitch //true to Web-based MongoDB

    constructor(wholeOfUser){
        this.wholeInfo = wholeOfUser
    }
    switcher(bol){
        if(bol===true){
            return API1
        }else{
            return APIL1
        }
    }
    async signUpProcess(){
            const response = await fetch(this.switcher(this.pitch),{
                method:"POST",
                headers:{
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.wholeInfo)

            })
            const dataRetrieved = await response.json()
            const {status, userUID, username, profilePhoto, password, email, role, orders} = dataRetrieved //deconstruct server response

            return {status:status, userUID:userUID, username:username, password:password, profilePhoto:profilePhoto, email:email, role:role, orders:orders}
    }
    async loggerRoleSpotter(){
        this.signUpProcess()
            
    }   
}

export class LoggerInfo extends Logger{
    emitLoggerInfo(){
        return this.loggerRoleSpotter()
    }
}