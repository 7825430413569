//LIBRARIES
import React, {Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
//import { MDBSpinner } from 'mdb-react-ui-kit';

//COMPONENTS
import {SDAPIL, SDAPIW, PAAPIL, PAAPIW } from '../../../config'
import {accessSwitch} from '../variables'

//OPERATIONS
const PortfolioImages = ()=>{
    const [galItem, setGalItem] = useState([])

    const pitch = accessSwitch //true to Web-based MongoDB

    const switcher =(bol)=>{
        if(bol===true){
            return SDAPIW
        }else{
            return SDAPIL
        }
    }
    const [isProtruding, setIsProtruding] = useState(true)
    const servicesPingAPI = async()=>{
        setIsProtruding(true)
        await fetch(switcher(pitch)) //true to Web-based MongoDB
        .then((responsive)=>responsive.json())
        .then((data)=>{
            const wanton = data.data
            const drearies = []
            for(const key in wanton){
                drearies.push({
                    id:key,
                    title:wanton[key].title,
                    image: wanton[key].image,
                    gallery: wanton[key].gallery
                })
            }
            setGalItem(drearies)
        })
        setIsProtruding(false)
    }
    useEffect(()=>{
        servicesPingAPI()
    },[]) 

    const [isLoading, setIsLoading] = useState(false)
    const [dito, setDito] = useState([])
    useEffect(()=>{
        setIsLoading(true)
        fetch(switcher(pitch))
        .then((responsive)=>responsive.json())
        .then((data)=>{
            const duga = data.data
            const doughnut = []
            for(const key in duga){
                doughnut.push({
                    id:key,
                    title: duga[key].title
                })
            }
            setDito(doughnut)
        })
        setIsLoading(false)
    },[])
  
    const likeThisBTN = (e)=>{
        e.preventDefault()
    }

    const [oneImage, setOneImage] = useState(false)
    const clickImage = (e)=>{
        e.preventDefault()
        setOneImage(true)
    }
    const [gaImage, setGaImage] = useState('')
    const [gaCaption, setGaCaption] = useState('')
    console.log("image: "+ gaImage)
    const PortfolioFloater = ()=>{
        return(
            <Fragment>
                <ul>
                    <div className="floating-gallery">
                        <div onClick={()=>{setOneImage(false); setGaImage(''); setGaCaption('')}} style={{position:"absolute", width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}></div>
                        <div style={{position:"relative",marginLeft:"auto",marginRight:"auto", marginTop:60, backgroundColor:"#f1f1f1", width:"98%", backgroundColor:"rgba(0,0,0,0.1)"}} >
                            {<img width="100%" src={gaImage.replace('www.dropbox.com','dl.dropboxusercontent.com')}/>}
                            <div style={{position:"absolute", opacity: 0.8, backgroundColor:"black", width:"100%", color:"white", bottom:0, padding:"15px 0px 15px 0px", textAlign:"center"}}>
                                <p style={{textAlign:"center", width:"100%"}}>{gaCaption}</p>
                            </div>
                            <button style={{position:"absolute", top:0, right:0, borderWidth:"0px", padding:10}} onClick={()=>{setOneImage(false); setGaImage(''); setGaCaption('')}}>Close</button>
                        </div> 
                    </div>     
                </ul>
            </Fragment>    
            
        )
    }
    return(
        <Fragment>
            {isProtruding?<div class="spin-container">
                            <div class="spin" id="loader"></div>
                            <div class="spin" id="loader2"></div>
                            <div class="spin" id="loader3"></div>
                            <div class="spin" id="loader4"></div>
                            <span id="text">Images Loading...</span>
                        </div>:<section name="portfolio-top" style={{position:"relative", backgroundColor:"none", margin:"auto", left:"auto", width:"100%"}}>
                <ul style={{position:"relative", width: "80%", margin:"auto", left:"auto"}}>
                    {galItem.map((portfors)=>(
                        (<li style={{position:"relative", left:-20, display:"block", margin:"auto", textAlign:"center"}}>
                                                    <h3 style={{fontFamily:"Baloo", fontSize:"22pt"}}>{portfors.title}</h3>
                                                <div style={{position:"relative", top:0, margin:"auto", left:"auto"}}>
                                                <button style={{position:"relative", top:-30, width:"70%", backgroundColor:"#16a068", borderWidth:0, borderRadius:20, padding:"10px 10px 10px 10px", color:"rgba(0,0,0, 0.7)", fontWeight:"bold"}}>Make Order</button>
                                                {portfors.gallery.map((sort)=>{
                                                    return <li style={{position:"relative",margin:"auto",left:"auto", width:"100%", display:"block"}}>
                                                                {sort.image.lenght===0?'':<Link >
                                                                    <img onClick={clickImage} onMouseOver={
                                                                    (e)=>{e.preventDefault();
                                                                        setGaImage(sort.image.replace('www.dropbox.com','dl.dropboxusercontent.com'))
                                                                        setGaCaption(sort.caption)
                                                                        console.log(sort.caption)
                                                                    }}  
                                                                    style={{position:"relative", width:"80%", margin:"auto", paddingBottom:20}} src={sort.image.replace('www.dropbox.com','dl.dropboxusercontent.com')}/>
                                                                </Link>}
                                                                
                                                                {oneImage?<PortfolioFloater/>:''}
                                                                
                                                            </li>
                                                })}
                                            </div>   
                        </li>)
                    ))}
                </ul>
            </section>}
            
        </Fragment>
    )
}

export default PortfolioImages