//LIBRARIES
import React, {Fragment, useState, useEffect} from 'react'
import {orderen, querriedData} from '../../../redux/querries/userdataquery'
import { Link, useHistory } from 'react-router-dom'

//COMPONENTS
import {accessSwitch} from '../variables'
import {
    ADUX_ALLAPIL, 
    ADUX_ALLAPIW, 
    SDAPIL, 
    SDAPIW
} from '../../../config'

//OPERATIONS
const ClientCompletedListing = (props)=>{
    const {user, userid} = props
    const historian = useHistory()
    const [proToggler, setProToggler] = useState(true)

    //const [corders, setCorders] = useState(null)
    const [orders]= orderen

    const [userItem, setUserItem] = useState([])
    const [ordersItem, setOrdersItem] = useState([])
    const [isGashing, setIsGashing] = useState(true)
    const pitch = accessSwitch //true to Web-based MongoDB
    const aduxSwitcher =(bol)=>{
        if(bol===true){
            return ADUX_ALLAPIW
        }else{
            return ADUX_ALLAPIL
        }
    }
    const switcher =(bol)=>{
        if(bol===true){
            return SDAPIW
        }else{
            return SDAPIL
        }
}
    const ordersLanded = async()=>{
        await fetch(aduxSwitcher(pitch)) //true to Web-based MongoDB
        .then((responsive)=>responsive.json())
        .then((data)=>{
            const wanton = data.data
            const drearies = []
            for(const key in wanton){
                drearies.push({
                    id:key,
                    uid:wanton[key].userUniqueID,
                    username: wanton[key].username,
                    email: wanton[key].email,
                    role: wanton[key].role,
                    dateSince: wanton[key].dateCreated,
                    orders:wanton[key].orders, 
                    payments:wanton[key].paymentSources,
                    orders:wanton[key].orders
                })
            }
            setUserItem(drearies)
        })
    }
    useEffect(()=>{
        setTimeout(handleSmallImage(), 1000)
        ordersLanded()
    },[]) 
    
    //SERVICES LIST LOADING
    const [dito, setDito] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const servicesPingAPI = async()=>{
        setIsLoading(true)
            await fetch(switcher(pitch)) //switcher(pitch) //'https://webbyjolme.com/api/j5d-servicesdisplay/'
            .then((responder)=>responder.json())
            .then((data)=>{
                const duga = data.data
                const doughnut = []
                for(const key in duga){
                    doughnut.push({
                        id:key,
                        title: duga[key].title,
                        price: duga[key].price,
                        description: duga[key].description,
                        imageLarge: duga[key].imageLarge,
                        imageSmall: duga[key].imageSmall,
                        tags: duga[key].tags
                    })
                }
                setDito(doughnut)
            })
            setIsLoading(false)
    } 
    const handleSmallImage = ()=>{
        servicesPingAPI()
    }
    console.log(dito)
    return(
        <Fragment>
            {proToggler===true?<section className='client-dash'>
                <button onClick={()=>{setProToggler(false)}} style={{position:"absolute", right:20, marginTop:8, backgroundColor:"#16a068", borderWidth:0, padding:"5px 15px 5px 15px", borderRadius:25, color:"white", cursor:"pointer"}}>Minimize</button>
                <span style={{position:"absolute", left:30, marginTop:8, fontSize:"12pt", fontWeight:"bold", color:"#000", textAlign:"center"}}>COMPLETED ASSIGNMENTS</span><br/><br/>
                {userItem.map(euser=>{
                        return(
                            <Fragment>
                                {euser.orders.map(order=>{
                                    if(order.handledBy === user && order.status === 'completed'){
                                        return(
                                            <Fragment>
                                                <section className='project-single'>
                                                    <ul className='single-spanned'>
                                                        <li>
                                                            {dito.map(omg=>{
                                                                    if(omg.title === order.categoryChosen){
                                                                        //src={imageSSrc} 
                                                                        let imageSSrc = omg.imageLarge.replace('www.dropbox.com','dl.dropboxusercontent.com')
                                                                        let roestyle = {
                                                                                position:"absolute",
                                                                                height:72,
                                                                                clip: "rect(15px,72px,72px,15px)"
                                                                        }
                                                                        return(
                                                                            <Fragment>
                                                                                <div style={{position:"relative", width:72}}>
                                                                                    <img src = {imageSSrc} style={roestyle} />
                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                            })}
                                                        </li>
                                                        <li>
                                                            <div style={{position:"relative", width:280, left:100}}>
                                                                <h3 style={{position:"relative", top:0, left:0}}>{order.categoryChosen}</h3>
                                                                <span style={{position:"relative", top:-20, left:0, fontSize:"9pt", color:"#16a068"}}>{order.progress}% Complete</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <h3>Order By</h3>
                                                                <span style={{position:"relative", top:-20, fontSize:"9pt", color:"#16a068"}}>{euser.username}</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button className='view-client-project-btn' onClick={()=>{historian.push(`/dashboard/project/${order.orderID}`)}}>
                                                                <span>Preview</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className='update-project-status-btn'>
                                                                <span>Rate Client Interaction</span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </section>
                                            </Fragment>
                                        ) 
                                    }
                                })}
                            </Fragment>
                        )       
                    })}
            </section>:<section onClick={()=>{setProToggler(true)}} className='client-prev-projects'>
                        <span style={{position:"relative", fontSize:"12pt", color:"#c1c1c1", textAlign:"center"}}>Completed Projects</span>
            </section>}
        </Fragment>
    )
}

export default ClientCompletedListing