//LIBRARIES
import { current } from '@reduxjs/toolkit'
import { Fragment } from 'react'
import {React, useState, useRef} from 'react'
import axios from 'axios'
import { useHistory } from 'react-router'

//COMPONENTS
import { pitch } from '../../screens/components/variables'
import { 
    GUE_TOCLIL, GUE_TOCLIW,
    UPDUX_APIL, UPDUX_APIW
    } from '../../../src/config'

//COOKIES
import {mongoCollaborate} from './auth/auth'

//MUI

//OPERATIONS
const MobileMongoDepositProgressPage = (props)=>{
    const historian = useHistory()
    //STEP#1: Creating a Payment INTENT from the server-side
    const [depoValue, setDepoValue] = useState({amount:100})
    const [payments, setPayments] = useState([])
    const [dataid, setDataid] = useState('') //Intent ID
    const intentSwitcher = (pitch)=>{
        if(pitch===true){
            return "https://webbyjolme.com/api/paymongo/new-intent"
        }else{
            return "http://localhost:8888/api/paymongo/new-intent"
        }
    }
    const pingMongoIntent = async(deposit)=>{
        //console.log(deposit)
        const pinger = await fetch(intentSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({amount:deposit})
        })
        const listenPinger = await pinger.json()
        const {data} = listenPinger
        if(data){
            setPayments([data][0].data.attributes.payment_method_allowed)
            setDataid([data][0].data.id)
        }else{
            console.log("Failed to ping!")
        }
    }
    const listenAmount = (amount)=>(event)=>{
        setDepoValue({...depoValue, [amount]:event.target.value})
    }
    const triggerIntent = (e)=>{
        e.preventDefault()
         setTimeout(()=>{
            let paddedNum = depoValue.amount.toString()+"00"
            pingMongoIntent(parseFloat(paddedNum))
        }, 600)
        
    }
    
    //STEP#2: Create a PAYMENT METHOD from the client-side
    const [rePaymentID, setRePaymentID] = useState('') //Payment ID
    const [payData, setPayData] = useState({
        name:'',
        email:'',
        phone: 0,
        paytype:''
    })
    const paytypeSwitcher = (pitch)=>{
        if(pitch===true){
            return "https://webbyjolme.com/api/paymongo/payment-method-id"
        }else{
            return "http://localhost:8888/api/paymongo/payment-method-id"
        }    
    }
    const pingMongoPaymentmethod = async(paymentData)=>{
        const getPaymentID = await fetch(paytypeSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(paymentData)
        })
        const listenPID = await getPaymentID.json()
        const {data} = listenPID
        if(data){
            console.log([data][0].data)
            setRePaymentID([data][0].data.id)
            attachMongoIntentAndPaymentIds({intentid:dataid, paymentid:[data][0].data.id})
        }
        
    }
    const listenData = name=>event=>{
        setPayData({...payData, [name]:event.target.value})
    }
    const submitData = (e)=>{
        e.preventDefault()
        pingMongoPaymentmethod(payData)
    }
    //STEP#3: ATTACH the Payment Intent to the Payment Method
    const mongoIDSwitcher = (pitch)=>{
        if(pitch===true){
            return "https://webbyjolme.com/api/paymongo/paymentid-attach-tointent"
        }else{
            return "http://localhost:8888/api/paymongo/paymentid-attach-tointent"
        }    
    }
    const [gcashPage, setGcashPage] = useState('')
    const attachMongoIntentAndPaymentIds = async(ids)=>{
        console.log("IID: "+ids.intentid)
        console.log("PID: "+ids.paymentid)
        const attachMongoIDs = await fetch(mongoIDSwitcher(pitch), {
            method:"POST",
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(ids)
        })
        const respondedIDsQuery = await attachMongoIDs.json()
        const {data} = respondedIDsQuery
        if(data){
            console.log([data][0].data)
            setGcashPage([data][0].data.attributes.next_action.redirect.url) //STEP#4, Redirect URL in an <iFrame />
            setInterval(()=>{
                getListFromPaymongo(dataid)
            }, 1500) //Initiating STEP#5
        }
    }
    //STEP#5, FINISH

    const depositUrlSwitcher =(bol)=>{
        if(bol===true){
            return 'https://salty-inlet-81949.herokuapp.com/api/paymongo/list' //GUESTAT_MSW
        }else{
            return 'http://localhost:8888/api/paymongo/list' //GUESTAT_MSL
        }
    }
    const getListFromPaymongo = async(pi_id)=>{
        const paymongoRecord = await fetch(depositUrlSwitcher(pitch), {
          method:"GET",
          headers:{"Content-Type":"application/json"}
        })
        const defcon = await paymongoRecord.json()
        const {data} = await defcon
        //console.log([data][0].data)
        
        let marder = []
        let depAmount = 0
        setTimeout(()=>{
            [data][0].data.map(item=>{
                marder.push(item.attributes.payment_intent_id)
                if(item.attributes.payment_intent_id === pi_id){
                    depAmount += item.attributes.amount
                }
            })
        },500)
        console.log(marder)
        setTimeout(()=>{
            if(marder.includes(pi_id)){
                mongoCollaborate({mongoDeposit:depAmount})
                //updating database records
                const depositBalanceSwitcher = (pitch)=>{
                    if(pitch===true){
                        return 'https://salty-inlet-81949.herokuapp.com/api/j5d-client-peso-deposit-update/' //PESODEP_CW
                    }else{
                        return 'http://localhost:8888/api/j5d-client-peso-deposit-update/' //PESODEP_CL
                    }
                }
                const braintLQualifySwitcher = (pitch)=>{
                    if(pitch===true){
                        return GUE_TOCLIW
                    }else{
                        return GUE_TOCLIL
                    }
                }
                const braintRoleSwitcher = (pitch)=>{
                    if(pitch===true){
                        return UPDUX_APIW
                    }else{
                        return UPDUX_APIL
                    }
                }
                const changeGuestRole = async(uid, role)=>{
                    const changeRole = await fetch(braintRoleSwitcher(pitch), {
                        method:"POST",
                        headers:{"Content-Type":"application/json"},
                        body:JSON.stringify({uid:uid, newrole:role})
                    })
                    const rulingResponse = await changeRole.json()
                    const {status} = rulingResponse
                    if(status === true){
                        console.log("Just changed the Role as Client!")
                    }
                }
                const addQualifiedRole = async(uid)=>{
                    const addLastQualification = await fetch(braintLQualifySwitcher(pitch), {
                        method:"POST",
                        headers:{"Content-Type":"application/json"},
                        body:JSON.stringify({userUniqueID:uid})
                    })
                    const qualifyingResponse = await addLastQualification.json()
                    const {status} = qualifyingResponse
                    if(status==="Success"){
                        const clientele = 'client'
                        changeGuestRole(uid, clientele)
                        console.log("Writing Client On!")
                    }else{
                        console.log("Nothing is off the table!")
                    }
                }
                const addDepositAmount = async(params)=>{
                    //console.log(PESODEP_CW)
                    const changeRole = await fetch(depositBalanceSwitcher(pitch), {
                        method:"POST",
                        headers:{"Content-Type":"application/json"},
                        body:JSON.stringify(params)
                    })
                    const depositResponse = await changeRole.json()
                    const {status, data} = depositResponse
                    if(!data){
                        console.log("Error Found")
                    }
                    addQualifiedRole(props.uid)
                }
                const realAmount = 100
                const addUpDeposit = parseFloat(realAmount)
                addDepositAmount({userUniqueID:props.uid, amount:addUpDeposit})
                setTimeout(()=>{
                    historian.push("/dashboard/mongo")
                }, 800)
            }else{
                return
            }
        }, 500)
    }

    return(
        <Fragment>
            <section style={{position:"relative", width:"100%", height:"100%"}}>
                {rePaymentID.length===0?
                <Fragment>
                    {!dataid?
                        <Fragment>
                            <span style={{color:"#16a068", fontWeight:"bold", fontSize:"14pt"}}>Membership Fee</span> <br/><br/>
                            <form>
                                <input style={{marginBottom:10, height:30,  width:"50%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenAmount('amount')} placeholder="₱100.00" value="₱100.00" /><br/><br/>
                                <input onClick={triggerIntent} type="button" value="Continue" className="mongo_btn" style={{borderRadius:30}}/>
                            </form>
                        </Fragment>
                        :
                        <Fragment>
                            <section style={{margin:"auto", width:"90%", textAlign:"center"}}>
                                <h1 style={{fontSize:"13pt",color:"#16a068"}}>Fill up billing informations</h1>
                                <form style={{margin:"auto", width:"100%"}}>
                                    <input style={{marginBottom:10, height:30,  width:"100%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenData('name')} type="text" placeholder="Name"/><br/>
                                    <input style={{marginBottom:10, height:30,  width:"100%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenData('email')} type="text" placeholder="Email"/><br/>
                                    <input style={{marginBottom:10, height:30,  width:"100%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenData('phone')} type="number" placeholder="Phone"/><br/>
                                    <select style={{marginBottom:10, height:30,  width:"102%", fontSize:"12pt", borderColor:"#16a068", borderWidth:1, borderStyle:"solid"}} onChange={listenData('paytype')}>
                                        <option>PAY OPTIONS</option>
                                        {payments.map((item)=>{
                                            return(
                                                <option value={item}>{item.toUpperCase()}</option>
                                            )
                                        })}
                                    </select><br/>
                                    <br/>
                                    {payData.name.length>5 && payData.email.includes('@') && payData.phone.length>10 && payData.paytype!==''?
                                    <input className="mongo_btn" type="button" style={{width:"100%", borderRadius:25}} value="CONTINUE" onClick={submitData} />
                                    :
                                    <input className="mongo_btn_dead" type="button" style={{width:"100%", borderRadius:25}} value="CONTINUE"/>
                                    }
                                </form>
                            </section>
                            <section style={{position:"relative", width:"90%", marginTop:20, marginBottom:15, padding:"0px 12px 0px 12px", textAlign:"center"}}>
                                <span style={{fontSize:"9pt"}}>
                                    Note: JOLME5D, Inc. will not be storing the information you entered above. <a href="https://www.paymongo.com">Paymongo</a> a Philippine Central Back regulated entity is the one that stores these financial information. 
                                </span>
                            </section>
                        </Fragment>
                        }
                </Fragment>
                :
                <Fragment>
                    <section style={{marginTop:20}}>
                        <iframe style={{width:"96%", height:380, borderWidth:0}} src={gcashPage} title="Gcash Payment Gateway"></iframe>
                    </section>
                </Fragment>
                }
            </section>
        </Fragment>
    )
}

export default MobileMongoDepositProgressPage