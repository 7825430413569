//LIBRARY
import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, useHistory } from 'react-router-dom'
import Axios from 'axios';

//COMPONENTS
import {accessSwitch,
    passwordResetterLinkLocal,
    passwordResetterLinkWeb
} from '../components/variables'
import DeskHeader from './deskHeader';
import DeskFooter from './deskFooter';
import {APIL1, APIL2, API1, API2} from '../../config'
import {ADUX_ALLAPIL, ADUX_ALLAPIW} from '../../config'
import DefaultLoginStateContext from '../../controllers/loginContext'

const DeskPasswordResetPage = ({setToken})=>{
    const historian = useHistory()

    //Step#1 Check email address if it existed or not
    const [galItem, setGalItem] = useState([])
    const pitch = accessSwitch //true to Web-based MongoDB
    const switcher =(bol)=>{
        if(bol===true){
            return ADUX_ALLAPIW
        }else{
            return ADUX_ALLAPIL
        }
    }
    const usersListAPI = async()=>{
        await fetch(switcher(pitch)) //true to Web-based MongoDB
        .then((responsive)=>responsive.json())
        .then((data)=>{
            const wanton = data.data
            const drearies = []
            for(const key in wanton){
                drearies.push({
                    id:key,
                    uid:wanton[key].userUniqueID,
                    username: wanton[key].username,
                    email: wanton[key].email,
                    role: wanton[key].role,
                    dateSince: wanton[key].dateCreated,
                    orders:wanton[key].orders, 
                    payments:wanton[key].paymentSources
                })
            }
            setGalItem(drearies)
        })
    }
    useEffect(()=>{
        usersListAPI()
    },[])

    // if(errorLoggingFound.loggerIn === false){

    // }
    //RESET THROUGH EMAIL
    const [clientEmail, setClientEmail] = useState({email:''})
    const [passwordReq, setPasswordReq] = useState(false)
    const [passwordResetError, setPasswordResetError] = useState(false)
    const [newResetId, setNewResetId] = useState('')
    //Step#2 Create reset ID with expiry 
    // const getPasswordResetID = async(client)=>{
    //         const pipedMail = await fetch('http://localhost:8888/api/j5d-passwordresetid', {
    //           method:"POST",
    //           headers:{"Content-Type":"application/json"},
    //           body: JSON.stringify(client)
    //         })
    //         const parcel = await pipedMail.json()
    //         const {status, resetid} = parcel
    //         if(status === "success"){
    //             setNewResetId(resetid)
    //         }else{
    //             setPasswordResetError(true)
    //         }
    // }

    //Step#3 Send email message to the retrieving client
    const [resetIDOk, setResetIDOk] = useState(false)
    const pwresetterswitcher =(bol)=>{
        if(bol===true){
            return passwordResetterLinkWeb
        }else{
            return passwordResetterLinkLocal
        }
    }
    const sendEmailToResetPassword = async(mailer)=>{
        console.log(mailer)
        const pipedMail = await fetch(pwresetterswitcher(pitch), {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(mailer)
        })
        const parcel = await pipedMail.json()
        const {status, data} = parcel
        if(data === "success"){
            setPasswordReq(true)
            setResetIDOk(true)
        }else{
            setPasswordResetError(true)
        }
      }
    const catchClientEmail = etc=>event=>{
        setClientEmail({...clientEmail, [etc]:event.target.value})
    }
    let emaillist = []
    const resetPasswordRequest = (e)=>{
        e.preventDefault()
        galItem.map((item)=>{
            emaillist.push(item.email)
        })
        //console.log(emaillist)
        if (emaillist.includes(clientEmail.email)){
            sendEmailToResetPassword({clientEmail:clientEmail}) //#3, sending email
        }else{
            console.log("NEIN")
            setPasswordResetError(true)
        }
    }
    return(
        <Fragment>
            <div id="MAIN-BODY">
                <DeskHeader />
                <div id="MAIN-DASHBOARD">
                    <div className="error_login_notice" style={{color:'#19CB5B', textAlign:'center'}}>
                        <h1 style={{textAlign:'center', fontFamily:"Baloo", fontSize:"30pt"}}>Password Reset</h1>
                        <p>There is no way we ever know your password.<br/> You have to create a new one.</p> <br/>
                        <section className="error_crendential">
                            <input style={{textAlign:"center"}} onChange={catchClientEmail('email')} className="email_retriever" name="email_retriever" placeholder="Enter Email Address"/><br/><br/>
                            <b>We'll send a reset link to this email address</b> 
                        </section>
                         <br/><br/>  
                         {passwordReq===false?
                         <button className="error_back_btn" onClick={resetPasswordRequest}>Password Reset</button>
                         :''}
                         {resetIDOk===true?
                         <button className="error_back_btn" onClick={()=>{historian.push("/")}}>Link Sent. Check Your Email!</button>
                         :''}   
                         {passwordResetError===true?
                         <button className="error_back_btn" onClick={()=>{window.location.reload()}}>Failed! Try again</button>
                         :''}                  
                        
                    </div> <br/><br/><br/><br/>
                </div>
                <DeskFooter />
            </div>
        </Fragment>
    )
}
DeskPasswordResetPage.propTypes = {
    setToken: PropTypes.func.isRequired
}
export default DeskPasswordResetPage;