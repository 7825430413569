export const authenticate = (data, next)=>{
    if(typeof window !== 'undefined'){
        var locust = localStorage.setItem('j5djwt', JSON.stringify(data))
        next()
    }
    
}

export const paypalCollaborate = (data)=>{
    if(typeof window !== 'undefined'){
        var locust = localStorage.setItem('pcollaborator', JSON.stringify(data))
    }
    
}

export const destroyPaypalCollaborator = ()=>{
    if(typeof window !== 'undefined'){
        var locust = localStorage.removeItem("pcollaborator")
    }
    
}

export const guestIntroLocker = (data)=>{
    if(typeof window !== 'undefined'){
        var locust = localStorage.setItem('intro_locker', JSON.stringify(data))
    }
    
}

export const mongoCollaborate = (data)=>{
    if(typeof window !== 'undefined'){
        var locust = localStorage.setItem('mongo_deposit', JSON.stringify(data))
    }  
}

export const destroyMongoCollaborator = ()=>{
    if(typeof window !== 'undefined'){
        var locust = localStorage.removeItem("mongo_deposit")
    }
    
}
