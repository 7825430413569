//LIBRARIES
import React, {Fragment, useEffect, useState, useContext, useRef} from 'react'
import Popup from 'reactjs-popup'
import { Widget } from '@uploadcare/react-widget' //missing found

//COMPONENTS
import { SDAPIL, SDAPIW } from '../../config'
import { 
    OAPIL,
    OAPIW 
} from '../../config' //missing found
import { accessSwitch } from './variables'
import DefaultLoginStateContext from '../../controllers/loginContext'

//OPERATIONS
const ServicesSearchResults = (props)=>{
    const {searches} = props
    const itemEntry = searches.toLowerCase()

    const [isLoading, setIsLoading] = useState(true)
    const [dito, setDito] = useState([])

    const pitch = accessSwitch //true to Web-based MongoDB

    const switcher =(bol)=>{
        if(bol===true){
            return SDAPIW
        }else{
            return SDAPIL
        }
    }
    const servicesPingAPI = async()=>{
        setIsLoading(true)
        await fetch(switcher(pitch))
        .then((responder)=>responder.json())
        .then((data)=>{
            const duga = data.data
            const doughnut = []
            for(const key in duga){
                doughnut.push({
                    id:key,
                    title: duga[key].title,
                    price: duga[key].price,
                    description: duga[key].description,
                    imageLarge: duga[key].imageLarge,
                    tags:duga[key].tags
                })
            }
            setDito(doughnut)
            setIsLoading(false)
        })
    }
    useEffect(async()=>{
        await servicesPingAPI()
    },[])

    const triggerHappy = (e)=>{
        e.preventDefault()
        setFloatOver(true)
      }
      const triggerSadly = (e)=>{
        e.preventDefault()
        setFloatOver(false)
      }

    const [floatOver, setFloatOver] = useState(false)
    
    const [projectTitle, setProjectTitle] = useState('') 
    const [projectPrice, setProjectPrice] = useState('')
    const [projectPriceInDollar, setProjectPriceInDollar] = useState('')

    const loggedRecord = useContext(DefaultLoginStateContext)
    const currentUser= loggedRecord.username

    const QuoteForm = (props)=>{
        //const loggedRecord = useContext(DefaultLoginStateContext)
        const localToken = JSON.parse(localStorage.getItem('j5djwt'))
        const currentUrole = ()=>{  //used whether to allow Guest or not to place another order
            if(!localToken){
                return ''
            }else{
                return localToken.role
            }
        }
        const currentUser= ()=>{
            if(!localToken){
                return 'Guest'
            }else{
                return localToken.username
            }
        }
        const {situation} = props
    
        const [fileUploaded, setFileUploaded] = useState('')//client's uploaded image url

        const [orderSuccessFloater, setOrderSuccessFloater] = useState('Failure!') //missing found
        const [orderData, setOrderData] = useState({
            status:'',
            username:'',
            email:'',
            password:'',
            error:''
        }) //missing found

        const switcher0 =(bol)=>{ //missing found
            if(bol===true){
                return OAPIW
            }else{
                return OAPIL
            }
            }
        const addtlMsgRef = useRef() //missing found
        const [clientSays, setClientSays] = useState('')
        const handleWritingChange = name=>event=>{
            event.preventDefault()
            //const name = [name] = event.target.value
            setClientSays([name] = event.target.value)
        }
        console.log(clientSays)
        const placeOrder=async(e)=>{
            e.preventDefault()
            const enteredAddtlMsg = addtlMsgRef.current.value
            //console.log(enteredAddtlMsg)
            var orderingClient = ''
    
            if(currentUser().length===0){
                orderingClient='Guest'
            }else{
                orderingClient = currentUser()
            }
    
            const orderSubmitted={
                orderingClient:orderingClient,
                categoryChosen: projectTitle,
                phpPrice: projectPrice,
                usdPrice: projectPriceInDollar,
                addedMsg: enteredAddtlMsg,
                addedImg: fileUploaded
            }
            console.log(orderSubmitted)
    
            const orderResponse = await fetch(switcher0(pitch), {
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify(orderSubmitted)
            })
            const dataOrderSubmitted = await orderResponse.json()
            
            //console.log(dataOrderSubmitted)
            if(dataOrderSubmitted.status === "Successful!"){
                setFloatOver(false)
                setOrderSuccessFloater('Successful!')
                setOrderData({...{orderData}, 
                    status:dataOrderSubmitted.status, 
                    username:dataOrderSubmitted.username,
                    email: dataOrderSubmitted.email,
                    password: dataOrderSubmitted.password,
                    error:dataOrderSubmitted.error
                })
            }
        }
        const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
        if(situation){
          return(
              <Fragment>
                      <div className="quotation_rounder">
                          <div className="escape_area" onClick={triggerSadly}></div>
                          <div style={{width:"96%"}} className="quotation_floater_mobile">
                              <button className="escape_btn" onClick={triggerSadly}>X</button>
                              <div>     
                              {currentUser()?<div style={{borderTopRightRadius:5, borderTopLeftRadius:5}} className="profcircle"><h2>{currentUser()}'s Job Order Form</h2></div>:<div className="profcircle"><h2>Guest's Job Order Form</h2></div>}
                              {currentUrole()==='guest'?<console className="order_warning">You cannot place order more than one until you updated your profile</console>:''}
                              {currentUrole()==='client'?<console className="order_warning" style={{color:"green"}}>As our patron you can place more order as you like</console>:''}
                              </div>
                              <script
                                src="https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js">
                              </script>
                              <form className="quote_form">
                                  {/* <select className="job_options">
                                      {jobfields.map((vk)=>(
                                          <option value={vk}>{vk}</option>
                                      ))}
                                  </select> */} {/*ref={addtlMsgRef}*/}
                                  <h3 style={{position:"absolute", left:0, top:-33}}>{projectTitle}</h3>
                                  <h4 style={{position:"absolute", right:0, top:-33}}>{"₱"+projectPrice} or {"US$"+projectPriceInDollar}</h4>
                                  {clientSays.length<80?<p style={{position:"relative", color:"green", left:0, top:28, fontSize:"8pt"}}>To proceed, write a detailed description of your order.</p>:''}
                                  <textarea ref={addtlMsgRef} name="quote" onChange={handleWritingChange('quote')} className="quotation_text_area" placeholder="Add notes and reference links to your order"></textarea>
                                  <div style={{position:"absolute", bottom:-4, left:0}}>
                                  {currentUrole()==='guest'?'':<Widget style={{backgroundColor:"black"}}
                                        publicKey='c1f6acc2c018005bfb0c' 
                                        id='file' 
                                        name='file'
                                        tabs='file url'
                                        previewStep='true'
                                        onDialogClose={(file) => {
                                            console.log('File changed: ', file)
                                            if (file) {
                                            file.progress(info => console.log('File progress: ', info.progress))
                                            file.done(info => console.log('File uploaded: ', info))
                                            }
                                            }}
                                        onChange={info => setFileUploaded(info.cdnUrl) /*console.log('Upload completed:', info)*/}
                                  />}    
                                  </div>
                                  {clientSays.length<80 || currentUrole()==='guest'?<button disabled="true" className="quotation_submit_btn" style={{backgroundColor:"grey"}}>Order not allowed</button>:<button onClick={placeOrder} className="quotation_submit_btn">Place Order</button>}
                                  
                              </form>
                          </div>
                      </div>
              </Fragment>
          )
        }else{
            return
        }
    }
    const [money, setMoney] = useState(0)
    const pingCurrency = async()=>{
        const usCurrency = 'https://api.coindesk.com/v1/bpi/currentprice/PHP.json'
        const currencyReponse = await fetch(usCurrency) 
        const recordUSResponse = await currencyReponse.json()
        let php1 = recordUSResponse.bpi.PHP.rate.replace(',','')
        let php2 = parseFloat(php1.replace(',',''))
  
        let usd1 = recordUSResponse.bpi.USD.rate.replace(',','')
        let usd2 = parseFloat(usd1.replace(',',''))
  
        //let nene = (Math.round(usd2 * 100) / 100).toFixed(2)
        let exchanges = usd2/php2
        //let moneyExchange = php/usd
        setMoney((Math.round(exchanges*100) / 100).toFixed(2))
      //   console.log(recordUSResponse.bpi.USD.rate)
      //   console.log(recordUSResponse.bpi.PHP.rate)
    }
    pingCurrency()
    let jobrequest = []
    let pricerequest = []

    if(dito){
        return(
            <Fragment>
                {isLoading?<div class="spin-container">
                            <div class="spin" id="loader"></div>
                            <div class="spin" id="loader2"></div>
                            <div class="spin" id="loader3"></div>
                            <div class="spin" id="loader4"></div>
                            <span id="text">Please wait...</span>
                        </div>:<ul style={{position:"relative", left:-20, width:"100%", margin:"auto"}}>
                        {dito.map((search)=>{
                            if(search.tags.includes(itemEntry)){
                                return(
                                    <li style={{position:"relative", width:"100%", display:"block", marginTop:50, marginBottom:200, paddingBottom:20}}>
                                <div style={{width:"100%"}}>                         
                                    <div >          
                                    <img style={{position:"absolute", margin:"auto", left:0, width:"100%", clip: "rect(0px,200px,200px,0px)", borderRadius:20}} src={search.imageLarge.replace('www.dropbox.com','dl.dropboxusercontent.com')}/>                               
                                    </div>  
                                    <svg style={{right:30}} className="SVG-part" width="64%" viewBox="0 0 386 323" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M111.806 0.240417H375.306C380.829 0.240417 385.306 4.71757 385.306 10.2404V312.24C385.306 317.763 380.829 322.24 375.306 322.24H0.305908L111.806 0.240417Z" fill="black"/>
                                    </svg>   
                                    <h2 style={{position:"absolute", top:-10, padding:"0px 5px 0 20px", right:50,textAlign:"right", backgroundColor:"black", fontFamily:"Baloo", color:"white"}} >{search.title}</h2>{jobrequest.push(search.title)}{pricerequest.push({amount:search.price})}
                                    <Popup trigger={ <h3 style={{position:"absolute", top:30, right:0}} className="task-price">Price: P{search.price}</h3>} position="top right">
                                        <div style={{position:"relative", width:200, height:"20px", left:"20px", padding:"5px 5px 5px 5px"}}>Exchange Rate at US${parseFloat(search.price)*money}</div>
                                    </Popup>    
                                    <h4 style={{position:"absolute", fontSize:"12pt", top:60, width:180, right:55, textAlign:"right"}} className="task-description">{search.description}</h4>  

                                    <form>
                                        <button style={{position:"absolute", top:170, width:220}} onClick={
                                                (e)=>{
                                                    e.preventDefault(); 
                                                    setFloatOver(true);
                                                    setProjectTitle(search.title);
                                                    setProjectPrice(search.price);
                                                    setProjectPriceInDollar(parseFloat(search.price)*money);
                                                    }} 
                                            className="task-button">
                                                Get Quote
                                        </button> 
                                    </form>
                                            {!floatOver?"":<QuoteForm situation={floatOver} jobfields={jobrequest}/>}              
                                </div>
                             </li>
                                )
                            }                         
                            })}
                        </ul>
                    } 
            </Fragment>
        )
    }
}

export default ServicesSearchResults

