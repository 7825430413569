//LIBRARIES
import React, {Fragment, useState, useEffect, useRef} from 'react';
import {Link, useParams, useRouteMatch, useHistory} from 'react-router-dom'
import DeskHeader from './deskHeader';
import DeskFooter from './deskFooter';
import { Widget } from '@uploadcare/react-widget'

//COMPONENTS
import {SDAPIL, SDAPIW, 
    PAAPIL, PAAPIW,
    OAPIW, OAPIL } from '../../config'
import {accessSwitch} from './variables'

//OPERATIONS
const DeskPortfolioSingle = ()=>{
    
    //const {portfolio} = useParams()
    const portfolioSpage = useRouteMatch("/portfolio/:portcat/")
    const {portfolio} = useParams()

    //console.log("Portfolio: "+portfolio)
    const crack = portfolioSpage.params.portcat.split("_").join(" ");
    const stretch = crack.split(" ")
    const cramp = []
    for(var i = 0; i<stretch.length; i++){
        cramp.push(stretch[i].charAt(0).toUpperCase()+stretch[i].slice(1))
    }
    const title = cramp.join(" ")

    const historian = useHistory()
    const {path, url} = useRouteMatch()

    const [galItem, setGalItem] = useState([])
    const [gaImage, setGaImage] = useState('')
    const [gaCaption, setGaCaption] = useState('')

    const pitch = accessSwitch //true to Web-based MongoDB

    const switcher =(bol)=>{
        if(bol===true){
            return SDAPIW
        }else{
            return SDAPIL
        }
    }
    const [isGashing, setIsGashing] = useState(true)
    const servicesPingAPI1 = async()=>{
        setIsGashing(true)
        await fetch(switcher(pitch)) //true to Web-based MongoDB
        .then((responsive)=>responsive.json())
        .then((data)=>{
            const wanton = data.data
            const drearies = []
            for(const key in wanton){
                drearies.push({
                    id:key,
                    title:wanton[key].title,
                    image: wanton[key].image,
                    gallery: wanton[key].gallery,
                    price: wanton[key].price
                })
            }
            setGalItem(drearies)
        })
        setIsGashing(false)
    }
    useEffect(()=>{
        servicesPingAPI1()
    },[]) 

    const [oneImage, setOneImage] = useState(false)
    const clickImage = (e)=>{
        e.preventDefault()
        setOneImage(true)
    }
    const [imgWidth, setImgWidth] = useState(0)
    const [imgHeight, setImgHeight] = useState(0)
    const onImgLoad = ({target:img}) => {
        const {offsetHeight, offsetWidth} = img
        setImgWidth(offsetWidth)
        setImgHeight(offsetHeight)
      }
    const PortfolioFloater = ()=>{
        return(
            <Fragment>
                <ul>
                    <div className="floating-gallery-2">
                        <div onClick={()=>{setOneImage(false); setGaImage(''); setGaCaption('')}} style={{position:"absolute", width:"100%", height:"100%"}}></div>
                        <div style={{position:"relative",marginLeft:"auto",marginRight:"auto", marginTop:60, backgroundColor:"#f1f1f1", width:imgHeight>imgWidth?400:550}} >
                            <img width={imgHeight>imgWidth?400:550}
                                onLoad={onImgLoad}
                                alt=""
                                src={gaImage.replace('www.dropbox.com','dl.dropboxusercontent.com')}
                            />
                            <div style={{position:"absolute", opacity: 0.4, backgroundColor:"black", width:"100%", color:"white", bottom:0, padding:"15px 0px 15px 0px", textAlign:"center"}}>
                                <p style={{textAlign:"center", width:"100%"}}>{gaCaption}</p>
                            </div>
                            <button style={{position:"absolute", top:0, right:0, borderWidth:"0px", padding:10}} onClick={()=>{setOneImage(false); setGaImage(''); setGaCaption('')}}>Close</button>
                        </div> 
                    </div>     
                </ul>
            </Fragment>    
            
        )
    }

    //SUCCESS ORDER FLOATER
    const addtlMsgRef = useRef()
    const switcher0 =(bol)=>{
        if(bol===true){
            return OAPIW
        }else{
            return OAPIL
        }
    }

    const [floatOver, setFloatOver] = useState(false)
    const [money, setMoney] = useState(0)
    const pingCurrency = async()=>{
      const usCurrency = 'https://api.coindesk.com/v1/bpi/currentprice/PHP.json'
      const currencyReponse = await fetch(usCurrency) 
      const recordUSResponse = await currencyReponse.json()
      let php1 = recordUSResponse.bpi.PHP.rate.replace(',','')
      let php2 = parseFloat(php1.replace(',',''))

      let usd1 = recordUSResponse.bpi.USD.rate.replace(',','')
      let usd2 = parseFloat(usd1.replace(',',''))

      //let nene = (Math.round(usd2 * 100) / 100).toFixed(2)
      let exchanges = usd2/php2
      //let moneyExchange = php/usd
      setMoney((Math.round(exchanges*100) / 100).toFixed(2))
    //   console.log(recordUSResponse.bpi.USD.rate)
    //   console.log(recordUSResponse.bpi.PHP.rate)
    }
    pingCurrency()
    const triggerSadly = (e)=>{
        e.preventDefault()
        setFloatOver(false)
    }
    const triggerHappy = (e)=>{
        e.preventDefault()
        setFloatOver(true)
    }

    let jobrequest = []
    let pricerequest = []

    const [orderSuccessFloater, setOrderSuccessFloater] = useState('Failure!')
    const triggerClose = (e)=>{
        e.preventDefault()
        setOrderSuccessFloater('Failure!')
    }
    const [orderData, setOrderData] = useState({
        status:'',
        username:'',
        email:'',
        password:'',
        error:''
    })
    const OrderSuccess = (props)=>{
          const {status, username, email, password, error} = orderData
          const {closeMark} = props
          const [clipboardCopied, setClipboardCopied] = useState(false)
          if(closeMark==="Successful!"){
              return(
                  <Fragment>
                            <div className="quotation_rounder">
                                <div className="escape_area" onMouseDown={triggerClose}></div>
                                <div className="quotation_floater">
                                    <button className="escape_btn" onMouseDown={triggerClose}>X</button>
                                    <div>
                                      {username?<div className="ordercircle"><h2 style={{position:"relative", top:35 }}>{username}, your order is {status}</h2>{username==="Guest"?<p style={{position:"relative", top:25 }}>Login with these <b>Temporary Credentials</b> <br/>and update your profile and order details.</p>:""}</div>:<div className="ordercircle"><h2>Your order is Unsuccessful!</h2></div>}
                                      {username==="Guest"?
                                        <section style={{position:"relative", margin:"auto", borderWidth:1, borderColor:"#d9d9d9", width:"60%",  borderStyle:"solid", borderRadius:3}}>
                                        <div><h4><span style={{color:"#16a54b"}}>Email</span> {email}</h4><h4><span style={{color:"#16a54b"}}>Password</span> {password}</h4> </div>
                                        </section>
                                        :""}
                                    </div>
                                    <section style={{position:"relative", margin:"auto",  width:"60%", marginTop:50}}>
                                        {username==="Guest"?
                                        <Fragment>
                                            {clipboardCopied===true?
                                            <button className='ordersuccess-btn1' style={{backgroundColor:"#000", color:"#16a068"}}>Information Copied!</button>
                                            :<button className='ordersuccess-btn1' onClick={(e)=>{
                                                e.preventDefault();
                                                var data = "Email: "+email+", Password: "+password;
                                                navigator.clipboard.writeText(data);
                                                setClipboardCopied(true);
                                            }}>Copy To Clipboard</button>
                                            }
                                            <button className='ordersuccess-btn2' onClick={()=>{historian.push("/login")}}>Login & Update</button>
                                        </Fragment>
                                        :<button className='ordersuccess-btn2' onClick={()=>{historian.push("/dashboard")}}>Go Back To Dashboard</button>}
                                    </section>
                                </div>
                            </div>
                    </Fragment>
                  )
          }else{
              return 
          }
    }

    const [projectTitle, setProjectTitle] = useState('') 
    const [projectPrice, setProjectPrice] = useState('')
    const [projectPriceInDollar, setProjectPriceInDollar] = useState('')


    const QuoteForm = (props)=>{
        //const loggedRecord = useContext(DefaultLoginStateContext)
        const localToken = JSON.parse(localStorage.getItem('j5djwt'))
        const currentUrole = ()=>{  //used whether to allow Guest or not to place another order
            if(!localToken){
                return ''
            }else{
                return localToken.role
            }
        }
        const currentUser= ()=>{
            if(!localToken){
                return 'Guest'
            }else{
                return localToken.username
            }
        }
        const {situation} = props
    
        const [fileUploaded, setFileUploaded] = useState('')//client's uploaded image url
    
        const [clientSays, setClientSays] = useState('')
        const handleWritingChange = name=>event=>{
            event.preventDefault()
            setClientSays([name] = event.target.value)
        }
        console.log(clientSays)
    
        const [selected, setSelected] = useState('')
        const [defNum, setDefNum] = useState(1)
        const decreaseCount = ()=>{
            let def = defNum
            if(def===1){
                return
            }
            if(def>1){
                def--
                setDefNum(def)
            }
        }
        const increaseCount = ()=>{
            let incref = defNum
            incref++
            setDefNum(incref)
        }

        const placeOrder=async(e)=>{
            e.preventDefault()
            const enteredAddtlMsg = addtlMsgRef.current.value
            
            var orderingClient = ''
    
            if(currentUser().length===0){
                orderingClient='Guest'
            }else{
                orderingClient = currentUser()
            }
    
            let ordered = defNum //quaints.quantity
            setSelected(ordered)
            const orderSubmitted={
                orderingClient:orderingClient,
                categoryChosen: projectTitle,
                quantity: parseInt(ordered), 
                phpPrice: projectPrice,
                usdPrice: projectPriceInDollar,
                addedMsg: enteredAddtlMsg,
                addedImg: fileUploaded
            }
            //console.log(orderSubmitted)
    
            const orderResponse = await fetch(switcher0(pitch), {
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify(orderSubmitted)
            })
            const dataOrderSubmitted = await orderResponse.json()
            
            //console.log(dataOrderSubmitted)
            if(dataOrderSubmitted.status === "Successful!"){
                setFloatOver(false)
                setOrderSuccessFloater('Successful!')
                setOrderData({...{orderData}, 
                    status: dataOrderSubmitted.status, 
                    username: dataOrderSubmitted.username,
                    email: dataOrderSubmitted.email,
                    password: dataOrderSubmitted.password,
                    error: dataOrderSubmitted.error
                })
            }else{
                //console.log({STATUS: dataOrderSubmitted.status, ERROR:dataOrderSubmitted.error})
            }
        }
        const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

            if(situation){
            return(
                <Fragment>
                        <div className="quotation_rounder">
                            <div className="escape_area" onClick={triggerSadly}></div>
                            <div style={{width:"60%", height:"70%", boxShadow: "0 10px 10px 0 rgba(0, 0, 0, 0.02), 0 5px 5px 0 rgba(0, 0, 0, 0.12);"}} className="quotation_floater">
                                <button className="escape_btn" onClick={triggerSadly}>X</button>
                                <div>
                                {currentUser()?<div className="profcircle"><h2>{currentUser()}'s Job Order Form</h2></div>:<div className="profcircle"><h2>Guest's Job Order Form</h2></div>}
                                {currentUrole()==='guest'?<console className="order_warning">You cannot place order more than once until you updated your profile</console>:''}
                                {currentUrole()==='client'?<console className="order_warning" style={{color:"green"}}>As our patron you can place more order as you like</console>:''}
                                </div>
                                <script
                                    src="https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js">
                                </script>
                                <form className="quote_form">
                                    <h3 style={{position:"absolute", float:"left", top:-35, left:3}}>{projectTitle}</h3>
                                    <span style={{position:"absolute", color:"#16a068", fontSize:"10pt", left:3, top:5}}>{"US$"+projectPriceInDollar} / {"₱"+projectPrice}</span><br/>
                                    <section style={{position:"absolute", top:0,  width:100,  right:2, padding:"10px 20px 10px 20px"}}>
                                        <span  style={{position:"absolute", fontWeight:600, width:120, right:100, top:0}}>No. of Pieces</span>
                                        <button class='creaseBTN1' onClick={(e)=>{e.preventDefault(); decreaseCount();}}>
                                            <svg style={{color:"#fff"}} xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                        </button>
                                        <input type="text" value={defNum} style={{position:"absolute", width:20, right:35, top:0, padding:"2px 5px 2px 5px", borderColor:"#16a068", borderStyle:"solid", borderRadius:3}} />
                                        <button class='creaseBTN2' onClick={(e)=>{e.preventDefault(); increaseCount(); }}>
                                            <svg style={{color:"#fff"}} xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </section>

                                        {/* <select  onChange={getOrderQuantity('order')} style={{position:"relative", float:"right", textAlign:"center", width:50, right:0, top:-30}}>
                                                <option value="">{quaints.quantity}</option>
                                                <Fragment>
                                                            {orderMax.map(ordinal=>{
                                                                return(
                                                                    <Fragment>
                                                                        <option value={ordinal}>{ordinal}</option>
                                                                    </Fragment>
                                                                )
                                                            })}
                                                </Fragment>
                                                
                                        </select> */}
                                    {clientSays.length<80?<p style={{position:"absolute", color:"green", left:3, top:14, fontSize:"8pt"}}>Please write a detailed description of your order so you will be allowed to proceed</p>:''} 
                                    <textarea ref={addtlMsgRef} onChange={handleWritingChange('quote')}className="quotation_text_area" placeholder="Add notes and reference links to your order"></textarea>
                                    <div style={{position:"absolute", bottom:-4, left:0}}>
                                    {currentUrole()==='guest'?'':<Widget style={{backgroundColor:"black"}}
                                            publicKey='c1f6acc2c018005bfb0c' 
                                            id='file' 
                                            name='file'
                                            tabs='file url'
                                            previewStep='true'
                                            onDialogClose={(file) => {
                                                console.log('File changed: ', file)
                                                if (file) {
                                                file.progress(info => console.log('File progress: ', info.progress))
                                                file.done(info => console.log('File uploaded: ', info))
                                                }
                                                }}
                                            onChange={info => setFileUploaded(info.cdnUrl) /*console.log('Upload completed:', info)*/}
                                    />}    
                                    </div>
                                    {clientSays.length<80 || currentUrole()==='guest'?<button disabled="true" className="quotation_submit_btn" style={{backgroundColor:"grey"}}>Order Not Yet Allowed</button>:<button onClick={placeOrder} className="quotation_submit_btn">Place Order Now!</button>}
                                </form>
                            </div>
                        </div>
                </Fragment>
            )
            }else{
                return
                }
    }

    return(
        <Fragment>
            <div id="MAIN-BODY">
                    <div className="log-form-space">
                                <section className="masterspace" name="masterspace">
                                    <section className="masterpiecebox">
                                        <h1>{title} Gallery</h1>
                                        <section className='port_breadcrumbs' name="breadcrumbs">
                                            <button className='btn1' onClick={(e)=>{e.preventDefault(); historian.push("/portfolio")}}>Go Back</button>
                                            {galItem.map((portfors)=>{
                                                if(portfors.title === title ){
                                                    return(
                                                        <button className='btn2' onClick={(e)=>{
                                                        e.preventDefault(); 
                                                        setFloatOver(true);
                                                        setProjectTitle(portfors.title);
                                                        setProjectPrice(portfors.price);
                                                        setProjectPriceInDollar(parseFloat(portfors.price)*money);
                                                        }}>Order Like This
                                                        </button>
                                                    )
                                                } 
                                            })}
                                            
                                        </section>
                                        {isGashing?<div class="spin-container">
                                                <div class="spin" id="loader"></div>
                                                <div class="spin" id="loader2"></div>
                                                <div class="spin" id="loader3"></div>
                                                <div class="spin" id="loader4"></div>
                                                <span id="text">Images Loading...</span>
                                            </div>:<ul>
                                            {galItem.map((portfors)=>{
                                                 if(portfors.title === title ){  
                                                return (<li className="listed-gal-box">
                                                                        {portfors.gallery.map((sort)=>{
                                                                                return <li style={{margin:10, display:"inline"}}>
                                                                                        <Link >
                                                                                        
                                                                                            <img onClick={clickImage} onMouseOver={
                                                                                            (e)=>{e.preventDefault();
                                                                                                setGaImage(sort.image.replace('www.dropbox.com','dl.dropboxusercontent.com'))
                                                                                                setGaCaption(sort.caption)
                                                                                                console.log(sort.caption)
                                                                                            }}  
                                                                                            className="gal-image-item" style={{paddingTop:16}} width="190" src={sort.image.replace('www.dropbox.com','dl.dropboxusercontent.com')}/>
                                                                                        </Link>
                                                                                        {oneImage?<PortfolioFloater/>:''}
                                                                                    </li>
                                                                        })}   
                                                </li>)
                                                 }
                                            })}
                                        </ul>}

                                    </section>
                                </section>
                    </div>
            </div>
            {!floatOver?"":<QuoteForm situation={floatOver}/>}   
            {orderSuccessFloater==="Failure!"?"":<OrderSuccess closeMark={orderSuccessFloater}/>} 
        </Fragment>
    )
}

export default DeskPortfolioSingle;