import React, {Fragment, useRef} from 'react'
import {Link} from 'react-router-dom'
import Year from './deskYear' //importing custom code and inserted at JSX

let MainFooter = ()=>{
    //this is Footer's mailing-list manipulator
    const ftextInputRef = useRef(null);
    const fbuttonClick = ()=>ftextInputRef.current.style="color:blue";
    //this is Day Teller
    const Dayot = ()=>{
        var dtime = new Date();
        var dDay = dtime.getDay();
        var karon = "";
        if(dDay === 0){
            karon = "Dominggo"
        }else if(dDay === 1){
            karon = "Lunes"
        }else if(dDay === 2){
            karon = "Martes"
        }else if(dDay === 3){
            karon = "Miyerkules"
        }else if(dDay === 4){
            karon = "Huebes"
        }else if(dDay === 5){
            karon = "Biernes"
        }else if(dDay === 6){
            karon = "Sabado"
        }
        return(
            <Fragment>
            <h3 className="theDay">Maayong {karon}!</h3>
            </Fragment>
        )
    }
    return(
        <Fragment>
            <div id="FOOTER-SPACE">
                <section className="footer-box">
                    <div className="first-part-f">
                        <div className="logo-space"><img src="../../footer_logo.png"></img></div>
                    </div>
                    <div className="second-part-f">
                        <div className="fslogan-space">
                            <p>
                            We are a Web, Graphics and Illustration Agency based in Cebu Philippines. Our goal is to make your digital and printing needs easily accessible from any points in the Philippines and delivered in your doorsteps. We excelled in everything we do.
                            </p>
                        </div>
                    </div>
                    <div className="third-part-f">
                        <section className="mail_list">
                            <form>
                            <input className="prime_field" ref={ftextInputRef} type="text" placeholder="Enlist your email to receive" ></input><input className="prime_button" onClick={fbuttonClick} type="button" value="Updates & Offer" ></input>
                            </form>
                        </section>
                    </div>
                    <div className="fourth-part-f">
                        <div className="f-widget-1"> 
                            <ul className="f1-list">
                                <li className="list-item"><Link className="llink" to=""> <img className="limage"src="../../fb_logo.png"/>  <img className="blimage"/>facebook</Link></li>
                                <li className="list-item"><Link className="llink" to=""> <img className="limage"src="../../twitter_logo.png"/>  <img className="blimage"/>twitter</Link></li>
                                <li className="list-item"><Link className="llink" to=""> <img className="limage"src="../../instagram_logo.png"/>  <img className="blimage"/>instagram</Link></li>
                            </ul>
                        </div> 
                        <div className="f-widget-2"> 
                            <ul className="f2-list">
                                <li className="f2-listitem"><Link className="f2link" to="/disclaimer">Disclaimer</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/terms">Terms of Use</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/faqs">FAQs</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/howtos">Howtos</Link></li>
                                <li className="f2-listitem"><Link className="f2link" to="/payments">Payments</Link></li>
                            </ul>
                        </div>
                        <div className="f-widget-3"> 
                            <ul className="f3-list">
                                    <li className="f3-listitem"><Link className="f3link" to="/accessibility">Accebility</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/">Home</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/graphics">Graphics</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/illustration">Illustration</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/web">Web</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/services">Services</Link></li>
                                    <li className="f3-listitem"><Link className="f3link" to="/prices">Pricelist</Link></li>
                            </ul>
                        </div>
                        <div className="f-widget-4"> 
                            <ul className="f4-list">
                                        <li className="f4-listitem"><Link className="f4link" to="/testimony">Testimony</Link></li>
                                        <li className="f4-listitem"><Link className="f4link" to="/concerns">Concerns</Link></li>
                                        <li className="f4-listitem"><Link className="f4link" to="/contacts">Contacts</Link></li>
                                        <li className="f4-listitem"><Link className="f4link" to="/about">About Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="fifth-part-f">
                        <div className="copyright-space"> 
                            <Dayot />
                            <Year />
                        </div>    
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default MainFooter;