//switch to TRUE for Web-based MongoDB 
//or to FALSE for Local-based MongoDB

//COMPONENTS
import { UGAPIL, UGAPIW } from '../../config' 

export const accessSwitch = true // TRUE if for the Web-based webbyjolme, or else false to localhost webbyjolme.com
    
export let pitch = accessSwitch 

//EXCLUSIVE FOR USER PROFILE GUEST
export const userSwitcher =(bol)=>{
    if(bol===true){
        return UGAPIW
    }else{
        return UGAPIL 
    }
}

export const emailMessengerLinkLocal = 'http://localhost:8888/api/nodemailer/outgoing'
export const emailMessengerLinkWeb = 'https://salty-inlet-81949.herokuapp.com/api/nodemailer/outgoing'

export const passwordResetterLinkLocal = 'http://localhost:8888/api/nodemailer/resetpw'
export const passwordResetterLinkWeb = 'https://salty-inlet-81949.herokuapp.com/api/nodemailer/resetpw'

export const resetIDLinkLocal = 'http://localhost:8888/api/j5d-view-reset-id'
export const resetIDLinkWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-view-reset-id'

export const pushNewPasswordLocal = 'http://localhost:8888/api/j5d-reset-pw/'
export const pushNewPasswordWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-reset-pw/'

//PAYMONGO SERVICES
export const mongoDepositLinkLocal = 'http://localhost:8888/api/paymongo/new-intent'
export const mongoDepositLinkWeb = 'https://salty-inlet-81949.herokuapp.com/api/paymongo/new-intent'

//CLIENT ACCOUNT UPDATING USERNAME
export const clientAccUpdateUsernameLocal = 'http://localhost:8888/api/j5d-update-client-account-username/'
export const clientAccUpdateUsernameWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-username/'

//CLIENT ACCOUNT UPDATING PASSWORD
export const clientAccUpdatePasswordLocal = 'http://localhost:8888/api/j5d-update-client-account-password/'
export const clientAccUpdatePasswordWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-password/'

//CLIENT ACCOUNT UPDATING EMAIL
export const clientAccUpdateEmailLocal = 'http://localhost:8888/api/j5d-update-client-account-email/'
export const clientAccUpdateEmailWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-email/'

//CLIENT ACCOUNT UPDATING PAYPAL EMAIL
export const clientAccUpdatePaypalEmailLocal = 'http://localhost:8888/api/j5d-update-client-account-paypalemail/'
export const clientAccUpdatePaypalEmailWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-paypalemail/'

//CLIENT ACCOUNT UPDATING MOBILE
export const clientAccUpdateMobileLocal = 'http://localhost:8888/api/j5d-update-client-account-mobilenumber/'
export const clientAccUpdateMobileWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-mobilenumber/'

//CLIENT ACCOUNT UPDATING SHIPPING
export const clientAccUpdateShippingLocal = 'http://localhost:8888/api/j5d-update-client-account-shipaddress/'
export const clientAccUpdateShippingWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-update-client-account-shipaddress/'

//CLIENT ACCOUNT UPDATING SHIPPING
export const clientAccUpdateToDeleteLocal = 'http://localhost:8888/api/j5d-delete-client-account/'
export const clientAccUpdateToDeleteWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-delete-client-account/'

//CLIENT PAYMONGO PAYTYPE
export const clientPaymongoPaytypeLocal = 'http://localhost:8888/api/paymongo/payment-method-id'
export const clientPaymongoPaytypeWeb = 'https://salty-inlet-81949.herokuapp.com/api/paymongo/payment-method-id'

//CLIENT PAYMONGO ATTACH INTENT
export const clientPaymongoAttachIntentLocal = 'http://localhost:8888/api/paymongo/paymentid-attach-tointent'
export const clientPaymongoAttachIntentWeb = 'https://salty-inlet-81949.herokuapp.com/api/paymongo/paymentid-attach-tointent'

//CLIENT PAYMONGO FINISH
export const clientPaymongoFinishLocal = 'http://localhost:8888/api/paymongo/list'
export const clientPaymongoFinishWeb = 'https://salty-inlet-81949.herokuapp.com/api/paymongo/list'

//CLIENT PAYMONGO UPDATE BALANCE
export const clientPaymongoUpdateBalanceLocal = 'http://localhost:8888/api/j5d-client-peso-deposit-update/'
export const clientPaymongoUpdateBalanceWeb = 'https://salty-inlet-81949.herokuapp.com/api/j5d-client-peso-deposit-update/'