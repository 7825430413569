//LIBRARIES
import React, {Fragment, useState, createRef} from 'react'
import { useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Widget } from '@uploadcare/react-widget'
import { currPrimary, currBalanceView, viewUser, viewOrder, defaultCurrBalance, proPicView, viewClientOrders} from '../../../redux/slices/counter'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import {paypalCollaborate, destroyPaypalCollaborator} from '../auth/auth'

//COMPONENTS
import ClientDepositSlots from './clientDepositSlots'
import ClientMongoDepositProgressPage from '../clientsComps/clientMongoDepositProgress'
import {mixen, orderen, userslist} from '../../../redux/querries/userdataquery'
import { pitch } from '../variables'
import {
    UPPHOTOL, UPPHOTOW,
    CURR_CL, CURR_CW
    } from '../../../config'
import { width } from '@mui/system';
import { reverse } from 'dns'
//import ClientDepositDetectorComp from './clientDepositDetector'

//OPERATIONS
const ClientProfileFader = (props)=>{
            const dispatch2 = useDispatch()
            const details2 = useSelector(state => state.counter.userDetails)
            const currencyPrimary2 = ()=>{
                setInterval(()=>{
                    dispatch2(viewUser())
                    
                },1000)
            }
            currencyPrimary2()

            const {user, cbalance, userid, email} = props
            const historian = useHistory()
            const [introProfile, setIntroProfile] = useState(true)
            const [depositTrigger, setDepositTrigger] = useState(false)
            
            //const user = useSelector(state => state.counter.userDetails)
            const bamount = useSelector(state => state.counter.currBalance)
            const currencyFiat = useSelector(state => state.counter.currFiat)
            const primeCurrency = useSelector(state => state.counter.currFiat)
            const details = useSelector(state => state.counter.userDetails)
            const upuser = useSelector(state => state.counter.userDetails.username)
            const upemail = useSelector(state => state.counter.userDetails.email)
            const photo = useSelector(state => state.counter.profilePic)
            const ordersTotal = useSelector(state => state.counter.allOrders)
            //console.log(orderpays)
            const dispatch = useDispatch()
            const loadProfilePic = ()=>{
                setInterval(()=>{
                    dispatch(proPicView())
                    dispatch(viewUser())
                    dispatch(viewOrder())
                    dispatch(currPrimary())
                    dispatch(currBalanceView())
                    dispatch(viewClientOrders())
                },1000)
            }
            loadProfilePic() //triggering to reload every 1 second

            const currencySwitcher = (curr)=>{
                dispatch(currBalanceView(curr))
            }
            const currencyPrimary = ()=>{
                dispatch(currPrimary())
            }
            //console.log([mixen][0][0].balance[0].PHP)
            const [walletTrigger, setWalletTrigger] = useState(false)
            const [wallet, setWallet] = useState(true)
            const [dHistory, setDHistory] = useState(false)
            const [money, setMoney] = useState(0)
            const [depositView, setDepositView] = useState(false)
            const getMoneySticked = (num)=>(event)=>{
                event.preventDefault()
                let money = event.target.value
                setMoney(money)
            }
            const currPrimeSwitcher = (pitch)=>{
                if(pitch===true){
                    return CURR_CW
                }else{
                    return CURR_CL
                }
            }
            const processNewPrimary =async(uid, primary)=>{
                console.log(`${uid} & ${primary}`)
                const processPrime = await fetch(currPrimeSwitcher(pitch), {
                    method:"POST",
                    headers:{"Content-Type":"application/json"},
                    body:JSON.stringify({userUniqueID:uid, primary:primary})
                }) 
                const listenPrime = await processPrime.json()
                const {data, status} = listenPrime
                if(data.modifiedCount===1){
                    setTimeout(window.location.reload(),1000)
                }else{
                    console.log("Something is Wrong!")
                }
            }
            const [newCurrency, setNewCurrency] = useState('')
            const getToProcessPrimary =()=>{
                processNewPrimary(userid, newCurrency)
            }
            const setNewPrimaryCurrency = currency=>event=>{
                let currency = event.target.value
                setNewCurrency(currency)
            }
            //WALLET
            const [payLocal, setPayLocal] = useState(false)
            const [payPal, setPayPal] = useState(false)
            const [depositOption, setDepositOption] = useState('')
            const [payScoped, setPayScoped] = useState(0)
            const depositListen = (amount)=>(event)=>{
                event.preventDefault()
                amount = event.target.value
                setDepositOption(amount)
                if(amount === "Option 1"){
                    setPayScoped(20)
                }else if(amount === "Option 2"){
                    setPayScoped(50)
                }else if(amount === "Option 3"){
                    setPayScoped(100)
                }else if(amount === "Option 4"){
                    setPayScoped(200)
                }else if(amount === "Option 5"){
                    setPayScoped(300)
                }else if(amount === "Option 6"){
                    setPayScoped(500)
                }else if(amount === "Option 7"){
                    setPayScoped(1000)
                }else if(amount === "Option 8"){
                    setPayScoped(1500)
                }else if(amount === "Option 9"){
                    setPayScoped(2000)
                }
                
                
            }
            const WalletFloater = ()=>{
                currencySwitcher('') //decapitate this function
                currencyPrimary() //triggering primary currency
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:9, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                        </section>
                        <section style={{position:"absolute", zIndex:9, top:0, margin:"auto", left:"auto", width:"101.5%", borderRadius:5, backgroundColor:"#16a068", boxShadow:"0 7px 7px 0 rgba(0, 0, 0, 0.1), 0 7px  7px 0 rgba(0, 0, 0, 0.1)"}}>
                            <button onClick={()=>{setWalletTrigger(false); setWallet(true); setDHistory(false); destroyPaypalCollaborator()}} className='deposit-close-btn' style={{backgroundColor:"#000",fontSize:"9pt", cursor:"pointer"}}>
                                X CLOSE
                            </button>
                            <section style={{backgroundColor:"#fff", height:690,  margin:"80px 50px 45px 50px", borderRadius:4, borderTopLeftRadius:0, boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                                <h1 style={{position:"relative", zIndex:1, top:-70, color:"#fff"}}>Finacial Dashboard</h1>
                                <section style={{position:"absolute", zIndex:-1, top:50}}>
                                    {wallet===true?
                                    <button className='wallet-switcher-tab-btn' style={{backgroundColor:"#fff", color:"#16a068"}}>Wallet</button>
                                    :
                                    <button onClick={()=>{setWallet(true); setDHistory(false)}} className='wallet-switcher-tab-btn'>Wallet</button>
                                    }
                                    {dHistory===true?
                                    <button className='wallet-switcher-tab-btn' style={{borderTopLeftRadius:0, borderTopRightRadius:3, backgroundColor:"#fff", color:"#16a068"}}>Deposit History</button>
                                    :
                                    <button onClick={()=>{setDHistory(true); setWallet(false)}} className='wallet-switcher-tab-btn' style={{borderTopLeftRadius:0, borderTopRightRadius:3}}>Deposit History</button>
                                    } 
                                </section>
                                {wallet===true?
                                <Fragment>
                                    <section style={{position:"absolute", top:80, margin:"20px 20px 20px 40px"}}>
                                        <h3>Your Primary Currency</h3>
                                        <select onChange={setNewPrimaryCurrency('currency')} style={{position:"relative", top:-40, left:220, fontWeight:600, width:185, textAlign:"left"}}>
                                            <option value={newCurrency !==''?newCurrency:currencyFiat}>{newCurrency !==''?newCurrency:currencyFiat} (Primary Currency)</option>
                                            <option value='PHP'>PHP (Philippine Peso)</option>
                                            <option value='USD'>USD (US Dollar)</option>
                                            <option value='EUR'>EUR (Eurozone Currency)</option>
                                            <option value='GBP'>GBP (Great Britain Pound)</option>
                                            <option value='AUD'>AUD (Australian Dollar)</option>
                                            <option value='CAD'>CAD (Canadian Dollar)</option>
                                            <option value='YUAN'>YUAN (Chinese Yuan)</option>
                                            <option value='RUB'>RUBLE (Russian Ruble)</option>
                                        </select>
                                        <button onClick={getToProcessPrimary} className='wallet-set-primary-btn'>
                                            <span>Set New Primary Currency</span>
                                        </button>
                                    </section>
                                    <section style={{position:"absolute", top:160, width:250,  borderRadius:4, borderWidth:1, borderColor:"#f1f1f1", borderStyle:"solid",   margin:"20px 20px 20px 40px"}}>
                                        <h3 style={{position:"relative", left:20, top:-10 }}>Balances</h3>
                                        <ul className='wallet-balance-list' style={{position:"relative", left:-20}}>
                                            {details.balance.map(bal=>{
                                                if(bal.primary === true){
                                                    return(
                                                        <li style={{position:"relative", left:-20, backgroundColor:"#16a068", color:"#fff", padding:"5px 20px 5px 20px", cursor:"pointer"}}><b>{bal.currency}</b> {bal.amount} <i>(Primary Currency)</i></li>
                                                    )
                                                }else if(bal.primary === false){
                                                    return(
                                                        <li style={{backgroundColor:"#fff", color:"#16a068"}}><b>{bal.currency}</b> {bal.amount<1?'0.00':bal.amount}</li>
                                                    )
                                                }
                                            })}
                                        </ul>
                                    </section>
                                    <section style={{position:"absolute", top:160, left:330, width:490,  borderRadius:4, borderWidth:1, borderColor:"#f1f1f1", borderStyle:"solid", padding:"10px 10px 10px 10px",   margin:"20px 20px 20px 40px"}}>
                                        {/* 
                                        {currencyFiat === 'USD'?
                                        ''
                                        :''} 
                                        */}
                                        {newCurrency === 'USD'?
                                        <Fragment>
                                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                            <input type="hidden" name="cmd" value="_s-xclick"/>
                                            <input type="hidden" name="hosted_button_id" value="XLHNYKSUVKJLS"/>
                                            <table>
                                            <tr><td><input type="hidden" name="on0" value="Amount Deposit Options"/><b>Amount Deposit Options</b></td></tr><tr><td><select name="os0" onChange={depositListen('amount')}>
                                                <option value={depositOption} >{depositOption} ${payScoped}.00 USD</option>
                                                <option value="Option 1" >Option 1 $20.00 USD</option>
                                                <option value="Option 2" >Option 2 $50.00 USD</option>
                                                <option value="Option 3" >Option 3 $100.00 USD</option>
                                                <option value="Option 4" >Option 4 $200.00 USD</option>
                                                <option value="Option 5" >Option 5 $300.00 USD</option>
                                                <option value="Option 6" >Option 6 $500.00 USD</option>
                                                <option value="Option 7" >Option 7 $1,000.00 USD</option>
                                                <option value="Option 8" >Option 8 $1,500.00 USD</option>
                                                <option value="Option 9" >Option 9 $2,000.00 USD</option>
                                            </select> </td></tr><br/>
                                            </table>
                                            <input type="hidden" name="currency_code" value="USD"/>
                                            <input onClick={paypalCollaborate({amount:payScoped})} className="off_paypal_btn" type="submit" value="Paypal (Visa, Mastercards, UnionPay, etc.)" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
                                            <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
                                            </form>
                                        </Fragment>
                                        :
                                        <Fragment>
                                                    {depositView===false?
                                                <div style={{paddingBottom:"50px"}}>
                                                    <h3 style={{position:"relative", left:20, top:-10 }}>Make Deposit in Philippine Peso</h3>
                                                    <p style={{position:"relative", left:20, top:-25, width:"80%", fontSize:"10pt", color:"#16a068" }}>You are about to deposit in Philippine Peso. <b> If you'd like to deposit in USD, in the above selection, choose USD as Primary Currency.</b></p>
                                                    {/* <label style={{position:"relative", zIndex:1, top:10, left:45, color:"#d1d1d1", }}>Amount To Deposit</label>
                                                    <input id="deposit-field" placeholder={!money?'0.00':money} onMouseLeave={getMoneySticked("money")} type="number" style={{position:"relative", top:-20, left:20, width:"80%", height:100, fontSize:"29pt", color:"#16a068", borderWidth:1, borderStyle:"solid", borderRadius:4, borderColor:"#d1d1d1", paddingTop:10, paddingLeft:20}}/> */}
                                                    <section style={{position:"relative", left:20, top:5, width:"100%", fontSize:"10pt", color:"#16a068" }}>
                                                        <button onClick={()=>{setMoney(0); setWalletTrigger(false);}} className='wallet-deposit-btn' style={{position:"relative", left:0, top:5, width:"42.1%", fontSize:"10pt", color:"#fff" }}>Cancel</button>
                                                        {money===null?
                                                        <button className='wallet-deposit-btn' style={{position:"relative", left:0, top:5, width:"42.1%", fontSize:"10pt", color:"#fff", backgroundColor:"#d1d1d1"}}>Continue</button>
                                                        :<button onClick={()=>{setDepositView(true)}} className='wallet-deposit-btn' style={{position:"relative", left:0, top:5, width:"42.1%", fontSize:"10pt", color:"#fff" }}>Continue</button>}
                                                    </section>
                                                </div>:
                                                <Fragment>
                                                    {/* <ClientDepositSlots uid = {userid} depo = {money} existing = {bamount} fiat = {primeCurrency}/> */}
                                                    {payLocal===true?
                                                    <Fragment>
                                                        <button onClick={()=>{
                                                            setDepositView(false);
                                                            setPayLocal(false);
                                                        }} style={{textDecoration:"none", color:"#16a068", borderRadius:15, borderWidth:0, backgroundColor:"#000", color:"#fff", padding:"2px 20px 2px 10px"}}>
                                                        <svg style={{position:"relative", top:2, width:20}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                                        </svg>
                                                        <span style={{position:"relative", top:-3.2, width:20}}>Step Back</span>
                                                        </button> 
                                                        <ClientMongoDepositProgressPage/>
                                                        {/* <ClientDepositDetectorComp email= "jolme333@gmail.com"/> {details2.email} */}
                                                        {/* <iframe style={{position:"relative", marginTop:20, marginBottom:20, width:"100%", height:320, border:"none"}} src="https://paymongo.page/l/jolme5d-graphics" title="jolme5d-entry-payment"></iframe> */}

                                                        
                                                    </Fragment>
                                                    :
                                                    <section style={{position:"relative", top:20, marginBottom:20}}>
                                                        <button onClick={()=>{
                                                            setDepositView(false);
                                                            setPayLocal(false);
                                                        }} style={{textDecoration:"none", color:"#16a068", borderRadius:15, borderWidth:0, backgroundColor:"#000", color:"#fff", padding:"2px 20px 2px 10px"}}>
                                                        <svg style={{position:"relative", top:2, width:20}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                                        </svg>
                                                        <span style={{position:"relative", top:-3.2, width:20}}>Step Back</span>
                                                        </button>
                                                        <section style={{position:"relative", marginTop:20}}>
                                                            <button className='payment-choice-btn' onClick={()=>{
                                                                setPayLocal(true);
                                                            }}><b>GCash, Maya, GrabPay and BPI Online</b> (Philippines Payments)</button><br/>   
                                                        </section>
                                                    </section>
                                                    }
                                                </Fragment>
                                                }
                                           </Fragment>
                                        }
                                        
                                    </section>
                                </Fragment>
                                :''}
                                {dHistory===true?
                                <Fragment>
                                    <section style={{margin:"20px 20px 20px 20px"}}>
                                        <h2>View Deposit History</h2>
                                    </section>
                                </Fragment>
                                :''}
                            </section>
                        </section>
                    </Fragment>
                )
            }
            //DEPOSIT (direct)
            const DepositFloater = ()=>{
                currencySwitcher('') //decapitate this function
                currencyPrimary() //triggering primary currency
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:9, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                        </section>
                        <section style={{position:"absolute", zIndex:9, top:0, margin:"auto", left:"auto", width:"101.5%", borderRadius:5, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                            <button onClick={()=>{setDepositTrigger(false); destroyPaypalCollaborator();}} className='deposit-close-btn'>
                                X CLOSE
                            </button>
                            <section style={{position:"relative", margin:"auto", width:"100%", marginTop:50, marginBottom:20}}>
                                        {currencyFiat === 'USD'?
                                        <Fragment>
                                            <section style={{position:"absolute", left:-70, width:250, textAlign:"justify", borderWidth:1, borderStyle:"dotted", borderColor:"#d1d1d1", borderRadius:5, padding:"0px 20px 20px 20px"}}>
                                                    <h3 style={{textAlign:"center", color:"#16a068"}} >Reminder</h3>
                                                    <span style={{color:"#000"}}>
                                                        You are about to make a deposit in US Dollar. If you'd like to deposit in Philippine Peso follow the button below to change default currency to Philippine Peso. 
                                                    </span><br/><br/>
                                                    <button onClick={()=>{
                                                        setDepositTrigger(false);
                                                        setWalletTrigger(true);
                                                        }} className='change-primary-currency-btn' style={{width:"100%"}}>Change Primary Currency</button>
                                            </section>
                                            <section style={{position:"relative", left:200, margin:"auto", width:"70%", height:200}}>
                                                <form style={{position:"relative", top:40, left:-30}} action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                                <input type="hidden" name="cmd" value="_s-xclick"/>
                                                <input type="hidden" name="hosted_button_id" value="XLHNYKSUVKJLS"/>
                                                <table>
                                                <tr><td><input type="hidden" name="on0" value="Amount Deposit Options"/><b>Amount Deposit Options</b></td></tr><tr><td><select name="os0" onChange={depositListen('amount')}>
                                                    <option value={depositOption} >{depositOption} ${payScoped}.00 USD</option>
                                                    <option value="Option 1">Option 1 $20.00 USD</option>
                                                    <option value="Option 2">Option 2 $50.00 USD</option>
                                                    <option value="Option 3">Option 3 $100.00 USD</option>
                                                    <option value="Option 4">Option 4 $200.00 USD</option>
                                                    <option value="Option 5">Option 5 $300.00 USD</option>
                                                    <option value="Option 6">Option 6 $500.00 USD</option>
                                                    <option value="Option 7">Option 7 $1,000.00 USD</option>
                                                    <option value="Option 8">Option 8 $1,500.00 USD</option>
                                                    <option value="Option 9">Option 9 $2,000.00 USD</option>
                                                </select> </td></tr><br/>
                                                </table>
                                                <input type="hidden" name="currency_code" value="USD"/>
                                                <input onClick={paypalCollaborate({amount:payScoped})} className="off_paypal_btn" type="submit" value="Pay with PayPal (Visa, Mastercards, UnionPay, etc.)" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
                                                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
                                                </form>
                                                
                                            </section>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            {/*  */}
                                            {depositView === false?
                                            <Fragment>
                                                <section style={{position:"relative",  margin:"20px 20px 20px 20px"}}>
                                                    <section style={{position:"absolute", top:-10,  left:0, width:"30%", borderWidth:1, borderStyle:"dotted", borderColor:"#d1d1d1", borderRadius:5, padding:"0px 20px 20px 20px"}}>
                                                            <h3 style={{textAlign:"center", color:"#16a068"}} >Reminder</h3>
                                                                <span style={{textAlign:"center", color:"#16a068"}}>
                                                                    By default you are directed to deposit in Philippine Peso.
                                                                    However, if you'd like to deposit in USD set USD as primary first. Follow the button below.
                                                                </span><br/><br/>
                                                            <button onClick={()=>{
                                                                setDepositTrigger(false);
                                                                setWalletTrigger(true);
                                                                }} className='change-primary-currency-btn' style={{width:"100%"}}>Change Primary Currency</button>
                                                    </section>
                                                    <section style={{position:"relative", left:330, top:10,  marginBottom:100, width:"60%"}}>
                                                        <ClientMongoDepositProgressPage/>
                                                        {/* <h3>Make deposit to fund your projects</h3>
                                                        <span style={{position:"relative", top:-20, color:"#16a068"}}>Your Primary Currency is <b>{primeCurrency}</b></span>
                                                        <label style={{position:"absolute", zIndex:2, top:70, left:20, fontSize:"10pt", color:"#d1d1d1"}}>Amount</label><input className='depo-placeholder' type="number" placeholder = {!money?'0.00':money} onMouseLeave={getMoneySticked("money")} style={{position:"relative", fontSize:"22pt", width:"65%", height:60, borderWidth:0.5, borderColor:"#d1d1d1", color:"#16a068", borderStyle:"solid",  padding:"5px 20px 5px 20px"}} /><br/><br/><br/>
                                                        <button onClick={()=>{setMoney(0); }} className='depo-btn' style={{borderTopLeftRadius:3, borderBottomLeftRadius:3}}>Clear</button>
                                                        {money<1?
                                                        <button className='depo-btn' style={{backgroundColor:"#d1d1d1", borderTopRightRadius:3, borderBottomRightRadius:3}}>Make Deposit</button>
                                                        :<button onClick={()=>{setDepositView(true)}} className='depo-btn' style={{borderTopRightRadius:3, borderBottomRightRadius:3}}>Make Deposit</button>} */}
                                                    </section>
                                                </section>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                {payLocal===true?
                                                        <Fragment>
                                                            <button onClick={()=>{
                                                                setDepositView(false);
                                                                setPayLocal(false);
                                                            }} style={{textDecoration:"none", color:"#16a068", borderRadius:15, borderWidth:0, backgroundColor:"#000", color:"#fff", padding:"2px 20px 2px 10px"}}>
                                                            <svg style={{position:"relative", top:2, width:20}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                                            </svg>
                                                            <span style={{position:"relative", top:-3.2, width:20}}> Change Amount</span>
                                                            </button> 
                                                            
                                                            
                                                        </Fragment>
                                                        :
                                                        <section style={{position:"relative", top:20, marginBottom:20}}>
                                                            <button onClick={()=>{
                                                                setDepositView(false);
                                                                setPayLocal(false);
                                                            }} style={{textDecoration:"none", color:"#16a068", borderRadius:15, borderWidth:0, backgroundColor:"#000", color:"#fff", padding:"2px 20px 2px 10px"}}>
                                                            <svg style={{position:"relative", top:2, width:20}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                                            </svg>
                                                            <span style={{position:"relative", top:-3.2, width:20}}> Change Amount</span>
                                                            </button>
                                                            <section style={{position:"relative", marginTop:20}}>
                                                                <button className='payment-choice-btn' onClick={()=>{
                                                                    setPayLocal(true);
                                                                }}><b>GCash, Maya, GrabPay and BPI Online</b> (Philippines Payments)</button><br/>
                                                                    
                                                            </section>
                                                        </section>
                                                        }
                                            </Fragment>
                                            }

                                            {/*  */}
                                        </Fragment>
                                        }
                            </section>
                        </section>
                    </Fragment>
                )
            }
            //CURRENCY FLOATER
            const exchangeCurrencies = currency=>event=>{
                    currency = event.target.value
                    currencySwitcher(currency)
            }
            const [currencyBTN, setCurrencyBTN] = useState(false)
            const currencies =details.balance
            //const currentium = arrot=>arrot.sort(()=>0.5-Math.random())
            const CurrencyFloater = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:11, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.1)"}}>
                        </section>
                        <section style={{position:"absolute", zIndex:12, top:80, margin:"auto", right:230, width:"30%", borderRadius:5, height:35, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rgba(0, 0, 0, 0.19)"}}>
                            <button onClick={()=>{setCurrencyBTN(false)}} className='deposit-close-btn' style={{borderBottomLeftRadius:0, borderBottomRightRadius:5}}>
                                X CLOSE
                            </button>
                            <span style={{position:"relative", top:5, left:10, fontWeight:600}}>Currency</span>
                            <select onClick={exchangeCurrencies('currency')} style={{position:"relative", top:5, left:20, fontWeight:600, width:80, textAlign:"center"}}>
                                <option value={currencyFiat}>{currencyFiat}</option>
                                {currencies.map(bal=>(
                                    <option value={bal.currency}>{bal.currency}</option>
                                ))}
                                {/* <option value='PHP'>PHP</option>
                                <option value='USD'>USD</option>
                                <option value='EUR'>EURO</option>
                                <option value='GBP'>GBP</option>
                                <option value='AUD'>AUD</option>
                                <option value='CAD'>CAD</option>
                                <option value='CNY'>YUAN</option>
                                <option value='RUB'>RUBLE</option> */}
                            </select>
                        </section>
                    </Fragment>
                )
            }
            //UPLOADING PROFILE PHOTO
            const [photoUploadTrigger, setPhotoUploadTrigger] = useState(false)
            const [fileUploaded, setFileUploaded] = useState('')//client's uploaded image url
            const [imageData, setImageData] = useState({width:0, height:0})
            const [processUpload, setProcessUpload] = useState(false)
            const [uploadSuccess, setUploadSuccess] = useState(false)
            const [uploadFailed, setUploadFailed] = useState(false)

            let diff = 0
            let multiplyer = 0
            let minLeft = 0
            let minimum = 25
            if(imageData.width>imageData.height){
                diff+=imageData.width-imageData.height
                multiplyer+=imageData.width*0.01
                minLeft += diff/multiplyer
            }else{
                diff+=imageData.height-imageData.width
                multiplyer+=imageData.height*0.01
                minLeft += diff/multiplyer
            }
            const uploadPhotoSwitcher = (pitch)=>{
                if(pitch===true){
                    return UPPHOTOW
                }else{
                    return UPPHOTOL
                }
            }
            const uploadProfileImage = async()=>{
                setPhotoUploadTrigger(false)
                setProcessUpload(true)
                const uploadImageQuery = await fetch(uploadPhotoSwitcher(pitch), {
                    method:"POST",
                    headers:{"Content-Type":"application/json"},
                    body:JSON.stringify({userUniqueID:userid, profileImage:fileUploaded})
                })
                const uploadGetsContact = await uploadImageQuery.json()
                const {status, data} = uploadGetsContact
                if(data.modifiedCount>0){
                    setProcessUpload(false)
                    setUploadSuccess(true)
                }else{
                    setProcessUpload(false)
                    setUploadFailed(true)
                }
            }
            const UploadingProcess = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", left:0, top:0, zIndex:11, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                        </section>
                        <section onClick = {()=>{setProcessUpload(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:12, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                            <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                                    <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                                    <span style={{position:"absolute", color:"#fff", top:35, right:85}}>Image Upload</span> <br />
                                    <span style={{position:"absolute", color:"#fff", fontSize:"22pt", fontWeight:"bolder", top:50, right:85}}>PROCESSING</span>
                            </section>
                        </section>
                    </Fragment>
                )
            }
            const SuccessfulUpload = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:11, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                        </section>
                        <section style={{position:"relative", zIndex:12, top:-400, margin:"auto", left:"auto", width:"60%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                            <section style={{position:"relative", margin:"auto", top:20, width:400, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"10px 10px 10px 10px"}}>
                                <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                                    <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image Succeeded!</span><br/>
                                </section>
                                <button onClick={()=>{setUploadSuccess(false); window.location.reload()}} style={{position:"absolute", top:10, right:20, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                                    EXIT
                                </button>
                            </section>
                            
                        </section>
                    </Fragment>
                )
            }
            const FailedUpload = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:11, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                        </section>
                        <section style={{position:"relative", zIndex:12, top:-400, margin:"auto", left:"auto", width:"60%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                            <section style={{position:"relative", margin:"auto", top:20, width:400, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"10px 10px 10px 10px"}}>
                                <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                                    <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image has failed!</span><br/>
                                </section>
                                <button onClick={()=>{setUploadFailed(false)}} style={{position:"absolute", top:10, right:20, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                                    EXIT
                                </button>
                            </section>
                            
                        </section>
                    </Fragment>
                )
            }
            const PhotoUploader = ()=>{
                return(
                    <Fragment>
                        <section style={{position:"fixed", zIndex:9, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                        </section>
                        <section style={{position:"fixed", zIndex:11, left:0, top:0, width:"100%"}}>
                            <section style={{position:"relative", zIndex:12, top:75, margin:"auto", left:"auto", width:"40%", borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                                <button onClick={()=>{setPhotoUploadTrigger(false);}} className='deposit-close-btn' style={{position:"position",backgroundColor:"white", color:"#16a068", padding:"16px 20px 16px 20px", right:-55}}>
                                    <span style={{fontWeight:"bold"}}>X</span>
                                </button>
                                <section style={{position:"relative", margin:"auto", top:45, width:500, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"20px 20px 20px 20px"}}>
                                    <section style={{position:"relative", margin:"auto", zIndex:2, top:-32, width:200, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                                        <span style={{fontWeight:"bolder", color:"#16a068"}}>Upload A Profile Picture</span><br/>
                                        <span style={{fontWeight:"500", fontSize:"7pt", color:"#16a068"}}>Only upload image with equal sides' length</span>
                                    </section>
                                    <section style={{position:"relative", top:-10}}>
                                        <Widget style={{backgroundColor:"black", top:0}}
                                                        publicKey='c1f6acc2c018005bfb0c' 
                                                        id='file' 
                                                        name='file'
                                                        tabs='file url'
                                                        previewStep='true'
                                                        onDialogClose={(file) => {
                                                            console.log('File changed: ', file)
                                                            if (file) {
                                                            file.progress(info => console.log('File progress: ', info.progress))
                                                            file.done(info => console.log('File uploaded: ', info))
                                                            }
                                                            }}
                                                        onChange={(info) => {
                                                            setFileUploaded(info.cdnUrl); /*console.log('Upload completed:', info)*/
                                                            setImageData({width:info.originalImageInfo.width, height:info.originalImageInfo.height})} 
                                                        }
                                        />
                                    </section>
                                    {fileUploaded !=='' && minLeft<minimum?
                                    <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                        <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                        <b>You can upload this picture</b>
                                    </section>
                                    :
                                    <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                        <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                        <b>Cannot be uploaded! Must be nearly equal in both sides.</b>
                                    </section>
                                    }


                                    <section>
                                        <img src={fileUploaded} id="client_pic" width="500"/>
                                    </section>
                                    {fileUploaded==='' || minLeft>minimum?
                                    <button className='guest-upload-btn' style={{backgroundColor:"#a1a1a1"}}>
                                        No Image or Image Have Problems
                                    </button>:
                                    <button className='guest-upload-btn' onClick={uploadProfileImage}>
                                    Upload Image
                                    </button>}
                                </section>
                                
                            </section>
                        </section>
                    </Fragment>
                )
            }
            const [changeProImage, setChangeProImage] = useState([])
            return(
                <Fragment>
                    
                    {introProfile===true?<section className='client-fader'>
                        <button style={{position:"relative", borderRadius:50, padding:"10px 10px 10px 10px", width:40, height:40, borderWidth:2, borderStyle:"solid", backgroundColor:"rgba(0,0,0,0.0)",borderColor:"#16A068", cursor:'pointer'}} onClick={()=>{setIntroProfile(false)}}><b style={{width:30, height:30}}>X</b></button>
                        <section onClick = {()=>{setPhotoUploadTrigger(true)}} className='profile-picture-space'>
                            {!photo?
                            <section id='profile-picture' style={{position:'relative', margin:"auto", width:148, height:148, borderRadius:"50%", backgroundColor:"#16a068", textAlign:"center", cursor:"pointer"}}>
                                <svg style={{position:"relative", color:"white", width:50, top:30, verticalAlign:"sub" }} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg><br/>
                                <span style={{position:"relative", top:20, color:"white", fontSize:"18pt", fontWeight:600}}>Update</span>
                            </section>:
                            <section id='profile-picture' style={{position:'relative', margin:"auto", width:148, height:148, borderRadius:"50%", backgroundColor:"#f1f1f1"}}>
                                <img onLoad={currencySwitcher} style={{width:138, height:138, borderRadius:"50%", borderWidth:5, borderColor:"#16a068", borderStyle:"solid"}} src={photo}/>
                            </section>} 
                        </section>
                        <section className='profile-semi-details'>
                                <h1>{upuser}</h1>
                                <span style={{position:"relative", fontSize:"9pt", top:-18, color:"#16a068"}}>ID: {userid.toUpperCase()}</span><br/>
                                <span style={{position:"relative", fontSize:"9pt", top:-20, color:"#16a068"}}>{upemail}</span><br/>
                                <span style={{color:"#16a068"}}><b>Lifetime Discount</b> {details.discount}%</span>
                        </section>
                        <section className='profile-semi-details-2'>
                                <Fragment>
                                    <span>
                                        <b>Balance</b><span> </span>
                                            <button onClick={()=>{setCurrencyBTN(true)}} className='current-btn'>
                                            {currencyFiat}<span> </span>
                                            <span style={{color:"#fff", fontStyle:"bold"}}>{bamount===0?'0.00':bamount}</span>
                                            </button> 
                                    </span><br/>
                                </Fragment>
                                {/* <span style={{fontSize:"9pt", color:"#16a068"}}>Total Spent in <b>{primeCurrency}</b> {bamount===0?'0.00':bamount}</span><br/> */}
                                <span style={{fontSize:"9pt", color:"#16a068"}}>Spent: <b>{primeCurrency}</b> <b>{ordersTotal}</b></span><br/>
                        </section>
                        <section className='profile-semi-details-3'>
                                <button onClick={()=>{setDepositTrigger(true)}} className='client-deposit-btn'>DEPOSIT</button>
                                <button onClick={()=>{setWalletTrigger(true)}} className='client-wallet-btn'>WALLET</button>
                                <button onClick={()=>{historian.push("/dashboard/profile")}} className='client-updateinfo-btn'>PROFILE</button>
                        </section>

                    </section>:<section className='intro-prof' style={{cursor:'pointer'}} onClick={()=>{setIntroProfile(true)}}>
                                    <b style={{position:"relative", padding:"1px 1px 1px 1px", textAlign:"center"}}>{upuser.charAt(0).toUpperCase()}{upuser.charAt(1).toUpperCase()}</b>
                    <i style={{position:"absolute", left:50, top:10}}>{upuser}</i></section>}
                    {walletTrigger === true?<WalletFloater />:''}
                    {depositTrigger===true?<DepositFloater />:''}
                    {currencyBTN === true? <CurrencyFloater />:''}
                    {photoUploadTrigger === true? <PhotoUploader />:''}
                    {processUpload === true?<UploadingProcess />:''}
                    {uploadSuccess === true?<SuccessfulUpload />:''}
                    {uploadFailed === true?<FailedUpload />:''}
                </Fragment>
            )  
}

export default ClientProfileFader