//LIBRARY
import {React, Fragment, useState, useEffect} from 'react'
import { useHistory } from 'react-router'

//COMPONENTS
import MobileClientActivityExchanges from './mobileClientActivityExchanges'
import {
    ADUX_ALLAPIL, ADUX_ALLAPIW,
    SDAPIW, SDAPIL
} from '../../config'
import {accessSwitch} from '../../screens/components/variables'

//OPERATIONS
const MobileProjectActivity = (props)=>{
    const historian = useHistory()
    const pitch = accessSwitch //true to Web-based MongoDB
    const aduxSwitcher =(bol)=>{
        if(bol===true){
            return ADUX_ALLAPIW
        }else{
            return ADUX_ALLAPIL
        }
    }
    const [userItem, setUserItem] = useState([])
    const [isOozing, setIsOozing] = useState(true)
    const singleProject = async()=>{
        setIsOozing(true)
        await fetch(aduxSwitcher(pitch)) //true to Web-based MongoDB
            .then((responsive)=>responsive.json())
            .then((data)=>{
                const wanton = data.data
                const drearies = []
                for(const key in wanton){
                    drearies.push({
                        id:key,
                        uid:wanton[key].userUniqueID,
                        username: wanton[key].username,
                        email: wanton[key].email,
                        role: wanton[key].role,
                        dateSince: wanton[key].dateCreated,
                        orders:wanton[key].orders, 
                        payments:wanton[key].paymentSources,
                        orders:wanton[key].orders,
                        progress:wanton[key].progress
                    })
                }
                setUserItem(drearies)
        })
        
        setIsOozing(false)
    }
    useEffect(()=>{
        singleProject()
        servicesPingAPI()
    },[])
    //SERVICES LIST LOADING
    const [dito, setDito] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const switcher =(bol)=>{
            if(bol===true){
                return SDAPIW
            }else{
                return SDAPIL
            }
    }
    const servicesPingAPI = async()=>{
        setIsLoading(true)
        await fetch(switcher(pitch)) //switcher(pitch) //'https://webbyjolme.com/api/j5d-servicesdisplay/'
        .then((responder)=>responder.json())
        .then((data)=>{
            const duga = data.data
            const doughnut = []
            for(const key in duga){
                doughnut.push({
                    id:key,
                    title: duga[key].title,
                    price: duga[key].price,
                    description: duga[key].description,
                    imageLarge: duga[key].imageLarge,
                    imageSmall: duga[key].imageSmall,
                    tags: duga[key].tags
                })
            }
            setDito(doughnut)
        })
        setIsLoading(false)
    }
    const Activity = ()=>{
        return(
            <Fragment>
                <section className='activity-room'>
                    <section className='content-space'>
                    {!isOozing?
                        <Fragment>
                            {userItem.map(item=>{
                                if(item.username === props.user){
                                    return(
                                        <Fragment>
                                            {item.orders.map(order=>{
                                                if(order.orderID === props.projectid){
                                                    return(
                                                        <Fragment>
                                                            <section style={{padding:"30px 30px 30px 30px", marginBottom:0}}>
                                                                <MobileClientActivityExchanges ids = {{uid:props.uid, oid:order.orderID}} />
                                                            </section>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        </Fragment>
                                    )
                                }
                            })}
                        </Fragment>:
                        <Fragment>
                        {userItem.map(item=>{
                            if(item.username === props.user){
                                return(
                                    <Fragment>
                                        {item.orders.map(order=>{
                                            if(order.orderID === props.projectid){
                                                return(
                                                    <Fragment>
                                                        <section style={{padding:"30px 30px 30px 30px", marginBottom:0}}>
                                                            <MobileClientActivityExchanges ids = {{uid:props.uid, oid:order.orderID}} />
                                                        </section>
                                                    </Fragment>
                                                )
                                            }
                                        })}
                                    </Fragment>
                                )
                            }
                        })}
                        </Fragment>}
                    </section>    
                </section>
            </Fragment>
        )
    }
    return(
        <Fragment>
            {/* <-- SNAP TO DASHBOARD */}
            <section onClick={()=>{historian.push("/dashboard")}} style={{margin:"auto", width:"60%", borderRadius:25, borderStyle:"solid", borderColor:"#16a068", borderWidth:0.5, textAlign:"center", padding:"5px 0px 5px 0px"}}>
                <span style={{position:"relative", top:-7.5,  color:"#16a068", fontSize:"12pt"}}>
                    <svg style={{position:"relative", top:8, color:"#16a068", width:30}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>
                TO DASHBOARD
                </span>
            </section>
            <section style={{margin:"auto", width:"80%", textAlign:"center"}}>
                <h1>Project Overview</h1>
            </section>
            {/* PROJECT DETAILS */}
            {!isOozing?
            <Fragment>
                {userItem.map(zuser=>{
                    return(
                        <Fragment>
                            {zuser.orders.map(item=>{
                                if(item.orderID === props.projectid ){
                                    const dTimeGot = new Date(item.dateOrdered) 
                                    return(
                                        <Fragment>
                                            <section style={{marginBottom:20, width:"100%" }}>
                                                <section style={{position:"relative", margin:"auto", width:"80%", textAlign:"center",  backgroundColor:"#ffffff", right:10, padding:"0px 10px 0px 10px", zIndex:2, top:-12}}>
                                                    <span style={{color:"#16a068", fontSize:"9pt"}}>PROJECT# {props.projectid.toUpperCase()}</span>
                                                </section>
                                                <section style={{position:"absolute", left:30, margin:"auto", width:100, paddingTop:20 }}>
                                                        {dito.map(omg=>{
                                                            if(omg.title === item.categoryChosen){
                                                                let imageSSrc = omg.imageLarge.replace('www.dropbox.com','dl.dropboxusercontent.com')
                                                                let roestyle = {
                                                                        position:"absolute",
                                                                        height:100,
                                                                        width:100,
                                                                        clip: "rect(0px,100px,100px,0px)",
                                                                        borderRadius:"100%"
                                                                }
                                                                return(
                                                                    <Fragment>
                                                                        <img src = {imageSSrc} style={roestyle} />
                                                                    </Fragment>
                                                                )
                                                            }
                                                        })}
                                                </section>
                                                        {/* <button onClick={()=>{setAllocateFund(true)}} className='client-allocate-btn' style={{position:"absolute", top:12, left:10, padding:"2px 30px 2px 30px"}}>
                                                            Allocate Fund
                                                        </button> */}
                                                <section style={{position:"relative", width:200,  left:140}}>
                                                        <h3>{item.categoryChosen}</h3>
                                                        <span style={{color:"#16a068", fontWeight:"bolder"}}>{item.progress}% Completion</span><br/><br/>
                                                        <span style={{color:"#16a068"}}>Started on {dTimeGot.getMonth()+1}/{dTimeGot.getDate()}/{dTimeGot.getFullYear()}</span><br/>
                                                        <span style={{color:"#16a068"}}>Ends on {dTimeGot.getMonth()+1}/{dTimeGot.getDate()+3}/{dTimeGot.getFullYear()}</span>
                                                </section>
                                                <section style={{position:"relative", width:200,  left:140}}>
                                                    <h3>Breakdown</h3>
                                                    <span style={{color:"#16a068"}}>Total: ${item.usdPrice*item.quantity}</span><br/>
                                                    <span style={{color:"#16a068"}}>Each: ${item.usdPrice}</span><br/>
                                                </section>
                                                <section style={{position:"relative", width:200,  left:140}}>
                                                    <h3>Ordered</h3>
                                                    <span style={{color:"#16a068"}}>{item.quantity} pcs</span><br/>
                                                </section>
                                                <section style={{position:"relative", width:200,  left:140}}>
                                                    <h3>Status</h3>
                                                    <section style={{width:"30%", backgroundColor:"#16a068", borderRadius:20, textAlign:"center", color:"#fff",  padding:"5px 10px 5px 10px"}}>
                                                        {item.status.toUpperCase()}
                                                    </section>
                                                </section> 
                                                <section style={{position:"relative", width:200,  left:140}}>
                                                    <h3>Handled By</h3>
                                                    <span style={{color:"#16a068"}}>{item.handledBy===''?'Nobody':item.handledBy}</span><br/>
                                                </section> 
                                            </section>
                                        </Fragment>
                                    )                        
                            }})}
                        </Fragment>
                        ) 
                    }
                )}
            </Fragment>:''}
            {/* <Activity /> */}
            {/* <-- SNAP TO DASHBOARD */}

        </Fragment>
    )
}

export default MobileProjectActivity