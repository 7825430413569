import React, {Fragment, useState, useEffect} from 'react'
import {orderen, querriedData} from '../../../redux/querries/userdataquery'
import { Link, useHistory } from 'react-router-dom'
import {accessSwitch} from '../variables'
import { useSelector, useDispatch } from 'react-redux';

//ENV
import { 
    SDAPIL, SDAPIW,
    OAPIL, OAPIW,
    ADUX_ALLAPIL, ADUX_ALLAPIW,
    BUDALOC_CL, BUDALOC_CW 
} from '../../../config'

const ClientCompletedListing = (props)=>{
    const {user, userid, corders} = props
    const historian = useHistory()
    const [proToggler, setProToggler] = useState(true)
    const currencyFiat = useSelector(state => state.counter.currFiat)

    //const [corders, setCorders] = useState(null)
    const [orders]= orderen
    const [dito, setDito] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const pitch = accessSwitch //true to Web-based MongoDB
    const switcher =(bol)=>{
            if(bol===true){
                return SDAPIW
            }else{
                return SDAPIL
            }
    }
    const servicesPingAPI = async()=>{
        setIsLoading(true)
        await fetch(switcher(pitch)) //switcher(pitch) //'https://webbyjolme.com/api/j5d-servicesdisplay/'
        .then((responder)=>responder.json())
        .then((data)=>{
            const duga = data.data
            const doughnut = []
            for(const key in duga){
                doughnut.push({
                    id:key,
                    title: duga[key].title,
                    price: duga[key].price,
                    description: duga[key].description,
                    imageLarge: duga[key].imageLarge,
                    imageSmall: duga[key].imageSmall,
                    tags: duga[key].tags
                })
            }
            setDito(doughnut)
        })
        setIsLoading(false)
    } 
    const handleSmallImage = ()=>{
        servicesPingAPI()
    }
    
    //CURRENCY MATTERS
    const [currencyList, setCurrencyList] = useState({
        PHP:0,
        USD:0,
        EUR:0,
        GBP:0,
        AUD:0,
        CAD:0,
        CNY:0,
        RUB:0
    })
    const currencySniffer = async()=>{
        const sniffer = await fetch("https://api.currencyfreaks.com/latest?apikey=abeb9af08b68465c8446be6e4c91fdef&symbols=PHP,GBP,EUR,AUD,CAD,RUB,CNY,USD", 
        {
            method:"GET",
            headers:{"Content-Type":"application/json"}
        }
        )
        const gotSniffed = await sniffer.json()
        if(!gotSniffed || gotSniffed === 'undefined'){
            setCurrencyList({...currencyList, 
                PHP:0.00,
                USD:0.00,
                EUR:0.00,
                GBP:0.00,
                AUD:0.00,
                CAD:0.00,
                CNY:0.00,
                RUB:0.00
            })
        }else{
            setCurrencyList({...currencyList, 
                PHP:58.85,
                USD:1.0,
                EUR:0.92,
                GBP:1.32,
                AUD:0.76,
                CAD:0.72,
                CNY:6.26,
                RUB:60.88
            })
            // setCurrencyList({...currencyList, 
            //     PHP:gotSniffed['rates']['PHP'],
            //     USD:gotSniffed['rates']['USD'],
            //     EUR:gotSniffed['rates']['EUR'],
            //     GBP:gotSniffed['rates']['GBP'],
            //     AUD:gotSniffed['rates']['AUD'],
            //     CAD:gotSniffed['rates']['CAD'],
            //     CNY:gotSniffed['rates']['CNY'],
            //     RUB:gotSniffed['rates']['RUB']
            // })
        }
    }   
    const [ordersItem, setOrdersItem] = useState([])
    const [isGashing, setIsGashing] = useState(true)
    const ordersLanded = async()=>{
                setIsGashing(true)
                const wanton = await orders
                const drearies = []
                for(const key in wanton){
                    //setIsGashing(true)
                    drearies.push({
                        orderID:wanton[key].orderID,
                        categoryChosen:wanton[key].categoryChosen,
                        usdPrice:wanton[key].usdPrice,
                        handledBy:wanton[key].handledBy
                    })
                    setOrdersItem(drearies)
                 }   
                 setIsGashing(false)
    }
    useEffect(()=>{
        setTimeout(handleSmallImage(), 1000)
            setInterval(()=>{
                ordersLanded()
            }, 2000)
    },[]) 
    return(
        <Fragment>
            {proToggler===true?<section className='client-dash'>
                <button onClick={()=>{setProToggler(false)}} style={{position:"absolute", right:20, marginTop:8, backgroundColor:"#16a068", borderWidth:0, padding:"5px 15px 5px 15px", borderRadius:25, color:"white", cursor:"pointer"}}>Minimize</button>
                <span style={{position:"absolute", left:30, marginTop:8, fontSize:"12pt", color:"#16a068", textAlign:"center"}}>COMPLETED PROJECTS</span><br/><br/>
                {!isGashing?<Fragment>
                    {corders.map(item=>{
                            let vilar = 0
                            let fcurrency = ''
                            let fconversed = ''
                            let converted = ''
                            let backer = ''
                            let currmoney = 0
                            if(item.status === 'completed'){
                                return(
                                    <Fragment>
                                        <section className='project-single'>
                                            <ul className='single-spanned'>
                                                <li>
                                                {dito.map(omg=>{
                                                    if(omg.title === item.categoryChosen){
                                                        //src={imageSSrc} 
                                                        let imageSSrc = omg.imageLarge.replace('www.dropbox.com','dl.dropboxusercontent.com')
                                                        let roestyle = {
                                                                position:"absolute",
                                                                height:72,
                                                                clip: "rect(15px,72px,72px,15px)"
                                                        }
                                                        return(
                                                            <Fragment>
                                                                <div style={{position:"relative", width:72}}>
                                                                    <img onLoad = {currencySniffer} src = {imageSSrc} style={roestyle} />
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    }
                                                 })}
                                                </li>
                                                <li>
                                                    <div style={{position:"relative", width:280, left:90}}>
                                                        <h3 style={{position:"relative", top:0, left:0}}>{item.categoryChosen}</h3>
                                                        <span style={{position:"relative", top:-20, left:0, fontSize:"9pt", color:"#16a068"}}>{item.progress}% Complete</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h3>Spent</h3>
                                                        {/* <span style={{position:"relative", top:-20, fontSize:"9pt", color:"#16a068"}}>${item.usdPrice}</span> */}
                                                        <span style={{position:"relative", top:-18, fontSize:"9pt", color:"#16a068"}}>
                                                            <b> {currencyFiat==='USD'?'$':''}
                                                                {currencyFiat==='GBP'?'£':''}
                                                                {currencyFiat==='PHP'?'₱':''}
                                                                {currencyFiat==='EUR'?'€':''}
                                                                {currencyFiat==='AUD'?'AU$':''}
                                                                {currencyFiat==='CAD'?'CA$':''}
                                                                {currencyFiat==='RUB'?'₽':''}
                                                                {currencyFiat==='CNY'?'¥':''}</b>
                                                                {item.paid.map(fund=>{
                                                                    if(fund.orderid===item.orderID){
                                                                        let array = []
                                                                        array.push(parseFloat(fund.amount))

                                                                        function disarray(total, num){
                                                                            let nija = parseFloat(total)
                                                                            let kanje = parseFloat(num)
                                                                            return nija+kanje
                                                                        }
                                                                        let total = array.reduce(disarray)
                                                                        vilar += total
                                                                        fcurrency = fund.currency 
                                                                        fconversed = fund.conversions
                                                                        converted = fconversed
                                                                        //console.log(item.paid)
                                                                        try{
                                                                            backer = {...converted}
                                                                            if(currencyFiat === 'USD'){
                                                                                currmoney+=parseFloat(backer[0]['USD'])
                                                                            }
                                                                            if(currencyFiat === 'PHP'){
                                                                                currmoney+=parseFloat(backer[1]['PHP'])
                                                                            }
                                                                            if(currencyFiat === 'EUR'){
                                                                                currmoney+=parseFloat(backer[2]['EUR'])
                                                                            }
                                                                            if(currencyFiat === 'GBP'){
                                                                                currmoney+=parseFloat(backer[3]['GBP'])
                                                                            }
                                                                            if(currencyFiat === 'AUD'){
                                                                                currmoney+=parseFloat(backer[4]['AUD'])
                                                                            }
                                                                            if(currencyFiat === 'CAD'){
                                                                                currmoney+=parseFloat(backer[5]['CAD'])
                                                                            }
                                                                            if(currencyFiat === 'CNY'){
                                                                                currmoney+=parseFloat(backer[6]['CNY'])
                                                                            }
                                                                            if(currencyFiat === 'RUB'){
                                                                                currmoney+=parseFloat(backer[7]['RUB'])
                                                                            }
                                                                        }catch(err){
                                                                                console.log(err)
                                                                        }
                                                                    }
                                                        })}{currencyFiat === fcurrency?<Fragment>{(parseFloat(vilar)).toFixed(2)}</Fragment>:<Fragment>{(parseFloat(currmoney)).toFixed(2)}</Fragment>}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h3>Artist</h3>
                                                        <span style={{position:"relative", top:-20, fontSize:"9pt", color:"#16a068"}}>{item.handledBy===''?'No One':item.handledBy}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div style={{position:"relative", left:20, top:25}}>
                                                        <button className="leave_feedback-btn">Leave Feedback</button>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div style={{position:"relative", left:0, top:25}}>
                                                        <button onClick={()=>{historian.push(`/dashboard/project/${item.orderID}`)}} className="preview-btn">Preview</button>
                                                        {/* onClick={()=>{historian.push(`/dashboard/project/${item.orderID}`)}}  */}
                                                        {/* <Link hidden style={{textDecoration:"none"}}><h3 style={{color:"#16a068", color:"grey"}}>View</h3></Link> */}
                                                    </div>
                                                </li>
                                            </ul>
                                        </section>
                                    </Fragment>
                                )
                            }
                        })}    
                    </Fragment>:<Fragment>
                    {ordersItem.map(item=>{
                            if(item.status === 'completed'){
                                return(
                                    <Fragment>
                                        <section className='project-single'>
                                            <ul className='single-spanned'>
                                                <li>
                                                    <div style={{width:75, height:75, backgroundColor:"#16a068", borderRadius:50}}>
                                                        
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h3 style={{position:"relative", top:0, left:-20}}>{item.categoryChosen}</h3>
                                                        <span style={{position:"relative", top:-20, left:-20, fontSize:"9pt", color:"#16a068"}}>80% Complete</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h3>Price</h3>
                                                        <span style={{position:"relative", top:-20, fontSize:"9pt", color:"#16a068"}}>${item.usdPrice}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h3>Handled By</h3>
                                                        <span style={{position:"relative", top:-20, fontSize:"9pt", color:"#16a068"}}>Hanx Robilon</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <Link onClick={()=>{historian.push(`/dashboard/project/${item.orderID}`)}} style={{textDecoration:"none"}}><h3 style={{color:"#16a068"}}>View</h3></Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </section>
                                    </Fragment>
                                )
                            }
                        })}    
                    </Fragment>}
            </section>:<section onClick={()=>{setProToggler(true)}} className='client-prev-projects'>
                        <span style={{position:"relative", fontSize:"12pt", color:"#c1c1c1", textAlign:"center"}}>Completed Projects</span>
            </section>}
        </Fragment>
    )
}

export default ClientCompletedListing