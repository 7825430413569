//LIBRARIES
import React, {Fragment, Link} from 'react'
import Media from 'react-media'

//VITALS
import logo from './logo.svg';
import './App.css';
import './AppMobile.css';

//COMPONENTS

import DesktopPages from './screens/desktoppipe'
import MobilePages from './screens/mobilepipe'
import TabletPages from './screens/tabletpipe'

class App extends React.Component {
  render() {
    return (
      <div>
        <Media queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1024px)",
          large: "(min-width: 1024px)"
        }}>
          {matches => (
            <Fragment>
              {matches.large && <DesktopPages />}
              {matches.small && <MobilePages  />}
              {matches.medium && <TabletPages />}
            </Fragment>
          )} 
        </Media>
      </div>
    );
  }
}

export default App;
