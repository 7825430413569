//LIBRARIES
import React, {Fragment, useState, useEffect} from 'react'
import DropboxChooser from 'react-dropbox-chooser'

//COMPONENTS
import TempServeForms from './tempforms'
import {accessSwitch} from '../variables'
import {DSAPIL, DSAPIW } from '../../../config'
import {SDAPIL, SDAPIW, DFAPIL, DFAPIW } from '../../../config'

//OPERATIONS
export const ServicesPortfolioPanel=()=>{
    const [toggle, setToggle] = useState(false)
    const [serviceConts, setServiceConts] = useState({
        title:'',
        price:'',
        description:'',
        tag:'',
        imageLarge: '',
        imageSmall:'',
        errorTyped:false
    })
    //SWITCH TO WEB OR LOCAL DB
    const pitch = accessSwitch //true for Web-based MongoDB

    const switcher =(bol)=>{
        if(bol===true){
            return DSAPIW
        }else{
            return DSAPIL
        }
    }   
    const switcher2 =(bol)=>{
        if(bol===true){
            return SDAPIW
        }else{
            return SDAPIL
        }
    }  
    const switcher3 =(bol)=>{
        if(bol===true){
            return DFAPIW
        }else{
            return DFAPIL
        }
    } 

    const postUp = async(newService)=>{
       const responsiveness = await fetch(switcher(pitch),{ //true to Web-based MongoDB
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(newService)
        })
        //const dataRevs = await responsiveness.json()
    }
    const serviceFormHandler = name => event =>{
        setServiceConts({...serviceConts, [name]:event.target.value})
    }
    const clicksFormService = (e)=>{
        e.preventDefault()
        postUp(serviceConts)
        setServiceConts({...serviceConts, title:'', price:'', description:'', tag:'', imageLarge:'', imageSmall:''})
    }
  
    const clearFields = (e)=>{
        e.preventDefault()
        setServiceConts({...serviceConts, title:'', price:'', description:'', tag:'', imageLarge:'', imageSmall:''})
    } 

    const {title, price, description, tag, imageLarge, imageSmall} = serviceConts 

    const preventDropboxAutoDirect=(e)=>{
        e.preventDefault()
    }
    const DrAPI = 'mqmr75sk507vt92'

    const handleSuccess = (files)=>{
        setServiceConts({...serviceConts, imageLarge:files[0].link, imageSmall:files[0].thumbnailLink})
    }

    const onCancel = ()=>{
        return
    }
    const ThumbImageDisplay = ()=>{
        return(
            <div className="new-service_thumb">
                <img src={imageSmall} style={{position:"absolute",backgroundColor: '#dfdfdf',top:30,right:50, borderColor: '#dfdfdf',borderWidth: 5,borderStyle:'solid'}}></img>
            </div>
        )
    }
    const [disabled, setDisabled] = useState(true)
    const checkDisabled = (e)=>{
        e.preventDefault()
        let listPortFields = [title, price, description, tag, imageLarge, imageSmall]
        listPortFields.forEach((item)=>{
            if(item===''){
                setDisabled(true)
            }else{
                setDisabled(false)
            }
        })
        
    }
    //Portfolio thing starts here
    const [portfolioConts, setPortfolioConts] = useState({
        category:'',
        tags:'',
        caption:'',
        imagePortfolio:'',
        errorTyped:false        
    })
    const {category, tags, caption, imagePortfolio} = portfolioConts

    const handlePortfolioChange = (fields)=>(event)=>{
        event.preventDefault()
        setPortfolioConts({...portfolioConts, [fields]:event.target.value})
    }

    const clearPortfolio = (e)=>{
        e.preventDefault()
        setPortfolioConts({...portfolioConts, category:'', tags:'', caption:'', imagePortfolio:''})
    }

    const handlePortfolioSuccess = (files)=>{
        setPortfolioConts({...portfolioConts, imagePortfolio:files[0].link})
    }
    const [portfolioImage, setPortfolioImage] = useState('')
  
    const PortfolioCategorySelections = ()=>{
        //const [isGoading, setIsGoading] = useState(false)
        const [ganito, setGanito] = useState([])
        useEffect(()=>{
            fetch(switcher2(pitch)) 
            .then((responder)=>responder.json())
            .then((data)=>{
                const duga = data.data
                const doughnut = []
                for(const key in duga){
                    doughnut.push({
                        id:key,
                        title: duga[key].title
                    })
                }
                setGanito(doughnut)
                console.log(doughnut.title)
            })
        },[]) 
        //category option
        const catbook = ["Select Category"]
        ganito.map((list)=>{
            catbook.push(list.title)
        })
        console.log("shaund"+catbook)
        if(ganito){
            return(
                <Fragment>
                    <select name="category" style={{width:"51%"}} id="Category" onChange={handlePortfolioChange('category')}>
                    {catbook.map((item)=>(
                        <option value={item}>{item}</option>
                    ))}
                    </select><br/><br/>
                </Fragment>
            )
        }else{
            return(
                <Fragment>
                    <option value="">No options Available</option>
                </Fragment>
            )
        }
    }
    console.log(imagePortfolio)
    // console.log(imagePortfolio.replace("?dl=0", ""))
    const ImagePortfolioShow = ()=>{
        if(imagePortfolio.length>0){
            return(
                <Fragment>
                    <h1 style={{position:"relative",fontFamily:"Baloo", textAlign:"center", top:75, cursor:"pointer"}}>Image Loaded</h1>
                </Fragment>
            )
        }else{
            return(
                <h1 style={{position:"relative",fontFamily:"Baloo", textAlign:"center", top:75, cursor:"pointer"}}>Add Image</h1>
            )
        }
    }  
    const getDisability = ()=>{
        if(!category || !tags || !caption || !imagePortfolio && ''===''){
            return true
        }else if(category.length>0 && tags.length>0 && caption.length>0 && imagePortfolio.length>0){   
            return false
        }
    }

    const storeNewPortfolio = async(newPortItem)=>{
        console.log(newPortItem)
        // const responsiveness = await fetch(`${DSAPIL}`,{
        //     method:'POST',
        //     headers:{'Content-Type':'application/json'},
        //     body:JSON.stringify(newService)
        // })
        await fetch(switcher3(pitch),{  
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(newPortItem)
        })
    }
        
    const clicksFormPortfolio = (e)=>{
        e.preventDefault() 
        storeNewPortfolio(portfolioConts)
        setPortfolioConts({...portfolioConts, category:'', tags:'', caption:'', imagePortfolio:''})
    }
    const Catmoon = ()=>{
        if(category){
            return ("Selected Category: "+category)
        }else{
            return ("Selected No Category")
        }
    }
    return(
        <Fragment>
            <div className="temp_servport_panel">
                {
                !toggle?<form onMouseOver={checkDisabled} onClick={checkDisabled} className="temp_form_serv">
                    <button className='services-portfolio-switch-btn' onClick={(e)=>{e.preventDefault(); setToggle(true)}}>Switch to Portfolio</button><br/><br/>
                    <h1>Add Services Form</h1>
                    <input type="text" style={{width:"100%"}} placeholder="Service Title"  onChange={serviceFormHandler('title')} value={title} name="title" required></input><br/><br/>
                    <input type="text" style={{width:"100%"}} placeholder="Price" value={price}  onChange={serviceFormHandler('price')} name="price" required></input><br/><br/>
                    <input type="text" style={{width:"100%"}} placeholder="Description" value={description}  onChange={serviceFormHandler('description')} name="description" required></input><br/><br/>
                    <input type="text" style={{width:"100%"}} placeholder="Tag names" value={tag} onChange={serviceFormHandler('tag')} name="tag" required></input><br/><br/><br/>
                <div className="dropbox-uploader">
                <DropboxChooser
                onClick={preventDropboxAutoDirect} 
                appKey={`${DrAPI}`}
                success={handleSuccess}
                cancel={onCancel}
                multiselect={false}
                extensions={['.jpeg', '.jpg','.png', '.svg', '.gif']}>
                Add Image 
                </DropboxChooser>
                </div><br/>
                {!handleSuccess?"":<ThumbImageDisplay />}
                <br/>
                <input disabled={disabled} type="submit" value="Create New Service" onMouseUp={checkDisabled} onMouseOver={checkDisabled} onClick={clicksFormService}></input>
                <button onMouseOver={checkDisabled} onClick={clearFields}>Clear Fields</button><br/>
                </form>:<form className="temp_form_port"><button className='services-portfolio-switch-btn' onClick={(e)=>{e.preventDefault(); setToggle(false)}}>Switch to Services</button><br/><br/><h1>Add Portfolio Form</h1>
                <h5 style={{position:"absolute", paddingTop:5, left:20}}><Catmoon/></h5>
                <PortfolioCategorySelections/><br/>
                   
                <input className="portfolio_textarea" type="text" style={{width:"50%"}} placeholder="Tags" name="tags" value={tags} onChange={handlePortfolioChange('tags')}></input><br/><br/>
                <textarea className="portfolio_textarea" type="text" style={{width:"50%", height:"100px"}}placeholder="Caption" name="caption" value={caption} onChange={handlePortfolioChange('caption')}></textarea><br/><br/>
                <input disabled={getDisability()}  type="submit" value="Create New Portfolio" onClick={clicksFormPortfolio} /><input type="submit" value="Clear Fields" onClick={clearPortfolio}></input><br/>
                {portfolioImage?"":<DropboxChooser
                onClick={preventDropboxAutoDirect} 
                appKey={`${DrAPI}`}
                success={handlePortfolioSuccess}
                cancel={onCancel}
                multiselect={false}
                extensions={['.jpeg', '.jpg','.png', '.svg', '.gif']}>
                    <div className="portfolio-click-add">
                        <ImagePortfolioShow/>
                    </div>
                </DropboxChooser>}
                
                {/* {JSON.stringify(portfolioConts)} */}
                </form>
                }                
            </div>
        </Fragment>
    )
}


export default ServicesPortfolioPanel

