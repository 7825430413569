//REGISTRY & LOGIN POINTS
export const APIL1 = process.env.REACT_APP_API1_LURL;
export const APIL2 = process.env.REACT_APP_API2_RURL;

export const API1 = process.env.REACT_APP_CORS_LOGIN_API_URL;
export const API2 = process.env.REACT_APP_CORS_REGISTER_API_URL;

//DASHBOARD SERVICE QUERRIES
export const DSAPIL = process.env.REACT_APP_DSAPIL_NEW_SERVICE_URL;
export const DSAPIW = process.env.REACT_APP_DSAPIW_NEW_SERVICE_URL;

//DASHBOARD GALLERY QUERRIES
export const DFAPIL = process.env.REACT_APP_DFAPIL_NEW_PORTFOLIO_URL; 
export const DFAPIW = process.env.REACT_APP_DFAPIW_NEW_PORTFOLIO_URL;

//SEARCH DISPLAYS
export const SDAPIL = process.env.REACT_APP_SERVICES_DESKPLAY_L_URL;
export const SDAPIW = process.env.REACT_APP_SERVICES_DESKPLAY_W_URL;

//IMAGE GALLERY DISPLAYS
export const PAAPIL = process.env.REACT_APP_PAAPIL_PORTFOLIO_URL; 
export const PAAPIW = process.env.REACT_APP_PAAPIW_PORTFOLIO_URL;

//ORDER PROCESS
export const OAPIL = process.env.REACT_APP_OAPIL_ORDER_URL; 
export const OAPIW = process.env.REACT_APP_OAPIW_ORDER_URL;

//USER PASS
export const UGAPIL = process.env.REACT_APP_USER_GUEST_APIL_URL; 
export const UGAPIW = process.env.REACT_APP_USER_GUEST_APIW_URL;

//CURRENT USER REDUX API
export const REDUX_APIL = process.env.REACT_APP_USER_REDUX_APIL;
export const REDUX_APIW = process.env.REACT_APP_USER_REDUX_APIW;

//NEWSLETTER API
export const NEWSLETTER_APIL = process.env.REACT_APP_NEWSLETTER_APIL;  
export const NEWSLETTER_APIW = process.env.REACT_APP_NEWSLETTER_APIW; 

//ADMIN'S VIEW ALL USERS REDUX API
export const ADUX_ALLAPIL = process.env.REACT_APP_ADMIN_REDUX_ALLAPIL;
export const ADUX_ALLAPIW = process.env.REACT_APP_ADMIN_REDUX_ALLAPIW;

//ADMIN'S USER DELETION API
export const DELDUX_APIL = process.env.REACT_APP_ADMIN_DELUSER_APIL;
export const DELDUX_APIW = process.env.REACT_APP_ADMIN_DELUSER_APIW;

//ADMIN'S USER'S ROLE API
export const UPDUX_APIL = process.env.REACT_APP_ADMIN_UPDUSER_APIL;
export const UPDUX_APIW = process.env.REACT_APP_ADMIN_UPDUSER_APIW;

//USER GUEST'S USERNAME UPDATING
export const CHUSERNAME_PAPIL = process.env.REACT_APP_CHUSERNAME_PAPIL;
export const CHUSERNAME_PAPIW = process.env.REACT_APP_CHUSERNAME_PAPIW;

//USER GUEST'S OLD PASSWORD CHECKING
export const CHUSER_PAPIL = process.env.REACT_APP_CHUSER_PAPIL;
export const CHUSER_PAPIW = process.env.REACT_APP_CHUSER_PAPIW; 

//USER GUEST'S PASSWORD UPDATING
export const CHUSERPASSW_PAPIL = process.env.REACT_APP_CHUSERPASW_PAPIL;
export const CHUSERPASSW_PAPIW = process.env.REACT_APP_CHUSERPASW_PAPIW;

//USER GUEST'S EMAIL UPDATING
export const CHUSERNEMAIL_PAPIL = process.env.REACT_APP_CHUSERNEMAIL_PAPIL;
export const CHUSERNEMAIL_PAPIW = process.env.REACT_APP_CHUSERNEMAIL_PAPIW;

//USER GUEST PAYMENT TOKEN
export const BRAINTREE_APIL = process.env.REACT_APP_USER_PROFILE_BRAINTREE_APIL;
export const BRAINTREE_APIW = process.env.REACT_APP_USER_PROFILE_BRAINTREE_APIW;

//USER GUEST PAYMENT CARD & PAYPAL PROCESS API
export const UBRAINTREE_PAYPIL = process.env.REACT_APP_UPROF_BRAINTREE_PAYPIL;
export const UBRAINTREE_PAYPIW = process.env.REACT_APP_UPROF_BRAINTREE_PAYPIW;

//ASSIGNER OF SERVICE PROVIDER TO A PROJECT
export const ADMINTO_S_ASSIGNER_SPROL = process.env.REACT_APP_ASSIGNER_SPROL;
export const ADMINTO_S_ASSIGNER_SPROW = process.env.REACT_APP_ASSIGNER_SPROW;

//MARKER TO MARK COMPLETE A PROJECT
export const ADM_PROCOMP_MARKERL = process.env.REACT_APP_PROJCOMPL_MARKERL;
export const ADM_PROCOMP_MARKERW = process.env.REACT_APP_PROJCOMPL_MARKERW;

//REACTIVATE MARKED COMPLETED ORDER
export const ORDER_M_ACTIVEL = process.env.REACT_APP_ORDER_REACTIVATE_L;
export const ORDER_M_ACTIVEW = process.env.REACT_APP_ORDER_REACTIVATE_W;

//ORDER TO DELETE FROM ADMIN
export const ADM_DELORDERL = process.env.REACT_APP_ORDER_DELETE_IDL;
export const ADM_DELORDERW = process.env.REACT_APP_ORDER_DELETE_IDW;

//UPLOAD PHOTO FROM GUEST DASHBOARDS
export const UPPHOTOL = process.env.REACT_APP_GUPDATE_UPHOTOL;
export const UPPHOTOW = process.env.REACT_APP_GUPDATE_UPHOTOW;

//GUEST CHAT SYSTEM
export const GCHATSYS_L = process.env.REACT_APP_GUESTCHATL;
export const GCHATSYS_W = process.env.REACT_APP_GUESTCHATW;

//GUEST CHAT RELOAD
export const GCHATSYS_REL = process.env.REACT_APP_GUESTCHATREL;
export const GCHATSYS_REW = process.env.REACT_APP_GUESTCHATREW;

//LOGGER IN BOOK
export const LOGIN_BOOKL = process.env.REACT_APP_LOGBOOK_QL;
export const LOGIN_BOOKW = process.env.REACT_APP_LOGBOOK_QW;

//LOGGER OUT BOOK
export const LOGOUTER_BQL = process.env.REACT_APP_LOGOUT_BOOK_QL;
export const LOGOUTER_BQW = process.env.REACT_APP_LOGOUT_BOOK_QW;

//CLIENT CHAT SYSTEM
export const CDISQ_L = process.env.REACT_APP_CLIENT_DISQ_L;
export const CDISQ_W = process.env.REACT_APP_CLIENT_DISQ_W;

//CLIENT CHAT SYSTEM - RELOAD
export const CDISQ_REL = process.env.REACT_APP_CLIENT_DISQ_REL;
export const CDISQ_REW = process.env.REACT_APP_CLIENT_DISQ_REW;

//CLIENT ACTIVITY SYSTEM
export const CLITAC_L = process.env.REACT_APP_CLIENTAC_L;
export const CLITAC_W = process.env.REACT_APP_CLIENTAC_W;

//CLIENT ACTIVITY SYSTEM - RELOAD
export const CLITAC_REL = process.env.REACT_APP_CLIENTAC_REL;
export const CLITAC_REW = process.env.REACT_APP_CLIENTAC_REW;

//GUEST PROFILE STATUS UPDATOR
export const GUESTAT_PL = process.env.REACT_APP_GUEST_UPDATORL;
export const GUESTAT_PW = process.env.REACT_APP_GUEST_UPDATORW;

export const GUESTAT_MSL = process.env.REACT_APP_GUEST_CHECKMSG_STATL;
export const GUESTAT_MSW = process.env.REACT_APP_GUEST_CHECKMSG_STATW;

export const GUEST_MG_INDL = process.env.REACT_APP_GUEST_CHECKMSG_REAINDICL;
export const GUEST_MG_INDW = process.env.REACT_APP_GUEST_CHECKMSG_REAINDICW;

//GUEST TO CLIENT ROLE
export const GUE_TOCLIL = process.env.REACT_APP_GUEST_TO_CLIENTL;
export const GUE_TOCLIW = process.env.REACT_APP_GUEST_TO_CLIENTW;

//CLIENT TO DEPOSIT (IN USD WHEN FIRST TIME)
export const DEPO_MONCL = process.env.REACT_APP_CDEP_CLIENTL;
export const DEPO_MONCW = process.env.REACT_APP_CDEP_CLIENTW;

//CLIENT PESO DEPOSIT
export const PESODEP_CL = process.env.REACT_APP_CPESOD_CLIENTL;
export const PESODEP_CW = process.env.REACT_APP_CPESOD_CLIENTW;

//CLIENT CHANGE PRIMARY CURRENCY
export const CURR_CL = process.env.REACT_APP_PRIMARYCURR_CLIENTL;
export const CURR_CW = process.env.REACT_APP_PRIMARYCURR_CLIENTW;

//CLIENT BUDGET ALLOCATION API
export const BUDALOC_CL = process.env.REACT_APP_BUDGET_ALLOC_CLIENTL;
export const BUDALOC_CW = process.env.REACT_APP_BUDGET_ALLOC_CLIENTW;

//CLIENT FIRST DEPOSIT API
export const FIRSTDEPO_CL = process.env.REACT_APP_FIRSTDEPO_CLIENTL; 
export const FIRSTDEPO_CW = process.env.REACT_APP_FIRSTDEPO_CLIENTW;

//CLIENT DELETE ACCOUNT API
export const DELEACC_CL = process.env.REACT_APP_DELACC_CLIENTL; 
export const DELEACC_CW = process.env.REACT_APP_DELACC_CLIENTW;

//CLIENT UPDATE ACCOUNT USERNAME API
export const UPDACCUNAME_CL = process.env.REACT_APP_UPDATEUN_CLIENTL; 
export const UPDACCUNAME_CW = process.env.REACT_APP_UPDATEUN_CLIENTW;

//CLIENT UPDATE ACCOUNT USERNAME API
export const UPDACCEMAIL_CL = process.env.REACT_APP_UPDATEEMAIL_CLIENTL; 
export const UPDACCEMAIL_CW = process.env.REACT_APP_UPDATEEMAIL_CLIENTW;

