//LIBRARIES
import React, {Fragment, useEffect, useState, useContext} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

//COMPONENTS
import DeskHeader from './deskHeader'
import DeskFooter from './deskFooter'
import DeskServicesSearch from './mobileServicesSearch'
import ReconDataResponse from './mobileServicesDefault'
import {servicesListLoaded, loadingWatcher} from './deskServicesDefault'
import { SDAPIL, SDAPIW } from '../../config'
import DefaultLoginStateContext from '../../controllers/loginContext'


const DeskServices = ()=>{
    const [search, setSearch] = useState('')
    const [searchClicks, setSearchClicks] = useState(false)
    const searchWriteHandler = name=>event=>{
        event.preventDefault()
        setSearch([name]=event.target.value)
    }
    //refresh services listed
    const contextual = useContext(DefaultLoginStateContext)
    const searchService = (e)=>{
        e.preventDefault()
        window.location.reload(true)
    }
    return(
        <Fragment>
                    <div style={{position:"relative", width:"90%", margin:"auto", textAlign:"center"}}>
                        <h1 style={{fontFamily:"Baloo", fontSize:"20pt"}}>Browse Through Our Services</h1>

                        <div style={{position:"relative", width:"100%", left:"auto"}}>
                            <input style={{position:"relative", color:"black", margin:"auto", paddingLeft:10, width:"90%", height:40, fontSize:"16pt", margin:"auto", borderWidth:1, borderStyle:"solid", borderColor:"rgba(0,0,0,0.1)", borderRadius:5}} onChange={searchWriteHandler('searchItem')} type="text" placeholder="Search... e.g Design, drawing, etc" >
                            </input>  
                            {/* <Link style={{textDecoration:"none"}}>
                                <b onClick={searchService}>Refresh</b>
                            </Link>                         */}
                        </div>
                        {!search?<ReconDataResponse/>:<DeskServicesSearch searches={search}></DeskServicesSearch>}    
                    </div>
        </Fragment>
    )
}

export default DeskServices