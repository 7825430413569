//LIBRARIES
import React, {Fragment, useState} from 'react'

//COMPONENTS
import MobilePagesHeader from './mobilePagesHeader'
import PortfolioImageContainer from '../components/portfolioComps/mobilePortfolioDisplay'
import MobileFooter from '../components/mobileFooter'
import MobileDashboardHeaderStrip from './mobileDashboardHeader'

//OPERATIONS
let MobilePortfolioPage = ()=>{
    const loggedman = JSON.parse(localStorage.getItem('j5djwt'))
    return(
        <Fragment>
            <div id="MOBILE-MAIN-BODY">
                    {!loggedman || loggedman === null?
                    <MobilePagesHeader />
                    :
                    <MobileDashboardHeaderStrip user={loggedman.username} role={loggedman.role}/>
                    }
                    <section className="mobile-main-space">
                        <PortfolioImageContainer />
                    </section>
                    <MobileFooter />
            </div>
        </Fragment>
    )
}

export default MobilePortfolioPage;