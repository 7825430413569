import React from 'react';

const Baki = ()=>{
    var dtime = new Date();
    var dyear = dtime.getFullYear();
    var strbeans = 'Copyright'; 
    return(
        <h3 className="copyrightYear">&copy; {strbeans + ' ' + dyear}</h3>
    )
}

export default Baki;