//LIBRARIES
import React, {Fragment, useState, useEffect} from 'react'
import DropboxChooser from 'react-dropbox-chooser'

//COMPONENTS
import {accessSwitch} from '../variables'
import {DSAPIL, DSAPIW } from '../../../config'
import {SDAPIL, SDAPIW, DFAPIL, DFAPIW } from '../../../config'

import DashboardSearchesAndOthers from './daskSearches'
//OPERATIONS
const DashboardPanels = (props)=>{
    return(
        <Fragment>
            <DashboardSearchesAndOthers loggeduser={props.loggeduser}/>
        </Fragment>
    )
}

export default DashboardPanels