import { createSlice } from '@reduxjs/toolkit'

//COMPONENTS
import {mixen, primeBalance, orderen, userslist, prophoto} from '../querries/userdataquery'
console.log(userslist)

const initialState = {
  value: 0,
  userDetails: {
            userUniqueID:'',
            username:'',
            discount:0,
            mobileNumber:'',
            password:'',
            email:'',
            paypalEmail:'',
            shippingAddress:'',
            balance:'',
            role:'',
            subrole:'',
            inquiries:''
          },
  orderDetails:{
    orderid:'',
    progress:'',
    categorychosen:'',
    phpprice:0,
    usdprice:0,
    paid:'',
    clientmsg:'',
    dateordered:'',
    active:false
  },
  guestIntro:true,
  allOrders:0,
  projectXView:false,
  userListsView: true,
  currBalance: 0,
  currFiat:'PHP',
  currPrimary:true,
  profilePic:'',
  messageTabTrigger:''
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    viewGuestStatusIntro(state, action){
      state.guestIntro = action.payload
    },
    proPicView:(state)=>{
        state.profilePic = prophoto
        
    },
    viewUser:(state)=>{ //this Reducer actually works
        try{
          state.userDetails.userUniqueID = mixen[0].userUID
          state.userDetails.username = mixen[0].username
          state.userDetails.discount = mixen[0].discount
          state.userDetails.mobileNumber = mixen[0].mobileNumber
          state.userDetails.password = mixen[0].password
          state.userDetails.email = mixen[0].email
          state.userDetails.paypalEmail = mixen[0].paypalEmail
          state.userDetails.shippingAddress = mixen[0].shippingAddress
          state.userDetails.balance = mixen[0].balance
          state.userDetails.role = mixen[0].role
          state.userDetails.subrole = mixen[0].subrole
          state.userDetails.inquiries = mixen[0].inquiries

        }catch(e){
          //console.log(e)
        }
    },
    viewOrder:(state)=>{ //this Reducer actually works
        try{
          if(orderen){
            const [orders]= orderen
            state.orderDetails.categorychosen = orders[0].categoryChosen
            state.orderDetails.orderid = orders[0].orderID
            state.orderDetails.progress = orders[0].progress
            state.orderDetails.clientmsg = orders[0].addedMsg
            state.orderDetails.clientmsg = orders[0].addedMsg
            // console.log(orders[0].categoryChosen)
          }
        }catch(e){
          //console.log("Error found: "+ e)
        }
    },
    viewClientOrders:(state)=>{
      try{
        if(orderen){
          const [orders]= orderen
          let sum = 0
          orders.map((item)=>{
            item.paid.map((pay)=>{
              sum+=pay.amount
            })
          })
          state.allOrders = sum
        }
      }catch(e){
        //console.log("Error found: "+ e)
      }
    },
    emptyReduxInitialState:(state)=>{
        // state.userDetails.username = null
        // state.userDetails.password = null
        // state.userDetails.email = null

        state.orderDetails.orderid = ''
        state.orderDetails.categorychosen = ''
        state.orderDetails.phpprice = ''
        state.orderDetails.usdprice = ''
        state.orderDetails.clientmsg = ''
    },
    currPrimary(state){
      const [balancer] = mixen
      try{
        if(mixen){
          const [balancer] = mixen
          balancer.balance.map(money=>{
            if(money.primary === true){
              state.currBalance = money.amount
              state.currFiat = money.currency
            }
          })
        }
      }catch(e){
        console.log(e)
      }
    },
    currBalanceView(state, action){
      const [balancer] = mixen
      try{
        if(mixen){
          const loadedCurr = action.payload
          const [balancer] = mixen
          balancer.balance.map(money=>{
            if(money.currency === loadedCurr){
              state.currBalance = money.amount
              state.currFiat = money.currency
              state.currPrimary = money.primary
            }else if(money.primary === true){
              state.currBalance = money.amount
              state.currFiat = money.currency
              state.currPrimary = money.primary
            }
          })
        }
      }catch(e){
        console.log(e)
      }
    },
    defaultCurrBalance(state){
      try{
        if(mixen){
          const [balancer] = mixen
          balancer.balance.map(money=>{
              if(money.primary === true){
                state.currBalance = money.amount
                state.currFiat = money.currency
                state.currPrimary = money.primary
              }
          })
        }
      }catch(e){
        console.log(e)
      }
      
    },
    projectXViewToggle(state, action){
      state.projectXView = action.payload
    },
    userListsViewToggle(state, action){
      state.userListsView = action.payload
    },
    messageTabToggle(state, action){
      state.messageTabTrigger = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { viewGuestStatusIntro, proPicView, viewUser, 
  defaultCurrBalance, currPrimary, currBalanceView, viewOrder, 
  viewClientOrders, emptyReduxInitialState, projectXViewToggle, 
  userListsViewToggle, messageTabToggle } = counterSlice.actions
defaultCurrBalance();
export default counterSlice.reducer