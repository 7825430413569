//LIBRARIES
import React, {Fragment, useState, useEffect, useContext, useRef} from 'react'
import {Link, useHistory} from 'react-router-dom'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Widget } from '@uploadcare/react-widget'

//COMPONENTS
import { 
    SDAPIL, 
    SDAPIW,
    OAPIL,
    OAPIW 
} from '../../config'
import {accessSwitch} from './variables'
import DefaultLoginStateContext from '../../controllers/loginContext'

//OPERATIONS
export const servicesListLoaded = (loaded)=>{
    return loaded

}
export const loadingWatcher=(data)=>{
    var [long] =[data]
    var pile = []
    for(var i in long){
        pile.push(i)
    }
    var nuker = JSON.stringify(pile)
    //console.log(nuker.length)
    //console.log(JSON.stringify(pile))
    // var sumate = pile.length
    // console.log(sumate)

        if(nuker.length>1){
            return true
        }else{
            return false
        }
}

const ReconDataResponse = ()=>{
  const [isLoading, setIsLoading] = useState(true)
  const [dito, setDito] = useState([])

  loadingWatcher(dito)
  
  const pitch = accessSwitch //true to Web-based MongoDB

  const switcher =(bol)=>{
        if(bol===true){
            return SDAPIW
        }else{
            return SDAPIL
        }
  }
  const switcher0 =(bol)=>{
    if(bol===true){
        return OAPIW
    }else{
        return OAPIL
    }
    }

  const addtlMsgRef = useRef() //for order's client' additional message

    const servicesPingAPI = async()=>{
        setIsLoading(true)
        await fetch(switcher(pitch)) //switcher(pitch) //'https://webbyjolme.com/api/j5d-servicesdisplay/'
        .then((responder)=>responder.json())
        .then((data)=>{
            const duga = data.data
            const doughnut = []
            for(const key in duga){
                doughnut.push({
                    id:key,
                    title: duga[key].title,
                    price: duga[key].price,
                    description: duga[key].description,
                    imageLarge: duga[key].imageLarge,
                    tags:duga[key].tags
                })
            }
            setDito(doughnut)
        })
        setIsLoading(false)
    } 
    useEffect(()=>{
        servicesPingAPI()
    },[])

//   const reload = ()=>{
//     setIsLoading(true)
//       fetch(switcher(pitch)) 
//       .then((responder)=>responder.json())
//       .then((data)=>{
//           const duga = data.data
//           const doughnut = []
//           for(const key in duga){
//               doughnut.push({
//                   id:key,
//                   title: duga[key].title,
//                   price: duga[key].price,
//                   description: duga[key].description,
//                   imageLarge: duga[key].imageLarge,
//                   tags:duga[key].tags
//               })
//           }
//           setDito(doughnut)
//       })
//     setIsLoading(false)      
//   }

  const [floatOver, setFloatOver] = useState(false)
  const [money, setMoney] = useState(0)
  const pingCurrency = async()=>{
      const usCurrency = 'https://api.coindesk.com/v1/bpi/currentprice/PHP.json'
      const currencyReponse = await fetch(usCurrency) 
      const recordUSResponse = await currencyReponse.json()
      let php1 = recordUSResponse.bpi.PHP.rate.replace(',','')
      let php2 = parseFloat(php1.replace(',',''))

      let usd1 = recordUSResponse.bpi.USD.rate.replace(',','')
      let usd2 = parseFloat(usd1.replace(',',''))

      //let nene = (Math.round(usd2 * 100) / 100).toFixed(2)
      let exchanges = usd2/php2
      //let moneyExchange = php/usd
      setMoney((Math.round(exchanges*100) / 100).toFixed(2))
    //   console.log(recordUSResponse.bpi.USD.rate)
    //   console.log(recordUSResponse.bpi.PHP.rate)
  }
  pingCurrency()
  const triggerSadly = (e)=>{
    e.preventDefault()
    console.log("I am truly SAD!")
    setFloatOver(false)
  }
  const triggerHappy = (e)=>{
    e.preventDefault()
    setFloatOver(true)
  }

  let jobrequest = []
  let pricerequest = []

  //SUCCESS ORDER FLOATER
  const [orderSuccessFloater, setOrderSuccessFloater] = useState('Failure!')
  const triggerClose = (e)=>{
    e.preventDefault()
    setOrderSuccessFloater('Failure!')
  }
  const [orderData, setOrderData] = useState({
      status:'',
      username:'',
      email:'',
      password:'',
      error:''
  })
  const OrderSuccess = (props)=>{
        const {status, username, email, password, error} = orderData
        const {closeMark} = props
        const historian = useHistory()
        if(closeMark==="Successful!"){
            return(
                <Fragment>
                          <div  className="quotation_rounder">
                              <div className="escape_area" onMouseDown={triggerClose}></div>
                              <div style={{width:"96%"}} className="quotation_floater">
                                  <button style={{position:"absolute", top:-46, right:-1}} className="escape_btn" onMouseDown={triggerClose}>X</button>
                                  <div style={{position:"relative", width:"80%", margin:"auto", marginBottom:30}}>
                                    {username?<div className="ordercircle"><h2>{username} your order is {status}</h2>{username==="Guest"?<p>Login with these temporary credentials <br/>and update your profile and order details.</p>:""}</div>:<div className="ordercircle"><h2>Your order is Unsuccessful!</h2></div>}
                                    {username==="Guest"?<div><h4><span style={{color:"#16a54b"}}>Email:</span><br/> {email}</h4><h4><span style={{color:"#16a54b"}}>Password:</span> <br/>{password}</h4> </div>:""}<br/>
                                    <button style={{position:"relative", backgroundColor:"black", color:"white", width:"70%", height:40, borderWidth:0, borderRadius:20, marginBottom:7}} onMouseDown={(e)=>{e.preventDefault(); historian.push("/")}}>Login</button><br/>
                                    <button style={{position:"relative", backgroundColor:"black", color:"white", width:"70%", height:40, borderWidth:0, borderRadius:20}} onMouseDown={triggerClose}>Not Now</button>
                                  </div>
                              </div>
                          </div>
                  </Fragment>
                )
        }else{
            return 
        }
  }

  const [projectTitle, setProjectTitle] = useState('') 
  const [projectPrice, setProjectPrice] = useState('')
  const [projectPriceInDollar, setProjectPriceInDollar] = useState('')

  const QuoteForm = (props)=>{
    //const loggedRecord = useContext(DefaultLoginStateContext)
    const localToken = JSON.parse(localStorage.getItem('j5djwt'))
    const currentUrole = ()=>{  //used whether to allow Guest or not to place another order
        if(!localToken){
            return ''
        }else{
            return localToken.role
        }
    }
    const currentUser= ()=>{
        if(!localToken){
            return 'Guest'
        }else{
            return localToken.username
        }
    }
    const {situation} = props

    const [fileUploaded, setFileUploaded] = useState('')//client's uploaded image url

    const [clientSays, setClientSays] = useState('')
    const handleWritingChange = name=>event=>{
        event.preventDefault()
        setClientSays([name] = event.target.value)
    }
    console.log(clientSays)

    const placeOrder=async(e)=>{
        e.preventDefault()
        const enteredAddtlMsg = addtlMsgRef.current.value
        
        var orderingClient = ''

        if(currentUser().length===0){
            orderingClient='Guest'
        }else{
            orderingClient = currentUser()
        }

        const orderSubmitted={
            orderingClient:orderingClient,
            categoryChosen: projectTitle,
            phpPrice: projectPrice,
            usdPrice: projectPriceInDollar,
            addedMsg: enteredAddtlMsg,
            addedImg: fileUploaded
        }
        console.log(orderSubmitted)

        const orderResponse = await fetch(switcher0(pitch), {
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(orderSubmitted)
        })
        const dataOrderSubmitted = await orderResponse.json()
        
        //console.log(dataOrderSubmitted)
        if(dataOrderSubmitted.status === "Successful!"){
            setFloatOver(false)
            setOrderSuccessFloater('Successful!')
            setOrderData({...{orderData}, 
                status:dataOrderSubmitted.status, 
                username:dataOrderSubmitted.username,
                email: dataOrderSubmitted.email,
                password: dataOrderSubmitted.password,
                error:dataOrderSubmitted.error
            })
        }
    }
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    return(
        <Fragment>
                <div className="quotation_rounder_mobile">
                    <section className="escape_area" style={{width:"100%", height:"100%"}} onClick={triggerSadly}></section>
                    <section style={{top:30, width:"96%", height:"80%"}} className="quotation_floater_mobile">
                        <button className="escape_btn" style={{position:"absolute",zIdex:16,  top:-45, right:0}} onClick={triggerSadly}>X</button>
                        <div>
                        {currentUser()?<div style={{borderTopLeftRadius:5, borderTopRightRadius:5}} className="profcircle"><h2>{currentUser()}'s Job Order Form</h2></div>:<div className="profcircle"><h2>Guest's Job Order Form</h2></div>}
                        {currentUrole()==='guest'?<console className="order_warning">You cannot place order more than once until you updated your profile</console>:''}
                        {currentUrole()==='client'?<console className="order_warning" style={{color:"green"}}>As our patron you can place more order as you like</console>:''}
                        </div>
                        <script
                          src="https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js">
                        </script>
                        <section style={{position:"relative", backgroundRepeat:"no-repeat"}}className="quote_form">
                            
                            <h3 style={{position:"absolute", left:0, top:-33}}>{projectTitle}</h3>
                            <h4 style={{position:"absolute", right:0, top:-33}}>{"₱"+projectPrice} or {"US$"+projectPriceInDollar}</h4>
                            {clientSays.length<80?<p style={{position:"relative", color:"green", left:0, top:28, fontSize:"8pt"}}>To proceed, write a detailed description of your order.</p>:''} 
                            
                              <textarea style={{position:"relative", height:320, backgroundRepeat:"no-repeat"}} ref={addtlMsgRef} onChange={handleWritingChange('quote')} className="quotation_text_area" placeholder="Add notes and reference links to your order"></textarea>

                            <section style={{position:"absolute", bottom:-4, left:0}}>
                            {currentUrole()==='guest'?'':<Widget style={{backgroundColor:"black"}}
                                  publicKey='c1f6acc2c018005bfb0c' 
                                  id='file' 
                                  name='file'
                                  tabs='file url'
                                  previewStep='true'
                                  onDialogClose={(file) => {
                                      console.log('File changed: ', file)
                                      if (file) {
                                      file.progress(info => console.log('File progress: ', info.progress))
                                      file.done(info => console.log('File uploaded: ', info))
                                      }
                                      }}
                                  onChange={info => setFileUploaded(info.cdnUrl) /*console.log('Upload completed:', info)*/}
                            />}    
                            </section>
                            {clientSays.length<80 || currentUrole()==='guest'?<button disabled="true" className="quotation_submit_btn" style={{backgroundColor:"grey"}}>Order not yet allowed</button>:<button onClick={placeOrder} className="quotation_submit_btn">Place Order</button>}
                        </section>
                    </section>
                </div>
        </Fragment>
    )
}
    const consortium = arrot=>arrot.sort(()=>0.5-Math.random())
    return(
        <Fragment>
            {/*UP-TO-DATE CURRENCY
                //src: https://api.coindesk.com/v1/bpi/currentprice.json
                */}
                {/*
                https://api.coindesk.com/v1/bpi/currentprice/<PHP>.json
            */}
            {/*isLoading?<h1 style={{color:"greenyellow", zIndex:2}}>Waiting for data...</h1>:''*/}
            {isLoading?<div class="spin-container">
                            <div class="spin" id="loader"></div>
                            <div class="spin" id="loader2"></div>
                            <div class="spin" id="loader3"></div>
                            <div class="spin" id="loader4"></div>
                            <span id="text">Please wait...</span>
                        </div>:<ul style={{position:"relative", left:-20, width:"100%", margin:"auto"}}>
                {consortium(dito).map((search)=>(
                    <li style={{position:"relative", width:"100%", display:"block", marginTop:50, marginBottom:200, paddingBottom:20}}>
                        <div style={{position:"relative", margin:"auto", width:"90%"}}>                         
                            <img style={{position:"absolute", margin:"auto", left:-30, height:179, clip: "rect(0px,250px,195px,0px)"}} src={search.imageLarge.replace('www.dropbox.com','dl.dropboxusercontent.com')}/>   
                            <svg style={{position:"absolute", right:0, height:180}} viewBox="0 0 386 323" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M111.806 0.240417H375.306C380.829 0.240417 385.306 4.71757 385.306 10.2404V312.24C385.306 317.763 380.829 322.24 375.306 322.24H0.305908L111.806 0.240417Z" fill="black"/>
                            </svg>   
                            <section style={{position:"absolute", right:20, textAlign:"right", paddingTop:12}}>
                                <span style={{position:"relative", right:-10,  float:"right",  backgroundColor:"black", color:"#fff", fontFamily:"Baloo", fontSize:"18pt",  padding:"5px 10px 5px 10px"}}>{search.title}</span>
                                {/* {jobrequest.push(search.title)}{pricerequest.push({amount:search.price})} */}
                                <Popup trigger={ <h3 style={{position:"relative", top:-15,  color:"white", fontSize:"10pt", right:0, fontFamily:"Segoe UI"}}>Price: P{search.price}</h3>} position="top right">
                                    <div style={{position:"relative", width:"100%", height:25, left:"20px", padding:"5px 5px 5px 5px", color:"black"}}>Exchange Rate at US${parseFloat(search.price)*money}</div>
                                </Popup>    
                                <section style={{position:"absolute", top:110,  textAlign:"right", width:200, right:0}}>
                                    <span style={{color:"white", backgroundColor:"#000", fontFamily:"Segoe UI", fontSize:"10pt", padding:"5px 5px 5px 5px"}}>{search.description}</span> 
                                </section> 
                                <form><button onClick={(e)=>{
                                    e.preventDefault(); 
                                    setFloatOver(true);
                                    setProjectTitle(search.title);
                                    setProjectPrice(search.price);
                                    setProjectPriceInDollar(parseFloat(search.price)*money);
                                    }} 
                                style={{position:"relative", bottom:16,  width:"120px", margin:"auto", backgroundColor:"#16a068", color:"#fff",  borderWidth:0, height:30, borderRadius:5}}>Order Now</button> </form>
                            </section>
                            {floatOver===true?<QuoteForm/>:""}   
                            {orderSuccessFloater==="Failure!"?"":<OrderSuccess closeMark={orderSuccessFloater}/>}           
                        </div>
                    </li>
                ))}
            </ul>}
        </Fragment>
        )
}
export default ReconDataResponse