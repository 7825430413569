//LIBRARIES
import React, {Fragment, useState} from 'react'
import {useHistory} from 'react-router-dom'
//COMPONENTS
import MobilePagesHeader from './mobilePagesHeader'
import MobileHomeLanding from './mobileHomeLanding'
import FeatureOne from './mobileHomeFeature1'
import FeatureTwo from './mobileHomeFeature2'
import FeatureThree from './mobileHomeFeature3'
import FeatureFour from './mobileHomeFeature4'
import MobileFooter from '../components/mobileFooter'
import MobileDashboardHeaderStrip from './mobileDashboardHeader'
import {emptyReduxInitialState, currPrimary, viewUser} from '../../../src/redux/slices/counter'
import { querriedData } from '../../redux/querries/userdataquery';
import { useSelector, useDispatch } from 'react-redux';

//OPERATIONS
let MobileHomePage = ()=>{
    const historian = useHistory()
    const dispatch = useDispatch()
    const token = JSON.parse(localStorage.getItem('j5djwt'))
    const details = useSelector(state => state.counter.userDetails)
    const currencyPrimary = ()=>{
        setInterval(()=>{
            dispatch(currPrimary())
            dispatch(viewUser())
        },1000)
    }
    const currencyPrimary2 = ()=>{
        setTimeout(()=>{
            dispatch(currPrimary())
            dispatch(viewUser())
        },300)
    }
    currencyPrimary()
    currencyPrimary2()
    querriedData()
    return(
        <Fragment>
            <div id="MOBILE-MAIN-BODY">
                    {!token || token === null?
                    <MobilePagesHeader />
                    :
                    <MobileDashboardHeaderStrip user={details.username?details.username:token.username} role={details.role?details.role:token.role}/>
                    }
                    
                    <MobileHomeLanding />
                    <section className="mobile-main-space">
                        <FeatureOne />
                        <FeatureTwo />
                        <FeatureThree />
                        <FeatureFour />
                    </section>
                    <MobileFooter />
            </div>
        </Fragment>
    )
}

export default MobileHomePage;