//LIBRARIES
import React, {useState, createContext} from 'react'

//COMPONENTS
import DeskDashboardPage from '../screens/components/desktopDashboard'

//OPERATIONS
var token = JSON.parse(localStorage.getItem('j5djwt'))
const DefaultLoginStateContext = createContext({
    loggerIn: '',
    username: '',
    userid:'',
    email:'',
    role: '',
    newly: false
})
export const SetLoginStateContext=(props)=>{
    const [logging, setLogging] = useState(false)// setLogging(true);
    const [roler, setRoler] = useState('')// roler is a role of a user
    const [newly, setNewly] = useState(false) // newly is for a newly registered user, intended as blip notice
    const trainer = {
        loggerIn: function(){if(!token.status){return ''}else{return token.status}},
        username: '',
        userid:'',
        email:'',
        role: function(){if(!token.role){return ''}else{return token.role}},
        newly:''
    }
    console.log(trainer)
    return(
        <DefaultLoginStateContext.Provider value={trainer}>
            {props.children}
        </DefaultLoginStateContext.Provider>
    )
}
export default DefaultLoginStateContext