//LIBRARIES
import React, {Fragment} from 'react';
import { Link } from 'react-router-dom'
import DeskHeader from './deskHeader';
import DeskFooter from './deskFooter';
import {useRouteMatch, useHistory} from 'react-router-dom'

//COMPONENTS
import PortfolioImageContainer from './portfolioComps/defaultPortfolioDisplay'
import DesktopPortfolioSinglePage from './desktopPortfolioSinglePage'

//OPERATIONS
const DeskPortfolio = ()=>{
    const cpath = window.location.pathname.split("/")
    return(
        <Fragment>
            <div id="MAIN-BODY">
                <DeskHeader />
                    <div className="log-form-space">
                                
                        <PortfolioImageContainer/>
                                    
                    </div>
                <DeskFooter />
            </div>
        </Fragment>
    )
}

export default DeskPortfolio;