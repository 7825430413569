//LIBRARY
import React, {Fragment, useEffect, useState, useContext} from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom'
import DeskHeader from './deskHeader';
import DeskFooter from './deskFooter';
import Axios from 'axios';

//COMPONENTS

const DeskDeactivationPage = ()=>{
    //TEST>
        
    //<TEST
    const historian = useHistory()

    const cancelToHome=()=>{
        historian.push('/')
    }
    return(
        <Fragment>
            <div id="MAIN-BODY">
                <DeskHeader />
                <div className="log-form-space">
                    <h1>Account Deleted</h1>
                    <section style={{position:"relative", margin:"auto", width:"60%", textAlign:"center", bottom:50}}>
                        <span style={{color:"#16a068", fontSize:"22pt", fontWeight:"bolder"}}>We are saddened to see you leave...</span> <br/><br/>
                        <button style={{width:"60%", height:50, borderRadius:25, fontSize:"16pt", borderColor:"#16a068"}} onClick={cancelToHome}> 
                            Go to Homepage
                        </button>
                    </section><br/>
                </div>
                {/* <DeskFooter /> */}
            </div>
        </Fragment>
    )
}
export default DeskDeactivationPage;