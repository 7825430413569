//LIBRARIES
import React, {Fragment, useState, useContext} from 'react'
import { Route, Redirect } from 'react-router'
import { Link, useHistory} from 'react-router-dom'

//COMPONENTS
import DeskHeader from './deskHeader'
import DeskFooter from './deskFooter'
import {APIL2, API2} from '../../config'
import DefaultLoginStateContext from '../../controllers/loginContext'
import {accessSwitch} from './variables'

//OPERATIONS
let DeskRegisterPage = ()=>{
    const pitch = accessSwitch //true to Web-based MongoDB

    const switcher =(bol)=>{
        if(bol===true){
            return API2
        }else{
            return APIL2
        }
    }

    const history = useHistory()
    const noticeMan = useContext(DefaultLoginStateContext)
    // const gotoRegister =  history.push('/register');

    const [register, setRegister] = useState({
        username: "",
        email: "",
        password: "",
        re_password: "",
        error: false
    })
    //deconstruct register
    const {username, email, password, re_password, error} = register
    const usernameChecker = ()=>{
        if(username.length>5){
            return (<div className="uchecker"><b style={{color:'green'}}>OK</b></div>)
        }else if(username.length===0){
            return ""
        }
    }
    const emailChecker=()=>{
        if(email.length===0){
            return ""
        }else if(!email.includes("@")){
            return (<div className="echecker"><b style={{color:'green'}}>@ missing</b></div>)
        }else if(!email.includes(".")){
            return (<div className="echecker2"><b style={{color:'green'}}>. missing</b></div>)
        }else{
            return (<div className="echecker3"><b style={{color:'green'}}>OK</b></div>)
        }
    }
    const pwChecker = ()=>{
        if(re_password.length>0 && re_password===password){
            return (<div className="pchecker1"><b style={{color:'green'}}>OK</b></div>)
        }else if(re_password.length===0 && password.length===0){
            return ""
        }
    }

    const handFieldChange = whatever=>event=>{
        setRegister({...register, [whatever]:event.target.value})
    }
    //catching data from API and load back to State
    const [userman, setUserman] = useState({
        user: "",
        email: "",
        password: "",
        token: ""
    })
    const registerUP = async (new_user)=>{
        console.log(new_user)
        const responsed = await fetch(switcher(pitch),{
            method:"POST",
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(new_user)
        })
        const dataCenter = await responsed.json()
        const {status} = dataCenter //deconstruct server response
        console.log("Status: "+ status)
        //setUserman({...userman, user:username, email:email, token:token})
        //console.log("MyToken: "+token)
        //setting back to empty state
        if(status==="Successful!"){
            setRegister({...register, username:"", email:"", password:"", re_password:""})
            noticeMan.newly=true
            return history.push('/register')
        }
    }
    const [blankFieldError, setBlankFieldError] = useState(false)
    const handleSubmitForm = (event)=>{
        event.preventDefault()
        const {username, email, password, re_password, error} = register
        const possibleBlanks = [username, email, password, re_password, error]
        //checking if spaces are left out
        if(possibleBlanks.includes("") || possibleBlanks.includes(" ")){
            setBlankFieldError(true) //floats warning
            console.log("Do not leave blank spaces")
        }else{
            registerUP({username:username, email:email, password:password, role: 'guest'})
        }
    }
    const clearRegistration = (e)=>{
        e.preventDefault()
        setBlankFieldError(false) //removes warning
        setRegister({...register, username:"", email:"", password:"", re_password:""})
        console.log("Spaces cleared")
    }
    const clickAwayFromRegisterStatus = ()=>{
        noticeMan.newly=false
    }
    const ErrorWarning=()=>{
        return(
            <section className="error_warning">
                <section className="centered_warning">
                    <h4 style={{color:"black"}}>Be sure to have all the fields filled out!</h4>
                    <button className="errorWarningCloseBTN" onClick={(e)=>{e.preventDefault(); setBlankFieldError(false)}}>CLOSE</button>
                </section>
            </section>
        )
    }
    const cancelToHome=(e)=>{
        e.preventDefault();
        history.push('/')
        
    }
    return(
        <Fragment>
            {/* This block up here is for a notice bar after making a successful registration */}
            {noticeMan.newly?<div style={{position:"fixed",backgroundImage:"linear-gradient(#16A068, #19CB5B)",width:"100%", height:60, bottom:0, zIndex:1, fontFamily:"Droid Sans", fontSize:"22pt", color:"white", textAlign:"center", padding:"30px 0px 10px 0px"}}>
                Successfully Registered!
                <Link to="/login"><button onClick={clickAwayFromRegisterStatus} style={{position:"relative", right:-40, top:-5, width:200, height:50, backgroundColor:"black", borderWidth:"0px", borderRadius:32, color:"white", fontFamily:"Baloo", fontSize:"14pt"}}>LOGIN</button></Link>
                <Link to="/register"><button onClick={()=>{noticeMan.newly=false}} style={{position:"relative", right:-50, top:-5, width:100, height:50, backgroundColor:"black", borderWidth:"0px", borderRadius:32, color:"white", fontFamily:"Baloo", fontSize:"14pt"}}>CLOSE</button></Link>
            </div>:''}
            <div id="MAIN-BODY">
                <DeskHeader />
                    {/* Registration Form Here */}
                    {blankFieldError?<ErrorWarning/>:''}
                    <div className="register-form-space" onMouseOver={()=>{noticeMan.newly=false}} onClick={()=>{history.push("/register")}}>
                        <h1>Register For Free!</h1>
                        <form className="register-form">
                            <label className="label">Username</label>
                            <input onChange={handFieldChange('username')} value={register.username} className="txt-field" type="text" placeholder="Username"></input><br/><br/><br/>
                            {usernameChecker()}
                            <label className="label">Email</label>
                            <input onChange={handFieldChange('email')} value={register.email}  className="txt-field" type="email" placeholder="Email"></input><br/><br/><br/>
                            {emailChecker()}
                            <label className="label">Password</label>
                            <input onChange={handFieldChange('password')} value={register.password}  className="txt-field" type="password" placeholder="Password"></input><br/><br/><br/>
                            <label className="label">Re-enter Password</label>
                            <input onChange={handFieldChange('re_password')} value={register.re_password} className="txt-field-4" type="password" placeholder="Password"></input><br/><br/><br/><br/><br/>
                            {pwChecker()}
                            <button onClick={handleSubmitForm} className="log-btn"> 
                                <b>REGISTER</b>
                            </button>
                            <button onClick={clearRegistration} className="log-clear-btn"> 
                                <b>CLEAR</b>
                            </button>
                            <button onClick={clearRegistration} className="log-canceler-btn"> 
                                <b>CANCEL</b>
                            </button>
                        </form>
                    </div>
                <DeskFooter />
            </div>
        </Fragment>
    )
}

export default DeskRegisterPage;