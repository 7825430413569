//LIBRARIES
import React, {Fragment, useEffect, useState, useContext} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

//COMPONENTS
import DeskHeader from './deskHeader'
import DeskFooter from './deskFooter'
import DeskServicesSearch from './deskServicesSearch'
import ReconDataResponse from './deskServicesDefault'
import {servicesListLoaded, loadingWatcher} from './deskServicesDefault'
import { SDAPIL, SDAPIW } from '../../config'
import DefaultLoginStateContext from '../../controllers/loginContext'


const DeskServices = ()=>{
    const [search, setSearch] = useState('')
    const [searchClicks, setSearchClicks] = useState(false)
    const searchWriteHandler = name=>event=>{
        event.preventDefault()
        setSearch([name]=event.target.value)
    }
    //refresh services listed
    const contextual = useContext(DefaultLoginStateContext)
    const searchService = (e)=>{
        e.preventDefault()
        window.location.reload(true)
    }
    return(
        <Fragment>
            <div id="MAIN-BODY">
                <DeskHeader />
                    <div className="services-space">
                        <h2 className="subtitle">You are not sure what to look for?</h2>
                        <h1>Browse Through Our Services</h1>

                        <div className="search-space">
                            <input className="service_search_bar" onChange={searchWriteHandler('searchItem')} type="text" placeholder="Search... e.g Design, Color, drawing, etc" >
                            </input>  
                            <Link>
                                <svg className="search-btn" onClick={searchService}width="264" height="42" viewBox="0 0 264 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 41.3744L15.6165 0.0497437H253.306C258.829 0.0497437 263.306 4.5269 263.306 10.0497V31.3744C263.306 36.8973 258.829 41.3744 253.306 41.3744H0Z" fill="black"/>
                                </svg>
                                <b className="search-search" onClick={searchService}>Press Refresh</b>
                            </Link>                        
                        </div>
                        {servicesListLoaded(loadingWatcher())==false?<h5 style={{color:"green", position:"absolute", zIndex:3}}>Still loading data...</h5>:''}
                        {!search?<ReconDataResponse/>:<DeskServicesSearch searches={search}></DeskServicesSearch>}    
                    </div>
                    
                <DeskFooter />
            </div>
        </Fragment>
    )
}

export default DeskServices