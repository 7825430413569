import '../App.css';
import React, {Fragment, Link} from 'react'

//COMPONENTS
import TabletNavigation from '../screens/tabletComponents/tabletnavigation'

let Tablet = ()=>{
    const redirector = ()=>{
        if(window.location.protocol === "http:"){
         setTimeout(()=>{window.location.assign(`https://jolme5d.com${window.location.pathname}`)}, 100)
        }else{
          return 
        }
    }
    redirector() //forgot this one  
    return(
        <Fragment>
            <TabletNavigation />
        </Fragment>
    )
}

export default Tablet;