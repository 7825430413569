//LIBRARIES
import React, {Fragment} from 'react';
import { Link } from 'react-router-dom'
import DeskHeader from './deskHeader';
import DeskFooter from './deskFooter';
//MATERIAL UI
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

//COMPONENTS

//OPERATIONS
const DeskAboutUs = ()=>{
    return(
        <Fragment>
            <div id="MAIN-BODY">
                <DeskHeader />
                        <section style={{
                            position:"relative", 
                            width:"80%",
                            height:300,
                            margin:"auto", 
                            marginTop:50,
                            textAlign:"center"
                            }}>
                            <h2 style={{position:"relative", margin:"auto",  fontFamily:"Baloo", fontSize:"30pt"}}>Contact Information</h2>
                            <h4>Tel#: <span style={{color:"#16a068"}}>(032) 421 5246</span></h4>
                            <h4>Mobile#: <span style={{color:"#16a068"}}>(+63) 912 668 9067</span></h4>
                            <h4>Email Address: <span style={{color:"#16a068"}}>webbyjolme@jolme5d.com</span></h4>
                        </section>
                    <Divider/>    
                    <div className="log-form-space">
                        <h2 style={{position:"relative", fontFamily:"Baloo", fontSize:"30pt", top:70, left:315}}>We are a cadre of </h2>
                        <h1 className="">Great Talents</h1>
                        <section className="about-people">
                                <Grid>
                                    <div style={{width: "250px", padding:20, margin:"auto", textAlign:"center"}}>
                                        <section className="ceo">

                                        </section>
                                        <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Connie Suerte</span> <br/>CEO</h5>
                                    </div>    
                                </Grid>     
                                <Grid>
                                    <div style={{width: "250px", padding:20, margin:"auto", textAlign:"center"}}>
                                        <section className="vice-ceo">

                                        </section>
                                        <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Jolme Limvilla</span><br/>COO</h5>
                                    </div>
                                </Grid>
                            <ul>  
                                <li className="main-people">
                                    <li>
                                        <div style={{width: "150px", padding:10, margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular1">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Mario Barabas</span><br/>Art Director</h5>
                                        </div>
                                    </li> 
                                    <li>
                                        <div style={{width: "150px", padding:10, margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular2">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Jena Ompisa</span><br/>Lead Developer</h5>
                                        </div>
                                    </li> 
                                    <li>
                                        <div style={{width: "150px", padding:10, margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular3">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Frank Gitlin</span><br/>Accounting</h5>
                                        </div>
                                    </li>   
                                    <li>
                                        <div style={{width: "150px", padding:10, margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular4">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Dimple Subarin</span><br/>HR Head</h5>
                                        </div>
                                    </li>                                                                                                                                                                                                               
                                </li>    
                                <li className="main-people">
                                    <li>
                                        <div style={{width: "150px", padding:10, margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular5">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Dino Amparo</span><br/>Supply Officer</h5>
                                        </div>
                                    </li> 
                                    <li>
                                        <div style={{width: "150px", padding:10, margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular6">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Shasha Mosa</span><br/>Marketing Head</h5>
                                        </div>
                                    </li> 
                                    <li>
                                        <div style={{width: "150px", padding:10, margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular7">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Lev De Nostre</span><br/>Quality Control</h5>
                                        </div>
                                    </li>   
                                    <li>
                                        <div style={{width: "150px", padding:10, margin:"auto", textAlign:"center"}}>
                                            <section className="profsingular8">

                                            </section>
                                            <h5><span style={{color:"#16A068", fontSize:"14pt"}}>Carly Conelio</span><br/>AfterSale</h5>
                                        </div>
                                    </li>                                                                                                                                                                                                               
                                </li>                                                                                                                                                                                        
                            </ul>
                        </section>
                        {/* <h3 className="">Our Brief History</h3> */}
                    </div>
                <DeskFooter />
            </div>
        </Fragment>
    )
}

export default DeskAboutUs;