//LIBRARY
import React, {Fragment, useState} from 'react'
import { useHistory } from 'react-router'
import Switch from '@mui/material/Switch';

//COMPONENTS

//OPERATIONS
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ClientSystemSetting = (props)=>{
    return(
        <Fragment>
                <h5 style={{textAlign:"center"}}>User Preferences</h5>
                <ul className='system-control'>
                    <li>
                        <section className='control-area'>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>NightTime</span> <br/>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>Daytime</span>

                            {/* <Switch {...label} defaultChecked />
                            <Switch {...label} disabled defaultChecked />
                            <Switch {...label} disabled /> */}
                        </section>
                    </li>
                    <li>
                        <section className='control-area'>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>NightTime</span> <br/>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>NightTime</span> <br/>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>NightTime</span> <br/>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>NightTime</span> <br/>
                        </section>
                    </li>
                    <li>
                        <section className='control-area'>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>NightTime</span> <br/>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>Daytime</span><br/>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>Daytime</span>
                        </section>
                    </li>
                    <li>
                        <section className='control-area'>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>NightTime</span> <br/>
                            <Switch {...label} color="primary" /><span style={{position:"relative", fontSize:"10pt", top:-3}}>Daytime</span>
                        </section>
                    </li>
                </ul>
                
        </Fragment>
    )
}

export default ClientSystemSetting