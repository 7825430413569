//LIBRARIES
import React, {Fragment, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'

//COMPONENTS
import {APIL2, API2,
        LOGIN_BOOKL, LOGIN_BOOKW,
} from '../../config'
import {accessSwitch} from '../../../src/screens/components/variables'
import {Logger, LoggerInfo} from '../types/loggers' //classes to segregate users
//COOKIES
import {authenticate} from './auth/auth'

let MobilePagesHeader = ()=>{
    const historian = useHistory()
    const curl = useLocation()
    let currentURL = curl.pathname.replace("/","")
    const [mTopMenu, setMTopMenu] = useState(false)
    const [mLogin, setMLogin] = useState(false)
    const [mRegister, setMRegister] = useState(false)
    const [mForgotful, setMForgotful] = useState(false)
    const [currentPage, setCurrentPage] = useState('')
    const MobileTopMenu = ()=>{
        return(
            <Fragment>
                <section style={{position:"relative", margin:"auto", top:0, width:"100%", height:800, backgroundColor:"rgba(22,33,55, 0.6)", zIndex:2}}>
                    <section  onClick={()=>{setMTopMenu(false)}} style={{position:"absolute", margin:"auto", top:0, width:"100%", height:600, backgroundColor:"rgba(0,0,0, 0.0)"}}>
                    </section>
                    <section style={{position:"relative", margin:"auto", top:65, width:"90%", height:260, padding:"10px 10px 10px 10px", backgroundColor:"white", borderRadius:5, boxShadow:"0 10px 10px 0 rgba(0, 0, 0, 0.02), 0 5px 5px 0 rgba(0, 0, 0, 0.12)"}}>
                                <ul className='mobile_menulist'>
                                    <li>
                                        <a href="/"><span style={{color:"#16a068"}} onClick={(e)=>{e.preventDefault(); historian.push('/'); setCurrentPage('HOME'); setMTopMenu(false)}}>HOME</span></a>
                                    </li>
                                    <li>
                                        <a href="/services"><span style={{color:"#16a068"}} onClick={(e)=>{e.preventDefault(); historian.push('/services'); setCurrentPage(''); setCurrentPage('SERVICES')}}>SERVICES</span></a>
                                    </li>                                    
                                    <li>    
                                        <a href="/portfolio"><span style={{color:"#16a068"}} onClick={(e)=>{e.preventDefault(); historian.push('/portfolio'); setCurrentPage('PORTFOLIO')}}>PORTFOLIO</span></a>
                                    </li>
                                    <li> 
                                        <a href="/aboutus"><span style={{color:"#16a068"}} onClick={(e)=>{e.preventDefault(); historian.push('/aboutus'); setCurrentPage('ABOUT')}}>ABOUT US</span></a>
                                    </li>  
                                </ul>
                                <section style={{position:"absolute", top:25,backgroundColor:"rgba(222,222,222)", width:2, height:"75%", right:180}}>

                                </section>
                                <ul className='mobile_menulist_2'>
                                    <li> 
                                        <a href="/faqs"><span style={{color:"#16a068"}} onClick={(e)=>{e.preventDefault(); historian.push('/faqs'); setCurrentPage('FAQs'); setMTopMenu(false)}}>FAQs</span></a>
                                    </li>
                                    <li> 
                                        <a><span style={{color:"#16a068"}} onClick={()=>{setMRegister(true); setMTopMenu(false); setMLogin(false)}}>REGISTER</span></a>
                                    </li>
                                    <li> 
                                        <a><span style={{color:"#16a068"}} onClick={()=>{setMLogin(true); setMTopMenu(false); setMRegister(false)}}>LOGIN</span></a>
                                    </li>    
                                </ul>
                            
                    </section>
                </section>
            </Fragment>
        )
    }
    const [spinLogger, setSpinLogger] = useState(false)
    const SpinLogger = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", margin:"auto", top:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0, 1.0)", zIndex:2}}>
                    <section  onClick={()=>{setSpinLogger(false)}} style={{position:"absolute", margin:"auto", top:0, width:"100%", backgroundColor:"rgba(0,0,0, 0.0)"}}>
                    </section>
                    <section onClick={()=>{setSpinLogger(false)}} style={{position:"relative",  margin:"auto", width:"100%", height:"100%",paddingTop:100, backgroundColor:"black", textAlign:"center"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>Checking record...</span>

                    </section>
                </section>
            </Fragment>
        )
    }
    
    const [wrongLogin, setWrongLogIn] = useState(false)
    const WrongLogin = ()=>{
        return(
            <Fragment>
            <section style={{position:"fixed", margin:"auto", top:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0, 1.0)", zIndex:2}}>
                <section  onClick={()=>{setSpinLogger(false)}} style={{position:"absolute", margin:"auto", top:0, width:"100%", backgroundColor:"rgba(0,0,0, 0.0)"}}>
                </section>
                <section onClick={()=>{setWrongLogIn(false); setSpinLogger(false)}} style={{position:"relative", top:100,  margin:"auto", width:"100%", height:"100%",paddingTop:100, backgroundColor:"black", textAlign:"center"}}>
                    <span style={{color:"#16a068"}}>Wrong Credentials!</span><br/><br/>
                    <button className="wrong_credential_btn" onClick={()=>{
                        historian.push("/")
                    }}>Try Again</button>
                </section>
            </section>
            </Fragment>
        )
    }
    const [tempSuccess, setTempSuccess] = useState(false)
    const TempSuccess = ()=>{
        return(
            <Fragment>
            <section style={{position:"fixed", margin:"auto", top:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0, 1.0)", zIndex:2}}>
                <section  onClick={()=>{setSpinLogger(false)}} style={{position:"absolute", margin:"auto", top:0, width:"100%", backgroundColor:"rgba(0,0,0, 0.0)"}}>
                </section>
                <section onClick={()=>{setWrongLogIn(false); setSpinLogger(false)}} style={{position:"relative",  margin:"auto", width:"100%", height:"100%",paddingTop:100, backgroundColor:"black", textAlign:"center"}}>
                    <span style={{color:"#16a068"}}>SUCCESS!</span>
                </section>
            </section>
            </Fragment>
        )
    }
    const LoginPage = ()=>{
        const [loggerInfo, setLoggerInfo] = useState({
            email:'',
            password:'',
            error:false
        })
        //LOGINS BOOK 
        const pitch = accessSwitch //true to Web-based MongoDB
        const bookLoginSwitcher =(bol)=>{
            if(bol===true){
                return LOGIN_BOOKW
            }else{
                return LOGIN_BOOKL
            }
        }

        const getLogRecorded = async(keys)=>{
            const loggerRes = await fetch(bookLoginSwitcher(pitch), {
                method:"POST",
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(keys)
            })
            const trackedLoggerRec = await loggerRes.json()
            const {status, data} = trackedLoggerRec
            if(data.modifiedCount>0){
                console.log("<<Getting Self On The Line Today>>")
            }
        }
        const handleLoggerFieldChanges =name=>event=>{
            setLoggerInfo({...loggerInfo, [name]:event.target.value })
        }
        const {email, password, error} = loggerInfo
        const [logErrorCatcher, setLogErrorCatcher] = useState(false)
        const checkLoggerInfo = async()=>{
            const loginErrorCatcher = [email, password]
            console.log(loginErrorCatcher)
            if(loginErrorCatcher.includes('') || loginErrorCatcher.includes(' ')){
                console.log("Blank gyud!")
                setLogErrorCatcher(true)
                setWrongLogIn(true) //orig
            }else{
                console.log('Logging you Inn: '+loggerInfo.email+", "+loggerInfo.password)
                const logOner = new Logger({email:loggerInfo.email, password:loggerInfo.password})
                const logStatus = await logOner.signUpProcess()
                // const statusWatcher = ()=>{if(!logStatus.status){return 'Nothing'}else{return 'Something'}}
                // console.log(statusWatcher())
                //    console.log(logStatus)
                //    setTempSuccess(true)
                //{status:status, username:username, email:email, token:token, role:role}
                if(logStatus.status==="Success" && logStatus.role==="guest"){
                    getLogRecorded({userUniqueID:logStatus.userUID})
                    authenticate(
                        logStatus,()=>{ 
                            historian.push('/dashboard')
                    }) 
                    //setTempSuccess(true)
                }else if(logStatus.status==="Success" && logStatus.role==="client"){
                    getLogRecorded({userUniqueID:logStatus.userUID})
                    authenticate(
                        logStatus,()=>{ 
                            historian.push('/dashboard')
                    }) 
                    //setTempSuccess(true)
                }else{
                    setWrongLogIn(true)
                }
            }
        }

        return(
            <Fragment>
                <section style={{position:"relative", margin:"auto", top:0, width:"100%", height:800, backgroundColor:"rgba(22,33,55, 0.6)", zIndex:2}}>
                    <section  onClick={()=>{setMLogin(false)}} style={{position:"absolute", margin:"auto", top:0, width:"100%", height:600, backgroundColor:"rgba(0,0,0, 0.0)"}}>
                    </section>
                    <section style={{position:"relative", margin:"auto", top:90, width:"90%", padding:"10px 10px 10px 10px", backgroundColor:"white", borderRadius:5, boxShadow:"0 10px 10px 0 rgba(0, 0, 0, 0.02), 0 5px 5px 0 rgba(0, 0, 0, 0.12)"}}>
                        <form style={{marginTop:20}} className='mobile_register_form'>
                            <span className='m_form_title'>Login Form</span><br/><br/><br/>
                            <section className='m_register_fields'>
                                <label>EMAIL</label><br/>
                                <input type="text" placeholder='Email Address' onChange={handleLoggerFieldChanges('email')}/><br/>
                            </section>
                            <section style={{marginBottom:20}}className='m_register_fields'>
                                <label>PASSWORD</label><br/>
                                <input type="password" placeholder='Password' onChange={handleLoggerFieldChanges('password')}/><br/>
                            </section>
                            <input className='m_register_btn' type="submit" value="SUBMIT" onClick={(e)=>{
                                e.preventDefault(); 
                                checkLoggerInfo();
                                setMLogin(false);
                                setSpinLogger(true);
                                }}/><br/>
                            <input style={{marginBottom:5}} className='m_register_cbtn' type="submit" value="CANCEL" onClick={(e)=>{e.preventDefault(); setMLogin(false)}}/><br/><br/>
                            <Link onClick={()=>{setMLogin(false); setMForgotful(true);}}><span className='m_form_forget'>Forget password?</span></Link><br/><br/><br/>
                        </form>          
                    </section>
                </section>
            </Fragment>
        )
    }
    const ForgottenPage = ()=>{
        return(
            <Fragment>
                <section style={{position:"relative", margin:"auto", top:0, width:"100%", height:800, backgroundColor:"rgba(22,33,55, 0.6)", zIndex:2}}>
                    <section  onClick={()=>{setMForgotful(false)}} style={{position:"absolute", margin:"auto", top:0, width:"100%", height:600, backgroundColor:"rgba(0,0,0, 0.0)"}}>
                    </section>
                    <section style={{position:"relative", margin:"auto", top:200, width:"90%", padding:"10px 10px 10px 10px", backgroundColor:"white", borderRadius:5, boxShadow:"0 10px 10px 0 rgba(0, 0, 0, 0.02), 0 5px 5px 0 rgba(0, 0, 0, 0.12)"}}>
                        <form style={{marginTop:20, marginBottom:20}} className='mobile_register_form'>
                            <span className='m_form_title'>Password Retrieval</span><br/><br/>
                            <span style = {{position:"absolute", width:"100%", fontSize:"10pt", textAlign:"center"}}>You'll receive a password in your email</span><br/><br/>
                            <section className='m_register_fields'>
                                <label>EMAIL</label><br/>
                                <input type="text" placeholder='Email Address'/><br/>
                            </section>
                            <input className='m_register_btn' type="submit" value="RETRIEVE PASSWORD" onClick={(e)=>{e.preventDefault(); }}/><br/>
                        </form>          
                    </section>
                </section>
            </Fragment>
        )
    }

    const [updating, setUpdating] = useState(false)
    const ProcessSpinner = ()=>{
        return(
            <Fragment>
                <section className='user-floater' onClick={(e)=>{e.preventDefault();}}>
                    
                </section>
                <section  style={{position:"fixed", top:200, left:500, width:"8%", height:"8%", backgroundColor:"rgba(0,0,0,0.0)",margin:"auto"}} className='user-floater-pad'>
                    <div class="lds-ripple"><div></div><div></div></div> <br/>
                    <span style={{position:"absolute", color:"white", top:25, left:85}}>Updating...</span>    
                </section>  
            </Fragment>
        )
    }
    const [exitStatus, setExitStatus] = useState(false)
    const RegisterSuccess = ()=>{
        return(
            <Fragment>
                <section className='user-floater' onClick={(e)=>{e.preventDefault(); setExitStatus(false);}}>
                </section>
                <section style={{width:"50%", margin:"auto"}} className='user-floater-pad'>
                        <section style={{textAlign:"center", width:"50%",  margin:"auto", padding:"30px 20px 50px 20px"}} className='user-floats-center'>
                            <span style={{fontSize:"18pt"}}>Registered Successfully!</span><br/>
                            <button style={{backgroundColor:"#16a068", borderWidth:0, borderRadius:5, padding:"5px 40px 5px 40px", marginRight:2, marginTop:20}}
                            onClick={(e)=>{e.preventDefault(); 
                                setExitStatus(false);
                            }}>EXIT</button>
                        </section> 
                </section> 
            </Fragment>
        )
    }

    const turnOffRegisterPage = ()=>{
        setMRegister(false)
        setExitStatus(true)
    }
    const RegisterPage = ()=>{
        const [mRegistered, setMRegistered] = useState({
            username:'',
            email:'',
            password:''
        })
        const handleMRegistration = fieldname=>event=>{
                setMRegistered({...mRegistered, [fieldname]:event.target.value})
        }
        const [goGreen, setGoGreen] = useState('')
        const pitch = accessSwitch //true to Web-based MongoDB
        const switcher =(bol)=>{
            if(bol===true){
                return API2
            }else{
                return APIL2
            }
        }
        const registerNewMuser = async (new_user)=>{
            const responsed = await fetch(switcher(pitch),{
                method:"POST",
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(new_user)
            })
            const dataCenter = await responsed.json()
            const {status} = dataCenter //deconstruct server response

            if(status==="Successful!"){
                setMRegistered({...mRegistered, username:"", email:"", password:""})
                setUpdating(false)
                turnOffRegisterPage()
            }
        }

        const processMInfo = (e)=>{
            e.preventDefault()
            if(mRegistered.username === "" || mRegistered.email === "" || mRegistered.password === ""){
                setGoGreen('Ops! Some fields are left empty.')
            }else{
                setGoGreen('You are good to go!')
                registerNewMuser({username:mRegistered.username, email:mRegistered.email, password:mRegistered.password, role:'guest'})
                setUpdating(true)
            }
        }

        return(
            <Fragment>
                <section style={{position:"relative", margin:"auto", top:0, width:"100%", height:800, backgroundColor:"rgba(22,33,55, 0.6)", zIndex:2}}>
                    <section  onClick={()=>{setMLogin(false); setMRegister(false); setMTopMenu(false);}} style={{position:"absolute", margin:"auto", top:0, width:"100%", height:600, backgroundColor:"rgba(0,0,0, 0.0)"}}>
                    </section>
                    <section style={{position:"relative", margin:"auto", top:40, width:"90%", padding:"10px 10px 10px 10px", backgroundColor:"white", borderRadius:5, boxShadow:"0 10px 10px 0 rgba(0, 0, 0, 0.02), 0 5px 5px 0 rgba(0, 0, 0, 0.12)"}}><br/>
                        <form className='mobile_register_form'>
                            <span className='m_form_title'>Registration Form<br/><span style={{position:"relative", top:-15, color:"red", fontSize:"8pt"}}>{goGreen}</span><br/></span><br/><br/><br/>
                            
                            <section className='m_register_fields'>
                                <label>USERNAME</label><br/>
                                <input onChange={handleMRegistration('username')} type="text" placeholder='Username'></input><br/>
                            </section>
                            
                            <section className='m_register_fields'>
                                <label>EMAIL</label><br/>
                                <input onChange={handleMRegistration('email')} type="text" placeholder='Email Address'></input><br/>
                            </section>
                            
                            <section className='m_register_fields'>
                                <label>PASSWORD</label><br/>
                                <input onChange={handleMRegistration('password')} type="password" placeholder='Password'></input><br/>
                            </section>
                            <input className='m_register_btn' type="submit" value="SUBMIT" onClick={processMInfo}/><br/>
                            <input className='m_register_cbtn' type="submit" value="CANCEL" onClick={(e)=>{e.preventDefault(); setMRegister(false)}}/><br/>
                        </form>        
                    </section>
                </section>
                {updating?<ProcessSpinner />:''}
            </Fragment>
        )
    }
    return(
        <Fragment>
            <section className="header_space">
                        <Link to="/">
                            <section style={{position:'absolute', top:5, left:15}}>
                                <img src="../../logo_jolme5d.png"></img>
                                <h3 style={{position:"absolute", top:-13, left:40, color:"#16A068"}}>JOLME5D</h3>
                            </section>
                        </Link>
                        <span style={{position:"absolute", top:10, right:55,color:"#16a068", fontWeight:"600", fontSize:"14pt"}}>{currentURL.toUpperCase()}</span>
                        <section>
                        {!mTopMenu?<Link to="/" onClick={(e)=>{e.preventDefault(); setMTopMenu(true)}}><svg style={{position:"absolute", top:7, right:10, width:35, color:"#16a068"}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
</svg></Link>:<Link to="/" onClick={(e)=>{e.preventDefault(); setMTopMenu(false)}}><svg style={{position:"absolute", top:7, right:10, width:35, color:"#16a068"}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
</svg></Link>}
                        </section>
                        {mTopMenu?<MobileTopMenu />:''}
                        {mLogin?<LoginPage/>:''}
                        {mRegister?<RegisterPage />:''}
                        {mForgotful?<ForgottenPage/>:''}
                        {exitStatus?<RegisterSuccess/>:''}
                        {spinLogger?<SpinLogger />:''}
                        {wrongLogin?<WrongLogin />:''}
                        {tempSuccess?<TempSuccess />:''}
            </section>
        </Fragment>
    )
}

export default MobilePagesHeader;