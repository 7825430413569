//LIBRARIES
import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import 'braintree-web'
import DropIn from 'braintree-web-drop-in-react'
import { Widget } from '@uploadcare/react-widget'
import { useSelector, useDispatch } from 'react-redux'
import { proPicView, viewUser } from '../../../redux/slices/counter'

//COMPONENTS
import CounterView from '../../../redux/viewslices/counterview'
import {CounterOrderView } from '../../../redux/viewslices/counterview'
import { pitch } from '../variables'
import { 
    BRAINTREE_APIL, BRAINTREE_APIW, 
    CHUSER_PAPIL, CHUSER_PAPIW,
    CHUSERNAME_PAPIL,
    CHUSERNAME_PAPIW,
    CHUSERPASSW_PAPIL,
    CHUSERPASSW_PAPIW,
    CHUSERNEMAIL_PAPIL,
    CHUSERNEMAIL_PAPIW,
    UBRAINTREE_PAYPIL,
    UBRAINTREE_PAYPIW,
    UPPHOTOL,
    UPPHOTOW,
    UPDUX_APIL, UPDUX_APIW,
    GUE_TOCLIL, GUE_TOCLIW,
    FIRSTDEPO_CL, FIRSTDEPO_CW
    } from '../../../config'
import {mixen, orderen, prophoto} from '../../../redux/querries/userdataquery'
import {querriedData} from '../../../redux/querries/userdataquery'
import { json } from 'body-parser'

//OPERATIONS


export const ProfileDetails = (props)=>{
    //PROFILE Picture
    const photoProfile = useSelector(state => state.counter.profilePic)
    const detailsOfUser = useSelector(state=> state.counter.userDetails)

    const dispatch = useDispatch()

    const loadProfilePic = ()=>{
        setInterval(()=>{
            dispatch(proPicView())
            dispatch(viewUser())
        },1000)
    }
    loadProfilePic() //triggering to reload every 1 second
    const {username, password, profileImage, uid, urole, email} = props
    var roler = urole
    var firstat = roler.charAt(0).toUpperCase()
    var breaker = roler.split("")
    var fallOff = breaker.shift()
    var glueRole = firstat+breaker.join("")
    
    const historian = useHistory()
    const [nFields, setNFields] = useState({
        new_username:'',
        old_password:'',
        new_password:'',
        new_email:''
    })

    const [checkedUsn, setCheckedUsn] = useState('')
    const chuserNameSwitcher = (pitch)=>{
        if(pitch===true){
            return CHUSERNAME_PAPIW
        }else{
            return CHUSERNAME_PAPIL
        }
    }
    const uploadPhotoSwitcher = (pitch)=>{
        if(pitch===true){
            return UPPHOTOW
        }else{
            return UPPHOTOL
        }
    }
    const updatingUsername = async(uid, newUsername)=>{
        console.log("sending these: "+uid +" "+newUsername)
        const checkUsn = await fetch(chuserNameSwitcher(pitch),{
            method:'POST',
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:uid, username:newUsername})
        })
        const deconstUsn = await checkUsn.json()
        const {status, error, udata} = deconstUsn
        setCheckedUsn(status)
        console.log("Username Update: "+checkedUsn)
        console.log("Data Received: "+udata)
        console.log("Error: "+error)
    }
    const getUpdatedUsername = (e)=>{
        e.preventDefault()
        updatingUsername(uid, nFields.new_username)
        
    }
    
    //comparing password
    const [checkedPw, setCheckedPw] = useState('')
    const chuserOPassSwitcher = (pitch)=>{
        if(pitch===true){
            return CHUSER_PAPIW
        }else{
            return CHUSER_PAPIL
        }
    }
    const comparePassword = async(oldPassword)=>{
        const checkPassw = await fetch(chuserOPassSwitcher(pitch),{
            method:'POST',
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({password:oldPassword})
        })
        const deconstPass = await checkPassw.json()
        const {status, password} = deconstPass
        setCheckedPw(status)
    }
    const getOldPassword = (e)=>{
        e.preventDefault()
        comparePassword(nFields.old_password)
    }

    //creating new password
    const [checkedNewPass, setCheckedNewPass] = useState('')
    const chuserNewPassWSwitcher = (pitch)=>{
        if(pitch===true){
            return CHUSERPASSW_PAPIW
        }else{
            return CHUSERPASSW_PAPIL
        }
    }
    const resetPassword = async(uid, newPassword)=>{
        const resetPassword = await fetch(chuserNewPassWSwitcher(pitch),{
            method:'POST',
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:uid, password:newPassword})
        })
        const deconstNewPass = await resetPassword.json()
        const {status, error, udata} = deconstNewPass
        setCheckedNewPass(status)
    }
    const getNewPassword = (e)=>{
        e.preventDefault()
        resetPassword(uid, nFields.new_password)
    }

    //email updating
    const [checkedNewEmail, setCheckedNewEmail] = useState('')
    const chuserNewEmailSwitcher = (pitch)=>{
        if(pitch===true){
            return CHUSERNEMAIL_PAPIW
        }else{
            return CHUSERNEMAIL_PAPIL
        }
    }
    const updatingEmail = async(uid, newEmail)=>{
        const resetEmail = await fetch(chuserNewEmailSwitcher(pitch),{
            method:'POST',
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:uid, email:newEmail})
        })
        const deconstNewEmail = await resetEmail.json()
        const {status, error, udata} = deconstNewEmail
        setCheckedNewEmail(status)
    }
    const getUpdatedEmail = (e)=>{
        e.preventDefault()
        updatingEmail(uid, nFields.new_email)
        
    }
    const handleUpdatedInfo = (newinfo)=>(event)=>{
        event.preventDefault()
        setNFields({...nFields, [newinfo]:event.target.value})
    }
    const UpdaterSuccess = ()=>{
        return(
            <Fragment>
                <div style={{position:"fixed", zIndex:2, width:"100%", height:"100%", margin:"auto", top:0, left:0, backgroundColor:"rgba(0,0,0,0.5)"}}>
                    <section style={{position:"relative", zIndex:3, margin:"auto", top:"30%", backgroundColor:"rgba(222,222,222)", textAlign:"center", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", padding:"30px 20px 40px 20px"}}>
                            <span style={{fontSize:"22pt"}}>Updated Successfully!</span><br/>
                            <section style={{width:"20%", margin:"auto"}}>
                                <p>
                                    This is just one of the processes needed to complete your profile.
                                </p>
                            </section>
                            <button className='profile_update_succ_btn' onClick={()=>{
                                setCheckedUsn('');
                                setCheckedNewPass('');
                                setCheckedNewEmail('');
                                window.location.reload(true);
                                }} >
                                Many Thanks
                            </button>
                    </section>
                </div>
               
            </Fragment>
        )
    }
    //UPLOADING PROFILE PHOTO
    const [photoUploadTrigger, setPhotoUploadTrigger] = useState(false)
    const [fileUploaded, setFileUploaded] = useState('')//client's uploaded image url
    const [imageData, setImageData] = useState({width:0, height:0})
    const [processUpload, setProcessUpload] = useState(false)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [uploadFailed, setUploadFailed] = useState(false)

    let diff = 0
    let multiplyer = 0
    let minLeft = 0 //percentage difference left
    let minimum = 25 //percentage allowed
    if(imageData.width>imageData.height){
        diff+=imageData.width-imageData.height
        multiplyer+=imageData.width*0.01
        minLeft += diff/multiplyer
    }else{
        diff+=imageData.height-imageData.width
        multiplyer+=imageData.height*0.01
        minLeft += diff/multiplyer
    }

    const uploadProfileImage = async()=>{
        setPhotoUploadTrigger(false)
        setProcessUpload(true)
        const uploadImageQuery = await fetch(uploadPhotoSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:uid, profileImage:fileUploaded})
        })
        const uploadGetsContact = await uploadImageQuery.json()
        const {status, data} = uploadGetsContact
        if(data.modifiedCount>0){
            setProcessUpload(false)
            setUploadSuccess(true)
        }else{
            setProcessUpload(false)
            setUploadFailed(true)
        }
    }
    const UploadingProcess = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessUpload(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                            <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                            <span style={{position:"absolute", color:"#fff", top:35, right:85}}>Image Upload</span> <br />
                            <span style={{position:"absolute", color:"#fff", fontSize:"22pt", fontWeight:"bolder", top:50, right:85}}>PROCESSING</span>
                    </section>
                </section>
            </Fragment>
        )
    }
    const SuccessfulUpload = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                </section>
                <section style={{position:"relative", zIndex:2, top:-400, margin:"auto", left:"auto", width:"60%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", margin:"auto", top:20, width:400, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"10px 10px 10px 10px"}}>
                        <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                            <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image Succeeded!</span><br/>
                        </section>
                        <button onClick={()=>{setUploadSuccess(false); window.location.reload()}} style={{position:"absolute", top:10, right:20, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                            EXIT
                        </button>
                    </section>
                    
                </section>
            </Fragment>
        )
    }
    const FailedUpload = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                </section>
                <section style={{position:"relative", zIndex:2, top:-400, margin:"auto", left:"auto", width:"60%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", margin:"auto", top:20, width:400, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"10px 10px 10px 10px"}}>
                        <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                            <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image has failed!</span><br/>
                        </section>
                        <button onClick={()=>{setUploadFailed(false)}} style={{position:"absolute", top:10, right:20, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                            EXIT
                        </button>
                    </section>
                    
                </section>
            </Fragment>
        )
    }

    const PhotoUploader = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                </section>
                <section style={{position:"relative", zIndex:2, top:-400, margin:"auto", left:"auto", width:"80%", borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                    <button onClick={()=>{setPhotoUploadTrigger(false);}} className='deposit-close-btn' style={{position:"position",backgroundColor:"white", color:"#16a068", right:-55, padding:"16px 20px 16px 20px"}}><span style={{fontWeight:"bold"}}>X</span>
                    </button>
                    <section style={{position:"relative", margin:"auto", top:45, width:500, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"20px 20px 20px 20px"}}>
                        <section style={{position:"relative", margin:"auto", zIndex:2, top:-32, width:200, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                            <span style={{fontWeight:"bolder", color:"#16a068"}}>Upload A Profile Picture</span><br/>
                            <span style={{fontWeight:"500", fontSize:"7pt", color:"#16a068"}}>Only upload image with equal sides' length</span>
                        </section>
                        <section style={{position:"relative", top:-10}}>
                            <Widget style={{backgroundColor:"black", top:0}}
                                            publicKey='c1f6acc2c018005bfb0c' 
                                            id='file' 
                                            name='file'
                                            tabs='file url'
                                            previewStep='true'
                                            onDialogClose={(file) => {
                                                console.log('File changed: ', file)
                                                if (file) {
                                                file.progress(info => console.log('File progress: ', info.progress))
                                                file.done(info => console.log('File uploaded: ', info))
                                                }
                                                }}
                                            onChange={(info) => {
                                                    setFileUploaded(info.cdnUrl); /*console.log('Upload completed:', info)*/
                                                    setImageData({width:info.originalImageInfo.width, height:info.originalImageInfo.height})} 
                                            }
                            />
                        </section>
                        {fileUploaded !=='' && minLeft<minimum?
                                <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                    <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                    <b>You can upload this picture</b>
                                </section>
                                :
                                <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                                    <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                                    <b>Cannot be uploaded! Must be nearly equal in both sides.</b>
                                </section>
                        }
                        <section>
                            <img src={fileUploaded} width="500"/>
                        </section>
                        {fileUploaded==='' || minLeft>minimum?
                        <button className='guest-upload-btn' style={{backgroundColor:"#a1a1a1"}}>
                            No Image or Image Have Problems
                        </button>:
                        <button className='guest-upload-btn' onClick={uploadProfileImage}>
                        Upload Image
                    </button>}
                    </section>
                    
                </section>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <section className="profile_image" >
                {photoProfile===''?
                <a style={{position:'absolute', top:40, left:40}} href="" onClick={(e)=>{e.preventDefault(); setPhotoUploadTrigger(true)}}>
                    {/* <svg width="57" height="46" viewBox="0 0 57 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0578 0C13.9532 0 13.0578 0.895432 13.0578 2V7.38566H42.012V2C42.012 0.89543 41.1165 0 40.0119 0H15.0578ZM0 12.2C0 10.5431 1.34315 9.2 3 9.2H54C55.6569 9.2 57 10.5431 57 12.2V43C57 44.6569 55.6568 46 54 46H3C1.34314 46 0 44.6568 0 43V12.2ZM42.2903 26.68C42.2903 34.3016 36.1162 40.48 28.5 40.48C20.8838 40.48 14.7097 34.3016 14.7097 26.68C14.7097 19.0585 20.8838 12.88 28.5 12.88C36.1162 12.88 42.2903 19.0585 42.2903 26.68ZM48.7258 20.24C50.249 20.24 51.4839 19.0043 51.4839 17.48C51.4839 15.9557 50.249 14.72 48.7258 14.72C47.2026 14.72 45.9677 15.9557 45.9677 17.48C45.9677 19.0043 47.2026 20.24 48.7258 20.24ZM40.4516 26.68C40.4516 33.2853 35.1007 38.64 28.5 38.64C21.8993 38.64 16.5484 33.2853 16.5484 26.68C16.5484 20.0747 21.8993 14.72 28.5 14.72C35.1007 14.72 40.4516 20.0747 40.4516 26.68Z" fill="black"/>
                    </svg> */}
                    <svg style={{position:"relative", left:0,margin:"auto"}} width="100%" height="66" viewBox="0 0 57 60.2" xmlns="http://www.w3.org/2000/svg" >
                        <path class="st0" d="M14.9,0c-1.1,0-2,0.9-2,2v5.4h29V2c0-1.1-0.9-2-2-2H14.9z M-0.2,12.2c0-1.7,1.3-3,3-3h51c1.7,0,3,1.3,3,3V43
                            c0,1.7-1.3,3-3,3h-51c-1.7,0-3-1.3-3-3V12.2z M42.1,26.7c0,7.6-6.2,13.8-13.8,13.8s-13.8-6.2-13.8-13.8s6.2-13.8,13.8-13.8
                            S42.1,19.1,42.1,26.7z M48.5,20.2c1.5,0,2.8-1.2,2.8-2.8s-1.2-2.8-2.8-2.8s-2.7,1.4-2.7,2.9S47,20.2,48.5,20.2z M40.3,26.7
                            c0,6.6-5.4,12-12,12s-12-5.4-12-12s5.4-12,12-12S40.3,20.1,40.3,26.7z"/>
                        <g>
                            <path d="M12,53.7c0,2.5-1.2,3.8-3.5,3.8c-2.3,0-3.4-1.2-3.4-3.7v-4.9H7v4.9c0,1.4,0.5,2,1.6,2c1,0,1.6-0.7,1.6-2v-4.9H12V53.7z"/>
                            <path d="M15.7,54.4v2.9h-1.9v-8.4h3c2.1,0,3.2,0.9,3.2,2.7c0,0.8-0.3,1.5-0.9,2s-1.4,0.8-2.4,0.8H15.7z M15.7,50.4V53h0.7
                                c1,0,1.5-0.4,1.5-1.3c0-0.9-0.5-1.3-1.5-1.3H15.7z"/>
                            <path d="M26.2,57.3h-5v-8.4h1.9v6.9h3.1V57.3z"/>
                            <path d="M30.6,57.5c-1.2,0-2.2-0.4-2.9-1.2s-1.1-1.8-1.1-3.1c0-1.3,0.4-2.4,1.2-3.2s1.8-1.2,3.1-1.2c1.2,0,2.2,0.4,2.9,1.2
                                s1.1,1.8,1.1,3.1c0,1.3-0.4,2.4-1.2,3.2S31.8,57.5,30.6,57.5z M30.7,50.4c-0.7,0-1.2,0.2-1.6,0.7s-0.6,1.2-0.6,2
                                c0,0.8,0.2,1.5,0.6,2s0.9,0.7,1.5,0.7c0.7,0,1.2-0.2,1.6-0.7s0.6-1.1,0.6-2c0-0.9-0.2-1.5-0.6-2S31.3,50.4,30.7,50.4z"/>
                            <path d="M43.4,57.3h-2.1l-0.6-1.9h-3l-0.6,1.9h-2.1l3.1-8.4h2.2L43.4,57.3z M40.3,54l-0.9-2.8c-0.1-0.2-0.1-0.5-0.1-0.8h0
                                c0,0.2-0.1,0.5-0.1,0.7L38.1,54H40.3z"/>
                            <path d="M44.4,57.3v-8.4h3c3,0,4.5,1.4,4.5,4.1c0,1.3-0.4,2.4-1.2,3.1s-1.9,1.2-3.3,1.2H44.4z M46.3,50.5v5.3h0.9
                                c0.8,0,1.5-0.2,1.9-0.7s0.7-1.2,0.7-2c0-0.8-0.2-1.4-0.7-1.9s-1.1-0.7-1.9-0.7H46.3z"/>
                        </g>
                        <circle class="st1" cx="28.3" cy="26.7" r="7.6"/>
                    </svg>                   
                </a>:
                <img src= {photoProfile} style={{position:"absolute", top:0, left:0, borderRadius:"50%", width:137, cursor:"pointer"}} onClick={(e)=>{e.preventDefault(); setPhotoUploadTrigger(true)}}/>                 
                }
            </section>
            <h1 className="user_name">{detailsOfUser.username}</h1>
            <console className="user_id">{uid.toUpperCase()}</console><br/>
            <b className="user_role">{glueRole}</b>
            <div className="vertical_line"></div>
            <section className="updator_box">
                <div className="f_updator">
                    {nFields.new_username.length<5?<span style={{fontSize:"8pt", color:"#16a068"}} >Must be more than 5 characters<br/></span>:''}
                    <label htmlFor="">Username</label>
                    <input type="text" placeholder={detailsOfUser.username} onChange = {handleUpdatedInfo('new_username')}/>
                    {nFields.new_username.length<5?<button className="updator_btn" style={{backgroundColor:"grey"}}>Update</button>:<button className="updator_btn" onClick={getUpdatedUsername} >Update</button>}
                </div>
                <div className="f_updator-s">
                    {checkedPw==='Failure'?<svg xmlns="http://www.w3.org/2000/svg" style={{position:"absolute", right:4, top:2, color:"red", width:20}} class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                    </svg>:''}
                    <label htmlFor="">Old Password</label>
                    <input type="password" placeholder="old password" onChange = {handleUpdatedInfo('old_password')} onBlur={getOldPassword} />
                    {checkedPw==='Success'?<svg xmlns="http://www.w3.org/2000/svg" style={{position:"absolute", right:4, top:2, color:"#16a068", width:20}} class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>:''}
                </div>
                <div className="f_updator">
                    {nFields.new_password.length<5?<span style={{fontSize:"8pt", color:"#16a068"}} >Must be more than 5 characters<br/></span>:''}
                    <label htmlFor="">New Password</label>
                    <input type="password" placeholder="new password" onChange = {handleUpdatedInfo('new_password')}/>
                    {checkedPw==='Success' && nFields.new_password.length>5?<button className="updator_btn-s" onClick={getNewPassword} >Update</button>:<button className="updator_btn-s" style={{backgroundColor:"grey"}}>Update</button>}
                    
                </div>
                <div className="f_updator">
                    {!nFields.new_email.includes('@') || !nFields.new_email.includes('.')?<span style={{fontSize:"8pt", color:"#16a068"}} >Must have '@' or '.' symbols<br/></span>:''}
                    <label htmlFor="">Email</label>
                    <input type="text" placeholder={detailsOfUser.email} onChange = {handleUpdatedInfo('new_email')}/>
                    {!nFields.new_email.includes('@') || !nFields.new_email.includes('.')?<button className="updator_btn" onClick={getUpdatedEmail} style={{backgroundColor:"grey"}}>Update</button>:<button className="updator_btn" onClick={getUpdatedEmail} >Update</button>}
                </div>
            </section>  
            {checkedUsn==="Success"?<UpdaterSuccess />:''} 
            {checkedNewPass==="Success"?<UpdaterSuccess />:''} 
            {checkedNewEmail==="Success"?<UpdaterSuccess />:''}
            {photoUploadTrigger === true? <PhotoUploader />:''}
            {processUpload === true?<UploadingProcess />:''}
            {uploadSuccess === true?<SuccessfulUpload />:''}
            {uploadFailed === true?<FailedUpload />:''}
        </Fragment>
    )
}

//PAYMENT TAB
export const PaymentDetails = ()=>{
    const [selectedPay, setSelectedPay] = useState({
        Paypal:'',
        TransferWise:'',
        Crypto:'',
        VISA:'',
        MasterCards:'',
        UnionPay:'',
        Mir:'',
        PalawanPawnshop:'',
        MLhuillier:'',
        CebuanaLhuillier:'',
        GCash:'',
        PayMaya:''
    })
    const [selectionTriggered, setSelectionTriggered] = useState(false)
    //let methods = []
    // const rampAll = ()=>{
    //     setSelectedPay(methods)
    //     console.log(selectedPay)
    // }
    const getSelectedPayMethod = pay=>event=>{
        event.preventDefault()
        setSelectedPay({...selectedPay,[pay]:event.target.value})
        setSelectionTriggered(true)
    }
    console.log(selectedPay)
    return (
        <Fragment>
            <section>
                <section style={{position:"relative", borderColor:"#f2f2f2", borderWidth:1, borderRadius:5, borderStyle:"solid", marginBottom:50}}>
                    <span style={{position:"absolute", top:-20, left:20,color:"#16a068", fontSize:"16pt", backgroundColor:"#f2f2f2", borderRadius:20, padding:"2px 20px 5px 20px"}}>Your Preferred Payment Methods</span>
                    <section style={{margin:"50px 50px 50px 50px", textAlign:"left"}}>
                        {selectionTriggered===false?
                        <span style={{position:"relative", left:200, color:"#16a068", textAlign:"center"}}>You have no preferred payment methods yet</span>
                        :<Fragment>
                            <ul className='paymethods'>
                                <li><span>{selectedPay.Paypal}<br/></span></li>
                                <li><span>{selectedPay.TransferWise}<br/></span></li>
                                <li><span>{selectedPay.Crypto}<br/></span></li>
                                <li><span>{selectedPay.VISA}<br/></span></li>
                                <li><span>{selectedPay.MasterCards}<br/></span></li>
                                <li><span>{selectedPay.UnionPay}<br/></span></li>
                                <li><span>{selectedPay.Mir}<br/></span></li>
                                <li><span>{selectedPay.PalawanPawnshop}<br/></span></li>
                                <li><span>{selectedPay.MLhuillier}<br/></span></li>
                                <li><span>{selectedPay.CebuanaLhuillier}<br/></span></li>
                                <li><span>{selectedPay.GCash}<br/></span></li>
                                <li><span>{selectedPay.PayMaya}<br/></span></li>
                            </ul>
                        </Fragment>}
                    </section>
                </section>
                <section style={{position:"relative", height:300, borderColor:"#f2f2f2", borderWidth:1, borderRadius:5, borderStyle:"solid"}}>
                    <span style={{position:"absolute", top:-20, left:20,color:"#16a068", fontSize:"16pt", backgroundColor:"#f2f2f2", borderRadius:20, padding:"2px 20px 5px 20px"}}>Available Payment Methods</span>
                    <section style={{position:"absolute", margin:"50px 50px 50px 50px", textAlign:"left"}}>
                        <input onChange={getSelectedPayMethod('Paypal')} type="checkbox" id="vehicle1" name="vehicle1" value="Paypal"/><label for="vehicle1"> Paypal</label><br/><br/>
                        <input onChange={getSelectedPayMethod('TransferWise')} type="checkbox" id="vehicle1" name="vehicle1" value="TransferWise"/><label for="vehicle1"> TransferWise</label><br/><br/>
                        <input onChange={getSelectedPayMethod('Crypto')} type="checkbox" id="vehicle1" name="vehicle1" value="Crypto"/><label for="vehicle1"> Crypto</label><br/><br/>
                    </section>
                    <section style={{position:"absolute", left:290, margin:"50px 50px 50px 50px", textAlign:"left"}}>
                        <input onChange={getSelectedPayMethod('VISA')} type="checkbox" id="vehicle1" name="vehicle1" value="VISA"/><label for="vehicle1"> VISA</label><br/><br/>
                        <input onChange={getSelectedPayMethod('MasterCards')} type="checkbox" id="vehicle1" name="vehicle1" value="MasterCards"/><label for="vehicle1"> MasterCards</label><br/><br/>
                        <input onChange={getSelectedPayMethod('UnionPay')} type="checkbox" id="vehicle1" name="vehicle1" value="UnionPay"/><label for="vehicle1"> UnionPay</label><br/><br/>
                        <input onChange={getSelectedPayMethod('Mir')} type="checkbox" id="vehicle1" name="vehicle1" value="Mir"/><label for="vehicle1"> Mir</label><br/><br/>
                    </section>
                    <section style={{position:"absolute", right:0, margin:"50px 50px 50px 50px", textAlign:"left"}}>
                        <input onChange={getSelectedPayMethod('PalawanPawnshop')} type="checkbox" id="vehicle1" name="vehicle1" value="Palawan Pawnshop"/><label for="vehicle1"> Palawan Pawnshop</label><br/><br/>
                        <input onChange={getSelectedPayMethod('MLhuillier')} type="checkbox" id="vehicle1" name="vehicle1" value="MLhuillier"/><label for="vehicle1"> MLhuillier</label><br/><br/>
                        <input onChange={getSelectedPayMethod('CebuanaLhuillier')} type="checkbox" id="vehicle1" name="vehicle1" value="Cebuana Lhuillier"/><label for="vehicle1"> Cebuana Lhuillier</label><br/><br/>
                        <input onChange={getSelectedPayMethod('GCash')} type="checkbox" id="vehicle1" name="vehicle1" value="GCash"/><label for="vehicle1"> GCash</label><br/><br/>
                        <input onChange={getSelectedPayMethod('PayMaya')} type="checkbox" id="vehicle1" name="vehicle1" value="PayMaya"/><label for="vehicle1"> PayMaya</label><br/><br/>
                    </section>
                </section>
                <button className='guest-update-payment-btn' >
                    UPDATE
                </button>
            </section>
        </Fragment>
    )
}

export const DepositSlots = (props)=>{
    const {uid} = props
    //step 1
    const userId = uid //isAuthenticated() && isAuthenticated().user._id
	// const token = isAuthenticated() && isAuthenticated().token

    //step 2
    const [data, setData] = useState({
            loading:false,
            success:false,
            clientToken: null,
            error:'',
            instance:{},
            address:''
    })
    const getBraintreeClientToken = async(userId)=>{ //, token
        const braintreeSwitcher = (pitch)=>{
            if(pitch===true){
                return BRAINTREE_APIW
            }else{
                return BRAINTREE_APIL
            }
        }
        // console.log(`${BRAINTREE_APIW}`)
        // console.log(`${braintreeSwitcher(pitch)}:${userId}`)

        const pingBraintreeAPI = await fetch(`${braintreeSwitcher(pitch)}${userId}`,{
            method:"GET",
            headers:{
                Accept:"application/json",
                "Content-Type":"application/json"
                
            } //Authorization:`Bearer ${token}`
        })
        const pingReceived = await pingBraintreeAPI.json()
        const {clientToken} = pingReceived
        setData({...data, clientToken:clientToken})
    }

    //step 3
    const getToken = (userId)=>{ //, token
		getBraintreeClientToken(userId) //, token

		// .then(data=>{
		// 	if(data.error){
		// 		setData({...data, error:data.error})
		// 	}else{
		// 		setData({...data, clientToken:data.clientToken})
		// 	}
		// })
		
	}

	useEffect(()=>{
		getToken(userId)//, token
	},[])

    //console.log(data.clientToken)

    // const getTotal = ()=>{
    //     return products.reduce((currentValue, nextValue)=>{
    //         return currentValue + nextValue.count * nextValue.price
    //     })
    // }
    const [brainSuccess, setBrainSuccess] = useState(false)
    const braintreePaySwitcher = (pitch)=>{
        if(pitch===true){
            return UBRAINTREE_PAYPIW
        }else{
            return UBRAINTREE_PAYPIL
        }
    }
    const braintRoleSwitcher = (pitch)=>{
        if(pitch===true){
            return UPDUX_APIW
        }else{
            return UPDUX_APIL
        }
    }
    const braintLQualifySwitcher = (pitch)=>{
        if(pitch===true){
            return GUE_TOCLIW
        }else{
            return GUE_TOCLIL
        }
    }
    const addFirstDespositToDB = (pitch)=>{
        if(pitch===true){
            return "https://salty-inlet-81949.herokuapp.com/api/j5d-client-first-deposit/" //FIRSTDEPO_CW
        }else{
            return "http://localhost:8888/api/j5d-client-first-deposit/" //FIRSTDEPO_CL
        }
    }
    //To DEPOSIT first US$1.00
    console.log("Target API: "+addFirstDespositToDB(pitch))
    const makeFirstDeposit = async(uid)=>{
        const useruid = {userUniqueID:uid}
        try{
            const addFirstAmount = await fetch(addFirstDespositToDB(pitch), { //
                method:"POST",
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(useruid)
            })
            const depositResponse = await addFirstAmount.json()
            const {data} = depositResponse
            if(data.modifiedCount === 1){
                window.location.reload()
            }else{
                console.log("Unsuccessful First Deposit")
            }
        }catch(err){
            console.log(err)
        }
    }
    //To Mark as qualified user to Clienthood
    const addQualifiedRole = async(uid)=>{
        const addLastQualification = await fetch(braintLQualifySwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:uid})
        })
        const qualifyingResponse = await addLastQualification.json()
        const {data} = qualifyingResponse
        if(data){
        }
    }
    //Changing the ROLE guest to client
    const changeGuestRole = async(uid, role)=>{
        const changeRole = await fetch(braintRoleSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({uid:uid, newrole:role})
        })
        const rulingResponse = await changeRole.json()
        const {status} = rulingResponse
        if(status === true){
            console.log("Just changed the Role as Client!")
        }
        addQualifiedRole(uid)
        makeFirstDeposit(uid)
    }

    const payIntoBrain = async(dataPay)=>{
        const payBrain = await fetch(braintreePaySwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(dataPay)
        })
        const brainResponse = await payBrain.json()
        const {data, success} = brainResponse
        
        setBrainSuccess(success)
        console.log(data) //exposing response from Braintree
        console.log("Pay Success: "+success)
        setData({loading:false})
        if(success === true){
            const clientele = 'client'
            changeGuestRole(uid, clientele)
        }
    }
    const payUp = ()=>{
        setData({loading:true})
        let nonce
        let amount
        let getNonce = data.instance.requestPaymentMethod()
        .then(data=>{
            //console.log(data)
            nonce = data.nonce  //nonce contains data to be submitted to braintree
            amount = 1.0
            //pay up into Braintree server
            const dataPayGotten = {paymentMethodNonce:nonce, amount:amount}
            payIntoBrain(dataPayGotten)
            //console.log(nonce)
        })
        .catch((err)=>{
            console.log("Error Paying up: "+err)
            setData({...data, error:err.message})
            setData({loading:false})//new addition
        })
    }
    const ShowError = ()=>(
        <Fragment>
            <section style={{width:"100%", backgroundColor:"red", height:60, color:"white"}}>
                <h1>Error is found!</h1>
            </section>
        </Fragment>
    )
    //authorization:data.clientToken, as part of CARD DropIn Options
    return (
        <Fragment>
            {data.clientToken !== null?
            <section onBlur={()=>setData({...data, error:""})} style={{textAlign:"center"}}>
                {data.error?<ShowError />:''}
                {brainSuccess===false?
                <Fragment>
                <h3>Make Commitment Deposit of $2.00</h3>
                <p style={{color:"#16a068"}}>After the <b>Check Out</b> process you will be deducted $2.00</p> <br/>
                <DropIn options={{
                    authorization:data.clientToken,
                    paypal:{
                        flow:"vault"
                    }
                }} onInstance={instance=>(data.instance=instance)}/>
                <button onClick={payUp} style={{backgroundColor:"#16a068", borderWidth:0, borderRadius:20,width:"100%", padding:"5px 10px 5px 10px"}}>Check Out!</button>
                </Fragment>:
                <section style={{width:"100%", margin:"auto", height:100, backgroundColor:"#16a068", borderRadius:20, paddingTop:20}}>
                    <h2 style={{position:"absolute", left:80, top:-10, color:"white", fontSize:"33pt"}}>Successfully paid!</h2>
                    <button onClick={()=>{setBrainSuccess(false);}} style={{position:"absolute", top:40, right:80, width:230, borderWidth:0, backgroundColor:"white", padding:"10px 30px 10px 30px", color:"#000", borderRadius:20}}>Exit</button>
                </section>}
            </section>:
            <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                <span style={{color:"#16a068"}}>Loading Card Console</span>
            </section>
            }
        </Fragment>
    )
}

export const MessageChannel = ()=>{
    return (
        <Fragment>
            <CounterView/>
        </Fragment>
    )
}

export const OrderDetails = ()=>{
    if(orderen){
        return (
            <Fragment>
                {querriedData()?<CounterOrderView/>:<h1>Refresh your browser...</h1>}
                {/*querryData() - has to be invoked in this file to display data in less-time*/}
            </Fragment>
        )
    }else{
        return (
            <Fragment>
                <h2>Loading data...</h2>
            </Fragment>
        ) 
    }
}

export const NoProfileDetails = ()=>{
    return (
        <Fragment>
            <h1>No Data Available</h1>
        </Fragment>
    )
}

export const TipsToReachClienthood = ()=>{
    const [tipsReminder, setTipsReminder] = useState(true)
    return(
        <Fragment>
            {tipsReminder === false?
            <section onClick={()=>{setTipsReminder(true)}} className="tips-box-space-collapsed" style={{cursor:"pointer"}}>
                <section style={{textAlign:"center"}}>
                    <span style={{color:"#fff"}}>
                        Tips For Clienthood
                    </span>
                </section>
            </section>:
            <section className="tips-box-space-expand">
                <button onClick={()=>{setTipsReminder(false)}} style={{position:"absolute", top:14, right:15, backgroundColor:"#000", color:"#fff", borderWidth:0, padding:"5px 8px 5px 8px", borderRadius:15}}>
                        X
                </button>
                <section style={{paddingTop:20}}>
                    <section style={{textAlign:"center"}}>
                        <span style={{color:"#fff", fontWeight:1000, fontSize:"33pt"}}>
                            Tips To Qualify For Clienthood
                        </span>
                    </section>
                    <section style={{textAlign:"center"}} className='tips-bg-image'>
                        {/* <span style={{color:"#fff"}}>
                            2. Create new password
                        </span> <br/>
                        <span style={{color:"#fff"}}>
                            3. Add your own email
                        </span> <br/>
                        <span style={{color:"#fff"}}>
                            4. Deposit a commitment amount
                        </span> <br/> */}
                    </section>
                </section>
            </section>}
        </Fragment>
    )
}

export const PrivacyReminder = ()=>{
    const [privacyInsights, setPrivacyInsights] = useState(false)
    return(
        <Fragment>
            {privacyInsights === false?
            <section onClick={()=>{setPrivacyInsights(true)}} className="tips-box-space-collapsed" style={{cursor:"pointer"}}>
                <section style={{textAlign:"center"}}>
                    <span style={{color:"#fff"}}>
                        Privacy Insights
                    </span>
                </section>
            </section>:
            <section className="tips-box-space-expand" style={{paddingTop:20}}>
                <button onClick={()=>{setPrivacyInsights(false)}} style={{position:"absolute", top:14, right:15, backgroundColor:"#000", color:"#fff", borderWidth:0, padding:"5px 8px 5px 8px", borderRadius:15}}>
                        X
                </button>
                <section style={{textAlign:"center"}}>
                    <span style={{color:"#fff", fontSize:"18pt", fontWeight:700}}>
                        Privacy Insights
                    </span>
                </section>
                <section style={{textAlign:"left", padding:"20px 50px 20px 50px"}}>
                    <span style={{color:"#fff"}}>
                        <p>
                            1. The trustworthiness of the information you provided depends to you alone. The only piece of information we needed to be legally correct will be your phone number and home address for the delivery of parcel such as printed materials.
                       </p>
                       <p>
                            2. We don't ask your real name. However you can share it at your own free will. As a company we don't have a policy to collect your personal information.
                       </p>
                       <p>
                            3. We don't collect phone number either. This kind of information will only be provided from your part if ever you need something delivered in your doorstep.
                       </p>
                       <p>
                            4. We don't need to know about your gender. We treated everyone with dignity. If you identified yourself to any gender classifications we don't want to be wrong at making communication with you.
                       </p>
                       <p>
                            5. We don't do inferior works. The reason why there is not a need to collect your personal infomation is rooted primarily in our philosohpy of rendering the highest quality services. The veracity of information you provided has nothing to do with our works' quality.
                       </p>
                       <p>
                            6. We don't accept ads placement in our web and mobile applications. That's to say we don't sell your information or your behavior online to the advertisers.
                       </p>
                    </span>
                    <span style={{color:"#fff"}}>
                        <p>
                            7. We do ask for commitment deposit in an amount equivalent to $1.00 or PHP50.00. This is so to really see your intention in dealing with us.
                       </p>
                       <p>
                            8. We do assure you our financial transaction to be secured. Your activity and your financial activity with us are encrypted through SSL or TSL Handshake. Our policy of not collecting vital information from your part is a first step in being secured in the first place.
                       </p>
                       <p>
                            9. We do transact or send printed materials to any parts of the world. We don't descriminate anyone solely on a particular race origins or based on a comtemporary geopolitical tensions.  
                       </p>
                       <p>
                            10. We do allow you to directly communicate with project's service provider. These providers are our very own. You communicate directly to him/her and at the same time you can communicate to our managers. The company however is monitoring every bits of infomation you pass through the chat system to ensure decency of communication is in place.
                       </p>
                       <p>
                            11. We do constantly make a system updates. The integrity of our existence depends on our company-tailored applications. Our competition is well ahead in terms of financial standing. We however try to circumvent this downsides by being ahead in the systems we put up.
                       </p>
                    </span>
                </section>
            </section>}
        </Fragment>
    )
}