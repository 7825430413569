//LIBRARIES
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { proPicView, viewUser, viewOrder, viewClientOrders, messageTabToggle} from '../../../redux/slices/counter'
import {querriedData, getAllUsers} from '../../../redux/querries/userdataquery'
import { Widget } from '@uploadcare/react-widget'
import axios from 'axios'
import fileDownload from 'js-file-download'
import EmojiPicker, { Picker, Emoji, EmojiStyle} from 'emoji-picker-react' 
import Dialog, { DialogProps } from '@mui/material/Dialog'
//COMPONENTS
import {
  GCHATSYS_L, GCHATSYS_W,
  CDISQ_L, CDISQ_W,
  ADUX_ALLAPIL, ADUX_ALLAPIW, 
  UPPHOTOL, UPPHOTOW,
  CLITAC_L, CLITAC_W,
  CLITAC_REL, CLITAC_REW
} from '../../../config'
import {accessSwitch} from '../variables'

//OPERATIONS
//THIS IS A MESSAGE COMPONENT
const ClientProjectActivities = (props)=>{ 
    //PICKER STATE AND METHOD
    const [inputStr, setInputStr] = useState('')
    const [showPicker, setShowPicker] = useState(false)
    const onEmojiClick = (emojiObject)=>{ //event, <- will cause 'undefined' value
      setInputStr(prevInput => prevInput + emojiObject.emoji);
      //setShowPicker(false)
    }

    const {chatWith, ids, userid, orderid} = props
    const history = useHistory()
    const pitch = accessSwitch
    const user = useSelector(state => state.counter.userDetails)
    const photo = useSelector(state => state.counter.profilePic)

    const dispatch = useDispatch()
    
    dispatch(viewUser(), proPicView())
  
    const refreshUserDetails = ()=>{
      dispatch(proPicView())
    }
    //refreshUserDetails()
    const messageTabler = (top)=>{
      dispatch(messageTabToggle(top))
    }
  
    const chattySwitcher = (pitch)=>{
      if(pitch===true){
          return CLITAC_W
      }else{
          return CLITAC_L
      }
    }
    const chaReSwitcher = (pitch)=>{
      if(pitch===true){
          return CLITAC_REW
      }else{
          return CLITAC_REL
      }
    }
    //DEFAULT QUERY
    const [inquiries, setInquiries] = useState([])
    const defaultQueryMessageGetter = async(sing)=>{
      const defaultQRedux = await fetch(chaReSwitcher(pitch), { //chaReSwitcher(pitch)
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(sing)
      })
      const defcon = await defaultQRedux.json()
      const {status, data} = defcon
      const {oid, uid} = ids
      var mast = []
      data.map(item=>{
          if(item.orders){
            mast.push(item.orders)
          }

      })
      //console.log(mast[0])  //<--works
      let gast = []
      try{
        if(mast[0]){
          mast[0].map(oten=>{
            if(oten.orderID === oid){
             gast.push(oten.activity)
            }
          })
        }
      }catch(e){
        console.log(e)
      }
      setInquiries(gast)
      //snapBackToDefault()
    }

    useEffect(()=>{
      defaultQueryMessageGetter({userUniqueID:userid})
    },[])

    const messageTabToggler = (tog)=>{
        dispatch(messageTabToggle(tog))
    }
    const [messageEntry, setMessageEntry] = useState({
      uname:'',
      role:'',
      subrole:'',
      message:''
    })
    const [refreshLoadedList, setRefreshLoadedList] = useState(false)
    const [newChatEntry, setNewChatEntry] = useState([])
  
    //RE-QUERY
    const reconnectReduxAPI = async(sing)=>{
      const reconRedux = await fetch(chaReSwitcher(pitch), { //chaReSwitcher(pitch)
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(sing)
      })
      const frecon = await reconRedux.json()
      const {status, data} = frecon
      //console.log("OID: "+ids.oid)
      //console.log(data[0].orders) //works
      const {oid, uid} = ids
      // //console.log("OID: "+oid)
      let dashlings = data[0].orders
      let dashmag = []
      dashlings.map(item=>{
        if(item.orderID === oid){
          dashmag.push(item.activity)
        }
      })
      setNewChatEntry(dashmag)
    }
    //FIRST QUERY
    const processNewMessage = async()=>{
      const mEntries = {
        uname:user.username, 
        userUniqueID:ids.uid, //<-- client userID
        oid:ids.oid, 
        message:messageEntry.message, 
        role:user.role,
        subrole:user.subrole,
        unread:true,
        attachment:listsImages,
        targetUsername: props.chatNoticeData.empUsername,
        targetEmail: props.chatNoticeData.empemail, //<-- we'll notify employee while he's offline
        targetStatus: props.chatNoticeData.empstatus //<--IN or OUT, offline or online
      }
      //console.log(mEntries)
      const clientMessageQuery = await fetch(chattySwitcher(pitch), { //chattySwitcher(pitch)
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body:JSON.stringify(mEntries)
      })
      const responseGchat = await clientMessageQuery.json()

      const {status, data} = responseGchat
      if(data.modifiedCount>0){
          console.log("Successfull Connection")
          reconnectReduxAPI({userUniqueID:ids.uid}) //--> TO RE-QUERY
          setRefreshLoadedList(true)
          snapBackToDefault()
      }
      else{
        console.log("Something is Wrong!")
      }
      setListsImages([])
      setInputStr('') //clearing textarea of previous messages
      
    }

    const snapBackToDefault = ()=>{
      setRefreshLoadedList(false)
      defaultQueryMessageGetter({userUniqueID:ids.uid})
    }
    useEffect(()=>{
      snapBackToDefault()
    },[])
    //NON-REDUX-BASED CONTENT GENERATED
    //default message listing SHORTENER
    //console.log(newChatEntry[0])
    const brokerage = newChatEntry[0]
    // let display = 5
    // let tailEnder = [];
    // if(newChatEntry.length<5){
    //   let laster = newChatEntry.length
    //   tailEnder = newChatEntry.slice(0, laster)
    // }else{
    //   let laster = newChatEntry.length
    //   tailEnder = newChatEntry.slice(newChatEntry.length-display, laster)
    // }
    const RefreshChatContent = ()=>{
      //console.log(newChatEntry)//<-checked
      return(
        <Fragment>
                {brokerage.length===0?
                <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                  <span style={{color:"#16a068"}}>loading data...</span>
                </section>
                :
                <Fragment>
                  {brokerage.slice(brokerage.length<10?0:brokerage.length-10).map(msg=>{
                  if(msg.length===0){
                    return(
                      <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>data loading...</span>
                      </section>
                    )
                  }else{
                        return(
                          <section style={{position:"relative", top:0, left:0, margin:"auto", width:"80%", marginBottom:0, padding:"10px 20px 10px 20px"}} onMouseOver={snapBackToDefault}>
                            {msg.role !== 'guest'?
                            <section style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            <span style={{color:"#fff", fontWeight:"bold"}}>
                              {msg.uname.charAt(0).toUpperCase()}{msg.uname.charAt(1).toUpperCase()}
                              <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                            </span>
                          </section>
                            :<section style={{position:"absolute", textAlign:"center", top:15, left:0, backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            {/* {msg.photo?
                            <img src={msg.photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:20, width:20}}/>
                            :<span style={{color:"#16a068", fontWeight:"bold"}}>
                            {msg.uname.charAt(0).toUpperCase()}{msg.uname.charAt(1).toUpperCase()}
                            </span>} */}
                            <span style={{position:"absolute", top:30, left:5, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                          </section>}
                            
                            <span style={{position:"relative", left:25, top:-10, color:"#16a068", fontSize:"8pt"}}>{msg.datePostedWithUTC}</span><br/>
                            <span style={{position:"relative", left:25, top:-10, color:"#16a068"}}>{msg.message}</span>
                          </section>
                        )
                  }
                })}
                </Fragment>
              }
        </Fragment>
      )
    }
  
    const getNewMessage = message=>event=>{
      event.preventDefault()
      message = event.target.value //uname:user.username, role:user.role, subrole:'', message:message
      setMessageEntry({...messageEntry, message:message})
    }
    const bakery = inquiries[0]
    const [minimum, setMinimum] = useState(5)
    // let tailInquiry = [];
    const decreaser = ()=>{
      if(minimum===5){
        var limit = 5
        setMinimum(limit)
      }else{
        var limit = minimum-5
        setMinimum(limit)
      }
    }
    const increaser = ()=>{
      if(minimum>=5){
        setMinimum(minimum+5)
      }
      if(minimum === inquiries.length){
        setMinimum(inquiries.length)
      }
    }
    //EMOJI
    const [emoji, setEmoji] = useState(false)
    //UPLOADING PROFILE PHOTO
    const [imagePops, setImagePops] = useState(false)
    const [currentPoppedImg, setCurrentPoppedImg] = useState('')

    const [photoUploadTrigger, setPhotoUploadTrigger] = useState(false)
    const [fileUploaded, setFileUploaded] = useState('')//client's uploaded image url
    const [listsImages, setListsImages] = useState([])
    const [imageData, setImageData] = useState({width:0, height:0})
    const [processUpload, setProcessUpload] = useState(false)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [uploadFailed, setUploadFailed] = useState(false)
    const uploadPhotoSwitcher = (pitch)=>{
        if(pitch===true){
            return UPPHOTOW
        }else{
            return UPPHOTOL
        }
    }
    const pushingImages =()=>{
      setListsImages([...listsImages, fileUploaded])
      console.log(listsImages)
    }
    const uploadProfileImage = async()=>{
        setPhotoUploadTrigger(false)
        setProcessUpload(true)
        const uploadImageQuery = await fetch(uploadPhotoSwitcher(pitch), {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({userUniqueID:userid, profileImage:fileUploaded})
        })
        const uploadGetsContact = await uploadImageQuery.json()
        const {status, data} = uploadGetsContact
        if(data.modifiedCount>0){
            setProcessUpload(false)
            setUploadSuccess(true)
        }else{
            setProcessUpload(false)
            setUploadFailed(true)
        }
    }
    const UploadingProcess = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", left:0, top:0, zIndex:2, width:"100%", height: "100%", backgroundColor:"rgba(0,0,0,0.6)"}}> 
                </section>
                <section onClick = {()=>{setProcessUpload(false)}} style={{position:"fixed", left:0, top:"30%", zIndex:3, margin:"auto", width:"100%", backgroundColor:"rgba(0,0,0,0.0)"}}>
                    <section style={{position:"relative", left:0, top:10, zIndex:3, margin:"auto", width:"25%", height: "90%", borderRadius:5, backgroundColor:"rgba(0,0,0,0.0)", padding:"10px 0px 20px 0px", cursor:"pointer", textAlign:"center",}}>
                            <div class="lds-ripple" style={{position:"relative", color:"#fff", top:12, left:130}} ><div></div><div></div></div> <br/>
                            <span style={{position:"absolute", color:"#fff", top:35, right:85}}>Image Upload</span> <br />
                            <span style={{position:"absolute", color:"#fff", fontSize:"22pt", fontWeight:"bolder", top:50, right:85}}>PROCESSING</span>
                    </section>
                </section>
            </Fragment>
        )
    }
    const SuccessfulUpload = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                </section>
                <section style={{position:"relative", zIndex:2, top:-400, margin:"auto", left:"auto", width:"60%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", margin:"auto", top:20, width:400, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"10px 10px 10px 10px"}}>
                        <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                            <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image Succeeded!</span><br/>
                        </section>
                        <button onClick={()=>{setUploadSuccess(false); window.location.reload()}} style={{position:"absolute", top:10, right:20, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                            EXIT
                        </button>
                    </section>
                    
                </section>
            </Fragment>
        )
    }
    const FailedUpload = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                </section>
                <section style={{position:"relative", zIndex:2, top:-400, margin:"auto", left:"auto", width:"60%", height:0, borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                    <section style={{position:"relative", margin:"auto", top:20, width:400, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"10px 10px 10px 10px"}}>
                        <section style={{position:"relative", margin:"auto", top:0, backgroundColor:"white", padding:"1px 10px 1px 10px"}}>
                            <span style={{fontWeight:"bolder", color:"#16a068"}}>Uploading Image has failed!</span><br/>
                        </section>
                        <button onClick={()=>{setUploadFailed(false)}} style={{position:"absolute", top:10, right:20, backgroundColor:"#16a068", color:"white", padding:"5px 20px 5px 20px", borderWidth:0,borderRadius:5, cursor:"pointer"}}>
                            EXIT
                        </button>
                    </section>
                    
                </section>
            </Fragment>
        )
    }
    const PhotoUploader = ()=>{
        return(
            <Fragment>
                <section style={{position:"fixed", zIndex:2, top:0, left:0, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0,0.6)"}}>
                </section>
                <section style={{position:"relative", zIndex:2, top:-400, margin:"auto", left:"auto", width:"80%", borderRadius:5, paddingBottom:90, backgroundColor:"rgba(255,255,255,1.0)", boxShadow:"0 10px 7px 0 rgba(0, 0, 0, 0.2), 0 10px  7px 0 rg(0, 0, 0, 0.19)"}}>
                    <button onClick={()=>{setPhotoUploadTrigger(false);}} className='deposit-close-btn' style={{position:"position",backgroundColor:"white", color:"#16a068", padding:"16px 20px 16px 20px", right:-55}}>
                        <span style={{fontWeight:"bold"}}>X</span>
                    </button>
                    <section style={{position:"relative", margin:"auto", top:45, width:500, borderWidth:1, borderStyle:"dashed", borderColor:"#d1d1d1", padding:"20px 20px 20px 20px"}}>
                        <section style={{position:"relative", margin:"auto", zIndex:2, top:-32,  padding:"1px 10px 1px 10px"}}>
                            <span style={{fontWeight:"bolder", color:"#16a068", backgroundColor:"white", padding:"0px 10px 0px 10px"}}>Add Reference Image</span><br/>
                        </section>
                        <section style={{position:"relative", top:-10}}>
                            <Widget style={{backgroundColor:"black", top:0}}
                                            publicKey='c1f6acc2c018005bfb0c' 
                                            id='file' 
                                            name='file'
                                            tabs='file url'
                                            previewStep='true'
                                            onDialogClose={(file) => {
                                                console.log('File changed: ', file)
                                                if (file) {
                                                file.progress(info => console.log('File progress: ', info.progress))
                                                file.done(info => console.log('File uploaded: ', info))
                                                }
                                                }}
                                            onChange={(info) => {
                                                setFileUploaded(info.cdnUrl); /*console.log('Upload completed:', info)*/
                                                setImageData({width:info.originalImageInfo.width, height:info.originalImageInfo.height})} 
                                            }
                            />
                        </section>
                        {/* {fileUploaded =='' || imageData.width === 0?
                        <section style={{color:"#16a068", padding:"20px 0px 20px 0px", textAlign:"center"}}>
                            <b>Please upload a 1x1 or 2x2 photo</b>
                        </section>:''
                        }
                        {imageData.width !== imageData.height?
                        <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                            <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                            <b>Image Error! Picture's square area doesn't have equal dimension</b>
                        </section>
                        :''} */}
                        {fileUploaded !==''?
                        <section style={{color:"#16a068", padding:"20px 0px 20px 0px"}}>
                            <console style={{fontSize:"8pt"}} >Image Width: {imageData.width}px, Image Height: {imageData.height}px</console> <br/>
                            <b>You can upload this picture</b>
                        </section>
                        :''}


                        <section>
                            <img src={fileUploaded} id="client_pic" width="500"/>
                        </section>
                        {fileUploaded===''?
                        '':
                        <button className='guest-upload-btn' style={{position:"absolute", top:30}} onClick={(e)=>{
                          e.preventDefault();
                          pushingImages();
                          setPhotoUploadTrigger(false);
                        }} >
                        Add Image To Message
                        </button>}
                    </section>
                    
                </section>
            </Fragment>
        )
    }
    //-->UPLOADPPHOTO ENDS
  const ImagePopper = ()=>{
        const openUrl = ()=>{
          window.open(currentPoppedImg, "_blank")
        }
        const handleDownload = (url, filename) => {
          axios.get(url, {
            responseType: 'blob',
          })
          .then((res) => {
            fileDownload(res.data, filename)
          })
        }
        return(
          <Fragment>
              <section style={{position:"fixed", top:0, left:0, zIndex:11, width:"100%", height:"100%", backgroundColor:"rgba(0,0,0, 0.6)"}}>
              </section>
              <Dialog open={true} maxWidth="xl">
                <section style={{position:"relative", zIndex:12, padding:"20px 20px 20px 20px"}}>
                  <button onClick={()=>{setImagePops(false);}} className='deposit-close-btn' style={{position:"position",backgroundColor:"#16a068",  borderRadius:10, color:"#16a068", padding:"16px 20px 16px 20px", right:22, top:22}}>
                          <span style={{fontWeight:"bold", color:"#fff"}}>CLOSE</span>
                  </button>
                  <button onClick={openUrl} className='deposit-close-btn' style={{position:"position",backgroundColor:"#16a068",  borderRadius:10, color:"#16a068", padding:"16px 20px 16px 20px", right:22, width:150, top:77}}>
                          <span style={{fontWeight:"bold", color:"#fff"}}>OPEN URL</span>
                  </button>
                  <button onClick={()=>{handleDownload(currentPoppedImg, 'j5d-image-download.jpg')}}className='deposit-close-btn' style={{position:"position",backgroundColor:"#16a068",  borderRadius:10, color:"#16a068", padding:"16px 20px 16px 20px", right:22, width:150, top:133}}>
                          <span style={{fontWeight:"bold", color:"#fff"}}>DOWNLOAD</span>
                  </button>
                  <section style={{position:"relative", top:"10%", zIndex:3, margin:"auto", width:"50%", backgroundColor:"#fff", padding:"20px 20px 20px 20px"}}>
                    <img src={currentPoppedImg} style={{position:"relative", top:0, zIndex:3, margin:"auto", width:"100%"}} id="popper_image" />
                  </section>
                </section>
                </Dialog>
              
          </Fragment>
      )
    }
    return (
      <Fragment>
          <section onMouseOver={snapBackToDefault} style={{backgroundColor:"#fff", borderRadius:5}}> {/*onMouseOver={snapBackToDefault} */}
            <section style={{position:"relative", width:"100%", padding:"30px 0px 30px 0px", top:-20, height:50, backgroundColor:"#fff", marginBottom:30, textAlign:"right"}}>
                  <h3 style={{position:"absolute", top:10, left:35, top:-10, color:"#16a068", fontSize:"10pt"}}>Project Activity</h3>
                  <span style={{position:"absolute", right:112, top:0, fontSize:"12pt", color:"#16a068"}} >View by</span>
                  <section style={{position:"absolute", right:30, top:0}}>
                    <button onClick={decreaser} className='incredement-btn'>
                      <svg style={{color:"#fff", width:13}}xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                      </svg>
                    </button>
                    <button style={{position:"relative", width:30, borderWidth:0, backgroundColor:"white", color:"#16a068", padding:"0px 5px 0px 5px"}}>
                      {minimum}
                    </button>
                    <button onClick={increaser} className='incredement-btn'>
                      <svg style={{color:"#fff", width:13}}xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </section>
            </section>
            <section style={{position:"relative", top:-70, width:"100%", margin:"auto"}}>
              {!bakery?
              <section onLoad={{}} style={{position:"relative", top:0, margin:"auto", textAlign:"center", width:"100%"}}>
                <span style={{position:"relative", width:"90%", margin:"auto", fontSize:"11pt", color:"#16a068",  padding:"50px 20px 20px 20px"}}>Mouse over to load data...</span>
              </section>
              :''
              }
              {refreshLoadedList===true?
              <Fragment>
                {/* <RefreshChatContent /> */}
              </Fragment>
              :<Fragment>
                {inquiries[0]?
                bakery.slice(bakery.length<minimum?0:bakery.length-minimum).map(msg=>{
                  if(msg.length===0){
                    return(
                      <section style={{position:"relative", zIndex:3, margin:"auto", top:"40%", backgroundColor:"rgba(255,255,255)", textAlign:"center", padding:"20px 20px 20px 20px"}}>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>  <br/>
                        <span style={{color:"#16a068"}}>data loading...</span>
                      </section>
                    )
                  }else{
                    return(
                      <section className='client-activity-entry' style={{position:"relative", top:0, left:10, borderRadius:10, margin:"auto", width:"90%", marginBottom:20, padding:"10px 20px 50px 20px"}}>
                        <section>
                          {msg.role !== 'client'?
                          <section style={{position:"absolute", top:17, left:10, textAlign:"center", backgroundColor:"#16a068", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            <span style={{color:"#fff", fontWeight:"bold"}}>{msg.uname.charAt(0).toUpperCase()}{msg.uname.charAt(1).toUpperCase()}</span>
                            <span style={{position:"absolute", top:17, left:45, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                            <span style={{position:"absolute", top:0, left:45, color:"#16a068", fontWeight:"bold"}}>{msg.uname}</span>
                          </section>
                          :<section style={{position:"absolute", top:17, left:10, textAlign:"center", backgroundColor:"#d1d1d1", width:20, height:20, borderRadius:"50%",  padding:"5px 5px 5px 5px",}}>
                            {msg.photo?
                            <img src={msg.photo} style={{position:"absolute", left:0, top:0, borderRadius:"50%", height:20, width:20}}/>
                            :<span style={{color:"#16a068", fontWeight:"bold"}}>{msg.uname.charAt(0).toUpperCase()}{msg.uname.charAt(1).toUpperCase()}</span>} {/**/}
                            <span style={{position:"absolute", top:17, left:45, color:"#16a068", fontSize:"6pt"}}>{msg.role}</span><br/>
                            <span style={{position:"absolute", top:0, left:45, color:"#16a068", fontWeight:"bold"}}>{msg.uname}</span>
                          </section>
                          }
                            <span style={{position:"absolute", top:22, right:25, color:"#16a068", fontSize:"7pt"}}><b>Posted on </b>{msg.datePostedWithUTC}</span><br/>
                        </section>
                        <section style={{position:"relative",width:"90%", left:25, top:30, borderRadius:5, borderColor:"#16a068", borderStyle:"solid", backgroundColor:"#fff", borderWidth:1, padding:"20px 30px 20px 10px"}}>
                          <span style={{position:"relative", left:25, top:0, textIndent:20, color:"#16a068"}}>{msg.message}</span><br/>
                        </section> 
                        {!msg.attachment?
                        <Fragment></Fragment>:
                        <section style={{position:"relative", top:50, marginTop:10}}>
                          {msg.attachment.length === 0? 
                          '':
                          <Fragment>
                            <span style={{position:"absolute", left:40, top:-20, color:"#16a068", fontSize:"10pt"}} >Attachment(s)</span>
                            <ul>
                              {msg.attachment.map(imgs=>{
                                return(
                                  <li style={{display:"inline-block", paddingRight:2}}>
                                    <img id="pop_img" className='attached-img' onClick={()=>{setImagePops(true); setCurrentPoppedImg(imgs); }} src={imgs} height="50" style={{borderColor:"#d1d1d1", borderStyle:"solid", borderWidth:1}}/>
                                  </li>
                                )
                              })}
                            </ul>
                          </Fragment>
                          }
                        </section> 
                        }
                      </section>
                    )
                  }
                })
                :''
              }
                </Fragment>}
  
            </section>
            {/*THIS IS A CHAT ENTRY FIELD*/}
            {props.eol==="active"?
              <section style={{position:"relative", width:"100%", bottom:30, marginBottom:20}}>
              <section style={{position:"relative", top:45, width:80, height:80, borderRadius:"50%", textAlign:"center"}}>
                {photo?
                <img onLoad={snapBackToDefault} alt={user.username} src={photo} style={{position:"absolute", zIndex:0,  left:0, top:23, borderRadius:"50%", height:80, width:80, borderWidth:2, borderStyle:"solid", borderColor:"#fff"}}/>:
                <b style={{position:"absolute", top:10, left:10, fontSize:"26pt"}}>{user.username.charAt(0).toUpperCase()}{user.username.charAt(1).toUpperCase()}</b>
                }
                <div style={{position:"relative", top:110}}>
                    {user.username.length>10?<span style={{fontWeight:700, color:"#16a068", fontSize:"10pt"}}>{user.username}<br/></span>:<span style={{fontWeight:700, color:"#16a068"}}>{user.username}<br/></span>}
                    <span style={{fontSize:"10pt", color:"#16a068"}}>{user.role}</span>
                </div>
              </section> 
              <section style={{position:"relative", left:100, top:-10,  borderColor:"#16a068", bottom:0, width:"83%", borderRadius:5, borderWidth:1, borderStyle:"solid"}}>
                <textarea value={inputStr} onChange={(e)=>{setInputStr(e.target.value)}} id='guest-message' onMouseLeave={getNewMessage('message')} onMouseDown={()=>{setShowPicker(true)}} style={{position:"relative", backgroundColor:"#fff", top:5, left:10, width:"92%", height:70, marginBottom:10, borderColor:"#fff", borderStyle:"solid", lineHeight: 1, borderWidth:0, borderRadius:5, padding:"10px 10px 10px 10px", color:"#16a068", fontFamily:"Baloo", fontSize:"11pt" }} type="text" row="20"></textarea>  
                
                {fileUploaded?
                 <section style={{position:"relative", top:0, marginLeft:15, marginBottom:-17}}>
                    {listsImages.length===0?
                    ''
                    :<ul style={{left:0}}>
                      {listsImages.map(load =>{
                        return(
                          <li style={{display:"inline-block"}}>
                            <img src={load} height="50" style={{borderColor:"#d1d1d1", borderStyle:"solid", borderWidth:1}}/>
                          </li>
                        )
                      })}
                      <li style={{display:"inline-block"}}>
                        <section onClick={()=>{setPhotoUploadTrigger(true);}} alt="add photo" style={{position:"absolute", top:0, cursor:"pointer", borderColor:"#d1d1d1", borderStyle:"solid", height:50, width:70, borderWidth:1, verticalAlign:"middle", textAlign:"center"}}>
                            <spa style={{fontSize:"22pt", color:"#16a068"}}>
                              +
                            </spa>
                        </section>
                      </li>
                    </ul>}
                 </section>:
                ''}
                <section style={{position:"relative", right:100, top:50}}>
                  <button style={{position:"absolute", top:0, right:100, width:40, height:30, borderWidth:1, borderRadius:5, borderStyle:"solid", borderColor:"#d1d1d1", backgroundColor:"#fff", cursor:"pointer" }}>
                    <svg style={{color:"#16a068", cursor:"pointer"}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                    </svg>
                  </button>
                  <button onClick={()=>setShowPicker(val=>!val)} style={{position:"absolute", top:0, right:50, width:40, height:30, borderWidth:1, borderRadius:5, borderStyle:"solid", borderColor:"#d1d1d1", backgroundColor:"#fff", cursor:"pointer" }}>
                    <svg style={{color:"#16a068", cursor:"pointer"}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </button>
                  <button onClick={()=>{setPhotoUploadTrigger(true); setShowPicker(false)}} style={{position:"absolute", top:0, right:0, width:40, height:30, borderWidth:1, borderRadius:5, borderStyle:"solid", borderColor:"#d1d1d1", backgroundColor:"#fff", cursor:"pointer" }}>
                    <svg style={{color:"#16a068", cursor:"pointer"}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                    </svg>
                  </button>
                  
                </section>
                {!messageEntry.message?
                <button onMouseOver={()=>{setShowPicker(false)}} style={{position:"relative", top:50, left:566, height:30, padding:"5px 20px 5px 20px", backgroundColor:"#c1c1c1", color:"white"}} className='guest-chat-btn'>
                  Send
                </button>
                :<button onMouseOver={()=>{setShowPicker(false)}} style={{position:"relative", top:50, left:566, height:30, padding:"5px 20px 5px 20px"}} className='guest-chat-btn' onClick={(e)=>{
                  e.preventDefault();
                  setRefreshLoadedList(false);
                  document.getElementById("guest-message").value="";
                  processNewMessage();
                  //refreshUserDetails()
                  }}>
                  Send
                </button>}
                {showPicker?
                <Fragment>
                  <section style={{position:"absolute", top:-200, left:-370, zIndex:11}}>
                    <EmojiPicker 
                    pickerStyle={{width:'100%'}} 
                    onEmojiClick={onEmojiClick}
                    />
                  </section>
                </Fragment>
              :''}
              </section>
              {!messageEntry.message?
              <span style={{position:"relative", top:0, left:100, color:"#16a068", fontSize:"8pt"}}>The field is empty</span>
              :''}
            </section>
            :''}
            
          </section>
              {photoUploadTrigger === true? <PhotoUploader />:''}
              {processUpload === true?<UploadingProcess />:''}
              {uploadSuccess === true?<SuccessfulUpload />:''}
              {uploadFailed === true?<FailedUpload />:''}
              {imagePops === true?<ImagePopper />:''}
      </Fragment>
    )
  }

  export default ClientProjectActivities