import React, {Fragment} from 'react'
import DeskHeader from './deskHeader'
import DeskMainContent from './deskContentHome'
import DeskFooter from './deskFooter'
import DeskLanding from './deskLanding'

//COMPONENTS
import { accessSwitch } from './variables'
import { 
    SDAPIL, 
    SDAPIW,
    OAPIL,
    OAPIW 
} from '../../config'
//<-
import * as THREE from 'three'

const scene = new THREE.Scene()
const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 )

const renderer = new THREE.WebGLRenderer()
renderer.setSize( window.innerWidth, window.innerHeight )
//document.body.appendChild( renderer.domElement )

const geometry = new THREE.BoxGeometry( 1, 1, 1 )
const material = new THREE.MeshBasicMaterial( { color: 0x16a068 } )
const cube = new THREE.Mesh( geometry, material )
scene.add( cube )

camera.position.z = 5

function animate() {
	requestAnimationFrame(animate)
	cube.rotation.x += 0.01
	cube.rotation.y += 0.01
	renderer.render( scene, camera )
}

//->
const pitch = accessSwitch //true to Web-based MongoDB
const switcher =(bol)=>{
      if(bol===true){
          return SDAPIW
      }else{
          return SDAPIL
      }
}
const servicesPingAPI = ()=>{
    fetch(switcher(pitch)) 
    .then((responder)=>responder.json())
    .then((data)=>{
        const duga = data.data
        const doughnut = []
        for(const key in duga){
            doughnut.push({
                id:key,
                title: duga[key].title,
                price: duga[key].price,
                description: duga[key].description,
                imageLarge: duga[key].imageLarge,
                tags:duga[key].tags
            })
        }
    })
}
//triggering the servicers data load
const triggeredNow = ()=>{
    if(servicesPingAPI()){
        console.log("triggered API")
    }else{
        console.log("API not triggered")
    }
}
triggeredNow()
let DeskHomePage = ()=>{
    return(
        <Fragment>
            <div id="MAIN-BODY">
                <DeskHeader />
                <DeskLanding />
                <DeskMainContent />
                <DeskFooter />
            </div>
        </Fragment>
    )
}

export default DeskHomePage;