import react, {Fragment} from 'react'
import { useHistory } from 'react-router'

const MobileHLanding = ()=>{
    const historian = useHistory()
    return(
        <Fragment>
            <section className="mobile-features">
                    <img style={{borderRadius:30}}width="100%" src = "../animated/mobile_landing_feature_1.svg"/>
                    <section style={{position:"absolute", top:185, margin:"auto", left:"auto", width:"100%"}}>
                        <h1 style={{position:"relative", top:20, margin:"auto", color:"white", fontSize:"33pt", fontFamily:"Segoe UI", fontWeight:600}}>YOU ORDER</h1>
                        <h1 style={{position:"relative", top:0, margin:"auto", color:"white", fontSize:"33pt", fontFamily:"Segoe UI", fontWeight:600}}>WE DELIVER</h1>
                        <h1 style={{position:"relative", left:0, top:-15, margin:"auto", color:"white", fontSize:"18pt", fontFamily:"Segoe UI", fontWeight:400}}>TO ANY POINTS IN THE</h1>
                        <h1 style={{position:"relative", top:-30, margin:"auto", color:"white", fontSize:"33pt", fontFamily:"Segoe UI", fontWeight:600}}>PHILIPPINES</h1>
                        <section style={{position:"relative", bottom:20, margin:"auto", height:50}}>
                            <button style={{position:"relative", backgroundColor:"black", color:"white", width:"80%",  padding:"10px 20px 10px 20px", margin:"auto", left:"auto", borderRadius:30, borderWidth:0, fontFamily:"Segoe UI", fontWeight:"500", fontSize:"22pt", cursor:"pointer"}} onClick={(e)=>{e.preventDefault(); historian.push('/faqs')}}>
                            LEARN MORE
                            </button>
                        </section>
                    </section>
            </section>
        </Fragment>        
    )
}

export default MobileHLanding